import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid, TextField, FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core'

// Components
import {
  CPFMaskCustom,
  CNPJMaskCustom,
  CelularMaskCustom,
  TelefoneMaskCustom,
} from 'components/Inputs/Masked'

// Styles
import useStyles from './styles'

// Stateless
const ClientesFormComp = ({ data, onChange }) => {
  const {
    tipoPessoa = '',
    nome = '',
    cgc = '',
    rg = '',
    nascimentoDia = '',
    nascimentoMes = '',
    nascimentoAno = '',
    email = '',
    celular = '',
    telefone = '',
    endereco = '',
    observacao = '',
    status = '',
  } = data
  const classes = useStyles()
  const inputLabel = useRef(null)
  const inputLabelTP = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  const [labelWidthTP, setLabelWidthTP] = useState(0)
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
    setLabelWidthTP(inputLabelTP.current.offsetWidth)
  }, [])
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={4}>
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
          className={classes.dataControl}
        >
          <InputLabel ref={inputLabelTP}>Tipo de Pessoa</InputLabel>
          <Select
            id="clientes-tipoPessoa"
            value={tipoPessoa}
            onChange={(evt) => onChange({ tipoPessoa: evt.target.value.toUpperCase() })}
            labelWidth={labelWidthTP}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="PF">P. FÍSICA</MenuItem>
            <MenuItem value="PJ">P. JURÍDICA</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          fullWidth
          id="clientes-nome"
          label="Nome"
          margin="normal"
          variant="outlined"
          value={nome}
          onChange={(evt) => onChange({ nome: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          InputProps={{
            inputComponent: (!tipoPessoa || tipoPessoa === 'PF') ? CPFMaskCustom : CNPJMaskCustom,
          }}
          id="clientes-cgc"
          label="CGC"
          margin="normal"
          variant="outlined"
          value={cgc}
          onChange={(evt) => onChange({ cgc: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          id="clientes-rg"
          label="RG"
          margin="normal"
          variant="outlined"
          value={rg}
          onChange={(evt) => onChange({ rg: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          fullWidth
          id="clientes-nascimentoDia"
          label="Dia Nasc."
          margin="normal"
          variant="outlined"
          type="number"
          inputProps={{
            maxlength: 2,
          }}
          value={nascimentoDia}
          onChange={(evt) => onChange({ nascimentoDia: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          fullWidth
          id="clientes-nascimentoMes"
          label="Mês Nasc."
          margin="normal"
          variant="outlined"
          type="number"
          inputProps={{
            maxlength: 2,
          }}
          value={nascimentoMes}
          onChange={(evt) => onChange({ nascimentoMes: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          fullWidth
          id="clientes-nascimentoAno"
          label="Ano Nasc."
          margin="normal"
          variant="outlined"
          type="number"
          InputProps={{
            maxlength: 4,
          }}
          value={nascimentoAno}
          onChange={(evt) => onChange({ nascimentoAno: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="clientes-email"
          label="E-mail"
          margin="normal"
          variant="outlined"
          value={email}
          onChange={(evt) => onChange({ email: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          InputProps={{
            inputComponent: CelularMaskCustom,
          }}
          id="clientes-celular"
          label="Celular"
          margin="normal"
          variant="outlined"
          value={celular}
          onChange={(evt) => onChange({ celular: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          InputProps={{
            inputComponent: TelefoneMaskCustom,
          }}
          id="clientes-telefone"
          label="Telefone"
          margin="normal"
          variant="outlined"
          value={telefone}
          onChange={(evt) => onChange({ telefone: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          multiline
          rowsMax="4"
          id="clientes-endereco"
          label="Endereço"
          margin="normal"
          variant="outlined"
          value={endereco}
          onChange={(evt) => onChange({ endereco: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          fullWidth
          multiline
          rowsMax="4"
          id="clientes-observacao"
          label="Observação"
          margin="normal"
          variant="outlined"
          value={observacao}
          onChange={(evt) => onChange({ observacao: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
          className={classes.dataControl}
        >
          <InputLabel ref={inputLabel}>Status</InputLabel>
          <Select
            id="clientes-status"
            value={status}
            onChange={(evt) => onChange({ status: evt.target.value.toUpperCase() })}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="L">LIBERADO</MenuItem>
            <MenuItem value="B">BLOQUEADO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

ClientesFormComp.propTypes = {
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ClientesFormComp
