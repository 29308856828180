import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, Icon,
} from '@material-ui/core'
// import _ from 'lodash'

import { parseToDec } from 'utils'

// Styles
import useStyles from './styles'

// Stateless
const ProdutosFormComp = ({ data, onSave }) => {
  const classes = useStyles()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  const [formData, setFormData] = useState(data)
  const {
    // id,
    ref,
    sku,
    ncm,
    um,
    preco,
    precoPromo,
    peso,
    qtdMin,
    descricao,
    status,
  } = formData

  const onChange = (value) => setFormData((s) => ({ ...s, ...value }))

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="servicos-descricao"
          label="Descrição:"
          margin="normal"
          variant="outlined"
          value={descricao}
          onChange={(evt) => onChange({ descricao: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          id="ref"
          label="REF:"
          margin="normal"
          variant="outlined"
          value={ref}
          onChange={(evt) => onChange({ ref: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          id="sku"
          label="SKU:"
          margin="normal"
          variant="outlined"
          value={sku}
          onChange={(evt) => onChange({ sku: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          id="ncm"
          label="NCM:"
          margin="normal"
          variant="outlined"
          value={ncm}
          onChange={(evt) => onChange({ ncm: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          id="um"
          label="U.M:"
          margin="normal"
          variant="outlined"
          value={um}
          onChange={(evt) => onChange({ um: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          id="preco"
          // type="number"
          label="PREÇO:"
          margin="normal"
          variant="outlined"
          value={preco}
          onChange={(evt) => onChange({ preco: evt?.target?.value ?? '' })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          id="precoPromo"
          // type="number"
          label="PREÇO PROMO:"
          margin="normal"
          variant="outlined"
          value={precoPromo}
          onChange={(evt) => onChange({ precoPromo: evt?.target?.value ?? '' })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          id="peso"
          label="PESO:"
          margin="normal"
          variant="outlined"
          value={peso}
          onChange={(evt) => onChange({ peso: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          id="qtdMin"
          label="Qtd.Min.:"
          margin="normal"
          variant="outlined"
          value={qtdMin}
          onChange={(evt) => onChange({ qtdMin: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl required variant="outlined" fullWidth margin="normal" className={classes.dataControl}>
          <InputLabel ref={inputLabel}>
            Status
          </InputLabel>
          <Select
            id="servicos-status"
            value={status}
            onChange={(evt) => onChange({ status: evt.target.value.toUpperCase() })}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="P">PENDENTE</MenuItem>
            <MenuItem value="L">LIBERADO</MenuItem>
            <MenuItem value="B">BLOQUEADO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.ctnActions}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon>save</Icon>}
            onClick={() => onSave({
              ...formData,
              preco: parseToDec(formData.preco),
              precoPromo: parseToDec(formData.precoPromo),
            })}
          >
            SALVAR
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

ProdutosFormComp.propTypes = {
  data: PropTypes.shape().isRequired,
  onSave: PropTypes.func.isRequired,
}

export default ProdutosFormComp
