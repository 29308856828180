import _ from 'lodash'
import { NotificationManager } from 'react-notifications'
import {
  listPagamentosCondicaoApi,
  getPagamentosCondicaoApi,
  addPagamentosCondicaoApi,
  editPagamentosCondicaoApi,
  deletePagamentosCondicaoApi,
} from 'api/pagamentos/condicao'

// State
const initialState = {
  loading: {
    list: false,
    find: false,
    add: false,
    edit: false,
    del: false,
  },
  list: { page: 0, rows: 0, data: [] },
  form: {
    id: 0,
    pagamentosForma: {},
    descricao: '',
    taxa: '',
    status: '',
    statusDescricao: '',
  },
}

// Actions Types
export const Types = {
  RESET: 'reset',
  PAGAMENTOS_CONDICAO_LOADING: 'pagamentosCondicao/LOADING',
  PAGAMENTOS_CONDICAO_LIST_LOAD: 'pagamentosCondicao/LIST_LOAD',
  PAGAMENTOS_CONDICAO_LIST_UPDATE: 'pagamentosCondicao/LIST_UPDATE',
  PAGAMENTOS_CONDICAO_LIST_CLEAN: 'pagamentosCondicao/LIST_CLEAN',
  PAGAMENTOS_CONDICAO_FORM_UPDATE: 'pagamentosCondicao/FORM_UPDATE',
}

// Reducers
export default function pagamentosCondicaoReducers(state = initialState, action) {
  switch (action.type) {
  // RESET DEFAULT
  case Types.RESET:
    return {
      ...state,
      ...initialState,
    }
  case Types.PAGAMENTOS_CONDICAO_LOADING:
    return {
      ...state,
      loading: { ...state.loading, ...action.payload },
    }
  case Types.PAGAMENTOS_CONDICAO_LIST_LOAD:
    return {
      ...state,
      list: action.payload,
    }
  case Types.PAGAMENTOS_CONDICAO_LIST_UPDATE:
    return {
      ...state,
      list: state.list.map((i) => (i.id === action.payload.id ? { ...i, ...action.payload } : i)),
    }
  case Types.PAGAMENTOS_CONDICAO_LIST_CLEAN:
    return {
      ...state,
      list: initialState.list,
    }
  case Types.PAGAMENTOS_CONDICAO_FORM_UPDATE:
    return {
      ...state,
      form: { ...state.form, ...action.payload },
    }
  default:
    return state
  }
}

// Actions Creators
export const Actions = {
  // Store Update
  onPagamentosCondicaoLoading: (payload) => ({ type: Types.PAGAMENTOS_CONDICAO_LOADING, payload }),
  onPagamentosCondicaoListLoad: (payload) => ({
    type: Types.PAGAMENTOS_CONDICAO_LIST_LOAD, payload,
  }),
  onPagamentosCondicaoListUpdate: (payload) => ({
    type: Types.PAGAMENTOS_CONDICAO_LIST_UPDATE, payload,
  }),
  onPagamentosCondicaoListClean: () => ({ type: Types.PAGAMENTOS_CONDICAO_LIST_CLEAN }),
  onPagamentosCondicaoFormUpdate: (payload) => ({
    type: Types.PAGAMENTOS_CONDICAO_FORM_UPDATE, payload,
  }),
  onPagamentosCondicaoFormClean: () => ({
    type: Types.PAGAMENTOS_CONDICAO_FORM_UPDATE,
    payload: initialState.form,
  }),
  onPagamentosCondicaoDataById: (id) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onPagamentosCondicaoLoading({ find: true }))
    // Chamada API
    getPagamentosCondicaoApi({ token, id }).then((payload) => {
      dispatch(Actions.onPagamentosCondicaoLoading({ find: false }))
      dispatch(Actions.onPagamentosCondicaoFormUpdate(
        _.isObject(payload)
          ? payload
          : initialState.form,
      ))
    })
  },
  onPagamentosCondicaoDataList: (filter) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onPagamentosCondicaoLoading({ list: true }))
    // Chamada API
    listPagamentosCondicaoApi({ token, filter }).then((payload) => {
      dispatch(Actions.onPagamentosCondicaoLoading({ list: false }))
      dispatch(Actions.onPagamentosCondicaoListLoad(payload || initialState.list))
    })
  },
  onPagamentosCondicaoDataSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    // Chamada API
    if (dto.id) {
      dispatch(Actions.onPagamentosCondicaoLoading({ edit: true }))
      editPagamentosCondicaoApi({ token, dto, id: dto.id }).then((payload) => {
        dispatch(Actions.onPagamentosCondicaoLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success(`Forma de pagamento ${dto.descricao} atualizado com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    } else {
      dispatch(Actions.onPagamentosCondicaoLoading({ add: true }))
      addPagamentosCondicaoApi({ token, dto }).then((payload) => {
        dispatch(Actions.onPagamentosCondicaoLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success(`Forma de pagamento ${dto.descricao} cadastrado com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    }
  },
  onPagamentosCondicaoDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onPagamentosCondicaoLoading({ del: true }))
    // Chamada API
    deletePagamentosCondicaoApi({ token, id }).then((payload) => {
      dispatch(Actions.onPagamentosCondicaoLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Forma de pagamento excluído com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    })
  },
}
