import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid, Tooltip, Chip } from '@material-ui/core'

// * Actions
import { Actions as OrcamentosActions } from 'store/ducks/orcamentos'
import { Actions as ClientesActions } from 'store/ducks/clientes'

// * Api's
import { updateStatusOrcamentosApi } from 'api/orcamentos'

// * Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import TableDataComp from 'components/Table'
import DialogComp from 'components/Dialogs'
import OrcamentosFormComp from '../../componentes/OrcamentosForm'
import ClientesBasicFormComp from 'pages/clientes/components/ClientesBasicForm'

// * Util's
import { parseToNum, MoneyFormatBr, DateIsoToBr } from 'utils'
import { format } from 'date-fns'

const OrcamentosListPage = ({
  session,
  orcamentos,
  clientes,
  onOrcamentosDataList,
  onOrcamentosFormUpdate,
  onOrcamentosFormClean,
  onOrcamentosDataSave,
  onOrcamentosDataDelete,
  onClientesDataById,
  onClientesFormUpdate,
  onClientesFormClean,
  onClientesDataSave,
}) => {
  // * States
  //#region
  const [search, setSearch] = useState('')
  const [loadingUpdateStatus, setLoadingUpdateStatus] = useState(false)
  const [dialogForm, setDialogForm] = useState({
    open: false,
    title: '',
    id: 0,
    isEmergencial: false,
  })
  const [dialogAddClientes, setDialogAddClientes] = useState({
    open: false,
    title: '',
  })
  const [dialogAskUpdateStatus, setDialogAskUpdateStatus] = useState({
    open: false,
    title: '',
    data: null,
  })
  //#endregion

  // * Header Actions
  //#region
  const handleFilterOrcamentos = filter => {
    onOrcamentosDataList({
      ...filter,
      filiaisId: session?.user?.filial ?? 0,
      usuariosId: session?.user?.id ?? 0,
    })
  }
  const handleSearch = () => {
    handleFilterOrcamentos({ search })
  }
  const handleOpenCloseDialogForm = (open, title = null, id = 0, isEmergencial = false) => {
    if (title) {
      setDialogForm(s => ({ ...s, open, title, id, isEmergencial }))
    } else {
      setDialogForm(s => ({ ...s, open, id, isEmergencial }))
    }
  }
  const handleOpenCloseDialogAskUpdateStatus = (
    open,
    title = null,
    data = null
  ) => {
    setDialogAskUpdateStatus(s => ({
      ...s,
      open,
      title,
      data,
    }))
  }
  const handleOpenCloseDialogClientes = (open, title = null) => {
    if (title) {
      setDialogAddClientes(s => ({ ...s, open, title }))
    } else {
      setDialogAddClientes(s => ({ ...s, open }))
    }
  }
  const handleAddOrcamentos = (isEmergencial = false) => {
    onOrcamentosFormClean()
    const filiaisCurrent = session?.user?.filial ?? 0
    if (filiaisCurrent) {
      onOrcamentosFormUpdate({
        filiaisId: session?.user?.filial ?? 0,
        usuariosId: session?.user?.id ?? 0,
      })
      handleOpenCloseDialogForm(true, 'Novo Orçamento:', 0, isEmergencial)
    } else {
      NotificationManager.warning('Filial não identificada', 'Atenção!', 8000)
    }
  }
  const handleUpdateStatusOrcamentos = async (id, status) => {
    try {
      setLoadingUpdateStatus(true)
      const respHttp = await updateStatusOrcamentosApi({
        token: session?.token ?? '',
        id,
        status,
      })
      if (respHttp && respHttp?.code == 'status-updated') {
        NotificationManager.success(respHttp.entity, 'Sucesso!', 8000)
      }
    } catch (e) {
      console.log('Error on update status from orcamento id: ', id)
    } finally {
      setLoadingUpdateStatus(false)
    }
  }

  const headerButtons = [
    {
      title: 'Atualizar',
      icon: 'sync',
      action: () => handleFilterOrcamentos({ search }),
    },
    /*
    {
      title: 'Adicionar',
      icon: 'add',
      action: () => handleAddOrcamentos(false),
    },
    */
    {
      title: 'Adicionar',
      icon: 'add',
      action: () => handleAddOrcamentos(true),
    },
  ]
  //#endregion

  // * Table Cols/Actions
  //#region
  const tableCols = [
    {
      key: '',
      title: 'ID/O.S',
      format: v => `${v.id}${v.ordensServicoId ? `/${v.ordensServicoId}` : ''}`,
    },
    {
      key: '',
      title: 'CLIENTE',
      format: v => `${v?.clientesId ?? ''}-${v?.clientesNome ?? ''}`,
    },
    {
      key: '',
      title: 'ATENDENTE',
      format: v => `${v?.vendedorId ?? ''}-${v?.vendedorNome ?? ''}`,
    },
    {
      key: '',
      title: 'T.BRUTO - DESCONTO = T.LIQUIDO',
      format: v => (
        <>
          <span>{`R$ ${MoneyFormatBr(
            parseToNum(v?.totalBruto)
          )} - R$ ${MoneyFormatBr(parseToNum(v?.descontoValor))}`}</span>
          <br />
          <strong>{`=R$ ${MoneyFormatBr(parseToNum(v?.totalLiquido))}`}</strong>
        </>
      ),
    },
    {
      key: '',
      title: 'DT.CRIAÇÃO/PRAZO',
      format: v => (
        <>
          <span>{DateIsoToBr(v?.dataCriacao)}</span>
          <br />
          <strong>{DateIsoToBr(v?.dataPrazo)}</strong>
        </>
      ),
    },
    {
      key: '',
      title: 'ITEM(NS)',
      format: v => `${v?.itens.length ?? 0} item(ns)`,
    },
    {
      key: '',
      title: 'STATUS',
      format: v => {
        switch (v?.status) {
          case 'CA':
            return (
              <Tooltip
                title={`DATA: ${DateIsoToBr(
                  v?.dataCancelamento
                )}, MOTIVO: ${v.motivoCancelamento ?? 'NENHUM MOTIVO'}`}
              >
                <Chip
                  color="secondary"
                  size="small"
                  label={v.statusDescricao ?? ''}
                ></Chip>
              </Tooltip>
            )
          case 'AP':
            return (
              <Tooltip
                title={`DATA APROVAÇÃO: ${DateIsoToBr(
                  v?.dataAprovacao
                )}, O.S Nº: ${v.ordensServicoId ?? ''}`}
              >
                <Chip
                  color="primary"
                  size="small"
                  label={v.statusDescricao ?? ''}
                ></Chip>
              </Tooltip>
            )
          default:
            return <Chip size="small" label={v.statusDescricao ?? ''}></Chip>
        }
      },
    },
  ]
  const tableActions = [
    {
      key: 'visualizar',
      title: 'Visualizar',
      icon: 'visibility',
      action: row => {
        handleOpenCloseDialogForm(
          true,
          `Visualizar do Orçamento: ${row.id}`,
          row.id
        )
      },
    },
    {
      key: 'atualizar',
      title: 'Atualizar Cliente',
      icon: 'person',
      action: row => {
        onClientesFormClean()
        onClientesDataById(row.clientesId)
        handleOpenCloseDialogClientes(true, 'Edição de Cliente:')
      },
    },
    {
      key: 'aprovar',
      title: 'Aprovar',
      icon: 'thumb_up_alt',
      action: row =>
        handleOpenCloseDialogAskUpdateStatus(true, 'Aprovar?', {
          ...row,
          newStatus: 'AP',
        }),
    },
    {
      key: 'cancel',
      title: 'Cancelar',
      icon: 'cancel',
      action: row =>
        handleOpenCloseDialogAskUpdateStatus(true, 'Cancelar?', {
          ...row,
          newStatus: 'CA',
        }),
    },
  ]
  //#endregion

  useEffect(() => {
    document.title = 'Backoffice - Orçamentos'
    handleFilterOrcamentos({ search })
  }, [session?.user?.filial])

  return (
    <DefaultPage>
      <PageHeaderComp
        title="Orçamentos"
        value={search}
        onChange={evt => setSearch(evt.target.value)}
        onClick={handleSearch}
        onKeyPress={evt => {
          if (evt.charCode === 13) {
            handleSearch()
          }
        }}
        buttons={headerButtons}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TableDataComp
            loading={orcamentos?.loading?.list ?? false}
            size="small"
            rows={orcamentos?.list?.data ?? []}
            cols={tableCols}
            actions={tableActions}
          />
        </Grid>
      </Grid>
      {/* DialogForm (add/edit) */}
      <DialogComp
        size="lg"
        open={dialogForm.open}
        title={dialogForm.title}
        onClose={() => handleOpenCloseDialogForm(false)}
      >
        <OrcamentosFormComp
          session={session}
          orcamentosId={dialogForm.id}
          emergencial={dialogForm.isEmergencial}
          onClose={() => handleOpenCloseDialogForm(false)}
          onSuccess={() => handleFilterOrcamentos({ search })}
        />
      </DialogComp>
      {/* Dialog Ask Update Status */}
      <DialogComp
        open={dialogAskUpdateStatus.open}
        title={dialogAskUpdateStatus.title}
        onClose={() => handleOpenCloseDialogAskUpdateStatus(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskUpdateStatus(false),
          },
          {
            label: 'SIM',
            onClick: async () => {
              await handleUpdateStatusOrcamentos(
                dialogAskUpdateStatus?.data?.id ?? 0,
                dialogAskUpdateStatus?.data?.newStatus ?? ''
              )
              handleOpenCloseDialogAskUpdateStatus(false)
              handleFilterOrcamentos({ search })
            },
          },
        ]}
      >
        {`Você deseja realmente ${dialogAskUpdateStatus?.data?.newStatus == 'CA'
            ? 'CANCELAR'
            : 'APROVAR'
          } o ORÇAMENTO Nº: ${dialogAskUpdateStatus?.data?.id ??
          0} do cliente: ${dialogAskUpdateStatus?.data?.clientesNome ?? ''} ?`}
      </DialogComp>
      {/* Dialog ClientesForm (add/edit) */}
      <DialogComp
        size="md"
        open={dialogAddClientes?.open ?? false}
        onClose={() => handleOpenCloseDialogClientes(false)}
        actions={[
          {
            label: 'Cancelar',
            onClick: () => {
              onClientesFormClean()
              handleOpenCloseDialogClientes(false)
            },
          },
          {
            label: 'Salvar',
            onClick: () => {
              onClientesDataSave(clientes.form, payload => {
                onClientesFormClean()
                handleOpenCloseDialogClientes(false)
              })
            },
          },
        ]}
      >
        <ClientesBasicFormComp
          data={clientes.form}
          onChange={onClientesFormUpdate}
        />
      </DialogComp>
    </DefaultPage>
  )
}

OrcamentosListPage.propTypes = {
  session: PropTypes.shape().isRequired,
  orcamentos: PropTypes.shape().isRequired,
  onOrcamentosDataList: PropTypes.func.isRequired,
  onOrcamentosFormUpdate: PropTypes.func.isRequired,
  onOrcamentosFormClean: PropTypes.func.isRequired,
  onOrcamentosDataSave: PropTypes.func.isRequired,
  onOrcamentosDataDelete: PropTypes.func.isRequired,
  onClientesDataById: PropTypes.func.isRequired,
  onClientesDataSave: PropTypes.func.isRequired,
  onClientesFormClean: PropTypes.func.isRequired,
  onClientesFormUpdate: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  session: state.auth.session,
  orcamentos: state.orcamentos,
  clientes: state.clientes,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...OrcamentosActions,
      ...ClientesActions,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(OrcamentosListPage)
