import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepConnector from '@material-ui/core/StepConnector'
import { getStatusById } from 'utils/enums/StatusPedido'

import styles from './styles'

const PedidoHeaderComp = ({ classes, activeStep, labelStep2 }) => {
  const { sku = '', step = 0 } = activeStep ? getStatusById(activeStep) : {}
  const getSteps = () => [
    getStatusById(1).descricao,
    labelStep2 || getStatusById(2).descricao,
    getStatusById(3).descricao,
    getStatusById(5).descricao,
  ]
  if (sku === 'CANCELADO') {
    const stepCancelado = ['CANCELADO']
    return (
      <div className={classes.rootCancelado}>
        <Stepper
          alternativeLabel
          activeStep={1}
          connector={(
            <StepConnector
              classes={{
                active: classes.connectorActive,
                completed: classes.connectorCompleted,
                // disabled: classes.connectorDisabled,
                line: classes.connectorLine,
              }}
            />
          )}
        >
          {stepCancelado.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    )
  }
  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={step}
        connector={(
          <StepConnector
            classes={{
              active: classes.connectorActive,
              completed: classes.connectorCompleted,
              // disabled: classes.connectorDisabled,
              line: classes.connectorLine,
            }}
          />
        )}
      >
        {getSteps().map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}

PedidoHeaderComp.propTypes = {
  classes: PropTypes.shape().isRequired,
  activeStep: PropTypes.number.isRequired,
  labelStep2: PropTypes.string,
}

PedidoHeaderComp.defaultProps = {
  labelStep2: '',
}

export default withStyles(styles)(PedidoHeaderComp)
