import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const path = '/orcamentos'

/**
 *
 * @param { token, filter: { id, filiaisId, usuariosId, vendedorId, clientesId, tabelaPrecoId, status, search, page, rows }} param0
 * @returns List of Orcamentos
 */
export const filterOrcamentosApi = ({ token, filter }) => {
  return api
    .get(path, {
      headers: { Authorization: `Bearer ${token}` },
      params: filter,
    })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getOrcamentosApi = ({ token, id }) => {
  return api
    .get(`${path}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const addOrcamentosApi = ({ token, dto }) => {
  return api
    .post(path, JSON.stringify(dto), {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const editOrcamentosApi = ({ token, id, dto }) => {
  return api
    .put(path, JSON.stringify(dto), {
      headers: { Authorization: `Bearer ${token}` },
      params: { id },
    })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const deleteOrcamentosApi = ({ token, id }) =>
  api
    .delete(path, {
      headers: { Authorization: `Bearer ${token}` },
      params: { id },
    })
    .then(httpValidState)
    .catch(httpValidErros)

export const cancelOrcamentosApi = ({ token, id }) =>
  api
    .post(`${path}/cancel`, JSON.stringify({ id }), {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(httpValidState)
    .catch(httpValidErros)

export const updateStatusOrcamentosApi = ({ token, id, status }) =>
  api
    .post(`${path}/status-update`, JSON.stringify({ id, status }), {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(httpValidState)
    .catch(httpValidErros)

export const relatOrcamentosPrint = ({ token, id }) =>
  api
    .get(`${path}/reports/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob',
      timeout: 0,
    })
    .then(httpValidState)
    .catch(httpValidErros)
