import React from 'react'
import PropTypes from 'prop-types'
import { TextField, IconButton, InputAdornment } from '@material-ui/core'
import { YoutubeSearchedFor, Clear } from '@material-ui/icons'
import _ from 'lodash'

// import useStyles from './styles'

const InputLookUpComp = (props) => {
  const {
    required,
    label,
    htmlId,
    value,
    disabled,
    iptSize,
    margin,
    variant,
    onChange,
    onKeyPress,
    onClickClear,
    onClickSearch,
  } = props
  // const classes = useStyles()
  const endAdornment = (
    <InputAdornment position="end">
      {!_.isEmpty(value) && (
        <IconButton aria-label="Limpar" onClick={onClickClear}>
          <Clear />
        </IconButton>
      )}
      <IconButton aria-label="Buscar" onClick={onClickSearch}>
        <YoutubeSearchedFor />
      </IconButton>
    </InputAdornment>
  )
  return (
    <TextField
      required={required}
      disabled={disabled}
      id={htmlId}
      fullWidth
      size={iptSize}
      margin={margin}
      variant={variant}
      type="text"
      label={label}
      value={value}
      onChange={onChange}
      onKeyPress={onKeyPress}
      InputProps={!disabled ? { endAdornment } : {}}
    />
  )
}

InputLookUpComp.propTypes = {
  required: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  htmlId: PropTypes.string.isRequired,
  iptSize: PropTypes.string,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func.isRequired,
  onClickClear: PropTypes.func.isRequired,
  onClickSearch: PropTypes.func.isRequired,
}

InputLookUpComp.defaultProps = {
  disabled: false,
  margin: 'normal',
  variant: 'outlined',
  iptSize: 'medium',
  onChange: null,
}

export default InputLookUpComp
