import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

// Util's
import { parseToNum, MoneyFormatBr, downloadStringToFile } from 'utils'

// Api
import { downloadLoteByIdApi } from 'api/fisco/nfse/rps'

// Actions
import { Actions as NfseRpsActions } from 'store/ducks/fisco/nfse/rps'

// Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import TableDataComp from 'components/Table'
import DialogComp from 'components/Dialogs'
import FiscoNfseRpsFormComp from './components/RpsLotesForm'

// Styles
// import useStyles from './styles'

const FiscoNfseRpsPage = ({
  session,
  fiscoNfseRps: { loading = {}, list = [] },
  onFiscoNfseRpsDataList,
  onFiscoNfseRpsFormClean,
  onFiscoNfseRpsDataDelete,
}) => {
  // const classes = useStyles()
  const [search, setSearch] = useState('')
  const [dialogForm, setDialogForm] = useState({ open: false, title: '' })
  const [dialogAskDelete, setDialogAskDelete] = useState({ open: false, title: '', data: null })

  // Header Actions >>
  const handleFilter = (filter) => {
    onFiscoNfseRpsDataList({ ...filter, filiais: session.user.filial })
  }
  const handleSearch = () => {
    handleFilter({ search })
  }
  const handleOpenCloseDialogForm = (open, title = null) => {
    if (title) {
      setDialogForm((s) => ({ ...s, open, title }))
    } else {
      setDialogForm((s) => ({ ...s, open }))
    }
  }
  const handleOpenCloseDialogAskDelete = (open, title = null, data = null) => {
    setDialogAskDelete((s) => ({
      ...s, open, title, data,
    }))
  }
  const headerButtons = [
    {
      title: 'Adicionar',
      icon: 'add',
      action: () => {
        onFiscoNfseRpsFormClean()
        handleOpenCloseDialogForm(true, 'Criação de Lote RPS:')
      },
    },
  ]
  // Header Actions <<

  // Table Actions >>
  const tableCols = [
    { key: 'id', title: 'ID' },
    { key: 'filiaisDescricao', title: 'FILIAL' },
    { key: 'qtdItens', title: 'ITENS' },
    {
      key: 'valorTotal',
      title: 'VALOR TOTAL',
      align: 'right',
      format: (valorTotal) => `R$ ${MoneyFormatBr(parseToNum(valorTotal))}`,
    },
    { key: 'sucesso', title: 'SUCESSO(S)', align: 'center' },
    { key: 'erros', title: 'ERRO(S)', align: 'center' },
  ]
  const tableActions = [
    {
      key: 'download',
      title: 'Download',
      icon: 'cloud_download',
      action: (row) => {
        downloadLoteByIdApi({ token: session.token, id: row.id }).then((xml) => {
          downloadStringToFile(xml, `Lote_${row.id}.xml`)
        })
      },
    },
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: (row) => handleOpenCloseDialogAskDelete(true, 'Excluir?', row),
    },
  ]
  // Table Actions <<

  useEffect(() => {
    document.title = 'Backoffice - NFS-e/Rps'
    handleFilter({ search })
  }, [])

  return (
    <DefaultPage>
      <PageHeaderComp
        title="NFS-e/Rps"
        // subtitle={empresasDesc}
        value={search}
        onChange={(evt) => setSearch(evt.target.value)}
        onClick={handleSearch}
        onKeyPress={(evt) => {
          if (evt.charCode === 13) {
            handleSearch()
          }
        }}
        buttons={headerButtons}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TableDataComp
            loading={loading.list || false}
            size="small"
            rows={list.data || []}
            cols={tableCols}
            actions={tableActions}
          />
        </Grid>
      </Grid>
      {/* DialogForm (Add/Edit) */}
      <DialogComp
        open={dialogForm.open}
        title={dialogForm.title}
        onClose={() => handleOpenCloseDialogForm(false)}
      >
        <FiscoNfseRpsFormComp
          token={session.token}
          filiais={session.user.filial}
          onCallback={() => handleFilter({ search })}
        />
      </DialogComp>
      {/* Dialog Ask DELETE */}
      <DialogComp
        open={dialogAskDelete.open}
        title={dialogAskDelete.title}
        onClose={() => handleOpenCloseDialogAskDelete(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDelete(false),
          },
          {
            label: 'SIM',
            onClick: () => onFiscoNfseRpsDataDelete(dialogAskDelete.data.id, () => {
              handleOpenCloseDialogAskDelete(false)
              handleFilter({ search })
            }),
          },
        ]}
      >
        {`Você deseja realmente excluir o LOTE: "${dialogAskDelete.data ? dialogAskDelete.data.id : ''}"?`}
      </DialogComp>
    </DefaultPage>
  )
}

FiscoNfseRpsPage.propTypes = {
  session: PropTypes.shape().isRequired,
  fiscoNfseRps: PropTypes.shape().isRequired,
  onFiscoNfseRpsDataList: PropTypes.func.isRequired,
  onFiscoNfseRpsFormClean: PropTypes.func.isRequired,
  onFiscoNfseRpsDataDelete: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
  fiscoNfseRps: state.fiscoNfseRps,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...NfseRpsActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FiscoNfseRpsPage)
