import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid, TextField, FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core'

// Styles
import useStyles from './styles'

// Stateless
const PagamentosFormaFormComp = ({ data, onChange }) => {
  const {
    codigo = '',
    bandeira = '',
    descricao = '',
    status = '',
  } = data
  const classes = useStyles()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={5}>
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
          className={classes.dataControl}
        >
          <InputLabel ref={inputLabel}>Código</InputLabel>
          <Select
            id="pagamentosForma-codigo"
            value={codigo}
            onChange={(evt) => onChange({ codigo: evt.target.value.toUpperCase() })}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="D">DINHEIRO</MenuItem>
            <MenuItem value="CQ">CHEQUE</MenuItem>
            <MenuItem value="CC">CARTÃO CRÉDITO</MenuItem>
            <MenuItem value="CD">CARTÃO DÉBITO</MenuItem>
            <MenuItem value="FA">FATURADO</MenuItem>
            <MenuItem value="CO">CORTESIA</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
          className={classes.dataControl}
        >
          <InputLabel ref={inputLabel}>Bandeiras</InputLabel>
          <Select
            id="pagamentosForma-bandeira"
            value={bandeira}
            onChange={(evt) => onChange({ bandeira: evt.target.value.toUpperCase() })}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="VISA">VISA</MenuItem>
            <MenuItem value="MASTERCARD">MASTERCARD</MenuItem>
            <MenuItem value="AMEX">AMEX</MenuItem>
            <MenuItem value="DINERS">DINERS</MenuItem>
            <MenuItem value="ELO">ELO</MenuItem>
            <MenuItem value="HIPER">HIPER</MenuItem>
            <MenuItem value="BANESE">BANESE</MenuItem>
            <MenuItem value="DINHEIRO">DINHEIRO</MenuItem>
            <MenuItem value="CORTESIA">CORTESIA</MenuItem>
            <MenuItem value="FATURADO">FATURADO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
          className={classes.dataControl}
        >
          <InputLabel ref={inputLabel}>Status</InputLabel>
          <Select
            id="pagamentosForma-status"
            value={status}
            onChange={(evt) => onChange({ status: evt.target.value.toUpperCase() })}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="L">LIBERADO</MenuItem>
            <MenuItem value="B">BLOQUEADO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="pagamentosForma-descricao"
          label="Descrição"
          margin="normal"
          variant="outlined"
          value={descricao}
          onChange={(evt) => onChange({ descricao: evt.target.value.toUpperCase() })}
        />
      </Grid>
    </Grid>
  )
}

PagamentosFormaFormComp.propTypes = {
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}

export default PagamentosFormaFormComp
