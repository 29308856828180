import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

const HtmlTooltipComp = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#000',
    color: '#FFF',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

export default HtmlTooltipComp
