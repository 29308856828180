import _ from 'lodash'
import { NotificationManager } from 'react-notifications'
import {
  filterOrcamentosApi,
  getOrcamentosApi,
  addOrcamentosApi,
  editOrcamentosApi,
  deleteOrcamentosApi,
} from 'api/orcamentos'

// State
const initialState = {
  loading: {
    list: false,
    find: false,
    add: false,
    edit: false,
    del: false,
  },
  list: { page: 0, rows: 0, data: [] },
  form: {
    id: 0,
    filiaisId: 0,
    usuariosId: 0,
    vendedorId: 0,
    vendedorNome: '',
    clientesId: 0,
    clientesNome: '',
    descontoTipo: '',
    descontoTipoDescricao: '',
    descontoValor: 0.0,
    descontoPorcentagem: 0.0,
    totalBruto: 0.0,
    totalLiquido: 0.0,
    observacao: '',
    dataCriacao: '',
    dataAprovado: '',
    dataReprovado: '',
    dataPrazo: '',
    dataCancelamento: '',
    motivoCancelamento: '',
    status: '',
    statusDescricao: '',
    itens: [],
  },
  item: {
    id: 0,
    orcamentosId: 0,
    tabelasPrecoItensId: 0,
    funcionariosId: 0,
    funcionariosNome: '',
    preco: 0.0,
    desconto: 0.0,
    descricao: '',
    descricao2: '',
    dataAprovado: '',
    dataReprovado: '',
    dataFinalizado: '',
    status: '',
    statusDescricao: '',
  }
}

// Actions Types
export const Types = {
  RESET: 'reset',
  ORCAMENTOS_LOADING: 'orcamentos/LOADING',
  ORCAMENTOS_LIST_LOAD: 'orcamentos/LIST_LOAD',
  ORCAMENTOS_LIST_UPDATE: 'orcamentos/LIST_UPDATE',
  ORCAMENTOS_LIST_CLEAN: 'orcamentos/LIST_CLEAN',
  ORCAMENTOS_FORM_UPDATE: 'orcamentos/FORM_UPDATE',
}

// Reducers
export default function orcamentosReducers(state = initialState, action) {
  switch (action.type) {
  // RESET DEFAULT
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      }
    case Types.ORCAMENTOS_LOADING:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    case Types.ORCAMENTOS_LIST_LOAD:
      return {
        ...state,
        list: action.payload,
      }
    case Types.ORCAMENTOS_LIST_UPDATE:
      return {
        ...state,
        list: state.list.map((i) => (i.id === action.payload.id ? { ...i, ...action.payload } : i)),
      }
    case Types.ORCAMENTOS_LIST_CLEAN:
      return {
        ...state,
        list: initialState.list,
      }
    case Types.ORCAMENTOS_FORM_UPDATE:
      return {
        ...state,
        form: { ...state.form, ...action.payload },
      }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  // Store Update
  onOrcamentosLoading: (payload) => ({ type: Types.ORCAMENTOS_LOADING, payload }),
  onOrcamentosListLoad: (payload) => ({ type: Types.ORCAMENTOS_LIST_LOAD, payload }),
  onOrcamentosListUpdate: (payload) => ({ type: Types.ORCAMENTOS_LIST_UPDATE, payload }),
  onOrcamentosListClean: () => ({ type: Types.ORCAMENTOS_LIST_CLEAN }),
  onOrcamentosFormUpdate: (payload) => ({ type: Types.ORCAMENTOS_FORM_UPDATE, payload }),
  onOrcamentosFormClean: () => ({
    type: Types.ORCAMENTOS_FORM_UPDATE,
    payload: initialState.form,
  }),
  onOrcamentosDataById: (id) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onOrcamentosLoading({ find: true }))
    // Chamada API
    getOrcamentosApi({ token, id }).then((payload) => {
      dispatch(Actions.onOrcamentosLoading({ find: false }))
      dispatch(Actions.onOrcamentosFormUpdate(_.isObject(payload) ? payload : initialState.form))
    })
  },
  onOrcamentosDataList: (filter) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onOrcamentosLoading({ list: true }))
    // Chamada API
    filterOrcamentosApi({ token, filter }).then((payload) => {
      dispatch(Actions.onOrcamentosLoading({ list: false }))
      dispatch(Actions.onOrcamentosListLoad(payload || initialState.list))
    }).catch(() => dispatch(Actions.onOrcamentosLoading({ list: false })))
  },
  onOrcamentosDataSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    // Chamada API
    if (dto.id) {
      dispatch(Actions.onOrcamentosLoading({ edit: true }))
      editOrcamentosApi({ token, dto, id: dto.id }).then((payload) => {
        dispatch(Actions.onOrcamentosLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success(`Funcionário ${dto.nome} atualizado com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      }).catch(() => dispatch(Actions.onOrcamentosLoading({ edit: false })))
    } else {
      dispatch(Actions.onOrcamentosLoading({ add: true }))
      addOrcamentosApi({ token, dto }).then((payload) => {
        dispatch(Actions.onOrcamentosLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success(`Funcionário ${dto.nome} cadastrado com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      }).catch(() => dispatch(Actions.onOrcamentosLoading({ add: false })))
    }
  },
  onOrcamentosDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onOrcamentosLoading({ del: true }))
    // Chamada API
    deleteOrcamentosApi({ token, id }).then((payload) => {
      dispatch(Actions.onOrcamentosLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Funcionário excluído com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    }).catch(() => dispatch(Actions.onOrcamentosLoading({ del: false })))
  },
}
