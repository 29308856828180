import React, { useEffect, useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import {
  Icon,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { getPublicImage } from 'utils'

import useStyles from './styles'

export default function DrawerComp(props) {
  const { empresasId, data } = props
  const classes = useStyles()
  const [imageLogo, setImageLogo] = useState(require('assets/logo.png'))
  const [dataSorted, setDataSorted] = useState([])
  const [menuNested, setMenuNested] = useState([])

  const handleOpenClose = (m) => {
    setMenuNested(menuNested.map((s) => (s.id === m.id ? { ...s, open: !s.open } : { ...s })))
  }

  useEffect(() => {
    const aDataSorted = _.orderBy(data.filter((m) => m.visivel === 'S'), ['ordem'], ['asc'])
    const aMenuNested = aDataSorted.filter((p) => !p.root).map((m) => ({ ...m, open: false }))
    getPublicImage(`logo-emp-${empresasId}.png`, require('assets/logo.png')).then((image) => setImageLogo(image))
    setDataSorted(aDataSorted)
    setMenuNested(aMenuNested)
  }, [data])

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.toolbar} />
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item>
            <img className={classes.logo} src={imageLogo} alt="logo" />
          </Grid>
        </Grid>
        <Divider />
        <List dense disablePadding>
          {menuNested
            .filter((m) => m.visivel === 'S')
            .filter((p) => !p.root) // Apenas os roots
            .map((r) => {
              const aSubs = dataSorted.filter((c) => c.root === r.id)
              return aSubs.length > 0 ? (
                <Fragment key={r.id}>
                  <ListItem button onClick={() => handleOpenClose(r)}>
                    <ListItemIcon>
                      <Icon>{r.icone}</Icon>
                    </ListItemIcon>
                    <ListItemText primary={r.descricao} />
                    {r.open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={r.open} timeout="auto" unmountOnExit>
                    {aSubs
                      .filter((m) => m.visivel === 'S')
                      .map((sm) => (
                        <List dense disablePadding key={sm.id}>
                          <ListItem
                            button
                            className={classes.nested}
                            component={sm.external === 'S' ? 'a' : Link}
                            to={sm.external === 'N' ? sm.uri : null}
                            href={sm.external === 'S' ? sm.uri : null}
                            target={sm.target}
                          >
                            <ListItemIcon>
                              <Icon>{sm.icone}</Icon>
                            </ListItemIcon>
                            <ListItemText primary={sm.descricao} />
                          </ListItem>
                        </List>
                      ))}
                  </Collapse>
                </Fragment>
              ) : (
                <ListItem
                  key={r.id}
                  button
                  component={r.external === 'S' ? 'a' : Link}
                  to={r.external === 'N' ? r.uri : null}
                  href={r.external === 'S' ? r.uri : null}
                  target={r.target}
                  className={classes.listItem}
                >
                  <ListItemIcon>
                    <Icon>{r.icone}</Icon>
                  </ListItemIcon>
                  <ListItemText primary={r.descricao} />
                </ListItem>
              )
            })}
        </List>
      </Grid>
    </Grid>
  )
}

DrawerComp.propTypes = {
  empresasId: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.shape()),
}

DrawerComp.defaultProps = {
  empresasId: 0,
  data: [],
}
