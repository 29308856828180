import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Grid,
  Paper,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Typography,
  Icon,
  IconButton,
  Chip,
  Select,
  MenuItem,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TextField,
  Button,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import {
  hasRole,
  DateIsoToBrFormat,
  DateIsoToHours,
  DateIsoDiff,
  padLeft,
  MoneyFormatBr,
  parseToNum,
  handleImprimirOrdemServico,
} from 'utils'
import UserRoles from 'utils/enums/UserRoles'
import moment from 'moment'

// Api's
import { listFuncionariosApi } from 'api/funcionarios'

// Actions
import { Actions as OrdemServicoActions } from 'store/ducks/ordemservico'

// Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import { TableActionsComp } from 'components/Table'
import DialogComp from 'components/Dialogs'
import DialogPerguntaComp from 'components/Dialogs/Pergunta'
import ClientesVeiculosFormComp from 'pages/clientes/components/ClientesVeiculosForm'
import HtmlTooltipComp from 'components/ToolTips'
import InputSearchComp from 'components/Inputs/Search'
import FormCaixasMovimentoComp from 'components/Forms/Caixas/Movimento'
import OrdemServicoFormComp from './components/OrdemServicoForm'

// Styles
import useStyles from './styles'

const OrdemServicoPage = ({
  session,
  caixas,
  ordemServico,
  onOrdemServicoDataList,
  onOrdemServicoFormClean,
  onOrdemServicoFormUpdate,
  // onOrdemServicoDataSave,
  // onOrdemServicoDataDelete,
  onOrdemServicoMonitoramentoDataList,
  onOrdemServicoUpdateObsAction,
  onOrdemServicoFechamentoAction,
  onOrdemServicoCancelamentoAction,
  onOrdemServicoDataById,
  // Itens
  // onOrdemServicoItensFormClean,
  // onOrdemServicoItensFormUpdate,
}) => {
  const classes = useStyles()
  const [currentPage, setCurrentPage] = useState(0)
  const [search, setSearch] = useState('')
  const [tabSelected, setTabSelected] = useState(0)
  const [ordemServicoSelected, setOrdemServicoSelected] = useState({})
  const [dialogForm, setDialogForm] = useState({ open: false, title: '' })
  const [dialogClientesForm, setDialogClientesForm] = useState({
    open: false,
    title: '',
    data: {},
  })
  const [dialogFechamentoOS, setDialogFechamentoOS] = useState(false)
  const [funcionariosFiliais, setFuncionariosFiliais] = useState({
    loading: false,
    funcionarios: [],
    funcionarioServico: [],
    vistoria: 'S',
  })
  const [DialogPergunta, setDialogPergunta] = useState({
    open: '',
    title: '',
    ask: '',
    onOk: () => null,
    onClose: () => setDialogPergunta(s => ({ ...s, open: false })),
  })
  const [historicoFiltro, setHistoricoFiltro] = useState({
    search: '',
    atendente: {},
    dataAberturaIni: '',
    dataAberturaFim: '',
    dataEntregaIni: '',
    dataEntregaFim: '',
    dataFechamentoIni: '',
    dataFechamentoFim: '',
    dataPagamentoIni: '',
    dataPagamentoFim: '',
  })
  const [loadingFechamentoOS, setLoadingFechamentoOS] = useState(false)
  // * Pagamentos
  const [dialogPagamento, setDialogPagamento] = useState(false)
  // * Observação ordem de serviço
  const [dialogObsOrdem, setDialogObsOrdem] = useState({
    open: false,
    observacao: '',
    loading: false,
  })

  // * Usado para filtrar O.S para usuários da SofaComZelo
  const isOrcamentos = hasRole(
    session?.user?.grupos ?? [],
    UserRoles?.ROLE_ORCAMENTOS?.codigo ?? ''
  )

  // * Grid Monitoramento
  const monitoramentoLoading = ordemServico.loading.monitoramento || false
  const monitoramentoData = ordemServico.monitoramento.data || []
  // * Grid Historico
  const historicoLoading = ordemServico.loading.list || false
  const historicoData = ordemServico.list.data || []

  // * Header Actions >>
  const handleFilterMonitoramentoData = filter => {
    // * Monitoramento e pah!
    onOrdemServicoMonitoramentoDataList({
      ...filter,
      filiais: session?.user?.filial ?? 0,
      comVeiculos: String(!isOrcamentos),
    })
  }
  const handleFilterHistoricoData = filter => {
    // * Histórico e pah!
    onOrdemServicoDataList({
      ...filter,
      filiais: session?.user?.filial ?? 0,
      comVeiculos: String(!isOrcamentos),
    })
  }
  // Usado no campo de pesquisa (Superior direito)
  const handleSearch = pNewPage => {
    switch (tabSelected) {
      case 0:
        handleFilterMonitoramentoData({ search })
        break
      case 1:
        handleFilterHistoricoData({ search, page: pNewPage })
        break
      default:
        handleFilterMonitoramentoData({ search })
    }
  }
  const handleChangePage = (event, value) => {
    const newPage = (value ?? 1) - 1
    setCurrentPage(newPage)
    handleSearch(newPage)
  }
  const handleSelectTab = (event, newValue) => {
    setTabSelected(newValue)
    if (newValue) {
      handleFilterHistoricoData({ search })
    } else {
      handleFilterMonitoramentoData({ search })
    }
  }
  const handleOpenCloseDialogForm = (open, title = null) => {
    if (title) {
      setDialogForm(s => ({ ...s, open, title }))
    } else {
      setDialogForm(s => ({ ...s, open }))
    }
  }
  const handleOpenCloseDialogClientesForm = (open, title = null, data) => {
    if (title) {
      setDialogClientesForm(s => ({
        ...s,
        open,
        title,
        data,
      }))
    } else {
      setDialogClientesForm(s => ({ ...s, open }))
    }
  }
  // * Funcionarios/Filiais
  const handleFuncionariosFiliaisLoading = loading => {
    setFuncionariosFiliais(s => ({
      ...s,
      loading,
    }))
  }
  const handleSearchFuncionarios = () => {
    handleFuncionariosFiliaisLoading(true)
    listFuncionariosApi({
      token: session.token,
      filter: {
        filiais: session.user.filial,
      },
    }).then(resp => {
      handleFuncionariosFiliaisLoading(false)
      if (resp && _.isArray(resp.data)) {
        setFuncionariosFiliais(s => ({
          ...s,
          funcionarios: resp.data,
        }))
      }
    })
  }
  const handleOpenCloseDialogFechamentoOS = open => {
    if (open) {
      const { funcionarios = [] } = funcionariosFiliais
      if (_.isEmpty(funcionarios)) {
        handleSearchFuncionarios()
      }
    }
    setDialogFechamentoOS(open)
  }
  const handleChangeFuncionarioServico = (evt, ordemServicoItemId) => {
    const funcionariosId = evt.target.value
    setFuncionariosFiliais(s => ({
      ...s,
      funcionarioServico: s.funcionarioServico.map(i =>
        i.ordemServicoItemId === ordemServicoItemId
          ? { ordemServicoItemId, funcionariosId }
          : { ...i }
      ),
    }))
  }
  const handleChangeVistoria = evt => {
    const vistoria = evt.target.value
    setFuncionariosFiliais(s => ({
      ...s,
      vistoria,
    }))
  }
  // * Fechamento de O.S (ACTION)
  const handleConfirmaFechamentoOrdemServico = () => {
    const { vistoria, funcionarioServico } = funcionariosFiliais
    const dto = {
      id: ordemServicoSelected.id,
      status: 'F',
      vistoria,
      funcionarioServico,
    }
    setLoadingFechamentoOS(true)
    onOrdemServicoFechamentoAction(dto, () => {
      handleFilterMonitoramentoData({ search })
      setOrdemServicoSelected({})
      handleOpenCloseDialogFechamentoOS(false)
      setLoadingFechamentoOS(false)
    })
  }
  const handleFechaOrdemServico = row => {
    handleOpenCloseDialogFechamentoOS(true)
    setOrdemServicoSelected(row)
    setFuncionariosFiliais(s => ({
      ...s,
      funcionarioServico: row.itens.map(i => ({
        ordemServicoItemId: i.id,
        funcionariosId: i.funcionariosId,
      })),
    }))
  }
  const handlePagarOrdemServico = row => {
    setDialogPagamento(true)
    setOrdemServicoSelected(row)
  }
  const handleImprimir = ordemServicoId => {
    handleImprimirOrdemServico(session.token, ordemServicoId)
  }
  const handleCancelarOrdemServico = row => {
    const dto = {
      id: row.id,
      status: 'C',
    }
    setDialogPergunta(s => ({
      ...s,
      open: true,
      title: 'Cancelamento de O.S',
      ask: `Você confirma o cancelamento da O.S: ${padLeft(dto.id, 6, '0')}`,
      onOk: () =>
        onOrdemServicoCancelamentoAction(dto, () => {
          handleFilterMonitoramentoData({ search })
          DialogPergunta.onClose()
        }),
    }))
  }
  const handleFiltrarHistorico = () => {
    const filter = {
      ...historicoFiltro,
      vendedor: historicoFiltro.atendente.id,
    }
    handleFilterHistoricoData({ ...filter })
  }
  const headerButtons = !isOrcamentos
    ? [
        {
          title: 'Nova O.S',
          icon: 'post_add',
          action: () => {
            onOrdemServicoFormClean()
            handleOpenCloseDialogForm(true, 'Ordem de Serviço')
          },
        },
        {
          title: 'Novo Cliente',
          icon: 'person_add',
          action: () => {
            handleOpenCloseDialogClientesForm(true, 'Novo cliente:')
          },
        },
      ]
    : []
  // Header Actions <<

  // * Observações
  const handleObsOrdemOpenClose = open => {
    setDialogObsOrdem(s => ({ ...s, open }))
  }
  const handleObsOrdemIpt = observacao => {
    setDialogObsOrdem(s => ({ ...s, observacao }))
  }
  const handleObsOrdemLoading = loading => {
    setDialogObsOrdem(s => ({ ...s, loading }))
  }

  // * Table Monitoramento >>
  const tableMonitoramentoCols = [
    { key: 'statusDescricao', title: 'STATUS', align: 'center' },
    { key: 'abertura', title: 'ABERTURA/PREVISÃO/RESTANTE', align: 'center' },
    { key: 'os', title: 'O.S.', align: 'center' },
    { key: 'veiculo', title: 'VEICULO', align: 'center' },
    { key: 'cliente', title: 'CLIENTE', align: 'center' },
    { key: 'total', title: 'TOTAL', align: 'center' },
  ]
  const tableMonitoramentoActions = [
    {
      key: 'fecharOS',
      title: 'FECHAR',
      icon: 'thumb_up_alt',
      disabled: (row => row.status === 'F') || 1 === 2,
      action: row => handleFechaOrdemServico(row),
    },
    {
      key: 'pagarOS',
      title: 'PAGAR',
      icon: 'payment',
      disabled: (row => row.status === 'P') || caixas.aberto.status === 'F',
      action: row => handlePagarOrdemServico(row),
    },
    {
      key: 'imprimirOS',
      title: 'IMPRIMIR',
      icon: 'print',
      action: row => handleImprimir(row.id),
    },
    {
      key: 'visualizar',
      title: 'VISUALIZAR',
      icon: 'visibility',
      action: row => {
        onOrdemServicoDataById(row.id)
        handleOpenCloseDialogForm(true, 'Visualização de O.S')
      },
    },
    {
      key: 'cancelarOS',
      title: 'CANCELAR',
      icon: 'cancel',
      action: row => handleCancelarOrdemServico(row),
    },
  ]
  // * Table Monitoramento <<

  // * Table Histórico >>
  const tableHistoricoCols = [
    { key: 'statusDescricao', title: 'STATUS', align: 'center' },
    { key: 'abertura', title: 'ABER./PREV./FECHA./GASTO', align: 'center' },
    { key: 'os', title: 'O.S.', align: 'center' },
    { key: 'veiculo', title: 'VEICULO', align: 'center' },
    { key: 'cliente', title: 'CLIENTE', align: 'center' },
    { key: 'total', title: 'TOTAL', align: 'center' },
  ]
  const tableHistoricoActions = [
    {
      key: 'visualizar',
      title: 'VISUALIZAR',
      icon: 'visibility',

      action: row => {
        onOrdemServicoDataById(row.id)
        handleOpenCloseDialogForm(true, 'Visualização de O.S')
      },
    },
  ]

  useEffect(() => {
    document.title = 'Backoffice - Ordem de Serviço'
    handleFilterMonitoramentoData({ search })
  }, [session.user.filial])

  return (
    <DefaultPage>
      <PageHeaderComp
        title="Ordem de Serviço"
        // subtitle={empresasDesc}
        value={search}
        onChange={evt => setSearch(evt.target.value.toUpperCase())}
        onClick={() => handleSearch(currentPage)}
        onKeyPress={evt => {
          if (evt.charCode === 13) {
            handleSearch(currentPage)
          }
        }}
        buttons={headerButtons}
      />
      <Grid container spacing={2}>
        {/* Tabs */}
        <Grid item xs={12} md={12}>
          <Paper className={classes.root}>
            <Tabs
              value={tabSelected}
              onChange={handleSelectTab}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Monitoramento" />
              <Tab label="Histórico" />
            </Tabs>
          </Paper>
        </Grid>
        {/* Content */}
        <Grid item xs={12} md={12}>
          {/* Monitoramento */}
          {tabSelected === 0 && (
            <Paper className={classes.root}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  {/* Table Header */}
                  <TableHead>
                    <TableRow>
                      <TableCell>AÇÕES</TableCell>
                      {tableMonitoramentoCols
                        ?.filter(
                          c =>
                            (isOrcamentos && c.key != 'veiculo') ||
                            !isOrcamentos
                        )
                        ?.map(c => (
                          <TableCell key={c.key} align={c.align || 'left'}>
                            {c.title}
                          </TableCell>
                        ))}
                      <TableCell>OBS./SERVIÇO(S)</TableCell>
                    </TableRow>
                  </TableHead>
                  {/* Table Body */}
                  <TableBody>
                    {monitoramentoLoading && (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          colSpan={tableMonitoramentoCols.length}
                        >
                          <CircularProgress
                            className={classes.progress}
                            color="primary"
                          />
                        </TableCell>
                      </TableRow>
                    )}
                    {!monitoramentoLoading &&
                      (!_.isArray(monitoramentoData) ||
                        _.isEmpty(monitoramentoData)) && (
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            colSpan={tableMonitoramentoCols.length}
                          >
                            Sem registro(s).
                          </TableCell>
                        </TableRow>
                      )}
                    {!monitoramentoLoading &&
                      _.isArray(monitoramentoData) &&
                      monitoramentoData.map(row => {
                        const dtAbertura = DateIsoToHours(row.dataAbertura)
                        const dtEntrega = DateIsoToHours(row.dataEntrega)
                        const restante = DateIsoDiff(
                          moment(),
                          row.dataEntrega,
                          'minutes'
                        )
                        let rowColor = 'rgba(60, 118, 61, 0.1)'
                        rowColor =
                          restante > 0 && restante < 30
                            ? 'rgba(245, 252, 30, 0.2)'
                            : rowColor
                        rowColor =
                          restante < 0 ? 'rgba(220, 0, 78, 0.1)' : rowColor
                        return (
                          <TableRow
                            hover
                            key={row.id}
                            style={{ backgroundColor: rowColor }}
                          >
                            <TableCell component="td" scope="row">
                              <TableActionsComp
                                row={row}
                                actions={tableMonitoramentoActions}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Chip
                                label={row.statusDescricao}
                                className={classes[`status${row.status}`]}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <span>
                                {dtAbertura}/<strong>{dtEntrega}</strong>/
                                {`${restante}min`}
                              </span>
                            </TableCell>
                            <TableCell>{padLeft(row.id, 6, '0')}</TableCell>
                            {!isOrcamentos && (
                              <TableCell align="center">
                                <Typography variant="body2" component="span">
                                  {row.veiculosPlaca}
                                  <br />
                                  <Typography
                                    className={classes.font12}
                                    component="p"
                                    color="textSecondary"
                                  >
                                    <b>{row.veiculosModelo}</b>/
                                    {row.veiculosCor}
                                  </Typography>
                                </Typography>
                              </TableCell>
                            )}
                            <TableCell align="center">
                              <Typography variant="body2" component="span">
                                {row.clientesNome}
                                <br />
                                <Typography
                                  className={classes.font12}
                                  component="p"
                                  color="textSecondary"
                                >
                                  {row.clientesCelular}
                                </Typography>
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <HtmlTooltipComp
                                placement="top"
                                title={
                                  <>
                                    <em>{`${row.itens.length} serviço(s) `}</em>
                                    <b>{'desconto de '}</b>
                                    <u>{`R$ ${MoneyFormatBr(
                                      parseToNum(row.descontoValor)
                                    )}.`}</u>
                                    <br />
                                  </>
                                }
                              >
                                <Typography variant="body2" component="p">
                                  {`R$ ${MoneyFormatBr(
                                    parseToNum(row.totalLiquido)
                                  )}`}
                                </Typography>
                              </HtmlTooltipComp>
                            </TableCell>
                            <TableCell align="center">
                              <HtmlTooltipComp
                                placement="top"
                                title={
                                  <em>{row.observacao || 'SEM OBSERVAÇÃO'}</em>
                                }
                              >
                                <span>
                                  <IconButton
                                    color={
                                      row.observacao ? 'secondary' : 'primary'
                                    }
                                    onClick={() => {
                                      setOrdemServicoSelected(row)
                                      handleObsOrdemIpt(row.observacao)
                                      handleObsOrdemOpenClose(true)
                                    }}
                                  >
                                    <Icon>
                                      {row.observacao
                                        ? 'speaker_notes'
                                        : 'speaker_notes_off'}
                                    </Icon>
                                  </IconButton>
                                </span>
                              </HtmlTooltipComp>
                              <HtmlTooltipComp
                                placement="top"
                                title={
                                  <div>
                                    {row.itens.map((i, index) => (
                                      <div key={i.id}>
                                        <em>{`${index + 1}) ${
                                          i.tabelasPrecoItensServicosDescricao
                                        }`}</em>{' '}
                                        ({i.statusDescricao}
                                        )
                                        <br />
                                      </div>
                                    ))}
                                  </div>
                                }
                              >
                                <IconButton color="secondary">
                                  <Icon>assignment</Icon>
                                </IconButton>
                              </HtmlTooltipComp>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
          {/* Histórico */}
          {tabSelected === 1 && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Paper>
                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Icon>filter_list</Icon>
                      <Typography className={classes.heading}>
                        Filtros
                      </Typography>
                    </ExpansionPanelSummary>
                    {/* Painel de filtro */}
                    <ExpansionPanelDetails>
                      <Grid container spacing={2} justify="flex-end">
                        {/* Atendente */}
                        <Grid item xs={12}>
                          <InputSearchComp
                            required
                            title="Buscar de atendentes:"
                            label="Atendente:"
                            value={
                              historicoFiltro.atendente.id
                                ? `${historicoFiltro.atendente.id}-${historicoFiltro.atendente.descricao}`
                                : ''
                            }
                            htmlId="historico-filtro-atendente"
                            iptSize="small"
                            size="md"
                            searching={false}
                            data={funcionariosFiliais.funcionarios.map(f => ({
                              ...f,
                              id: f.id,
                              descricao: f.nome,
                            }))}
                            onSearch={handleSearchFuncionarios}
                            onSelectItem={atendente =>
                              setHistoricoFiltro(s => ({
                                ...s,
                                atendente,
                              }))
                            }
                            onClickClear={() =>
                              setHistoricoFiltro(s => ({
                                ...s,
                                atendente: {},
                              }))
                            }
                          />
                        </Grid>
                        {/* Search */}
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            id="historico-filtro-search"
                            label="O.S/Placa/Modelo/Nome do Cliente."
                            size="small"
                            variant="outlined"
                            value={historicoFiltro.search}
                            onChange={({ target: { value = '' } }) =>
                              setHistoricoFiltro(s => ({
                                ...s,
                                search: value.toUpperCase(),
                              }))
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            type="datetime-local"
                            id="historico-filtro-abertura-ini"
                            label="Abertura (inicial)"
                            size="small"
                            variant="outlined"
                            value={historicoFiltro.dataAberturaIni}
                            onChange={({ target: { value = '' } }) =>
                              setHistoricoFiltro(s => ({
                                ...s,
                                dataAberturaIni: value,
                              }))
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            type="datetime-local"
                            id="historico-filtro-fechamento-ini"
                            label="Fechamento (inicial)"
                            size="small"
                            variant="outlined"
                            value={historicoFiltro.dataFechamentoIni}
                            onChange={({ target: { value = '' } }) =>
                              setHistoricoFiltro(s => ({
                                ...s,
                                dataFechamentoIni: value,
                              }))
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            type="datetime-local"
                            id="historico-filtro-entrega-ini"
                            label="Entrega (inicial)"
                            size="small"
                            variant="outlined"
                            value={historicoFiltro.dataEntregaIni}
                            onChange={({ target: { value = '' } }) =>
                              setHistoricoFiltro(s => ({
                                ...s,
                                dataEntregaIni: value,
                              }))
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            type="datetime-local"
                            id="historico-filtro-pagamento-ini"
                            label="Pagamento (inicial)"
                            size="small"
                            variant="outlined"
                            value={historicoFiltro.dataPagamentoIni}
                            onChange={({ target: { value = '' } }) =>
                              setHistoricoFiltro(s => ({
                                ...s,
                                dataPagamentoIni: value,
                              }))
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            type="datetime-local"
                            id="historico-filtro-abertura-fim"
                            label="Abertura (final)"
                            size="small"
                            variant="outlined"
                            value={historicoFiltro.dataAberturaFim}
                            onChange={({ target: { value = '' } }) =>
                              setHistoricoFiltro(s => ({
                                ...s,
                                dataAberturaFim: value,
                              }))
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            type="datetime-local"
                            id="historico-filtro-fechamento-fim"
                            label="Fechamento (final)"
                            size="small"
                            variant="outlined"
                            value={historicoFiltro.dataFechamentoFim}
                            onChange={({ target: { value = '' } }) =>
                              setHistoricoFiltro(s => ({
                                ...s,
                                dataFechamentoFim: value,
                              }))
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            type="datetime-local"
                            id="historico-filtro-entrega-fim"
                            label="Entrega (final)"
                            size="small"
                            variant="outlined"
                            value={historicoFiltro.dataEntregaFim}
                            onChange={({ target: { value = '' } }) =>
                              setHistoricoFiltro(s => ({
                                ...s,
                                dataEntregaFim: value,
                              }))
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            type="datetime-local"
                            id="historico-filtro-pagamento-fim"
                            label="Pagamento (final)"
                            size="small"
                            variant="outlined"
                            value={historicoFiltro.dataPagamentoFim}
                            onChange={({ target: { value = '' } }) =>
                              setHistoricoFiltro(s => ({
                                ...s,
                                dataPagamentoFim: value,
                              }))
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Icon>filter_list/</Icon>}
                            onClick={handleFiltrarHistorico}
                          >
                            Filtrar!
                          </Button>
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12}>
                <Paper className={classes.root}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>AÇÕES</TableCell>
                          {tableHistoricoCols
                            ?.filter(
                              c =>
                                (isOrcamentos && c.key != 'veiculo') ||
                                !isOrcamentos
                            )
                            ?.map(c => (
                              <TableCell key={c.key} align={c.align || 'left'}>
                                {c.title}
                              </TableCell>
                            ))}
                          <TableCell>OBS./SERVIÇO(S)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {historicoLoading && (
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              colSpan={tableHistoricoCols.length}
                            >
                              <CircularProgress
                                className={classes.progress}
                                color="primary"
                              />
                            </TableCell>
                          </TableRow>
                        )}
                        {!historicoLoading &&
                          (!_.isArray(historicoData) ||
                            _.isEmpty(historicoData)) && (
                            <TableRow>
                              <TableCell
                                component="th"
                                scope="row"
                                colSpan={tableHistoricoCols.length}
                              >
                                Sem registro(s).
                              </TableCell>
                            </TableRow>
                          )}
                        {!historicoLoading &&
                          _.isArray(historicoData) &&
                          historicoData.map(row => {
                            const dtAbertura = DateIsoToBrFormat(
                              row.dataAbertura,
                              'DD/MM à\\s HH:mm'
                            )
                            const dtFechamento = DateIsoToHours(
                              row.dataFechamento
                            )
                            const dtEntrega = DateIsoToHours(row.dataEntrega)
                            const restante = DateIsoDiff(
                              row.dataAbertura,
                              row.dataFechamento,
                              'minutes'
                            )
                            let rowColor = 'rgba(60, 118, 61, 0.1)'
                            rowColor =
                              restante > 0 && restante < 30
                                ? 'rgba(245, 252, 30, 0.2)'
                                : rowColor
                            rowColor =
                              restante < 0 ? 'rgba(220, 0, 78, 0.1)' : rowColor
                            return (
                              <TableRow
                                hover
                                key={row.id}
                                style={{ backgroundColor: rowColor }}
                              >
                                <TableCell component="td" scope="row">
                                  <TableActionsComp
                                    row={row}
                                    actions={tableHistoricoActions}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <Chip
                                    label={row.statusDescricao}
                                    className={classes[`status${row.status}`]}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  {`${dtAbertura}/`}
                                  <b>{`${dtEntrega}/`}</b>
                                  {row.dataFechamento
                                    ? `${dtFechamento}/`
                                    : '--:--/'}
                                  {restante ? `${restante}min` : '0min'}
                                </TableCell>
                                <TableCell>{padLeft(row.id, 6, '0')}</TableCell>
                                {!isOrcamentos && (
                                  <TableCell align="center">
                                    <Typography
                                      variant="body2"
                                      component="span"
                                    >
                                      {row.veiculosPlaca}
                                      <br />
                                      <Typography
                                        className={classes.font12}
                                        component="p"
                                        color="textSecondary"
                                      >
                                        <b>{row.veiculosModelo}</b>/
                                        {row.veiculosCor}
                                      </Typography>
                                    </Typography>
                                  </TableCell>
                                )}
                                <TableCell align="center">
                                  <Typography variant="body2" component="span">
                                    {row.clientesNome}
                                    <br />
                                    <Typography
                                      className={classes.font12}
                                      component="p"
                                      color="textSecondary"
                                    >
                                      {row.clientesCelular}
                                    </Typography>
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <HtmlTooltipComp
                                    placement="top"
                                    title={
                                      <>
                                        <em>
                                          {`${row.itens.length} serviço(s) `}
                                        </em>
                                        <b>{'desconto de '}</b>
                                        <u>{`R$ ${MoneyFormatBr(
                                          parseToNum(row.descontoValor)
                                        )}.`}</u>
                                        <br />
                                      </>
                                    }
                                  >
                                    <Typography variant="body2" component="p">
                                      {`R$ ${MoneyFormatBr(
                                        parseToNum(row.totalLiquido)
                                      )}`}
                                    </Typography>
                                  </HtmlTooltipComp>
                                </TableCell>
                                <TableCell align="center">
                                  <HtmlTooltipComp
                                    placement="top"
                                    title={<em>{row.observacao}</em>}
                                  >
                                    <IconButton
                                      disabled={!row.observacao}
                                      color="secondary"
                                    >
                                      <Icon>info</Icon>
                                    </IconButton>
                                  </HtmlTooltipComp>
                                  <HtmlTooltipComp
                                    placement="top"
                                    title={
                                      <div>
                                        {row.itens.map((i, index) => (
                                          <div key={i.id}>
                                            <em>{`${index + 1}) ${
                                              i.tabelasPrecoItensServicosDescricao
                                            }`}</em>{' '}
                                            ({i.statusDescricao}
                                            )
                                            <br />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <IconButton color="secondary">
                                      <Icon>assignment</Icon>
                                    </IconButton>
                                  </HtmlTooltipComp>
                                </TableCell>
                              </TableRow>
                            )
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12}>
                <Pagination
                  count={historicoLoading ? 1 : ordemServico.list.total}
                  page={currentPage + 1}
                  onChange={handleChangePage}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* DialogForm (add/edit) ordem de serviço */}
      <DialogComp
        scroll="body"
        size="lg"
        open={dialogForm.open}
        title={dialogForm.title}
        onClose={() => {
          onOrdemServicoFormClean()
          handleOpenCloseDialogForm(false)
        }}
      >
        <OrdemServicoFormComp
          isOrcamentos={isOrcamentos}
          onOpen={data => {
            handleOpenCloseDialogClientesForm(true, 'Novo cliente:', data)
          }}
          onClose={() => handleOpenCloseDialogClientesForm(false)}
          onRealoadGrid={() => {
            handleFilterMonitoramentoData({ search })
            handleOpenCloseDialogForm(false)
          }}
        />
      </DialogComp>
      {/* DialogForm novo CLIENTE (add/edit) */}
      <DialogComp
        scroll="body"
        size="md"
        open={dialogClientesForm.open}
        title={dialogClientesForm.title}
        onClose={() => {
          handleOpenCloseDialogClientesForm(false)
          // onOrdemServicoFormClean()
          // handleOpenCloseDialogForm(false)
        }}
        actions={[]}
      >
        <ClientesVeiculosFormComp
          token={session.token}
          data={dialogClientesForm.data}
          filial={session.user.filiais.find(f => f.id === session.user.filial)}
          onCallback={payload => {
            const clientes = payload.entity
            const veiculo =
              _.isArray(clientes.veiculos) && !_.isEmpty(clientes.veiculos)
                ? clientes.veiculos[0]
                : null
            delete clientes.veiculos
            const veiculos = { ...veiculo, clientes }
            onOrdemServicoFormUpdate({ veiculos })
            handleOpenCloseDialogClientesForm(false)
          }}
        />
      </DialogComp>
      {/* DialogForm FECHAMENTO da ordem de serviço */}
      <DialogComp
        scroll="body"
        size="md"
        open={dialogFechamentoOS}
        title={`Fechamento da Ordem de Serviço: ${padLeft(
          ordemServicoSelected.id || '0',
          6,
          '0'
        )}`}
        onClose={() => {
          setOrdemServicoSelected({})
          handleOpenCloseDialogFechamentoOS(false)
        }}
        actions={[
          {
            label: 'FECHAR O.S',
            onClick: handleConfirmaFechamentoOrdemServico,
            loading: loadingFechamentoOS,
            variant: 'contained',
          },
        ]}
      >
        {dialogFechamentoOS && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Paper>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Serviço</TableCell>
                      <TableCell>Funcionário</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ordemServicoSelected &&
                      ordemServicoSelected.itens &&
                      ordemServicoSelected.itens.map(s => (
                        <TableRow>
                          <TableCell>
                            {s.tabelasPrecoItensServicosDescricao}
                          </TableCell>
                          <TableCell>
                            <Select
                              disabled={funcionariosFiliais.loading}
                              fullWidth
                              labelId="funcionariosFechamentoOs"
                              id="funcionariosFechamentoOs"
                              value={
                                funcionariosFiliais.loading
                                  ? 'LOADING'
                                  : funcionariosFiliais.funcionarioServico.find(
                                      fs => fs.ordemServicoItemId === s.id
                                    ).funcionariosId || s.funcionarios.id
                              }
                              onChange={evt =>
                                handleChangeFuncionarioServico(evt, s.id)
                              }
                            >
                              {funcionariosFiliais.loading ? (
                                <MenuItem value="LOADING">
                                  CARREGANDO...
                                </MenuItem>
                              ) : null}
                              {funcionariosFiliais.funcionarios.map(f => (
                                <MenuItem key={f.id} value={f.id}>
                                  {f.nome}
                                </MenuItem>
                              ))}
                            </Select>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12}>
              <Paper>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Vistoria realizada?</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Select
                          fullWidth
                          labelId="vistoriaFechamentoOs"
                          id="vistoriaFechamentoOs"
                          value={funcionariosFiliais.vistoria}
                          onChange={handleChangeVistoria}
                        >
                          <MenuItem value="S">SIM</MenuItem>
                          <MenuItem value="N">NAO</MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        )}
      </DialogComp>
      {/* Dialog de perguntas genericas */}
      <DialogPerguntaComp
        open={DialogPergunta.open}
        title={DialogPergunta.title}
        ask={DialogPergunta.ask}
        onOk={DialogPergunta.onOk}
        onClose={DialogPergunta.onClose}
      />
      {/* Dialog de PAGAMENTO */}
      <DialogComp
        scroll="body"
        size="md"
        open={dialogPagamento}
        title={`Pagamento da Ordem de Serviço: ${padLeft(
          ordemServicoSelected.id || '0',
          6,
          '0'
        )}`}
        onClose={() => {
          setOrdemServicoSelected({})
          setDialogPagamento(false)
        }}
      >
        <FormCaixasMovimentoComp
          item={ordemServicoSelected}
          onReload={() => {
            handleFilterMonitoramentoData({ search })
            setOrdemServicoSelected({})
            setDialogPagamento(false)
          }}
        />
      </DialogComp>
      {/* Dialog de OBSERVAÇÃO da O.S */}
      <DialogComp
        scroll="body"
        size="sm"
        open={dialogObsOrdem.open}
        title={`Observação da Ordem de Serviço: ${padLeft(
          ordemServicoSelected.id || '0',
          6,
          '0'
        )}`}
        onClose={() => handleObsOrdemOpenClose(false)}
        actions={[
          {
            label: 'SALVAR',
            onClick: () => {
              const dto = {
                id: ordemServicoSelected.id,
                observacao: dialogObsOrdem.observacao,
              }
              handleObsOrdemLoading(true)
              onOrdemServicoUpdateObsAction(dto, () => {
                handleObsOrdemLoading(false)
                handleObsOrdemOpenClose(false)
                handleFilterMonitoramentoData({ search })
              })
            },
            loading: dialogObsOrdem.loading,
          },
        ]}
      >
        <TextField
          fullWidth
          multiline
          rows={4}
          id="clientes-nome"
          label="Observação"
          margin="dense"
          variant="outlined"
          value={dialogObsOrdem.observacao}
          onChange={evt => handleObsOrdemIpt(evt.target.value.toUpperCase())}
        />
      </DialogComp>
    </DefaultPage>
  )
}

OrdemServicoPage.propTypes = {
  session: PropTypes.shape().isRequired,
  caixas: PropTypes.shape().isRequired,
  ordemServico: PropTypes.shape().isRequired,
  onOrdemServicoDataList: PropTypes.func.isRequired,
  onOrdemServicoFormClean: PropTypes.func.isRequired,
  onOrdemServicoFormUpdate: PropTypes.func.isRequired,
  onOrdemServicoUpdateObsAction: PropTypes.func.isRequired,
  onOrdemServicoFechamentoAction: PropTypes.func.isRequired,
  onOrdemServicoCancelamentoAction: PropTypes.func.isRequired,
  onOrdemServicoDataById: PropTypes.func.isRequired,
  onOrdemServicoMonitoramentoDataList: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  session: state.auth.session,
  ordemServico: state.ordemServico,
  caixas: state.caixas,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...OrdemServicoActions,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(OrdemServicoPage)
