import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid, TextField, FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core'
// import _ from 'lodash'

// Api's
// import { listFiliaisApi } from 'api/filiais'

// Components
// import InputSearchComp from 'components/Inputs/Search'

// Styles
import useStyles from './styles'

// Stateless
const FuncionariosFormComp = ({ data, onChange }) => {
  const {
    // filiais = {},
    // filiaisId,
    filiaisDescricao,
    matricula,
    nome,
    email,
    pai,
    mae,
    nascimento,
    rg,
    cpf,
    logradouro,
    numero,
    complemento,
    bairro,
    cidade,
    estado,
    cep,
    telefone,
    celular,
    status,
    lavador,
    atendente,
  } = data
  const classes = useStyles()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  const inputLabelLavador = useRef(null)
  const [labelWidthLavador, setLabelWidthLavador] = useState(0)
  const inputLabelAtendente = useRef(null)
  const [labelWidthAtendente, setLabelWidthAtendente] = useState(0)
  /*
  const [searchFilial, setSearchFilial] = useState({
    loading: false,
    payload: { page: 0, rows: 0, data: [] },
  })
  const handleSearchFilialLoading = (loading) => setSearchFilial((s) => ({ ...s, loading }))
  const handleSearchFilialLoadData = (payload) => setSearchFilial((s) => ({ ...s, payload }))
  const handleSearchFiliais = (pSearch) => {
    handleSearchFilialLoading(true)
    return listFiliaisApi({ token, filter: { search: pSearch } })
      .then((payload) => {
        handleSearchFilialLoading(false)
        if (payload && !_.isEmpty(payload.data)) {
          const dtos = payload.data.map(({ id, descricao }) => ({ id, descricao }))
          handleSearchFilialLoadData({ page: 0, rows: dtos.length, data: dtos })
        } else {
          handleSearchFilialLoadData({ page: 0, rows: 0, data: [] })
        }
      })
  }
  */

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
    setLabelWidthLavador(inputLabelLavador.current.offsetWidth)
    setLabelWidthAtendente(inputLabelAtendente.current.offsetWidth)
  }, [])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          // disabled
          id="funcionarios-filiais"
          label="Filial:"
          margin="normal"
          variant="outlined"
          value={filiaisDescricao || ''}
          // onChange={(evt) => onChange({ matricula: evt.target.value.toUpperCase() })}
        />
        {/*
        <InputSearchComp
          required
          title="Buscar filiais:"
          label="Descrição da filial:"
          value={_.isEmpty(filiais) ? '' : filiais.descricao}
          htmlId="iptSearchFiliais"
          size="md"
          searching={false}
          data={searchFilial.payload.data}
          onSearch={handleSearchFiliais}
          onSelectItem={(item) => onChange({ filiais: item })}
          onClickClear={() => onChange({ filiais: {} })}
        />
        */}
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          id="funcionarios-matricula"
          label="Matrícula:"
          margin="normal"
          variant="outlined"
          value={matricula}
          onChange={(evt) => onChange({ matricula: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <TextField
          required
          fullWidth
          id="funcionarios-nome"
          label="Nome:"
          margin="normal"
          variant="outlined"
          value={nome}
          onChange={(evt) => onChange({ nome: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="funcionarios-email"
          label="E-mail:"
          margin="normal"
          variant="outlined"
          value={email}
          onChange={(evt) => onChange({ email: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="funcionarios-pai"
          label="Nome do PAI:"
          margin="normal"
          variant="outlined"
          value={pai}
          onChange={(evt) => onChange({ pai: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="funcionarios-mae"
          label="Nome da MÃE:"
          margin="normal"
          variant="outlined"
          value={mae}
          onChange={(evt) => onChange({ mae: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          type="date"
          id="funcionarios-nascimento"
          label="Data de nascimento:"
          margin="normal"
          variant="outlined"
          value={nascimento}
          onChange={(evt) => onChange({ nascimento: evt.target.value })}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="funcionarios-rg"
          label="RG:"
          margin="normal"
          variant="outlined"
          value={rg}
          onChange={(evt) => onChange({ rg: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="funcionarios-cpf"
          label="CPF:"
          margin="normal"
          variant="outlined"
          value={cpf}
          onChange={(evt) => onChange({ cpf: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={10}>
        <TextField
          fullWidth
          id="funcionarios-logradouro"
          label="Logradouro:"
          margin="normal"
          variant="outlined"
          value={logradouro}
          onChange={(evt) => onChange({ logradouro: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          fullWidth
          id="funcionarios-numero"
          label="Número:"
          margin="normal"
          variant="outlined"
          value={numero}
          onChange={(evt) => onChange({ numero: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          id="funcionarios-complemento"
          label="Complemento:"
          margin="normal"
          variant="outlined"
          value={complemento}
          onChange={(evt) => onChange({ complemento: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <TextField
          fullWidth
          id="funcionarios-bairro"
          label="Bairro:"
          margin="normal"
          variant="outlined"
          value={bairro}
          onChange={(evt) => onChange({ bairro: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="funcionarios-cidade"
          label="Cidade:"
          margin="normal"
          variant="outlined"
          value={cidade}
          onChange={(evt) => onChange({ cidade: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          fullWidth
          id="funcionarios-estado"
          label="Estado:"
          margin="normal"
          variant="outlined"
          value={estado}
          onChange={(evt) => onChange({ estado: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="funcionarios-cep"
          label="CEP:"
          margin="normal"
          variant="outlined"
          value={cep}
          onChange={(evt) => onChange({ cep: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          id="funcionarios-telefone"
          label="Telefone:"
          margin="normal"
          variant="outlined"
          value={telefone}
          onChange={(evt) => onChange({ telefone: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          id="funcionarios-celular"
          label="Celular:"
          margin="normal"
          variant="outlined"
          value={celular}
          onChange={(evt) => onChange({ celular: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl required variant="outlined" fullWidth margin="normal" className={classes.dataControl}>
          <InputLabel ref={inputLabel}>
            Status
          </InputLabel>
          <Select
            id="funcionarios-status"
            value={status}
            onChange={(evt) => onChange({ status: evt.target.value.toUpperCase() })}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="P">PENDENTE</MenuItem>
            <MenuItem value="L">LIBERADO</MenuItem>
            <MenuItem value="B">BLOQUEADO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl required variant="outlined" fullWidth margin="normal" className={classes.dataControl}>
          <InputLabel ref={inputLabelLavador}>
            É um lavador?
          </InputLabel>
          <Select
            id="funcionarios-lavador"
            value={lavador}
            onChange={(evt) => onChange({ lavador: evt.target.value.toUpperCase() })}
            labelWidth={labelWidthLavador}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="S">SIM</MenuItem>
            <MenuItem value="N">NÃO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl required variant="outlined" fullWidth margin="normal" className={classes.dataControl}>
          <InputLabel ref={inputLabelAtendente}>
            É um atendente?
          </InputLabel>
          <Select
            id="funcionarios-atendente"
            value={atendente}
            onChange={(evt) => onChange({ atendente: evt.target.value.toUpperCase() })}
            labelWidth={labelWidthAtendente}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="S">SIM</MenuItem>
            <MenuItem value="N">NÃO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

FuncionariosFormComp.propTypes = {
  // token: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}

export default FuncionariosFormComp
