import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
// import { useLocation } from 'react-router-dom'
import {
  Grid,
  Breadcrumbs,
  Typography,
  Link,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Icon,
  Fab,
  Tooltip,
  Badge,
  CircularProgress,
  Backdrop,
  Paper,
} from '@material-ui/core'
import { TreeView, TreeItem } from '@material-ui/lab'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

// * Actions
import { Actions as VendasActions } from 'store/ducks/vendas'
import { Actions as DashboardActions } from 'store/ducks/dashboard'

// * Utils
import { parseToNum } from 'utils'

// * Any components
import { getThumbnailProdutos } from 'utils'
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import Card from 'components/Vendor/CreativeTim/Card/Card'
import CardHeader from 'components/Vendor/CreativeTim/Card/CardHeader'
import CardIcon from 'components/Vendor/CreativeTim/Card/CardIcon'
import CardFooter from 'components/Vendor/CreativeTim/Card/CardFooter'
import { grayColor } from 'components/Vendor/CreativeTim/assets/material-dashboard-react'
import CardProdutoComp from '../../components/card_produto'

// * Styles
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  cardContainer: {
    marginBottom: '0px',
  },
  cardCategory: {
    color: grayColor[0],
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    // fontFamily: ''Roboto', 'Helvetica', 'Arial', sans-serif',
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  stats: {
    color: grayColor[0],
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px',
    },
  },
  rootTree: {
    height: 'auto',
    flexGrow: 1,
    maxWidth: 400,
    paddingTop: 10,
    paddingBottom: 10,
  },
  ctnBtnShopCart: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    flexDirection: 'column',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
  },
}))

/*
function useQuery() {
  const { search } = useLocation()
  const ret = new URLSearchParams(search)
  return React.useMemo(() => ret, [search])
}
*/

const PedidosVendasDashboardPage = ({
  history,
  session: { user },
  vendas: {
    loading,
    data: { categories, list, cart },
  },
  dashboard: { vendasV1 },
  onVendasSearchProducts,
  onVendasCartItensInsert,
  onVendasGetCategoriasSumProdutos,
  onDashboardDataLoadVendasV1,
}) => {
  const classes = useStyles()
  // const query = useQuery()
  const [categorySelected, setCategorySelected] = useState(0)
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  // const [totalPage, setTotalPage] = useState(0)

  const handleIptSearchChange = evt => setSearch(evt?.target?.value ?? '')

  const handleSearch = pStartPage => {
    const toPage =
      pStartPage != null && pStartPage !== undefined ? pStartPage : currentPage
    onVendasSearchProducts(
      { search, page: toPage, categoriaId: categorySelected },
      data => {
        const queryUrl = new URLSearchParams(window.location.search)
        const page = +(queryUrl.get('page') ?? '0')
        // const total = +(queryUrl.get('total') ?? '0')
        const totalDb = data?.total ?? 0
        history.push({
          pathname: '/vendas',
          search: `?page=${pStartPage || page}&total=${totalDb}`,
        })
        /*
      const page = +(query.get('page') ?? '0')
      const totalUrl = +(query.get('total') ?? '0') // || data?.total ?? '0'
      const total = totalUrl || (data?.total ?? '0')
      history.push({
        pathname: '/vendas',
        search: `?page=${page}&total=${total}`
      })
      */
      }
    )
  }

  const handleSelectCategoria = (event, nodeIds) => {
    if (nodeIds === categorySelected) {
      setCategorySelected(0)
    } else {
      setCategorySelected(nodeIds)
    }
  }

  //* Infinity scroll
  const isBottom = () => {
    const { innerHeight, pageYOffset } = window
    return innerHeight + Math.ceil(pageYOffset) >= document.body.offsetHeight
  }
  const nextPage = () => {
    const queryUrl = new URLSearchParams(window.location.search)
    const page = +(queryUrl.get('page') ?? '0')
    const total = +(queryUrl.get('total') ?? '0')
    const newPage = page + 1
    if (page < total) {
      setCurrentPage(newPage)
      history.push({
        pathname: '/vendas',
        search: `?page=${newPage}&total=${total}`,
      })
    }
  }
  const trackScrolling = () => {
    const wrappedElement = document.getElementById('root')
    if (isBottom(wrappedElement)) {
      nextPage()
    }
  }

  useEffect(() => {
    handleSearch('0')
  }, [categorySelected])

  useEffect(() => {
    handleSearch(currentPage)
  }, [currentPage])

  useEffect(() => {
    document.title = 'Backoffice - Vendas'
    document.addEventListener('scroll', trackScrolling)
    onVendasGetCategoriasSumProdutos()
    handleSearch('0')
    onDashboardDataLoadVendasV1({ usuariosId: user.id })
    return () => document.removeEventListener('scroll', trackScrolling)
  }, [])

  return (
    <DefaultPage>
      <Grid container spacing={2}>
        {/* Header */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <PageHeaderComp title="Dashboard" />
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="textPrimary">Vendas</Typography>
                <Link color="inherit" href="/vendas">
                  Dashboard
                </Link>
              </Breadcrumbs>
            </Grid>
            <Grid item className={classes.ctnBtnShopCart}>
              <Tooltip title="Carrinho de compras">
                <Badge badgeContent={cart.itens.length} color="secondary">
                  <Fab
                    color="primary"
                    aria-label="Carrinho de compras"
                    className={classes.margins}
                    onClick={() => history.push('/vendas/carrinho')}
                  >
                    <Icon>shopping_cart</Icon>
                  </Fab>
                </Badge>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {/* Cards Dashboard */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {/* Total de Pedidos */}
            <Grid item xs={12} md={3}>
              <Card className={classes.cardContainer}>
                <CardHeader color="info" stats icon>
                  <CardIcon color="info">
                    <Icon>local_mall</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>
                    Total de pedidos até hoje
                  </p>
                  <Typography
                    variant="h5"
                    gutterBottom
                    className={classes.cardTitle}
                  >
                    {vendasV1?.totalPedidos ?? 0}
                  </Typography>
                  <p className={classes.cardCategory}>
                    {`Neste mês ${vendasV1?.totalPedidosMes ?? 0}`}
                  </p>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats} />
                </CardFooter>
              </Card>
            </Grid>
            {/* Total de Pedidos Confirmados */}
            <Grid item xs={12} md={3}>
              <Card>
                <CardHeader color="success" stats icon>
                  <CardIcon color="success">
                    <Icon>verified_user</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Pedidos Confirmados</p>
                  <Typography
                    variant="h5"
                    gutterBottom
                    className={classes.cardTitle}
                  >
                    {vendasV1?.totalPedidosConfirmados ?? 0}
                  </Typography>
                  <p className={classes.cardCategory}>
                    {`Neste mês ${vendasV1?.totalPedidosConfirmadosMes ?? 0}`}
                  </p>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats} />
                </CardFooter>
              </Card>
            </Grid>
            {/* Total de Pedidos Em Aberto */}
            <Grid item xs={12} md={3}>
              <Card>
                <CardHeader color="warning" stats icon>
                  <CardIcon color="warning">
                    <Icon>hourglass_empty</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Pedidos Em Aberto</p>
                  <Typography
                    variant="h5"
                    gutterBottom
                    className={classes.cardTitle}
                  >
                    {vendasV1?.totalPedidosEmAberto ?? 0}
                  </Typography>
                  <p className={classes.cardCategory}>
                    {`Neste mês ${vendasV1?.totalPedidosEmAbertoMes ?? 0}`}
                  </p>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats} />
                </CardFooter>
              </Card>
            </Grid>
            {/* Total de Pedidos Cancelados */}
            <Grid item xs={12} md={3}>
              <Card>
                <CardHeader color="danger" stats icon>
                  <CardIcon color="danger">
                    <Icon>close</Icon>
                  </CardIcon>
                  <p className={classes.cardCategory}>Pedidos Cancelados</p>
                  <Typography
                    variant="h5"
                    gutterBottom
                    className={classes.cardTitle}
                  >
                    {vendasV1?.totalPedidosCancelados ?? 0}
                  </Typography>
                  <p className={classes.cardCategory}>
                    {`Neste mês ${vendasV1?.totalPedidosCanceladosMes ?? 0}`}
                  </p>
                </CardHeader>
                <CardFooter stats>
                  <div className={classes.stats} />
                </CardFooter>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        {/* Tree Catagorias */}
        <Grid item xs={12} sm={2}>
          <Paper>
            <TreeView
              defaultExpanded={['0']}
              selected={[categorySelected]}
              className={classes.rootTree}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              onNodeSelect={handleSelectCategoria}
            >
              <TreeItem nodeId="0" label="Categorias">
                {loading.categories && <CircularProgress size={16} />}
                {!loading.categories &&
                  categories?.map(c => (
                    <TreeItem
                      nodeId={c.id}
                      label={`${c.descricao} (${c.total})`}
                    />
                  ))}
              </TreeItem>
              {/*
            <TreeItem nodeId="F1" label="Salvos para mais tarde">
              <TreeItem nodeId="F2" label="BARRA DE CEREAL NUTRY MORANGO" />
            </TreeItem>
            */}
            </TreeView>
          </Paper>
        </Grid>
        {/* Pesquisa/Lista de produtos */}
        <Grid item xs={12} sm={10}>
          <Grid container spacing={2}>
            {/* Form de Pesquisa */}
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="ipt-search-products">Pesquisar</InputLabel>
                <OutlinedInput
                  id="ipt-search-products"
                  onChange={handleIptSearchChange}
                  onKeyPress={evt => {
                    if (evt.charCode === 13) {
                      handleSearch()
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Pesquisar produtos"
                        onClick={handleSearch}
                        edge="end"
                      >
                        <Icon>search</Icon>
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            </Grid>
            {(loading?.search ?? false) && <CircularProgress />}
            {!(loading?.search ?? false) &&
              list?.data?.map(p => (
                <Grid item xs={12} sm={3}>
                  <CardProdutoComp
                    imageUrl={getThumbnailProdutos(p)}
                    title={p.descricao}
                    subtitle={`SKU: ${p?.sku ?? ''}, U.M: ${p?.um ??
                      ''}, NCM: ${p?.ncm ?? ''}, PESO: ${parseToNum(p?.peso) ??
                      ''}`}
                    value={p.preco}
                    valuePromo={p.precoPromo}
                    addItemToFavorite={() => null}
                    addItemToCart={() => onVendasCartItensInsert(p)}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={loading?.scrolling ?? false}>
        <CircularProgress color="inherit" />
        Carregando registros aguarde...
      </Backdrop>
    </DefaultPage>
  )
}

PedidosVendasDashboardPage.propTypes = {
  history: PropTypes.shape().isRequired,
  session: PropTypes.shape().isRequired,
  vendas: PropTypes.shape().isRequired,
  dashboard: PropTypes.shape().isRequired,
  onVendasSearchProducts: PropTypes.func.isRequired,
  onVendasCartItensInsert: PropTypes.func.isRequired,
  onVendasGetCategoriasSumProdutos: PropTypes.func.isRequired,
  onDashboardDataLoadVendasV1: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  session: state.auth.session,
  vendas: state.vendas,
  dashboard: state.dashboard,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...VendasActions,
      ...DashboardActions,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PedidosVendasDashboardPage)
