// import _ from 'lodash'
import { NotificationManager } from 'react-notifications'
import { listProdutosApi } from 'api/produtos'
import { getCategoriasSumProdutosApi } from 'api/categorias'
import {
  getPedidosVendasApi,
  addPedidosVendasApi,
  cancelPedidosVendasApi,
  // deletePedidosVendasApi,
  listPedidosVendasApi,
} from 'api/pedidos/vendas'
import { DateTimeIsoNow, parseToDec, parseToNum } from 'utils'
import { push } from 'connected-react-router'
import _ from 'lodash'

// State Usuario
const initialState = {
  loading: {
    categories: false,
    search: false,
    load: false,
    insert: false,
    update: false,
    delete: false,
    cancel: false,
    scrolling: false,
  },
  data: {
    categories: [],
    list: { page: 0, rows: 0, data: [] },
    form: {},
    cart: {
      observacoes: '',
      itens: [],
    },
    meuspedidos: {
      pedido: {},
      list: { page: 0, rows: 0, data: [] },
    },
  },
}

export const Types = {
  RESET: 'reset',
  VENDAS_LOADING: 'vendas/LOADING',
  VENDAS_PAYLOAD_UPDATE_CATEGORIES: 'vendas/VENDAS_PAYLOAD_UPDATE_CATEGORIES',
  VENDAS_PAYLOAD_UPDATE_LIST: 'vendas/VENDAS_PAYLOAD_UPDATE_LIST',
  VENDAS_PAYLOAD_UPDATE_FORM: 'vendas/VENDAS_PAYLOAD_UPDATE_FORM',
  VENDAS_PAYLOAD_UPDATE_CART: 'vendas/VENDAS_PAYLOAD_UPDATE_CART',
  VENDAS_CART_ITENS_INSERT: 'vendas/VENDAS_CART_ITENS_INSERT',
  VENDAS_CART_ITENS_UPDATE: 'vendas/VENDAS_CART_ITENS_UPDATE',
  VENDAS_CART_ITENS_DELETE: 'vendas/VENDAS_CART_ITENS_DELETE',
  VENDAS_CART_CLEAN: 'vendas/VENDAS_CART_CLEAN',
  VENDAS_PAYLOAD_UPDATE_MEUSPEDIDOS_LIST: 'vendas/VENDAS_PAYLOAD_UPDATE_MEUSPEDIDOS_LIST',
  VENDAS_PAYLOAD_UPDATE_MEUSPEDIDOS_PEDIDO: 'vendas/VENDAS_PAYLOAD_UPDATE_MEUSPEDIDOS_PEDIDO',
}

export default function vendasReducers(state = initialState, action) {
  switch (action.type) {
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      }
    case Types.VENDAS_LOADING:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    case Types.VENDAS_PAYLOAD_UPDATE_CATEGORIES:
      return {
        ...state,
        data: {
          ...state.data,
          categories: action.payload,
        },
      }
    case Types.VENDAS_PAYLOAD_UPDATE_LIST:
      return {
        ...state,
        data: {
          ...state.data,
          list: action.payload,
        },
      }
    case Types.VENDAS_PAYLOAD_UPDATE_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          form: action.payload,
        },
      }
    case Types.VENDAS_PAYLOAD_UPDATE_CART:
      return {
        ...state,
        data: {
          ...state.data,
          cart: {
            ...state.data.cart,
            ...action.payload,
          },
        },
      }
    case Types.VENDAS_CART_ITENS_INSERT:
      return {
        ...state,
        data: {
          ...state.data,
          cart: {
            ...state.data.cart,
            itens: [...state.data.cart.itens, action.payload],
          },
        },
      }
    case Types.VENDAS_CART_ITENS_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          cart: {
            ...state.data.cart,
            itens: state.data.cart.itens.map((i) => {
              if (i.id === action.payload.id) {
                return { ...i, ...action.payload }
              }
              return i
            }),
          },
        },
      }
    case Types.VENDAS_CART_ITENS_DELETE:
      return {
        ...state,
        data: {
          ...state.data,
          cart: {
            ...state.data.cart,
            itens: state.data.cart.itens.filter((i) => i.id !== action.payload.id),
          },
        },
      }
    case Types.VENDAS_CART_CLEAN:
      return {
        ...state,
        data: {
          ...state.data,
          cart: initialState.data.cart,
        },
      }
    case Types.VENDAS_PAYLOAD_UPDATE_MEUSPEDIDOS_LIST:
      return {
        ...state,
        data: {
          ...state.data,
          meuspedidos: {
            ...state.data.meuspedidos,
            list: action.payload,
          },
        },
      }
    case Types.VENDAS_PAYLOAD_UPDATE_MEUSPEDIDOS_PEDIDO:
      return {
        ...state,
        data: {
          ...state.data,
          meuspedidos: {
            ...state.data.meuspedidos,
            pedido: action.payload,
          },
        },
      }
    default:
      return state
  }
}

export const Actions = {
  //* Store Update
  onVendasLoading: (payload) => ({ type: Types.VENDAS_LOADING, payload }),
  onVendasUpdateList: (payload) => ({ type: Types.VENDAS_PAYLOAD_UPDATE_LIST, payload }),
  onVendasUpdateForm: (payload) => ({ type: Types.VENDAS_PAYLOAD_UPDATE_FORM, payload }),
  onVendasUpdateCart: (payload) => ({ type: Types.VENDAS_PAYLOAD_UPDATE_CART, payload }),
  onVendasCartItensInsert: (payload) => (dispatch, getState) => {
    const {
      vendas: { data: { cart } },
    } = getState()
    //* Verifica se já existe o item no carrinho
    const existItem = cart.itens.find((i) => i.id === payload.id)
    if (existItem) {
      dispatch(Actions.onVendasCartItensUpdate({
        ...existItem, qtd: existItem.qtd += parseToDec(1),
      }))
    } else {
      dispatch({
        type: Types.VENDAS_CART_ITENS_INSERT,
        payload: { ...payload, qtd: parseToDec(1) },
      })
    }
  },
  onVendasCartItensUpdate: (payload) => ({ type: Types.VENDAS_CART_ITENS_UPDATE, payload }),
  onVendasCartItensDelete: (payload) => ({ type: Types.VENDAS_CART_ITENS_DELETE, payload }),
  onVendasCartClean: () => ({ type: Types.VENDAS_CART_CLEAN }),
  //* Lista de categorias
  onVendasGetCategoriasSumProdutos: () => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onVendasLoading({ categories: true }))
    getCategoriasSumProdutosApi({ token }).then((httpResponse) => {
      dispatch(({ type: Types.VENDAS_PAYLOAD_UPDATE_CATEGORIES, payload: httpResponse }))
    }).catch((e) => NotificationManager.error(
      `Não foi possível listar as categorias dos produtos, motivo: "${e}".`,
      'Ops!',
      8000,
    )).finally(() => {
      dispatch(Actions.onVendasLoading({ categories: false }))
    })
  },
  //* Pesquisa de produtos
  onVendasSearchProducts: (pFilter, onCallback) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
      vendas: { data: { list } },
    } = getState()
    const {
      id, categoriaId, tabelaPrecoId, status = 'L', search, page = 0, rows = 50,
    } = pFilter
    dispatch(Actions.onVendasLoading({ search: false, scrolling: false }))
    if (page > 0) {
      dispatch(Actions.onVendasLoading({ scrolling: true }))
    } else {
      dispatch(Actions.onVendasLoading({ search: true }))
    }
    listProdutosApi({
      token,
      filter: {
        id, categoriaId, tabelaPrecoId, status, search, page, rows,
      },
    }).then((httpResponse) => {
      if (page > 0) {
        const payload = {
          ...httpResponse, data: [...list.data, ...httpResponse.data],
        }
        dispatch(Actions.onVendasUpdateList(payload))
        if (_.isFunction(onCallback)) {
          onCallback(payload)
        }
      } else {
        dispatch(Actions.onVendasUpdateList(httpResponse))
        if (_.isFunction(onCallback)) {
          onCallback(httpResponse)
        }
      }
    }).catch((e) => NotificationManager.error(
      `Não foi possível pesquisar os produtos motivo: "${e}".`,
      'Ops!',
      8000,
    )).finally(() => {
      if (page > 0) {
        dispatch(Actions.onVendasLoading({ scrolling: false }))
      } else {
        dispatch(Actions.onVendasLoading({ search: false }))
      }
    })
  },
  //* Finalizar Pedido de Venda
  onVendasFinalizarPedidoVenda: (callback) => (dispatch, getState) => {
    const {
      auth: { session: { token, user } },
      vendas: { data: { cart } },
    } = getState()
    dispatch(Actions.onVendasLoading({ insert: true }))
    const usuarioId = user?.id ?? 0
    const unidadeId = user?.filial ?? 0
    const clienteId = user?.cliente ?? 0
    const pedidosStatusId = 1
    const currentCliente = user.clientes.find((c) => c.id === clienteId)
    const vendedorId = currentCliente?.vendedoresId ?? 0
    const tabelasPrecoId = currentCliente?.tabelasPrecoId ?? 0
    const sumReducer = (i, o) => i + parseToNum(o.qtd * (o.precoPromo || o.preco))
    const valorTotal = cart?.itens?.reduce(sumReducer, 0)
    const observacoes = cart?.observacoes ?? ''
    const dto = {
      emissao: DateTimeIsoNow(),
      usuarioId,
      vendedorId,
      clienteId,
      unidadeId,
      pedidosStatusId,
      tabelasPrecoId,
      valorFrete: 0,
      valorDesconto: 0,
      valorAcrescimo: 0,
      valorTroco: 0,
      valorTotal,
      observacoes,
      pedidoPadrao: false,
      pedidoPadraoDescricao: '',
      itens: cart.itens.map((i, idx) => ({
        item: (idx + 1),
        produtosId: i.id,
        quantidade: i.qtd,
        peso: i.peso,
        valorUnitario: (i.precoPromo || i.preco),
        valorDesconto: 0,
        valorTotal: parseToNum(i.qtd * (i.precoPromo || i.preco)),
        descricao: i.descricao,
      })),
    }
    addPedidosVendasApi({
      token, dto,
    }).then((httpResponse) => {
      const { status, entity } = httpResponse
      if (status === 201) {
        NotificationManager.success(
          `O pedido ${entity.id}/${entity.ref} foi criado com sucesso!`,
          'Sucesso!',
          8000,
        )
        dispatch(Actions.onVendasCartClean())
        dispatch(push('/vendas'))
      }
    }).catch((e) => console.log(e))
      .finally(() => {
        if (_.isFunction(callback)) {
          callback()
        }
        dispatch(Actions.onVendasLoading({ insert: false }))
      })
  },
  //* Cancelar Pedido de Venda
  onVendasPedidoVendaCancelar: (id, callback) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onVendasLoading({ cancel: true }))
    cancelPedidosVendasApi({ token, id }).then((httpResponse) => {
      const { status, entity } = httpResponse
      if (status === 200) {
        NotificationManager.success(
          `O pedido ${entity.id}/${entity.ref} foi cancelado com sucesso!`,
          'Sucesso!',
          8000,
        )
      }
      console.log(httpResponse)
      if (_.isFunction(callback)) {
        callback()
      }
    }).catch((e) => {
      console.log(e)
    }).finally(() => {
      dispatch(Actions.onVendasLoading({ cancel: false }))
    })
  },
  //* Get Pedido By Id
  onVendasPedidosById: (id) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onVendasLoading({ load: true }))
    getPedidosVendasApi({ id, token })
      .then((httpResponse) => {
        dispatch(({ type: Types.VENDAS_PAYLOAD_UPDATE_MEUSPEDIDOS_PEDIDO, payload: httpResponse }))
      }).catch((e) => console.log(e))
      .finally(() => {
        dispatch(Actions.onVendasLoading({ true: true }))
      })
  },
  //* Filtro de pedidos de vendas
  onVendasPedidoVendaList: (pFilter) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onVendasLoading({ search: true }))
    listPedidosVendasApi({ token, filter: pFilter })
      .then((httpResponse) => {
        dispatch(({ type: Types.VENDAS_PAYLOAD_UPDATE_MEUSPEDIDOS_LIST, payload: httpResponse }))
      }).catch((e) => console.log(e))
      .finally(() => {
        dispatch(Actions.onVendasLoading({ search: false }))
      })
  },
}
