import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

// Actions
import { Actions as FuncionariosActions } from 'store/ducks/funcionarios'

// Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import TableDataComp from 'components/Table'
import DialogComp from 'components/Dialogs'
import FuncionariosFormComp from './components/FuncionariosForm'
// Styles
// import useStyles from './styles'

const FuncionariosPage = ({
  session: { token, user },
  funcionarios,
  onFuncionariosDataList,
  onFuncionariosFormUpdate,
  onFuncionariosFormClean,
  onFuncionariosDataSave,
  onFuncionariosDataDelete,
}) => {
  // const classes = useStyles()
  const [search, setSearch] = useState('')
  const [dialogForm, setDialogForm] = useState({ open: false, title: '' })
  const [dialogAskDelete, setDialogAskDelete] = useState({ open: false, title: '', data: null })

  // Header Actions >>
  const handleFiltraFuncionarios = (filter) => {
    onFuncionariosDataList({ ...filter, filiais: user.filial })
  }
  const handleSearch = () => {
    handleFiltraFuncionarios({ search })
  }
  const handleOpenCloseDialogForm = (open, title = null) => {
    if (title) {
      setDialogForm((s) => ({ ...s, open, title }))
    } else {
      setDialogForm((s) => ({ ...s, open }))
    }
  }
  const handleOpenCloseDialogAskDelete = (open, title = null, data = null) => {
    setDialogAskDelete((s) => ({
      ...s, open, title, data,
    }))
  }
  const headerButtons = [
    {
      title: 'Adicionar',
      icon: 'add',
      action: () => {
        onFuncionariosFormClean()
        const filiaisCurrent = user.filiais.find((f) => f.id === user.filial)
        onFuncionariosFormUpdate({
          filiaisId: filiaisCurrent.id,
          filiaisDescricao: filiaisCurrent.descricao,
        })
        handleOpenCloseDialogForm(true, 'Cadastro de funcionário:')
      },
    },
  ]
  // Header Actions <<

  // Table Actions >>
  const tableCols = [
    { key: 'nome', title: 'NOME' },
    { key: 'email', title: 'E-MAIL' },
    { key: 'statusDescricao', title: 'STATUS' },
  ]
  const tableActions = [
    {
      key: 'editar',
      title: 'Editar',
      icon: 'edit',
      action: (row) => {
        const form = {
          ...row,
          filiaisId: row.filiais.id,
          filiaisDescricao: row.filiais.descricao,
        }
        delete form.filiais
        onFuncionariosFormUpdate(form)
        handleOpenCloseDialogForm(true, 'Edição de funcionário:')
      },
    },
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: (row) => handleOpenCloseDialogAskDelete(true, 'Excluir?', row),
    },
  ]
  // Table Actions <<

  useEffect(() => {
    document.title = 'Backoffice - Funcionários'
    handleFiltraFuncionarios({ search })
  }, [user.filial])

  return (
    <DefaultPage>
      <PageHeaderComp
        title="Funcionários"
        // subtitle={empresasDesc}
        value={search}
        onChange={(evt) => setSearch(evt.target.value)}
        onClick={handleSearch}
        onKeyPress={(evt) => {
          if (evt.charCode === 13) {
            handleSearch()
          }
        }}
        buttons={headerButtons}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TableDataComp
            loading={funcionarios.loading.list}
            size="small"
            rows={funcionarios.list.data}
            cols={tableCols}
            actions={tableActions}
          />
        </Grid>
      </Grid>
      {/* DialogForm (add/edit) */}
      <DialogComp
        open={dialogForm.open}
        title={dialogForm.title}
        onClose={() => handleOpenCloseDialogForm(false)}
        actions={[
          {
            label: 'Cancelar',
            onClick: () => {
              onFuncionariosFormClean()
              handleOpenCloseDialogForm(false)
            },
          },
          {
            loading: funcionarios.loading.add || funcionarios.loading.edit,
            label: 'Salvar',
            onClick: () => {
              onFuncionariosDataSave(funcionarios.form, () => {
                onFuncionariosFormClean()
                handleOpenCloseDialogForm(false)
                handleFiltraFuncionarios({ search })
              })
            },
          },
        ]}
      >
        <FuncionariosFormComp
          token={token}
          data={funcionarios.form}
          onChange={onFuncionariosFormUpdate}
        />
      </DialogComp>
      {/* Dialog Ask DELETE */}
      <DialogComp
        open={dialogAskDelete.open}
        title={dialogAskDelete.title}
        onClose={() => handleOpenCloseDialogAskDelete(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDelete(false),
          },
          {
            label: 'SIM',
            onClick: () => onFuncionariosDataDelete(dialogAskDelete.data.id, () => {
              handleOpenCloseDialogAskDelete(false)
              handleFiltraFuncionarios({ search })
            }),
          },
        ]}
      >
        {`Você deseja realmente excluir o funcionário "${dialogAskDelete.data ? dialogAskDelete.data.nome : ''}"?`}
      </DialogComp>
    </DefaultPage>
  )
}

FuncionariosPage.propTypes = {
  session: PropTypes.shape().isRequired,
  funcionarios: PropTypes.shape().isRequired,
  onFuncionariosDataList: PropTypes.func.isRequired,
  onFuncionariosFormUpdate: PropTypes.func.isRequired,
  onFuncionariosFormClean: PropTypes.func.isRequired,
  onFuncionariosDataSave: PropTypes.func.isRequired,
  onFuncionariosDataDelete: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
  funcionarios: state.funcionarios,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...FuncionariosActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FuncionariosPage)
