import React, { useEffect, useState, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Grid, FormControl, InputLabel, Select, MenuItem, TextField, Typography,
  Card, CardContent, CardActions,
} from '@material-ui/core'
import { NotificationManager } from 'react-notifications'

// Api's
import { relatFaturamentos } from 'api/relatorios'

// Actions
import { Actions as PagamentosFormaActions } from 'store/ducks/pagamentos/forma'

// Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import ButtonLoadingComp from 'components/Buttons/ButtonLoading'

import { downloadExcelFile } from 'utils'

// Styles
// import useStyles from './styles'

// Stateless component
const RelatoriosFaturamentosPage = ({
  session,
  pagamentosForma,
  onPagamentosFormaDataList,
}) => {
  // const classes = useStyles()
  const inputLabel = useRef(null)
  const inputLabelPgtos = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  const [labelWidthPgtos, setLabelWidthPgtos] = useState(0)
  const {
    user,
    token,
  } = session

  // Form
  const [loading, setLoading] = useState(false)
  const [filiais, setFiliais] = useState(session.user.filial)
  const [formasPagamentos, setFormasPagamentos] = useState([])
  const [formasPagamentosOpen, setFormasPagamentosOpen] = useState(false)
  const [dataInicial, setDataInicial] = useState('')
  const [dataFinal, setDataFinal] = useState('')
  const [formato, setFormato] = useState('pdf')

  const listFormasPgto = _.orderBy(pagamentosForma.list.data, ['id'], ['asc'])
  listFormasPgto.push({ id: 'ALL', descricao: 'TODOS' })

  const handleSelectItem = (value) => {
    setFormasPagamentos(value)
    const filterAll = (i) => i === 'ALL'
    const filterNotAll = (i) => i !== 'ALL'
    if (value.findIndex(filterAll) > 0) {
      setFormasPagamentos(value.filter(filterAll))
      setFormasPagamentosOpen(false)
    }
    if (value.findIndex(filterAll) === 0 && value.length > 1) {
      setFormasPagamentos(value.filter(filterNotAll))
    }
  }

  const handleFiltrar = () => {
    let valid = true
    // Validações
    if (!filiais) {
      NotificationManager.warning('Filial inválida!', 'Atenção', 8000)
      valid = false
    }
    if (valid && (!dataInicial || !dataFinal)) {
      NotificationManager.warning('Data inicial ou final inválida!', 'Atenção', 8000)
      valid = false
    }
    if (valid) {
      setLoading(true)
      relatFaturamentos({
        token,
        filter: {
          filiaisId: filiais,
          dataInicial,
          dataFinal,
          formasPagamentos,
          formato,
        },
      }).then((resp) => {
        if (resp) {
          if (formato == 'pdf') {
            window.open(URL.createObjectURL(new Blob([resp], { type: 'application/pdf' })))
          }
          if (formato == 'excel') {
            downloadExcelFile(new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'faturamento.xlsx')
          }
        }
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    document.title = 'Backoffice - Relatório/Faturamentos'
    setLabelWidth(inputLabel.current.offsetWidth)
    setLabelWidthPgtos(inputLabelPgtos.current.offsetWidth)
    onPagamentosFormaDataList()
  }, [])

  return (
    <DefaultPage>
      <PageHeaderComp
        title="Relatório - Faturamentos"
      />
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Filtro
              </Typography>
              <Grid container spacing={2}>
                {/* Filiais */}
                <Grid item xs={12} md={12}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel ref={inputLabel} id="relatorios-faturamentos-filiais-label">
                      Filiais
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="relatorios-faturamentos-filiais-label"
                      id="relatorios-faturamentos-filiais-select"
                      size="small"
                      margin="dense"
                      value={filiais || ''}
                      onChange={(evt) => setFiliais(evt.target.value)}
                      labelWidth={labelWidth}
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {user.filiais
                        .filter((f) => f.status === 'L')
                        .map((f) => (
                          <MenuItem key={f.id} value={f.id}>
                            {f.descricao}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Formas de pagamentos */}
                <Grid item xs={12} md={12}>
                  <FormControl variant="outlined" fullWidth margin="dense" multiple>
                    <InputLabel ref={inputLabelPgtos} id="relatorios-faturamentos-pgtos-label">
                      Formas de pagamentos
                    </InputLabel>
                    <Select
                      fullWidth
                      multiple
                      open={formasPagamentosOpen}
                      onClose={() => setFormasPagamentosOpen(false)}
                      onOpen={() => setFormasPagamentosOpen(true)}
                      labelId="relatorios-faturamentos-pgtos-label"
                      id="relatorios-faturamentos-pgtos-select"
                      size="small"
                      margin="dense"
                      value={formasPagamentos}
                      onChange={(evt) => handleSelectItem(evt.target.value)}
                      labelWidth={labelWidthPgtos}
                    >
                      {_.orderBy(listFormasPgto, ['descricao'], ['desc']).map((f) => <MenuItem key={f.id} value={f.id}>{f.descricao}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Data inicial */}
                <Grid item xs={6} md={6}>
                  <TextField
                    fullWidth
                    id="date"
                    label="Data inicial"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={dataInicial}
                    onChange={(evt) => setDataInicial(evt.target.value)}
                  />
                </Grid>
                {/* Data final */}
                <Grid item xs={6} md={6}>
                  <TextField
                    fullWidth
                    id="date"
                    label="Data final"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={dataFinal}
                    onChange={(evt) => setDataFinal(evt.target.value)}
                  />
                </Grid>
                {/* Formas de pagamentos */}
                <Grid item xs={12} md={12}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel id="relatorios-faturamentos-formato">
                      Formato
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="relatorios-faturamentos-formato"
                      id="relatorios-faturamentos-formato"
                      label="Formato"
                      size="small"
                      margin="dense"
                      value={formato}
                      onChange={(evt) => setFormato(evt.target.value)}
                      // labelWidth={labelWidthPgtos}
                    >
                      <MenuItem value="pdf">PDF</MenuItem>
                      <MenuItem value="excel">EXCEL</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <ButtonLoadingComp loading={loading} size="small" variant="contained" color="primary" onClick={handleFiltrar}>
                Filtrar
              </ButtonLoadingComp>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </DefaultPage>
  )
}

RelatoriosFaturamentosPage.propTypes = {
  session: PropTypes.shape().isRequired,
  pagamentosForma: PropTypes.shape().isRequired,
  onPagamentosFormaDataList: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
  pagamentosForma: state.pagamentosForma,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...PagamentosFormaActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelatoriosFaturamentosPage)
