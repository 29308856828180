import React, { useEffect, useState, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core'
import { NotificationManager } from 'react-notifications'

// Api's
import { relatClientesDiasOff } from 'api/relatorios'

// Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import ButtonLoadingComp from 'components/Buttons/ButtonLoading'

// Styles
// import useStyles from './styles'

// Stateless component
const RelatoriosClientesOffPage = ({ session }) => {
  // const classes = useStyles()
  const { user, token } = session
  const inputLabelFiliais = useRef(null)
  const inputLabelDias = useRef(null)
  const [labelWidthFiliais, setLabelWidthFiliais] = useState(0)
  const [labelWidthDias, setLabelWidthDias] = useState(0)
  // Form
  const [loading, setLoading] = useState(false)
  const [filiais, setFiliais] = useState(session.user.filial)
  const [filiaisOpen, setFiliaisOpen] = useState(false)
  const [dias, setDias] = useState(['30'])
  const [diasOpen, setDiasOpen] = useState(false)
  const listDias = [
    { value: '30', label: '30 Dias' },
    { value: '60', label: '60 Dias' },
    { value: '90', label: '90 Dias' },
    { value: '183', label: '6 Meses' },
    { value: '335', label: '11 Meses' },
  ]
  const handleSelectFiliais = value => {
    setFiliais(value)
    const filterAll = i => i === 'TODAS'
    const filterNotAll = i => i !== 'TODAS'
    if (value.findIndex(filterAll) > 0) {
      setFiliais(value.filter(filterAll))
      setFiliaisOpen(false)
    }
    if (value.findIndex(filterAll) === 0 && value.length > 1) {
      setFiliais(value.filter(filterNotAll))
    }
  }
  const handleSelectDias = value => {
    setDias(value)
  }
  const handleFiltrar = async () => {
    let valid = true
    // Validações
    if (!filiais) {
      NotificationManager.warning('Filial inválida!', 'Atenção', 8000)
      valid = false
    }
    if (valid) {
      setLoading(true)
      const resp = await relatClientesDiasOff({
        token,
        filter: {
          filiaisId: filiais,
          dias,
        },
      })
      if (resp) {
        const fileName = listDias.find(d => d.value == dias)
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = URL.createObjectURL(
          new Blob([resp], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        )
        a.download = `Relatorios_${fileName.label.replace(' ', '_')}_off`
        a.click()
        setLoading(false)
      } else {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    document.title = 'Backoffice - Relatório/Clientes/Dias Off'
    setLabelWidthFiliais(inputLabelFiliais.current.offsetWidth)
    setLabelWidthDias(inputLabelDias.current.offsetWidth)
  }, [])

  return (
    <DefaultPage>
      <PageHeaderComp title="Relatório - Clientes Dias Off" />
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Filtro
              </Typography>
              <Grid container spacing={2}>
                {/* Filiais */}
                <Grid item xs={12} md={12}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel
                      ref={inputLabelFiliais}
                      id="relatorios-clientes-filiais-label"
                    >
                      Filiais
                    </InputLabel>
                    <Select
                      // multiple
                      fullWidth
                      labelId="relatorios-clientes-filiais-label"
                      id="relatorios-clientes-filiais-select"
                      size="small"
                      margin="dense"
                      value={filiais}
                      open={filiaisOpen}
                      onClose={() => setFiliaisOpen(false)}
                      onOpen={() => setFiliaisOpen(true)}
                      onChange={evt => handleSelectFiliais(evt.target.value)}
                      labelWidth={labelWidthFiliais}
                    >
                      <MenuItem value="TODAS">TODAS</MenuItem>
                      {_.orderBy(
                        user.filiais.filter(s => s.id !== 'TODAS'),
                        ['descricao'],
                        ['asc']
                      ).map(({ id, descricao, status }) => (
                        <MenuItem key={id} value={id}>
                          {`#${id} - ${descricao} ${
                            status === 'B' ? '<BLOQUEADA>' : ''
                          }`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Dias */}
                <Grid item xs={12} md={12}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel
                      ref={inputLabelDias}
                      id="relatorios-clientes-dias-label"
                    >
                      Dias
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="relatorios-clientes-dias-label"
                      id="relatorios-clientes-dias-select"
                      size="small"
                      margin="dense"
                      value={dias}
                      open={diasOpen}
                      onClose={() => setDiasOpen(false)}
                      onOpen={() => setDiasOpen(true)}
                      onChange={evt => handleSelectDias(evt.target.value)}
                      labelWidth={labelWidthDias}
                    >
                      {listDias.map(d => (
                        <MenuItem key={d.value} value={d.value}>
                          {d.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <ButtonLoadingComp
                loading={loading}
                size="small"
                variant="contained"
                color="primary"
                onClick={handleFiltrar}
              >
                Filtrar
              </ButtonLoadingComp>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </DefaultPage>
  )
}

RelatoriosClientesOffPage.propTypes = {
  session: PropTypes.shape().isRequired,
}

const mapStateToProps = state => ({
  session: state.auth.session,
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RelatoriosClientesOffPage)
