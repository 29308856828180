import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid, TextField, FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core'

// Components
import { PlacaMaskCustom } from 'components/Inputs/Masked'

// Styles
import useStyles from './styles'

// Stateless
const VeiculosFormComp = ({ data, onChange }) => {
  const {
    clientes = {},
    placa = '',
    modelo = '',
    cor = '',
    fabricante = '',
    porte = '',
    proprietario = '',
    status = '',
  } = data
  const classes = useStyles()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="veiculos-clientes-nome"
          label="Cliente"
          margin="normal"
          variant="outlined"
          value={`${clientes.nome}, ${clientes.email} ${clientes.celular}`}
          // onChange={(evt) => onChange({ cnpj: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          InputProps={{
            inputComponent: PlacaMaskCustom,
          }}
          id="veiculos-placa"
          label="Placa"
          margin="normal"
          variant="outlined"
          value={placa}
          onChange={(evt) => onChange({ placa: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          id="veiculos-modelo"
          label="Modelo"
          margin="normal"
          variant="outlined"
          value={modelo}
          onChange={(evt) => onChange({ modelo: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          id="veiculos-cor"
          label="Cor"
          margin="normal"
          variant="outlined"
          value={cor}
          onChange={(evt) => onChange({ cor: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          id="veiculos-fabricante"
          label="Fabricante"
          margin="normal"
          variant="outlined"
          value={fabricante}
          onChange={(evt) => onChange({ fabricante: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
          className={classes.dataControl}
        >
          <InputLabel ref={inputLabel}>Porte</InputLabel>
          <Select
            id="veiculos-porte"
            value={porte}
            onChange={(evt) => onChange({ porte: evt.target.value.toUpperCase() })}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="P">PEQUENO</MenuItem>
            <MenuItem value="G">GRANDE</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
          className={classes.dataControl}
        >
          <InputLabel ref={inputLabel}>Proprietário</InputLabel>
          <Select
            id="veiculos-proprietario"
            value={proprietario}
            onChange={(evt) => onChange({ proprietario: evt.target.value.toUpperCase() })}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="S">SIM</MenuItem>
            <MenuItem value="N">NÃO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
          className={classes.dataControl}
        >
          <InputLabel ref={inputLabel}>Status</InputLabel>
          <Select
            id="veiculos-status"
            value={status}
            onChange={(evt) => onChange({ status: evt.target.value.toUpperCase() })}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="P">PENDENTE</MenuItem>
            <MenuItem value="L">LIBERADO</MenuItem>
            <MenuItem value="B">BLOQUEADO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

VeiculosFormComp.propTypes = {
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}

export default VeiculosFormComp
