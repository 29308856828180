import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const ls = '@ls-dn-userlocal'
const pathLogin = '/login'
const pathForgot = '/auth/forgot'

export const authApi = ({ username: usuario, password: senha, origem = 'portal' }) => api
  .post(pathLogin, JSON.stringify({ usuario, senha, origem }))
  .then(httpValidState)
  .catch(httpValidErros)

export const forgotApi = ({ username, password }) => api
  .post(pathForgot, JSON.stringify({ username, password }))
  .then(httpValidState)
  .catch(httpValidErros)

export const setUserLocal = (payload) => localStorage.setItem(ls, btoa(JSON.stringify(payload)))
export const getUserLocal = () => {
  const payload = localStorage.getItem(ls)
  return JSON.parse(payload === null ? null : atob(payload))
}
export const delUserLocal = () => localStorage.removeItem(ls)
