import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid, Paper, Tabs, Tab } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import _ from 'lodash'

import { hasRole } from 'utils'
import UserRoles from 'utils/enums/UserRoles'

// Actions
import { Actions as ClientesActions } from 'store/ducks/clientes'
import { Actions as VeiculosActions } from 'store/ducks/veiculos'

// Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import TableDataComp from 'components/Table'
import DialogComp from 'components/Dialogs'
import ClientesFormComp from './components/ClientesForm'
import ClientesBasicFormComp from 'pages/clientes/components/ClientesBasicForm'
import VeiculosFormComp from './components/VeiculosForm'
// Styles
import useStyles from './styles'

// Stateless component
const ClientesPage = ({
  // Clientes
  session,
  clientes,
  onClientesFormUpdate,
  onClientesFormClean,
  onClientesDataList,
  onClientesDataSave,
  onClientesDataDelete,
  // Veiculos
  veiculos,
  onVeiculosFormUpdate,
  onVeiculosFormNew,
  onVeiculosFormClean,
  onVeiculosDataList,
  onVeiculosDataSave,
  onVeiculosDataDelete,
}) => {
  const classes = useStyles()
  const [currentPage, setCurrentPage] = useState(0)
  const [search, setSearch] = useState('')
  const [dialogForm, setDialogForm] = useState({ open: false, title: '' })
  const [dialogAskDelete, setDialogAskDelete] = useState({
    open: false,
    title: '',
    data: null,
  })
  const [dialogFormVeiculos, setDialogFormVeiculos] = useState({
    open: false,
    title: '',
  })
  const [dialogAskDeleteVeiculos, setDialogAskDeleteVeiculos] = useState({
    open: false,
    title: '',
    data: null,
  })
  const [tabSelected, setTabSelected] = useState(0)
  const [clientesSelected, setClientesSelected] = useState({
    id: 0,
    cnpj: '',
    nome: '',
    status: '',
    veiculos: [],
  })
  const handleFiltraClientes = filter => {
    onClientesDataList(filter)
  }
  const handleFiltraVeiculos = filter => {
    onVeiculosDataList({
      ...filter,
      clientes: filter.clientes || clientesSelected.id,
    })
  }
  const handleSelectTab = (event, newValue) => setTabSelected(newValue)
  const handleSearch = pNewPage => {
    switch (tabSelected) {
      case 0:
        handleFiltraClientes({ search, page: pNewPage })
        break
      case 1:
        handleFiltraVeiculos({ search })
        break
      default:
        handleFiltraClientes({ search, page: pNewPage })
    }
  }
  // Table/Aba clientes (INICIO)
  const handleOpenCloseDialogClientes = (open, title = null) => {
    if (title) {
      setDialogForm(s => ({ ...s, open, title }))
    } else {
      setDialogForm(s => ({ ...s, open }))
    }
  }
  const handleOpenCloseDialogAskDelete = (open, title = null, data = null) => {
    setDialogAskDelete(s => ({
      ...s,
      open,
      title,
      data,
    }))
  }
  const handleSelectClientes = pClientes => {
    setClientesSelected(pClientes)
    setTabSelected(1)
    handleFiltraVeiculos({ clientes: pClientes.id, search })
  }
  const handleChangePage = (event, value) => {
    const newPage = (value ?? 1) - 1
    setCurrentPage(newPage)
    handleSearch(newPage)
  }
  // * Usado para filtrar O.S para usuários da SofaComZelo
  const isOrcamentos = hasRole(
    session?.user?.grupos ?? [],
    UserRoles?.ROLE_ORCAMENTOS?.codigo ?? ''
  )
  const tableClientesCols = isOrcamentos
    ? [
        { key: 'id', title: '#ID' },
        { key: 'cgc', title: 'CGC', onClick: row => handleSelectClientes(row) },
        { key: 'nome', title: 'NOME' },
        { key: 'statusDescricao', title: 'STATUS' },
      ]
    : [
        { key: 'id', title: '#ID' },
        { key: 'cgc', title: 'CGC', onClick: row => handleSelectClientes(row) },
        { key: 'nome', title: 'NOME' },
        {
          key: 'veiculos',
          title: 'VEÍCULOS',
          format: listVeiculos =>
            `${_.isArray(listVeiculos) ? listVeiculos.length : 0} veículo(s)`,
        },
        { key: 'statusDescricao', title: 'STATUS' },
      ]
  const tableClientesActions = [
    {
      key: 'editar',
      title: 'Editar',
      icon: 'edit',
      action: row => {
        onClientesFormUpdate(row)
        handleOpenCloseDialogClientes(true, 'Edição de clientes:')
      },
    },
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: row => handleOpenCloseDialogAskDelete(true, 'Excluir?', row),
    },
  ]
  // Table/Aba clientes (FIM)

  // Table/Aba veiculos (INICIO)

  const handleOpenCloseDialogVeiculos = (open, title = null) => {
    if (title) {
      setDialogFormVeiculos(s => ({ ...s, open, title }))
    } else {
      setDialogFormVeiculos(s => ({ ...s, open }))
    }
  }
  const handleOpenCloseDialogAskDeleteVeiculos = (
    open,
    title = null,
    data = null
  ) => {
    setDialogAskDeleteVeiculos(s => ({
      ...s,
      open,
      title,
      data,
    }))
  }
  const tableVeiculosCols = [
    { key: 'id', title: '#ID' },
    { key: 'placa', title: 'PLACA' },
    { key: 'modelo', title: 'MODELO' },
    { key: 'cor', title: 'COR' },
    { key: 'porteDescricao', title: 'PORTE' },
    { key: 'proprietarioDescricao', title: 'PROPRIETÁRIO' },
    { key: 'statusDescricao', title: 'STATUS' },
  ]
  const tableVeiculosActions = [
    {
      key: 'editar',
      title: 'Editar',
      icon: 'edit',
      action: row => {
        onVeiculosFormUpdate(row)
        handleOpenCloseDialogVeiculos(true, 'Edição de veículos:')
      },
    },
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: row =>
        handleOpenCloseDialogAskDeleteVeiculos(true, 'Excluir?', row),
    },
  ]
  // Table/Aba veiculos (FIM)

  // Header button
  const headerButtons = [
    {
      title: 'Adicionar',
      icon: 'add',
      action: () => {
        if (!tabSelected) {
          onClientesFormClean()
          handleOpenCloseDialogClientes(true, 'Cadastro de clientes:')
        } else {
          onVeiculosFormNew({ clientes: clientesSelected })
          handleOpenCloseDialogVeiculos(true, 'Cadastro de veículos:')
        }
      },
    },
  ]
  // Header button

  useEffect(() => {
    document.title = 'Backoffice - Clientes'
    handleFiltraClientes({ search })
  }, [])

  const clientesDesc =
    tabSelected > 0 && clientesSelected.id
      ? `[${clientesSelected.id}] - ${clientesSelected.nome}`
      : ''

  return (
    <DefaultPage>
      <PageHeaderComp
        title="Clientes"
        subtitle={clientesDesc}
        value={search}
        onChange={evt => setSearch(evt.target.value)}
        onClick={() => handleSearch(currentPage)}
        onKeyPress={evt => {
          if (evt.charCode === 13) {
            handleSearch(currentPage)
          }
        }}
        buttons={headerButtons}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Paper className={classes.root}>
            <Tabs
              value={tabSelected}
              onChange={handleSelectTab}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Clientes" />
              {!isOrcamentos && (
                <>
                  <Tab label="Veiculos" disabled={!clientesSelected.id} />
                  <Tab label="Usuários" disabled={!clientesSelected.id} />
                  <Tab label="Pedidos" disabled={!clientesSelected.id} />
                  <Tab
                    label="Ordem de Serviços"
                    disabled={!clientesSelected.id}
                  />
                  <Tab label="Unidades" disabled={!clientesSelected.id} />
                </>
              )}
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* Listagem das clientes */}
          {tabSelected === 0 && (
            <TableDataComp
              loading={clientes.loading.list}
              size="small"
              rows={clientes.list.data}
              cols={tableClientesCols}
              actions={tableClientesActions}
            />
          )}
          {/* Listagem das veiculos */}
          {tabSelected === 1 && (
            <TableDataComp
              loading={veiculos.loading.list}
              size="small"
              rows={veiculos.list.data}
              cols={tableVeiculosCols}
              actions={tableVeiculosActions}
            />
          )}
        </Grid>
        {tabSelected === 0 && (
          <Grid item xs={12} md={12}>
            <Pagination
              count={clientes?.loading?.list ? 1 : clientes.list.total}
              page={currentPage + 1}
              onChange={handleChangePage}
            />
          </Grid>
        )}
      </Grid>
      {/* Dialog ClientesForm (add/edit) */}
      <DialogComp
        size="md"
        open={dialogForm.open}
        title={dialogForm.title}
        onClose={() => handleOpenCloseDialogClientes(false)}
        actions={[
          {
            label: 'Cancelar',
            onClick: () => {
              onClientesFormClean()
              handleOpenCloseDialogClientes(false)
            },
          },
          {
            label: 'Salvar',
            onClick: () => {
              onClientesDataSave(clientes.form, () => {
                onClientesFormClean()
                handleOpenCloseDialogClientes(false)
                handleFiltraClientes({ search })
              })
            },
          },
        ]}
      >
        {isOrcamentos ? (
          <ClientesBasicFormComp
            data={clientes.form}
            onChange={onClientesFormUpdate}
          />
        ) : (
          <ClientesFormComp
            data={clientes.form}
            onChange={onClientesFormUpdate}
          />
        )}
      </DialogComp>
      {/* Dialog Pergunta DELETE CLIENTES */}
      <DialogComp
        open={dialogAskDelete.open}
        title={dialogAskDelete.title}
        onClose={() => handleOpenCloseDialogAskDelete(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDelete(false),
          },
          {
            label: 'SIM',
            onClick: () =>
              onClientesDataDelete(dialogAskDelete.data.id, () => {
                handleOpenCloseDialogAskDelete(false)
                handleFiltraClientes({ search })
              }),
          },
        ]}
      >
        {`Você deseja realmente excluir o cliente "${
          dialogAskDelete.data ? dialogAskDelete.data?.nome.trim() : ''
        }"?`}
      </DialogComp>
      {/* Dialog Veiculos (add/edit) */}
      <DialogComp
        open={dialogFormVeiculos.open}
        title={dialogFormVeiculos.title}
        size="md"
        onClose={() => handleOpenCloseDialogVeiculos(false)}
        actions={[
          {
            label: 'Cancelar',
            onClick: () => {
              onVeiculosFormClean()
              handleOpenCloseDialogVeiculos(false)
            },
          },
          {
            label: 'Salvar',
            onClick: () => {
              const veiculo = veiculos.form
              delete veiculo.clientes.veiculos
              onVeiculosDataSave({ ...veiculo }, () => {
                onVeiculosFormClean()
                handleOpenCloseDialogVeiculos(false)
                onVeiculosDataList({ clientes: clientesSelected.id })
              })
            },
          },
        ]}
      >
        <VeiculosFormComp
          data={veiculos.form}
          onChange={onVeiculosFormUpdate}
        />
      </DialogComp>
      {/* Dialog Pergunta DELETE VEÍCULOS */}
      <DialogComp
        open={dialogAskDeleteVeiculos.open}
        title={dialogAskDeleteVeiculos.title}
        onClose={() => handleOpenCloseDialogAskDeleteVeiculos(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDeleteVeiculos(false),
          },
          {
            label: 'SIM',
            loading: veiculos.loading.del,
            onClick: () =>
              onVeiculosDataDelete(dialogAskDeleteVeiculos.data.id, () => {
                handleOpenCloseDialogAskDeleteVeiculos(false)
                handleFiltraVeiculos({ search })
              }),
          },
        ]}
      >
        {`Você deseja realmente excluir o veículo "${
          dialogAskDeleteVeiculos.data
            ? `${dialogAskDeleteVeiculos.data.modelo}/${dialogAskDeleteVeiculos.data.placa}`
            : ''
        }"?`}
      </DialogComp>
    </DefaultPage>
  )
}

ClientesPage.propTypes = {
  session: PropTypes.shape().isRequired,
  // Clientes
  clientes: PropTypes.shape().isRequired,
  onClientesFormUpdate: PropTypes.func.isRequired,
  onClientesFormClean: PropTypes.func.isRequired,
  onClientesDataList: PropTypes.func.isRequired,
  onClientesDataSave: PropTypes.func.isRequired,
  onClientesDataDelete: PropTypes.func.isRequired,
  // Veiculos
  veiculos: PropTypes.shape().isRequired,
  onVeiculosFormUpdate: PropTypes.func.isRequired,
  onVeiculosFormNew: PropTypes.func.isRequired,
  onVeiculosFormClean: PropTypes.func.isRequired,
  onVeiculosDataList: PropTypes.func.isRequired,
  onVeiculosDataSave: PropTypes.func.isRequired,
  onVeiculosDataDelete: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  session: state.auth.session,
  clientes: state.clientes,
  veiculos: state.veiculos,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ClientesActions,
      ...VeiculosActions,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ClientesPage)
