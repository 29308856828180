import React, { useEffect, useState, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Grid, FormControl, InputLabel, Select, MenuItem, Typography,
  Card, CardContent, CardActions,
} from '@material-ui/core'
import { NotificationManager } from 'react-notifications'

// Api's
import { relatClientes } from 'api/relatorios'

// UF's
import { UFs } from 'utils'

// Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import ButtonLoadingComp from 'components/Buttons/ButtonLoading'

// Styles
// import useStyles from './styles'

// Stateless component
const RelatoriosClientesPage = ({
  session,
}) => {
  // const classes = useStyles()
  const {
    user,
    token,
  } = session
  const inputLabelFiliais = useRef(null)
  const inputLabelUfs = useRef(null)
  const [labelWidthFiliais, setLabelWidthFiliais] = useState(0)
  const [labelWidthUfs, setLabelWidthUfs] = useState(0)
  // Form
  const [loading, setLoading] = useState(false)
  const [filiais, setFiliais] = useState([session.user.filial])
  const [filiaisOpen, setFiliaisOpen] = useState(false)
  const [uf, setUf] = useState(['TODAS'])
  const [ufOpen, setUfOpen] = useState(false)
  const handleSelectFiliais = (value) => {
    setFiliais(value)
    const filterAll = (i) => i === 'TODAS'
    const filterNotAll = (i) => i !== 'TODAS'
    if (value.findIndex(filterAll) > 0) {
      setFiliais(value.filter(filterAll))
      setFiliaisOpen(false)
    }
    if (value.findIndex(filterAll) === 0 && value.length > 1) {
      setFiliais(value.filter(filterNotAll))
    }
  }
  const handleSelectUf = (value) => {
    setUf(value)
    const filterAll = (i) => i === 'TODAS'
    const filterNotAll = (i) => i !== 'TODAS'
    if (value.findIndex(filterAll) > 0) {
      setUf(value.filter(filterAll))
      setUfOpen(false)
    }
    if (value.findIndex(filterAll) === 0 && value.length > 1) {
      setUf(value.filter(filterNotAll))
    }
  }
  const handleFiltrar = () => {
    let valid = true
    // Validações
    if (!filiais) {
      NotificationManager.warning('Filial inválida!', 'Atenção', 8000)
      valid = false
    }
    if (valid) {
      setLoading(true)
      relatClientes({
        token,
        filter: {
          filiaisId: filiais,
          uf,
        },
      }).then((resp) => {
        if (resp) {
          window.open(
            URL.createObjectURL(
              new Blob([resp], { type: 'application/pdf' }),
            ),
          )
          /*
          window.open(
            URL.createObjectURL(
              new Blob([resp], { type: 'application/vnd.ms-excel' }),
            ),
          )
          */
        }
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    document.title = 'Backoffice - Relatório/Clientes'
    setLabelWidthFiliais(inputLabelFiliais.current.offsetWidth)
    setLabelWidthUfs(inputLabelUfs.current.offsetWidth)
  }, [])

  return (
    <DefaultPage>
      <PageHeaderComp
        title="Relatório - Clientes"
      />
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Filtro
              </Typography>
              <Grid container spacing={2}>
                {/* Filiais */}
                <Grid item xs={12} md={12}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel
                      ref={inputLabelFiliais}
                      id="relatorios-clientes-filiais-label"
                    >
                      Filiais
                    </InputLabel>
                    <Select
                      multiple
                      fullWidth
                      labelId="relatorios-clientes-filiais-label"
                      id="relatorios-clientes-filiais-select"
                      size="small"
                      margin="dense"
                      value={filiais}
                      open={filiaisOpen}
                      onClose={() => setFiliaisOpen(false)}
                      onOpen={() => setFiliaisOpen(true)}
                      onChange={(evt) => handleSelectFiliais(evt.target.value)}
                      labelWidth={labelWidthFiliais}
                    >
                      <MenuItem value="TODAS">
                        TODAS
                      </MenuItem>
                      {_.orderBy(user.filiais
                        .filter((f) => f.status === 'L')
                        .filter((s) => s.id !== 'TODAS'), ['descricao'], ['asc'])
                        .map(({ id, descricao }) => (
                          <MenuItem key={id} value={id}>
                            {descricao}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* UF's */}
                <Grid item xs={12} md={12}>
                  <FormControl variant="outlined" fullWidth margin="dense" multiple>
                    <InputLabel
                      ref={inputLabelUfs}
                      id="relatorios-clientes-uf-label"
                    >
                      UF
                    </InputLabel>
                    <Select
                      multiple
                      fullWidth
                      labelId="relatorios-clientes-uf-label"
                      id="relatorios-clientes-uf-select"
                      size="small"
                      margin="dense"
                      value={uf}
                      open={ufOpen}
                      onClose={() => setUfOpen(false)}
                      onOpen={() => setUfOpen(true)}
                      onChange={(evt) => handleSelectUf(evt.target.value)}
                      labelWidth={labelWidthUfs}
                    >
                      <MenuItem value="TODAS">
                        TODAS
                      </MenuItem>
                      {UFs.filter((u) => u !== 'TODAS')
                        .map((u) => {
                          const ufs = Object.keys(u)[0]
                          return (
                            <MenuItem key={ufs} value={ufs}>
                              {ufs}
                            </MenuItem>
                          )
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <ButtonLoadingComp
                loading={loading}
                size="small"
                variant="contained"
                color="primary"
                onClick={handleFiltrar}
              >
                Filtrar
              </ButtonLoadingComp>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </DefaultPage>
  )
}

RelatoriosClientesPage.propTypes = {
  session: PropTypes.shape().isRequired,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {},
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelatoriosClientesPage)
