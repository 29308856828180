import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'

// Components
import {
  CPFMaskCustom,
  CNPJMaskCustom,
  CelularMaskCustom,
  TelefoneMaskCustom,
} from 'components/Inputs/Masked'

// Styles
import useStyles from './styles'

// Stateless
const ClientesBasicFormComp = ({ data, onChange }) => {
  const {
    tipoPessoa = '',
    nome = '',
    email = '',
    celular = '',
    telefone = '',
    logradouro = '',
    bairro = '',
    observacoes = '',
    status = 'L',
  } = data
  const classes = useStyles()
  const inputLabel = useRef(null)
  const inputLabelTP = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  const [labelWidthTP, setLabelWidthTP] = useState(0)
  useEffect(() => {
    onChange({ status: 'L' })
    // setLabelWidth(inputLabel.current.offsetWidth)
    setLabelWidthTP(inputLabelTP.current.offsetWidth)
  }, [])
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={4}>
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
          className={classes.dataControl}
        >
          <InputLabel ref={inputLabelTP}>Tipo de Pessoa</InputLabel>
          <Select
            id="clientes-tipoPessoa"
            value={tipoPessoa}
            onChange={evt =>
              onChange({ tipoPessoa: evt.target.value.toUpperCase() })
            }
            labelWidth={labelWidthTP}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="PF">P. FÍSICA</MenuItem>
            <MenuItem value="PJ">P. JURÍDICA</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          fullWidth
          id="clientes-nome"
          label="Nome"
          margin="normal"
          variant="outlined"
          value={nome}
          onChange={evt => onChange({ nome: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="clientes-email"
          label="E-mail"
          margin="normal"
          variant="outlined"
          value={email}
          onChange={evt => onChange({ email: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          InputProps={{
            inputComponent: CelularMaskCustom,
          }}
          id="clientes-celular"
          label="Celular"
          margin="normal"
          variant="outlined"
          value={celular}
          onChange={evt =>
            onChange({ celular: evt.target.value.toUpperCase() })
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          InputProps={{
            inputComponent: TelefoneMaskCustom,
          }}
          id="clientes-telefone"
          label="Telefone"
          margin="normal"
          variant="outlined"
          value={telefone}
          onChange={evt =>
            onChange({ telefone: evt.target.value.toUpperCase() })
          }
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          fullWidth
          multiline
          rowsMax="4"
          id="clientes-endereco"
          label="Endereço"
          margin="normal"
          variant="outlined"
          value={logradouro}
          onChange={evt =>
            onChange({ logradouro: evt.target.value.toUpperCase() })
          }
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          multiline
          rowsMax="4"
          id="clientes-bairro"
          label="Bairro"
          margin="normal"
          variant="outlined"
          value={bairro}
          onChange={evt => onChange({ bairro: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          multiline
          rowsMax="4"
          id="clientes-observacoes"
          label="Observações"
          margin="normal"
          variant="outlined"
          value={observacoes}
          onChange={evt => onChange({ observacoes: evt.target.value.toUpperCase() })}
        />
      </Grid>
    </Grid>
  )
}

ClientesBasicFormComp.propTypes = {
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ClientesBasicFormComp
