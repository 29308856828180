import React from 'react'
import {
  Done,
  HourglassEmpty,
  Beenhere,
  Cancel,
  BlurOff,
  CheckBox,
} from '@material-ui/icons'

const StatusPedido = [
  {
    id: 0,
    sku: 'S/D',
    descricao: 'S/D',
    icon: <BlurOff />,
    step: 1,
  },
  {
    id: 1,
    sku: 'REALIZADO',
    descricao: 'REALIZADO',
    icon: <Done />,
    step: 1,
  },
  {
    id: 2,
    sku: 'EM_ABERTO',
    descricao: 'EM ABERTO',
    icon: <HourglassEmpty />,
    step: 2,
  },
  {
    id: 3,
    sku: 'CONFIRMADO',
    descricao: 'CONFIRMADO',
    icon: <Beenhere />,
    step: 3,
  },
  {
    id: 4,
    sku: 'CANCELADO',
    descricao: 'CANCELADO',
    icon: <Cancel />,
    step: 4,
  },
  {
    id: 5,
    sku: 'CONCLUIDO',
    descricao: 'CONCLUÍDO',
    icon: <CheckBox />,
  },
]

const getStatusById = (id) => StatusPedido.find((s) => s.id === id)
const getStatusSkuId = (sku) => StatusPedido.find((s) => s.sku === sku)

export { getStatusById, getStatusSkuId }
