import _ from 'lodash'
import { NotificationManager } from 'react-notifications'
import {
  listParametrosApi, getParametrosApi, addParametrosApi, editParametrosApi, deleteParametrosApi,
} from 'api/parametros'

// State Usuario
const initialState = {
  loading: {
    list: false,
    find: false,
    add: false,
    edit: false,
    del: false,
  },
  list: { page: 0, rows: 0, data: [] },
  form: {
    filiais: 0,
    nome: '',
    descricao: '',
    valor: '',
  },
}

// Actions Types
export const Types = {
  RESET: 'reset',
  PARAMETROS_LOADING: 'parametros/LOADING',
  PARAMETROS_LIST_LOAD: 'parametros/PARAMETROS_LIST_LOAD',
  PARAMETROS_LIST_UPDATE: 'parametros/PARAMETROS_LIST_UPDATE',
  PARAMETROS_LIST_CLEAN: 'parametros/PARAMETROS_LIST_CLEAN',
  PARAMETROS_FORM_UPDATE: 'parametros/PARAMETROS_FORM_UPDATE',
}

// Reducers
export default function parametrosReducers(state = initialState, action) {
  switch (action.type) {
  // RESET DEFAULT
  case Types.RESET:
    return {
      ...state,
      ...initialState,
    }
  case Types.PARAMETROS_LOADING:
    return {
      ...state,
      loading: { ...state.loading, ...action.payload },
    }
  case Types.PARAMETROS_LIST_LOAD:
    return {
      ...state,
      list: action.payload,
    }
  case Types.PARAMETROS_LIST_UPDATE:
    return {
      ...state,
      list: state.list.map((i) => (i.id === action.payload.id ? { ...i, ...action.payload } : i)),
    }
  case Types.PARAMETROS_LIST_CLEAN:
    return {
      ...state,
      list: initialState.list,
    }
  case Types.PARAMETROS_FORM_UPDATE:
    return {
      ...state,
      form: { ...state.form, ...action.payload },
    }
  default:
    return state
  }
}

// Actions Creators
export const Actions = {
  // Store Update
  onParametrosLoading: (payload) => ({ type: Types.PARAMETROS_LOADING, payload }),
  onParametrosListLoad: (payload) => ({ type: Types.PARAMETROS_LIST_LOAD, payload }),
  onParametrosListUpdate: (payload) => ({ type: Types.PARAMETROS_LIST_UPDATE, payload }),
  onParametrosListClean: () => ({ type: Types.PARAMETROS_LIST_CLEAN }),
  onParametrosFormUpdate: (payload) => ({ type: Types.PARAMETROS_FORM_UPDATE, payload }),
  onParametrosFormClean: () => ({ type: Types.PARAMETROS_FORM_UPDATE, payload: initialState.form }),
  onDataParametrosById: (id) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onParametrosLoading({ find: true }))
    // Chamada API
    getParametrosApi({ token, id }).then((payload) => {
      dispatch(Actions.onParametrosLoading({ find: false }))
      dispatch(Actions.onParametrosFormUpdate(_.isObject(payload) ? payload : initialState.form))
    })
  },
  onParametrosDataList: (filter) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onParametrosLoading({ list: true }))
    // Chamada API
    listParametrosApi({ token, filter }).then((payload) => {
      dispatch(Actions.onParametrosLoading({ list: false }))
      dispatch(Actions.onParametrosListLoad(payload || initialState.list))
    })
  },
  onParametrosDataSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    // Chamada API
    if (dto.id) {
      dispatch(Actions.onParametrosLoading({ edit: true }))
      editParametrosApi({ token, dto, id: dto.id }).then((payload) => {
        dispatch(Actions.onParametrosLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success(`Empresa ${dto.razao} atualizada com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    } else {
      dispatch(Actions.onParametrosLoading({ add: true }))
      addParametrosApi({ token, dto }).then((payload) => {
        dispatch(Actions.onParametrosLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success(`Empresa ${dto.razao} cadastrada com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    }
  },
  onParametrosDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onParametrosLoading({ del: true }))
    // Chamada API
    deleteParametrosApi({ token, id }).then((payload) => {
      dispatch(Actions.onParametrosLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Empresa excluída com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    })
  },
  onParametrosGetByNome: (pNome, pPadrao) => (dispatch, getState) => {
    const {
      auth: { session: { user: { filial } } },
      parametros: { list: { data = [] } },
    } = getState()
    const parametro = data.find((p) => {
      const { filiais, filiaisId, nome } = p
      if (filiais && filiais.id === filial && nome === pNome) return p
      if (filiaisId === filial && nome === pNome) return p
      if (!filiais && !filiaisId && nome === pNome) return p
      return undefined
    })
    const ret = parametro ? parametro.valor : pPadrao
    // Tratando o tipo do retorno
    switch (typeof padrao) {
    case 'number':
      return Number(ret)
    default:
      return ret
    }
  },
}
