import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const path = '/dashboard'

export const getDashboardModel1 = ({ token, filter }) => {
  const {
    filiaisId, usuariosId, dataInicial, dataFinal,
  } = filter

  let queryUrl = `/model1?filiaisId=${filiaisId}`
  queryUrl = usuariosId ? `${queryUrl}&usuariosId=${usuariosId}` : queryUrl
  queryUrl = dataInicial ? `${queryUrl}&dataInicial=${dataInicial}` : queryUrl
  queryUrl = dataFinal ? `${queryUrl}&dataFinal=${dataFinal}` : queryUrl

  return api
    .get(path + queryUrl, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getDashboardVendasV1 = ({ token, filter }) => {
  const { usuariosId } = filter
  return api
    .get(`${path}/vendas/v1`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { usuariosId },
    })
    .then(httpValidState)
    .catch(httpValidErros)
}
