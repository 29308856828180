import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Grid, Paper, Tabs, Tab, Breadcrumbs, Typography,
} from '@material-ui/core'

// Actions
import { Actions as EmpresasActions } from 'store/ducks/empresas'
import { Actions as FiliaisActions } from 'store/ducks/filiais'

import {
  DateIsoToDateBrOnly, MoneyFormatBr, parseToNum, parseToDec,
} from 'utils'

// Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import TableDataComp from 'components/Table'
import DialogComp from 'components/Dialogs'
import EmpresasFormComp from './components/EmpresasForm'
import FiliaisFormComp from './components/FiliaisForm'
import MetasFormComp from './components/MetasForm'
// Styles
import useStyles from './styles'

// Stateless component
const EmpresasPage = ({
  empresas,
  onEmpresasFormUpdate,
  onEmpresasFormClean,
  onEmpresasDataList,
  onEmpresasDataSave,
  onEmpresasDataDelete,
  // Filiais
  filiais,
  onFiliaisFormUpdate,
  onFiliaisFormClean,
  onFiliaisDataList,
  onFiliaisDataSave,
  onFiliaisDataDelete,
  // Metas
  onFiliaisMetasFormUpdate,
  onFiliaisMetasFormClean,
  onFiliaisMetasList,
  onFiliaisMetasSave,
  onFiliaisMetasDelete,
}) => {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const [dialogForm, setDialogForm] = useState({ open: false, title: '' })
  const [dialogAskDelete, setDialogAskDelete] = useState({ open: false, title: '', data: null })
  const [dialogFormFiliais, setDialogFormFiliais] = useState({ open: false, title: '' })
  const [dialogAskDeleteFiliais, setDialogAskDeleteFiliais] = useState({ open: false, title: '', data: null })
  const [dialogFormFiliaisMetas, setDialogFormFiliaisMetas] = useState({ open: false, title: '' })
  const [dialogAskDeleteFiliaisMetas, setDialogAskDeleteFiliaisMetas] = useState({ open: false, title: '', data: null })
  const [tabSelected, setTabSelected] = useState(0)
  const [empresasSelected, setEmpresasSelected] = useState({
    id: 0,
    cnpj: '',
    razao: '',
    status: '',
    filiais: [],
  })
  const [filiaisSelected, setFiliaisSelected] = useState({
    id: 0,
    cnpj: '',
    razao: '',
    descricao: '',
    status: '',
  })

  // Metas
  const handleFiltraMetas = (filter) => {
    onFiliaisMetasList({
      ...filter,
      filiais: filter.filiais || filiaisSelected.id,
    })
  }
  const handleOpenCloseDialogFiliaisMetas = (open, title = null) => {
    if (title) {
      setDialogFormFiliaisMetas((s) => ({ ...s, open, title }))
    } else {
      setDialogFormFiliaisMetas((s) => ({ ...s, open }))
    }
  }
  const handleOpenCloseDialogAskDeleteFiliaisMetas = (open, title = null, data = null) => {
    setDialogAskDeleteFiliaisMetas((s) => ({
      ...s, open, title, data,
    }))
  }
  const tableMetasCols = [
    { key: 'metaInicio', title: 'INICIO', format: (d) => DateIsoToDateBrOnly(d) },
    { key: 'metaFim', title: 'FIM', format: (d) => DateIsoToDateBrOnly(d) },
    { key: 'valorTotal', title: 'VALOR TOTAL', format: (v) => (`R$ ${MoneyFormatBr(parseToNum(v))}`) },
    { key: 'valorDiario', title: 'VALOR DIÁRIO', format: (v) => (`R$ ${MoneyFormatBr(parseToNum(v))}`) },
    { key: 'quantDias', title: 'QUANT. DIAS' },
    { key: 'statusDescricao', title: 'STATUS' },
  ]
  const tableMetasActions = [
    {
      key: 'editar',
      title: 'Editar',
      icon: 'edit',
      action: (row) => {
        onFiliaisMetasFormUpdate({
          ...row,
          valorTotal: parseToNum(row.valorTotal),
          valorDiario: parseToNum(row.valorDiario),
        })
        handleOpenCloseDialogFiliaisMetas(true, 'Edição de meta (mês):')
      },
    },
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: (row) => {
        handleOpenCloseDialogAskDeleteFiliaisMetas(true, 'Excluir a meta?', row)
      },
    },
  ]

  // Filiais
  const handleFiltraFiliais = (filter) => {
    onFiliaisDataList({
      ...filter,
      empresas: filter.empresas || empresasSelected.id,
    })
  }
  const handleOpenCloseDialogFiliais = (open, title = null) => {
    if (title) {
      setDialogFormFiliais((s) => ({ ...s, open, title }))
    } else {
      setDialogFormFiliais((s) => ({ ...s, open }))
    }
  }
  const handleOpenCloseDialogAskDeleteFiliais = (open, title = null, data = null) => {
    setDialogAskDeleteFiliais((s) => ({
      ...s, open, title, data,
    }))
  }
  const handleSelectFiliais = (pFiliais) => {
    setFiliaisSelected(pFiliais)
    setTabSelected(2)
    handleFiltraMetas({ filiais: pFiliais.id, search })
  }
  const tableFiliaisCols = [
    { key: 'cnpj', title: 'CNPJ', onClick: (row) => handleSelectFiliais(row) },
    { key: 'descricao', title: 'DESCRIÇÃO' },
    { key: 'cidade', title: 'CIDADE' },
    { key: 'estado', title: 'UF' },
    { key: 'statusDescricao', title: 'STATUS' },
  ]
  const tableFiliaisActions = [
    {
      key: 'editar',
      title: 'Editar',
      icon: 'edit',
      action: (row) => {
        onFiliaisFormUpdate(row)
        handleOpenCloseDialogFiliais(true, 'Edição de filial:')
      },
    },
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: (row) => handleOpenCloseDialogAskDeleteFiliais(true, 'Excluir?', row),
    },
  ]

  // Empresas
  const handleFiltraEmpresas = (filter) => {
    onEmpresasDataList(filter)
  }
  const handleOpenCloseDialogEmpresas = (open, title = null) => {
    if (title) {
      setDialogForm((s) => ({ ...s, open, title }))
    } else {
      setDialogForm((s) => ({ ...s, open }))
    }
  }
  const handleOpenCloseDialogAskDelete = (open, title = null, data = null) => {
    setDialogAskDelete((s) => ({
      ...s, open, title, data,
    }))
  }
  const handleSelectEmpresas = (pEmpresas) => {
    setEmpresasSelected(pEmpresas)
    setTabSelected(1)
    handleFiltraFiliais({ empresas: pEmpresas.id, search })
  }
  const tableEmpresasCols = [
    { key: 'cnpj', title: 'CNPJ', onClick: (row) => handleSelectEmpresas(row) },
    { key: 'razao', title: 'RAZÃO' },
    { key: 'statusDescricao', title: 'STATUS' },
  ]
  const tableEmpresasActions = [
    {
      key: 'editar',
      title: 'Editar',
      icon: 'edit',
      action: (row) => {
        onEmpresasFormUpdate(row)
        handleOpenCloseDialogEmpresas(true, 'Edição de empresa:')
      },
    },
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: (row) => handleOpenCloseDialogAskDelete(true, 'Excluir?', row),
    },
  ]

  // Tabs
  const handleSelectTab = (event, newValue) => setTabSelected(newValue)
  const handleSearch = () => {
    switch (tabSelected) {
    case 0:
      handleFiltraEmpresas({ search })
      break
    case 1:
      handleFiltraFiliais({ search })
      break
    default:
      handleFiltraEmpresas({ search })
    }
  }

  // Header button
  const headerButtons = [
    {
      title: 'Adicionar',
      icon: 'add',
      action: () => {
        if (!tabSelected || tabSelected === 0) {
          onEmpresasFormClean()
          handleOpenCloseDialogEmpresas(true, 'Cadastro de empresa:')
        }
        if (tabSelected && tabSelected === 1) {
          onFiliaisFormClean()
          handleOpenCloseDialogFiliais(true, 'Cadastro de filial:')
        }
        if (tabSelected && tabSelected === 2) {
          onFiliaisMetasFormClean()
          handleOpenCloseDialogFiliaisMetas(true, 'Cadastro de meta(mês):')
        }
      },
    },
  ]
  // Header button

  const title = () => {
    let titulo = ''
    if (tabSelected === 1 && empresasSelected.id) {
      titulo = (
        <Breadcrumbs>
          <Typography color="textPrimary">{`[${empresasSelected.id}] - ${empresasSelected.razao}`}</Typography>
        </Breadcrumbs>
      )
    }
    if (tabSelected === 2 && empresasSelected.id && filiaisSelected.id) {
      titulo = (
        <Breadcrumbs>
          <Typography>{`[${empresasSelected.id}] - ${empresasSelected.razao}`}</Typography>
          <Typography color="textPrimary">{`[${filiaisSelected.id}] - ${filiaisSelected.descricao}`}</Typography>
        </Breadcrumbs>
      )
    }
    return titulo
  }

  useEffect(() => {
    document.title = 'Backoffice - Empresas/Filiais'
    handleFiltraEmpresas({ search })
  }, [])

  return (
    <DefaultPage>
      <PageHeaderComp
        title="Empresas/Filiais"
        subtitle={title()}
        value={search}
        onChange={(evt) => setSearch(evt.target.value)}
        onClick={handleSearch}
        onKeyPress={(evt) => {
          if (evt.charCode === 13) {
            handleSearch()
          }
        }}
        buttons={headerButtons}
      />
      <Grid container spacing={2}>
        {/*
        <Grid item xs={12} lg={12}>
          {title()}
        </Grid>
        */}
        {/* Tab Header */}
        <Grid item xs={12} md={12}>
          <Paper className={classes.root}>
            <Tabs
              value={tabSelected}
              onChange={handleSelectTab}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Empresas" />
              <Tab label="Filiais" disabled={!empresasSelected.id} />
              <Tab label="Metas" disabled={!filiaisSelected.id} />
            </Tabs>
          </Paper>
        </Grid>
        {/* Tab Content */}
        <Grid item xs={12} md={12}>
          {/* Listagem das empresas */}
          {tabSelected === 0 && (
            <TableDataComp
              loading={empresas?.loading?.list}
              size="small"
              rows={empresas?.list?.data}
              cols={tableEmpresasCols}
              actions={tableEmpresasActions}
            />
          )}
          {/* Listagem das filiais */}
          {tabSelected === 1 && (
            <TableDataComp
              loading={filiais?.loading?.list}
              size="small"
              rows={filiais?.list?.data}
              cols={tableFiliaisCols}
              actions={tableFiliaisActions}
            />
          )}
          {/* Metas das filiais */}
          {tabSelected === 2 && (
            <TableDataComp
              loading={filiais?.meta?.loading?.list}
              size="small"
              rows={filiais?.meta?.list?.data}
              cols={tableMetasCols}
              actions={tableMetasActions}
            />
          )}
        </Grid>
      </Grid>
      {/* Dialog EmpresasForm (add/edit) */}
      <DialogComp
        open={dialogForm.open}
        title={dialogForm.title}
        onClose={() => handleOpenCloseDialogEmpresas(false)}
        actions={[
          {
            label: 'Cancelar',
            onClick: () => {
              onEmpresasFormClean()
              handleOpenCloseDialogEmpresas(false)
            },
          },
          {
            label: 'Salvar',
            onClick: () => {
              onEmpresasDataSave(empresas.form, () => {
                onEmpresasFormClean()
                handleOpenCloseDialogEmpresas(false)
                handleFiltraEmpresas({ search })
              })
            },
          },
        ]}
      >
        <EmpresasFormComp data={empresas.form} onChange={onEmpresasFormUpdate} />
      </DialogComp>
      {/* Dialog Pergunta DELETE */}
      <DialogComp
        open={dialogAskDelete.open}
        title={dialogAskDelete.title}
        onClose={() => handleOpenCloseDialogAskDelete(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDelete(false),
          },
          {
            label: 'SIM',
            onClick: () => onEmpresasDataDelete(dialogAskDelete.data.id, () => {
              handleOpenCloseDialogAskDelete(false)
              handleFiltraEmpresas({ search })
            }),
          },
        ]}
      >
        {`Você deseja realmente excluir a empresa "${dialogAskDelete.data ? dialogAskDelete.data.razao : ''}"?`}
      </DialogComp>
      {/* Dialog FiliaisForm (add/edit) */}
      <DialogComp
        open={dialogFormFiliais.open}
        title={dialogFormFiliais.title}
        size="md"
        onClose={() => handleOpenCloseDialogFiliais(false)}
        actions={[
          {
            label: 'Cancelar',
            onClick: () => {
              onFiliaisFormClean()
              handleOpenCloseDialogFiliais(false)
            },
          },
          {
            label: 'Salvar',
            onClick: () => {
              onFiliaisDataSave({
                ...filiais.form,
                empresasId: empresasSelected.id,
              }, () => {
                onFiliaisFormClean()
                handleOpenCloseDialogFiliais(false)
                onFiliaisDataList({ empresas: empresasSelected.id })
              })
            },
          },
        ]}
      >
        <FiliaisFormComp data={filiais.form} onChange={onFiliaisFormUpdate} />
      </DialogComp>
      {/* Dialog Pergunta DELETE */}
      <DialogComp
        open={dialogAskDeleteFiliais.open}
        title={dialogAskDeleteFiliais.title}
        onClose={() => handleOpenCloseDialogAskDeleteFiliais(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDeleteFiliais(false),
          },
          {
            label: 'SIM',
            onClick: () => onFiliaisDataDelete(dialogAskDeleteFiliais.data.id, () => {
              handleOpenCloseDialogAskDeleteFiliais(false)
              handleFiltraFiliais({ search })
            }),
          },
        ]}
      >
        {`Você deseja realmente excluir a filial "${dialogAskDeleteFiliais.data ? dialogAskDeleteFiliais.data.razao : ''}"?`}
      </DialogComp>
      {/* Dialog MetasForm (add/edit) */}
      <DialogComp
        open={dialogFormFiliaisMetas.open}
        title={dialogFormFiliaisMetas.title}
        size="md"
        onClose={() => handleOpenCloseDialogFiliaisMetas(false)}
        actions={[
          {
            label: 'Cancelar',
            onClick: () => {
              onFiliaisMetasFormClean()
              handleOpenCloseDialogFiliaisMetas(false)
            },
          },
          {
            label: 'Salvar',
            loading: filiais.meta.loading.add || filiais.meta.loading.edit,
            onClick: () => {
              onFiliaisMetasSave({
                ...filiais.meta.form,
                filiaisId: filiaisSelected.id,
                filiaisDescricao: filiaisSelected.descricao,
                valorTotal: parseToDec(filiais.meta.form.valorTotal),
                valorDiario: parseToDec(filiais.meta.form.valorDiario),
              }, () => {
                onFiliaisMetasFormClean()
                handleOpenCloseDialogFiliaisMetas(false)
                onFiliaisMetasList({ filiais: filiaisSelected.id })
              })
            },
          },
        ]}
      >
        <MetasFormComp data={filiais.meta.form} onChange={onFiliaisMetasFormUpdate} />
      </DialogComp>
      {/* Dialog Pergunta DELETE Metas */}
      <DialogComp
        open={dialogAskDeleteFiliaisMetas.open}
        title={dialogAskDeleteFiliaisMetas.title}
        onClose={() => handleOpenCloseDialogAskDeleteFiliaisMetas(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDeleteFiliaisMetas(false),
          },
          {
            label: 'SIM',
            loading: filiais.meta.loading.del,
            onClick: () => onFiliaisMetasDelete(dialogAskDeleteFiliaisMetas.data.id, () => {
              handleOpenCloseDialogAskDeleteFiliaisMetas(false)
              handleFiltraMetas({ search })
            }),
          },
        ]}
      >
        Você deseja realmente excluir a meta ?
      </DialogComp>
    </DefaultPage>
  )
}

EmpresasPage.propTypes = {
  // Empresas
  empresas: PropTypes.shape().isRequired,
  onEmpresasFormUpdate: PropTypes.func.isRequired,
  onEmpresasFormClean: PropTypes.func.isRequired,
  onEmpresasDataList: PropTypes.func.isRequired,
  onEmpresasDataSave: PropTypes.func.isRequired,
  onEmpresasDataDelete: PropTypes.func.isRequired,
  // Filiais
  filiais: PropTypes.shape().isRequired,
  onFiliaisFormUpdate: PropTypes.func.isRequired,
  onFiliaisFormClean: PropTypes.func.isRequired,
  onFiliaisDataList: PropTypes.func.isRequired,
  onFiliaisDataSave: PropTypes.func.isRequired,
  onFiliaisDataDelete: PropTypes.func.isRequired,
  // Metas
  onFiliaisMetasFormUpdate: PropTypes.func.isRequired,
  onFiliaisMetasFormClean: PropTypes.func.isRequired,
  onFiliaisMetasList: PropTypes.func.isRequired,
  onFiliaisMetasSave: PropTypes.func.isRequired,
  onFiliaisMetasDelete: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  empresas: state.empresas,
  filiais: state.filiais,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...EmpresasActions,
    ...FiliaisActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmpresasPage)
