import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const path = '/categorias'

/**
 *
 * @param { token } param0
 * @returns List of Categorias
 */
/*
export const listCategoriasApi = ({ token }) => api
  .get(path, {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then(httpValidState)
  .catch(httpValidErros)
*/
/**
 *
 * @param { token } param0
 * @returns List of CategoriasSumCategoriaDTO
 */
export const getCategoriasSumProdutosApi = ({ token }) => api
  .get(`${path}/categorias-sum-produtos`, {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then(httpValidState)
  .catch(httpValidErros)

/**
*
* @param { token, filter: {
*  id, status = 'L', search, page = 0, rows = 50
 * }} param0
 * @returns List of produtos
 */
export const listCategoriasApi = ({ token, filter }) => api
  .get(path, {
    headers: { Authorization: `Bearer ${token}` },
    params: filter,
  })
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param { token, id } param0
 * @returns CategoriaDTO
 */
export const getCategoriasByIdApi = ({ token, id }) => api
  .get(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param { token, dto } param0
 * @returns
 */
export const addCategoriasApi = ({ token, dto }) => api
  .post(path, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {token, id, dto} param0
 * @returns
 */
export const editCategoriasApi = ({ token, id, dto }) => api
  .put(`${path}/${id}`, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {token, id} param0
 * @returns
 */
export const deleteCategoriasApi = ({ token, id }) => api
  .delete(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)
