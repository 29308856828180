import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Icon,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Checkbox,
} from '@material-ui/core'
import useStyles from './styles'

export const TableActionsComp = ({ row, actions }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleDisable(pAction, pRow) {
    if (_.isFunction(pAction)) {
      return pAction(pRow)
    }
    return pAction
  }

  function handleAction(pAction, pRow) {
    handleClose()
    pAction(pRow)
  }

  const classes = useStyles()
  return (
    <>
      <Tooltip title="AÇÕES">
        <IconButton
          size="small"
          aria-label="Editar"
          className={classes.margin}
          onClick={handleClick}
        >
          <Icon>more_vert</Icon>
        </IconButton>
      </Tooltip>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: 200,
          },
        }}
      >
        {actions.map((action) => (
          <MenuItem
            key={action.key}
            disabled={handleDisable(action.disabled, row)}
            onClick={() => handleAction(action.action, row)}
          >
            <ListItemIcon>
              <Icon>{action.icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={action.title} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

TableActionsComp.propTypes = {
  row: PropTypes.shape().isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
    }),
  ),
}

TableActionsComp.defaultProps = {
  actions: {
    disabled: false,
  },
}

const TableDataComp = (props) => {
  const {
    keyList, checkbox, rows, cols, actions, loading, size, onRowsSelected,
  } = props
  const classes = useStyles()
  const [data, setData] = useState(rows)
  const [totalRowsChecked, setTotalRowsChecked] = useState(0)
  const [checkAll, setCheckAll] = useState(false)
  const hasRows = _.isArray(data) && !_.isEmpty(data)

  // Actions
  const handleCheckOne = (evt, value) => {
    const isChecked = evt.target.checked
    const row = value
    const aSelected = data.map((r) => (r.id === row.id ? { ...r, checked: isChecked } : { ...r }))
    setData(aSelected)
    setTotalRowsChecked(isChecked ? (totalRowsChecked + 1) : (totalRowsChecked - 1))
    onRowsSelected(aSelected.filter((r) => r.checked))
  }
  const handleCheckAll = (evt) => {
    const isAllChecked = evt.target.checked
    setCheckAll(isAllChecked)
    if (hasRows) {
      const aSelected = data.map((r) => ({ ...r, checked: isAllChecked }))
      setData(aSelected)
      setTotalRowsChecked(isAllChecked ? data.length : 0)
      onRowsSelected(aSelected.filter((r) => r.checked))
    }
  }

  // On load comp
  useEffect(() => {
    if (checkbox) {
      setCheckAll(false)
      setTotalRowsChecked(0)
      setData(rows.map((r) => ({ ...r, checked: false })))
    } else {
      setData(rows.map((r) => ({ ...r })))
    }
  }, [rows])

  return (
    <Paper>
      <Table size={size}>
        <TableHead>
          <TableRow>
            {!_.isEmpty(actions) && <TableCell>AÇÕES</TableCell>}
            {checkbox && (
              <TableCell>
                <Checkbox
                  disabled={!hasRows}
                  checked={checkAll}
                  onChange={handleCheckAll}
                  value="all"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </TableCell>
            )}
            {cols.map((c, idx) => {
              const colIdx = `col-${idx}-${c.key}`
              return (
                <TableCell key={colIdx} align={c.align || 'left'}>{c.title}</TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell component="th" scope="row" colSpan={cols.length}>
                <CircularProgress size={25} className={classes.progress} color="primary" />
              </TableCell>
            </TableRow>
          )}
          {(!loading && !_.isArray(data))
            || (!loading && _.isEmpty(data) && (
              <TableRow>
                <TableCell component="th" scope="row" colSpan={cols.length}>
                  Sem registro(s).
                </TableCell>
              </TableRow>
            ))}
          {!loading
            && _.isArray(data)
            && data.map((row) => (
              <TableRow hover key={row[keyList]}>
                {!_.isEmpty(actions) && (
                  <TableCell component="th" scope="row">
                    <TableActionsComp row={row} actions={actions} />
                  </TableCell>
                )}
                {checkbox && (
                  <TableCell>
                    {/* row[c.key] */}
                    <Checkbox
                      checked={row.checked}
                      onChange={(evt) => handleCheckOne(evt, row)}
                      value={row.id}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </TableCell>
                )}
                {cols.map((c, idx) => {
                  const colIdx = `col-${idx}`
                  const onClick = (_.isFunction(c.onClick) ? () => c.onClick(row) : null)
                  const linkStyle = _.isFunction(onClick) ? classes.link : null
                  if (c.key.includes('.')) {
                    const arrData = c.key.split('.')
                    switch (arrData[1]) {
                      case 'length':
                        return (
                          <TableCell
                            key={c.key}
                            className={linkStyle}
                            onClick={onClick}
                            align={c.align || 'left'}
                          >
                            {row[arrData[0]].length}
                          </TableCell>
                        )
                      default:
                        return (
                          <TableCell
                            key={c.key}
                            className={linkStyle}
                            onClick={onClick}
                            align={c.align || 'left'}
                          >
                            {_.isArray(row[arrData[0]])
                              ? row[arrData[0]].map((d) => d[arrData[1]]).join(', ')
                              : row[arrData[0]][arrData[1]]}
                          </TableCell>
                        )
                    }
                  }
                  if (_.isFunction(c.format)) {
                    return (
                      <TableCell
                        key={`${colIdx}-${(c.key || row.id || row.codigo)}`}
                        className={linkStyle}
                        onClick={onClick}
                        align={c.align || 'left'}
                      >
                        {c.format(c.key ? row[c.key] : row)}
                      </TableCell>
                    )
                  }
                  return (
                    <TableCell
                      key={c.key}
                      className={linkStyle}
                      onClick={onClick}
                      align={c.align || 'left'}
                    >
                      {row[c.key]}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

TableDataComp.propTypes = {
  keyList: PropTypes.string,
  checkbox: PropTypes.bool,
  size: PropTypes.string,
  cols: PropTypes.arrayOf(PropTypes.shape()),
  rows: PropTypes.arrayOf(PropTypes.shape()),
  actions: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
  onRowsSelected: PropTypes.func,
}

TableDataComp.defaultProps = {
  keyList: 'id',
  checkbox: false,
  size: 'medium',
  cols: [],
  rows: [],
  actions: [],
  loading: false,
  onRowsSelected: () => null,
}

export default TableDataComp
