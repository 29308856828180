import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid, TextField, FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core'
import _ from 'lodash'

// Api's
import { listFuncionariosApi } from 'api/funcionarios'

// Components
import InputSearchComp from 'components/Inputs/Search'

// Styles
import useStyles from './styles'

// Stateless
const UsuariosFormComp = ({ token, data, onChange }) => {
  const {
    funcionarios = {},
    nome = '',
    email = '',
    usuario = '',
    senha = '',
    resenha = '',
    status = '',
  } = data
  const classes = useStyles()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  const [searchFuncionarios, setSearchFuncionarios] = useState({
    loading: false,
    payload: { page: 0, rows: 0, data: [] },
  })
  const handleSearchLoadingFuncionarios = (loading) => setSearchFuncionarios((s) => ({
    ...s,
    loading,
  }))
  const handleSearchLoadDataFuncionarios = (payload) => setSearchFuncionarios((s) => ({
    ...s,
    payload,
  }))
  const handleSearchActionFuncionarios = (pSearch) => {
    handleSearchLoadingFuncionarios(true)
    return listFuncionariosApi({ token, filter: { search: pSearch } }).then((payload) => {
      handleSearchLoadingFuncionarios(false)
      if (payload && !_.isEmpty(payload.data)) {
        const dtos = payload.data.map(({ id, nome: descricao, email: mail }) => ({
          id,
          descricao,
          email: mail,
        }))
        handleSearchLoadDataFuncionarios({ page: 0, rows: dtos.length, data: dtos })
      } else {
        handleSearchLoadDataFuncionarios({ page: 0, rows: 0, data: [] })
      }
    })
  }

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <InputSearchComp
          required
          title="Buscar funcionário:"
          label="Descrição do funcionário:"
          value={_.isEmpty(funcionarios) ? '' : funcionarios.nome}
          htmlId="iptSearchFuncionarios"
          size="sm"
          searching={searchFuncionarios.loading}
          data={searchFuncionarios.payload.data}
          onSearch={handleSearchActionFuncionarios}
          onSelectItem={(item) => onChange({
            funcionarios: {
              id: item.id,
              nome: item.descricao,
              email: item.email,
            },
            nome: item.descricao,
            email: item.email,
          })}
          onClickClear={() => onChange({ funcionarios: {} })}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          required
          fullWidth
          id="usuarios-nome"
          label="Nome"
          margin="normal"
          variant="outlined"
          value={nome}
          onChange={(evt) => onChange({ nome: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl
          required
          variant="outlined"
          fullWidth
          margin="normal"
          className={classes.dataControl}
        >
          <InputLabel ref={inputLabel}>Status</InputLabel>
          <Select
            id="usuarios-status"
            value={status}
            onChange={(evt) => onChange({ status: evt.target.value.toUpperCase() })}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="P">PENDENTE</MenuItem>
            <MenuItem value="L">LIBERADO</MenuItem>
            <MenuItem value="B">BLOQUEADO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="usuarios-email"
          label="E-mail"
          margin="normal"
          variant="outlined"
          value={email}
          onChange={(evt) => onChange({ email: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          id="usuarios-usuario"
          label="Usuário"
          margin="normal"
          variant="outlined"
          value={usuario}
          onChange={(evt) => onChange({ usuario: evt.target.value })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          id="usuarios-senha"
          type="password"
          label="Senha"
          margin="normal"
          variant="outlined"
          value={senha}
          autoComplete="new-password"
          onChange={(evt) => onChange({ senha: evt.target.value })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          id="usuarios-resenha"
          type="password"
          label="Confirmação de senha"
          margin="normal"
          variant="outlined"
          value={resenha}
          onChange={(evt) => onChange({ resenha: evt.target.value })}
        />
      </Grid>
    </Grid>
  )
}

UsuariosFormComp.propTypes = {
  token: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}

export default UsuariosFormComp
