import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

// Components
import InputLookUpComp from 'components/Inputs/LookUp'
import FormSearchComp from 'components/Forms/Search'
import DialogComp from 'components/Dialogs'

const InputSearchComp = ({
  required,  
  title,
  label,
  value = '',
  htmlId,
  iptSize = 'medium',
  size = 'sm',
  disabled = false,
  searching = false,
  showDialogSearch = false,
  data = [],
  onShowDialogSearch,
  onChange,
  onSearch,
  onSelectItem,
  onClickSearch,
  onClickClear,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const handleOpenCloseDialogSearch = (openClone) => {
    if (_.isFunction(onShowDialogSearch)) {
      onShowDialogSearch(openClone)
    } else {
      setDialogOpen(openClone)
    }
  }
  useEffect(() => {
    setDialogOpen(showDialogSearch)
  }, [showDialogSearch])
  return (
    <>
      <InputLookUpComp
        required={required}
        label={label}
        htmlId={htmlId}
        disabled={disabled}
        iptSize={iptSize}
        value={value}
        onChange={onChange}
        onClickClear={onClickClear}
        onClickSearch={() => {
          if (onClickSearch != null) {
            onClickSearch()
          }
          handleOpenCloseDialogSearch(true)
        }}
        onKeyPress={(evt) => {
          if (evt.charCode === 13) {
            onSearch(value)
          }
        }}
      />
      <DialogComp
        open={dialogOpen}
        title={title}
        size={size}
        onClose={() => handleOpenCloseDialogSearch(false)}
      >
        <FormSearchComp
          searching={searching}
          data={data}
          onSelectItem={(item) => {
            handleOpenCloseDialogSearch(false)
            onSelectItem(item)
          }}
          onSearch={onSearch}
        />
      </DialogComp>
    </>
  )
}

InputSearchComp.propTypes = {
  required: PropTypes.bool,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  htmlId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  iptSize: PropTypes.string,
  disabled: PropTypes.bool,
  searching: PropTypes.bool,
  showDialogSearch: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onShowDialogSearch: PropTypes.func,
  onChange: PropTypes.func,
  onSearch: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  onClickSearch: PropTypes.func,
  onClickClear: PropTypes.func.isRequired,
}

InputSearchComp.defaultProps = {
  required: false,
  iptSize: 'medium',
  disabled: false,
  searching: false,
  showDialogSearch: false,
  onShowDialogSearch: null,
  onClickSearch: null,
  onChange: null,
}

export default InputSearchComp
