/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
  Icon,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Divider,
  ButtonGroup,
  Button,
  InputAdornment,
} from '@material-ui/core'
import { Create } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'
import {
  DateIsoToBr,
  DateIsoNow,
  TimeNow,
  TimeNowAddHrs,
  MoneyFormatBr,
  parseToNum,
  parseToDec,
  padLeft,
  handleImprimirOrdemServico,
} from 'utils'

// * Api's
import { getClientesHistoricoApi, editClientesPathApi } from 'api/clientes'
import { listFuncionariosBasicApi } from 'api/funcionarios'
import { listTabelasPrecoItensBasicApi } from 'api/tabelasprecoitens'

// * Actions
import { Actions as OrdemServicoActions } from 'store/ducks/ordemservico'
import { Actions as ParametrosActions } from 'store/ducks/parametros'
import { Actions as ClientesActions } from 'store/ducks/clientes'

// * Components
import InputSearchComp from 'components/Inputs/Search'
import { CelularMaskCustom } from 'components/Inputs/Masked'
import IconPorcentagemComp from 'components/Icons/Porcentagem'
import DialogComp from 'components/Dialogs'
import ButtonLoading from 'components/Buttons/ButtonLoading'
import ClienteInputSearchComp from 'pages/clientes/components/ClientePesquisa'
import ClientesBasicFormComp from 'pages/clientes/components/ClientesBasicForm'

// * Styles
import useStyles from './styles'

// * Stateless
const OrdemServicoFormComp = ({
  session: { token, user },
  loading,
  ordemServico,
  clientes,
  ordemServicoItem,
  onOrdemServicoFormNew,
  onOrdemServicoFormUpdate,
  // onOrdemServicoFormClean,
  onOrdemServicoItensSaveItem,
  onOrdemServicoItensDelItem,
  onOrdemServicoItensFormClean,
  onOrdemServicoItensCalcTotal,
  onOrdemServicoItensFormUpdate,
  // Actions
  onOrdemServicoLoading,
  onOrdemServicoFormActionSalvar,
  onOrdemServicoFormActionCancelar,
  // Actions Externas
  onOpen,
  // onClose,
  onRealoadGrid,
  // Parametros
  onParametrosGetByNome,
  isOrcamentos,
  onClientesDataById,
  onClientesFormUpdate,
  onClientesFormClean,
  onClientesDataSave,
}) => {
  const classes = useStyles()

  // * Histórico do cliente
  const [clientesHistoricoLoading, setClientesHistoricoLoading] = useState(
    false
  )
  const [clientesHistoricoDialog, setClientesHistoricoDialog] = useState(false)
  const [clientesHistorico, setClientesHistorico] = useState([])
  const [dialogAddClientes, setDialogAddClientes] = useState({
    open: false,
    title: '',
  })

  const handleOpenCloseDialogClientes = (open, title = null) => {
    if (title) {
      setDialogAddClientes(s => ({ ...s, open, title }))
    } else {
      setDialogAddClientes(s => ({ ...s, open }))
    }
  }
  const handleClearCliente = () => {
    onOrdemServicoFormUpdate({
      veiculos: {},
      funcionarios: {},
      tabelasPrecoItens: {},
    })
    // handleSearchClienteValueData('')
  }
  const handleClientesHistorico = () => {
    const clientesId =
      ordemServico?.veiculos?.clientes?.id || ordemServico?.clientes?.id
    setClientesHistoricoLoading(true)
    setClientesHistoricoDialog(true)
    getClientesHistoricoApi({
      token,
      id: clientesId,
      placa: ordemServico?.veiculos?.placa ?? '',
    })
      .then(payload => {
        setClientesHistoricoLoading(false)
        setClientesHistorico(_.isArray(payload) ? payload : [])
      })
      .catch(() => setClientesHistoricoLoading(false))
  }
  const handleUpdateClientePath = (clientesId, clientesPath, value) => {
    editClientesPathApi({
      token,
      id: clientesId,
      clientesPath,
      dto: JSON.parse(`{"id": ${clientesId} , "${clientesPath}": "${value}"}`),
    })
      .then(resp => {
        if (resp && resp.message === 'updated') {
          NotificationManager.success(
            `"${clientesPath.toUpperCase()}" atualizado com sucesso!`,
            'Atenção!',
            8000
          )
        }
      })
      .catch(() => {
        NotificationManager.error(
          `Não foi possível atualizar o "${clientesPath.toUpperCase()}".`,
          'Ops!',
          8000
        )
      })
  }
  // Funcionários
  const [funcionariosOpen, setFuncionariosOpen] = useState(false)
  const [funcionariosOptions, setFuncionariosOptions] = useState([])
  const [funcionariosLoading, setFuncionariosLoading] = useState(false)
  const handleSearchFuncionarios = (pSearch, { lavador, atendente }) => {
    setFuncionariosLoading(true)
    listFuncionariosBasicApi({
      token,
      filter: {
        filiais: user.filial,
        search: pSearch,
        lavador,
        atendente,
      },
    }).then(payload => {
      setFuncionariosLoading(false)
      if (!_.isEmpty(payload) && _.isArray(payload.data)) {
        setFuncionariosOptions(payload.data)
      }
    })
  }
  // Serviços
  const [servicosOpen, setServicosOpen] = useState(false)
  const [servicosOptions, setServicosOptions] = useState([])
  const [servicosLoading, setServicosLoading] = useState(false)
  const handleSearchServicos = pSearch => {
    setServicosLoading(true)
    listTabelasPrecoItensBasicApi({
      token,
      filter: {
        filiais: user.filial,
        porte: ordemServico.veiculos.porte,
        search: pSearch,
        virgente: 'S',
      },
    }).then(payload => {
      setServicosLoading(false)
      if (!_.isEmpty(payload) && _.isArray(payload.data)) {
        setServicosOptions(payload.data)
      }
    })
  }
  const [imprimindo, setImprimindo] = useState(false)
  const handleImprimir = ordemServicoId => {
    setImprimindo(true)
    handleImprimirOrdemServico(
      token,
      ordemServicoId,
      () => setImprimindo(false),
      () => setImprimindo(false)
    )
  }

  useEffect(() => {
    // Iniciando conexão com a impressora...
    onOrdemServicoLoading({ add: false })
    const TIME_ENTREGA_AUTO =
      onParametrosGetByNome('TIME_ENTREGA_AUTO', 'NAO') === 'SIM'
    const INFORMA_VEND_ABER =
      onParametrosGetByNome('INFORMA_VEND_ABER', 'SIM') === 'SIM'
    onOrdemServicoItensFormClean()
    onOrdemServicoFormNew({
      descontoTipo: 'V',
      dataAbertura: DateIsoNow(),
      horaAbertura: TimeNow(),
      dataEntrega: DateIsoNow(), // (TIME_ENTREGA_AUTO ? DateIsoNow() : ''),
      horaEntrega: TIME_ENTREGA_AUTO ? TimeNowAddHrs(1) : '',
      vendedor: INFORMA_VEND_ABER ? user.funcionarios : '',
      itens: [],
    })
  }, [])

  useEffect(() => {
    if (!funcionariosOpen) setFuncionariosOptions([])
  }, [funcionariosOpen])

  useEffect(() => {
    if (!servicosOpen) setServicosOptions([])
  }, [servicosOpen])

  if (loading.find) return <CircularProgress color="inherit" size={20} />

  return (
    <>
      <Grid container spacing={2}>
        {/* Clientes e Serviços */}
        <Grid item xs={12} md={7}>
          {/* Cliente */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                Cliente
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container direction="row" justify="flex-start" spacing={1}>
                {!isOrcamentos && (
                  <Grid item xs={12} md={12}>
                    <ClienteInputSearchComp
                      token={token}
                      disabled={!!ordemServico.id}
                      valueIpt={ordemServico?.veiculos?.placa || ''}
                      size="small"
                      onVeiculoSelected={cliente => {
                        const dtoVeiculos = {
                          veiculos: {
                            ...cliente.veiculos,
                            clientes: {
                              ...cliente,
                            },
                          },
                        }
                        delete dtoVeiculos.veiculos.clientes.veiculos
                        onOrdemServicoFormUpdate(dtoVeiculos)
                      }}
                      onAddNewVeiculo={cliente => onOpen(cliente)}
                      onClearSearch={handleClearCliente}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    disabled
                    id="ordemServico-clientes-nome"
                    label="Nome"
                    size="small"
                    variant="outlined"
                    value={
                      (ordemServico?.veiculos?.clientes?.nome ||
                        ordemServico?.clientes?.nome) ??
                      ''
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    disabled={
                      !!ordemServico.id || _.isEmpty(ordemServico.veiculos)
                    }
                    id="ordemServico-clientes-celular"
                    label="Telefone"
                    size="small"
                    variant="outlined"
                    value={
                      (ordemServico?.veiculos?.clientes?.celular ||
                        ordemServico?.clientes?.celular) ??
                      ''
                    }
                    InputProps={{
                      inputComponent: CelularMaskCustom,
                      endAdornment: !_.isEmpty(ordemServico.veiculos) && (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={
                              !!ordemServico.id ||
                              _.isEmpty(ordemServico.veiculos)
                            }
                            aria-label="Editar celular"
                            onClick={() => {
                              const clientesId =
                                ordemServico?.veiculos?.clientes?.id ||
                                ordemServico?.clientes?.id
                              const clientesCelular =
                                ordemServico?.veiculos?.clientes?.celular ||
                                ordemServico?.clientes?.celular
                              handleUpdateClientePath(
                                clientesId,
                                'celular',
                                clientesCelular
                              )
                            }}
                          >
                            <Create />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={evt => {
                      const {
                        target: { value = '' },
                      } = evt
                      onOrdemServicoFormUpdate({
                        ...ordemServico,
                        veiculos: {
                          ...ordemServico.veiculos,
                          clientes: {
                            ...ordemServico.veiculos.clientes,
                            celular: value.toUpperCase(),
                          },
                        },
                      })
                    }}
                  />
                </Grid>
                {!isOrcamentos && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      disabled
                      id="ordemServico-veiculos-info"
                      label="Veículo"
                      size="small"
                      variant="outlined"
                      value={
                        !_.isEmpty(ordemServico.veiculos)
                          ? `${ordemServico.veiculos.modelo} - ${ordemServico.veiculos.cor}`
                          : ''
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={isOrcamentos ? 12 : 6}>
                  <TextField
                    fullWidth
                    disabled={
                      !!ordemServico.id || _.isEmpty(ordemServico.veiculos)
                    }
                    id="ordemServico-clientes-email"
                    label="E-mail"
                    size="small"
                    variant="outlined"
                    value={
                      (ordemServico?.veiculos?.clientes?.email ||
                        ordemServico?.clientes?.email) ??
                      ''
                    }
                    InputProps={{
                      endAdornment: !_.isEmpty(ordemServico.veiculos) && (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={
                              !!ordemServico.id ||
                              _.isEmpty(ordemServico.veiculos)
                            }
                            aria-label="Editar e-mail"
                            onClick={() => {
                              const clientesId =
                                ordemServico?.veiculos?.clientes?.id ||
                                ordemServico?.clientes?.id
                              const clientesEmail =
                                ordemServico?.veiculos?.clientes?.email ||
                                ordemServico?.clientes?.email
                              handleUpdateClientePath(
                                clientesId,
                                'email',
                                clientesEmail
                              )
                            }}
                          >
                            <Create />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={evt => {
                      const {
                        target: { value = '' },
                      } = evt
                      onOrdemServicoFormUpdate({
                        ...ordemServico,
                        veiculos: {
                          ...ordemServico.veiculos,
                          clientes: {
                            ...ordemServico.veiculos.clientes,
                            email: value.toUpperCase(),
                          },
                        },
                      })
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Serviços */}
          <Grid container spacing={2}>
            {/* Header */}
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                Serviço(s)
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container spacing={1}>
                {/* Funcionarios */}
                {!isOrcamentos && (
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      clearText="Remover"
                      disabled={
                        ordemServico.id || _.isEmpty(ordemServico.veiculos)
                      }
                      id="ordemServico-funcionarios"
                      open={funcionariosOpen}
                      onOpen={() => {
                        setFuncionariosOpen(true)
                        handleSearchFuncionarios('', { lavador: 'S' })
                      }}
                      onClose={() => setFuncionariosOpen(false)}
                      onChange={(evt, value) => {
                        if (!_.isEmpty(value) && value.id) {
                          onOrdemServicoItensFormUpdate({
                            funcionarios: value,
                          })
                        }
                      }}
                      // reason: Can be: "input" (user input), "reset" (programmatic change), "clear"
                      onInputChange={(evt, value, reason) => {
                        if (reason === 'clear' && !value) {
                          onOrdemServicoItensFormUpdate({ funcionarios: {} })
                        }
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={option => option.nome}
                      options={funcionariosOptions}
                      loading={funcionariosLoading}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Funcionário"
                          fullWidth
                          size="small"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {funcionariosLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
                {/* Serviços (tabelasPrecoItens) */}
                {!isOrcamentos && (
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      clearText="Remover"
                      disabled={
                        ordemServico.id ||
                        _.isEmpty(ordemServico.veiculos) ||
                        _.isEmpty(ordemServicoItem.funcionarios)
                      }
                      id="ordemServico-servicos"
                      open={servicosOpen}
                      onOpen={() => {
                        setServicosOpen(true)
                        handleSearchServicos('')
                      }}
                      onClose={() => setServicosOpen(false)}
                      onChange={(evt, value) => {
                        if (!_.isEmpty(value) && value.id) {
                          onOrdemServicoItensFormUpdate({
                            tabelasPrecoItens: value,
                          })
                          onOrdemServicoItensSaveItem({
                            funcionarios: ordemServicoItem.funcionarios,
                            tabelasPrecoItens: value,
                          })
                          onOrdemServicoItensFormUpdate({
                            tabelasPrecoItens: {},
                          })
                        }
                      }}
                      // reason: Can be: "input" (user input), "reset" (programmatic change), "clear"
                      onInputChange={(evt, value, reason) => {
                        if (reason === 'clear' && !value) {
                          onOrdemServicoItensFormUpdate({
                            tabelasPrecoItens: {},
                          })
                        }
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={option =>
                        `R$ ${MoneyFormatBr(
                          parseToNum(option.valor)
                        )} - ${option?.servicosDescricao ||
                          option?.servicos?.descricao}`
                      }
                      options={servicosOptions}
                      loading={servicosLoading}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Serviço"
                          fullWidth
                          size="small"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {servicosLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
                {/* Table of Itens */}
                <Grid item xs={12} md={12}>
                  <TableContainer>
                    <Table
                      stickyHeader
                      padding="none"
                      aria-label="sticky table"
                      size="small"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>ITEM</TableCell>
                          <TableCell>FUNCIONÁRIO</TableCell>
                          <TableCell>SERVIÇO</TableCell>
                          <TableCell>PREÇO</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ordemServico.itens.map((i, index) => (
                          <TableRow key={i.item || index + 1}>
                            <TableCell component="th" scope="row">
                              {i.item || index + 1}
                            </TableCell>
                            <TableCell>
                              {i.funcionarios
                                ? i.funcionarios.nome
                                : i.funcionariosNome}
                            </TableCell>
                            <TableCell>
                              {i?.tabelasPrecoItensServicosDescricao ||
                                i?.tabelasPrecoItens?.servicosDescricao ||
                                i?.tabelasPrecoItens?.servicos?.descricao}
                            </TableCell>
                            <TableCell>
                              {`R$ ${MoneyFormatBr(parseToNum(i.preco))}`}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                disabled={ordemServico.id}
                                aria-label="delete"
                                onClick={() =>
                                  onOrdemServicoItensDelItem(i.item)
                                }
                                color="secondary"
                              >
                                <Icon fontSize="small">delete</Icon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Ações */}
        <Grid item xs={12} md={5}>
          <Grid container spacing={2} justify="flex-start" alignItems="center">
            {/* Header */}
            <Grid item xs={12} md={12}>
              <Grid container justify="flex-end" alignItems="center">
                {!isOrcamentos && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<Icon>info</Icon>}
                      className={classes.btnHistorico}
                      // disabled={_.isEmpty(ordemServico.veiculos)}
                      onClick={handleClientesHistorico}
                    >
                      Histórico
                    </Button>
                  </Grid>
                )}
                {isOrcamentos && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<Icon>person</Icon>}
                      className={classes.btnHistorico}
                      onClick={() => {
                        const clientesId =
                          ordemServico?.veiculos?.clientes?.id ||
                          ordemServico?.clientes?.id
                        onClientesFormClean()
                        onClientesDataById(clientesId)
                        handleOpenCloseDialogClientes(true, 'Editar cliente:')
                      }}
                    >
                      Cliente
                    </Button>
                  </Grid>
                )}
                <Grid item>
                  <Typography
                    variant="button"
                    display="block"
                    gutterBottom
                    className={classes.numOS}
                  >
                    {`Nº ${padLeft(ordemServico?.id ?? '0', 6, '0')}`}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
            </Grid>
            {/* Actions */}
            {!isOrcamentos && (
              <Grid item xs={12} md={12}>
                <ButtonGroup
                  fullWidth
                  size="small"
                  aria-label="small outlined button group"
                >
                  {/* ADD CLIENTE */}
                  <Button
                    disabled={!!ordemServico.id}
                    onClick={onOpen}
                    variant="contained"
                    className={classes.btnCinza}
                    startIcon={<Icon>person_add</Icon>}
                  >
                    CLIENTE
                  </Button>
                  {/* SALVAR */}
                  <Button
                    disabled={
                      loading.add ||
                      (_.isEmpty(ordemServico.itens) && !ordemServico.id) ||
                      (!_.isEmpty(ordemServico.itens) && !!ordemServico.id)
                    }
                    onClick={() =>
                      onOrdemServicoFormActionSalvar(entity => {
                        onOrdemServicoFormUpdate({ id: entity.id })
                        if (_.isFunction(onRealoadGrid)) {
                          onRealoadGrid()
                        }
                        handleImprimir(entity.id)
                      })
                    }
                    variant="contained"
                    className={classes.btnVerde}
                    startIcon={<Icon>save</Icon>}
                    endIcon={
                      loading.add && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                          color="secondary"
                        />
                      )
                    }
                  >
                    SALVAR
                  </Button>
                  {/* CANCELAR */}
                  <Button
                    disabled={ordemServico.id}
                    onClick={onOrdemServicoFormActionCancelar}
                    variant="contained"
                    className={classes.btnVermelho}
                    startIcon={<Icon>cancel</Icon>}
                  >
                    CANCELAR
                  </Button>
                  {/* IMPRIMIR */}
                  <ButtonLoading
                    loading={imprimindo}
                    disabled={!ordemServico.id}
                    onClick={() => handleImprimir(ordemServico.id)}
                    variant="contained"
                    className={classes.btnAmarelo}
                    startIcon={<Icon>local_printshop</Icon>}
                  >
                    IMPRIMIR
                  </ButtonLoading>
                </ButtonGroup>
              </Grid>
            )}
            {/* Prisma */}
            {!isOrcamentos && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  disabled={ordemServico.id}
                  id="ordemServico-prisma"
                  label="Prisma"
                  size="small"
                  variant="outlined"
                  value={ordemServico.prisma}
                  onChange={evt =>
                    onOrdemServicoFormUpdate({
                      prisma: evt.target.value.toUpperCase(),
                    })
                  }
                />
              </Grid>
            )}
            {/* Desconto */}
            {!isOrcamentos && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  disabled={
                    ordemServico.id /* || parseToNum(ordemServico.totalLiquido) <= 0 */
                  }
                  id="ordemServico-descontoValor"
                  label="Desconto"
                  size="small"
                  type="number"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {ordemServico.descontoTipo === 'V' ? (
                          <IconButton
                            onClick={() =>
                              onOrdemServicoFormUpdate({
                                descontoTipo: 'P',
                              })
                            }
                          >
                            <Icon>attach_money</Icon>
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() =>
                              onOrdemServicoFormUpdate({
                                descontoTipo: 'V',
                              })
                            }
                          >
                            <IconPorcentagemComp />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                  value={
                    ordemServico.descontoTipo === 'V'
                      ? parseToNum(ordemServico.descontoValor) || ''
                      : ordemServico.descontoPorcentagem || ''
                  }
                  onChange={evt => {
                    if (ordemServico.descontoTipo === 'V') {
                      onOrdemServicoFormUpdate({
                        descontoValor: parseToDec(
                          evt.target.value.toUpperCase()
                        ),
                      })
                    } else {
                      onOrdemServicoFormUpdate({
                        descontoPorcentagem: evt.target.value.toUpperCase(),
                      })
                    }
                    onOrdemServicoItensCalcTotal()
                  }}
                />
              </Grid>
            )}
            {/* Totais da O.S */}
            <Grid item xs={12} md={12}>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                alignContent="space-between"
              >
                <Grid item xs={12} md={8}>
                  <Typography variant="h6">Desconto</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    {`R$ ${MoneyFormatBr(
                      parseToNum(ordemServico.descontoValor)
                    )}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography gutterBottom variant="h4">
                    Total
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography gutterBottom variant="h6">
                    {`R$ ${MoneyFormatBr(
                      parseToNum(ordemServico.totalLiquido)
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* Info: Datas e Atendente */}
            <Grid item xs={12} md={12}>
              <Grid container spacing={1}>
                {/* Data abertura */}
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    disabled={ordemServico.id}
                    type="date"
                    id="ordemServico-dataAbertura"
                    label="Data abertura"
                    size="small"
                    variant="outlined"
                    value={ordemServico.dataAbertura}
                    onChange={evt =>
                      onOrdemServicoFormUpdate({
                        dataAbertura: evt.target.value,
                      })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.iptDatas}
                  />
                  <TextField
                    fullWidth
                    disabled={ordemServico.id}
                    type="time"
                    id="ordemServico-horaAbertura"
                    label="Hora abertura"
                    size="small"
                    variant="outlined"
                    value={ordemServico.horaAbertura}
                    onChange={evt =>
                      onOrdemServicoFormUpdate({
                        horaAbertura: evt.target.value,
                      })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                {/* Data entrega */}
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    disabled={ordemServico.id}
                    type="date"
                    id="ordemServico-dataEntrega"
                    label="Data entrega"
                    size="small"
                    variant="outlined"
                    value={ordemServico.dataEntrega}
                    onChange={evt =>
                      onOrdemServicoFormUpdate({
                        dataEntrega: evt.target.value,
                      })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.iptDatas}
                  />
                  <TextField
                    fullWidth
                    disabled={ordemServico.id}
                    type="time"
                    id="ordemServico-horaEntrega"
                    label="Hora entrega"
                    size="small"
                    variant="outlined"
                    value={ordemServico.horaEntrega}
                    onChange={evt =>
                      onOrdemServicoFormUpdate({
                        horaEntrega: evt.target.value,
                      })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                {/* Atendente */}
                <Grid item xs={12} md={12}>
                  <InputSearchComp
                    // editable={!!ordemServico.id}
                    disabled={!!ordemServico.id}
                    required
                    title="Buscar de atendentes:"
                    label="Atendente:"
                    value={
                      _.isEmpty(ordemServico.vendedor)
                        ? ''
                        : ordemServico.vendedor.nome
                    }
                    htmlId="iptSearchAtendente"
                    iptSize="small"
                    size="md"
                    searching={funcionariosLoading}
                    data={funcionariosOptions.map(f => ({
                      ...f,
                      id: f.id,
                      descricao: f.nome,
                    }))}
                    onSearch={pSearch =>
                      handleSearchFuncionarios(pSearch, { atendente: 'S' })
                    }
                    onSelectItem={item =>
                      onOrdemServicoFormUpdate({ vendedor: item })
                    }
                    onClickSearch={() =>
                      handleSearchFuncionarios('', { atendente: 'S' })
                    }
                    onClickClear={() =>
                      onOrdemServicoFormUpdate({ vendedor: {} })
                    }
                    onChange={() => null}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    disabled={ordemServico.id}
                    multiline
                    rows="4"
                    id="ordemServico-observacao"
                    label="Observação"
                    size="small"
                    variant="outlined"
                    value={ordemServico.observacao}
                    onChange={evt =>
                      onOrdemServicoFormUpdate({
                        observacao: evt.target.value.toUpperCase(),
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Dialog de histórico */}
      <DialogComp
        size="lg"
        scroll="body"
        title="Histórico"
        open={clientesHistoricoDialog}
        loading={clientesHistoricoLoading}
        onClose={() => setClientesHistoricoDialog(false)}
      >
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="Table com os históricos de serviço realizado por cliente seleciondo."
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">O.S</TableCell>
                <TableCell align="center">ABERTURA</TableCell>
                {!isOrcamentos && <TableCell align="center">VEÍCULO</TableCell>}
                <TableCell>SERVIÇO(S)</TableCell>
                <TableCell align="center">VALORES</TableCell>
                <TableCell align="center">CLIENTE</TableCell>
                <TableCell align="center">FILIAL</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.orderBy(clientesHistorico, ['ordemServicoId'], ['desc']).map(
                r =>
                  r && (
                    <TableRow key={r.ordemServicoId ?? ''}>
                      <TableCell
                        className={classes.tableCellHistorico}
                        align="center"
                      >
                        {r?.ordemServicoId ?? ''}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellHistorico}
                        align="center"
                      >
                        {DateIsoToBr(r?.ordemServicoDataAbertura ?? '')}
                      </TableCell>
                      {!isOrcamentos && (
                        <TableCell
                          className={classes.tableCellHistorico}
                          align="center"
                        >
                          <span>{`${r?.veiculosPlaca ?? ''}/`}</span>
                          <span>
                            <strong>{r?.veiculosModelo ?? ''}</strong>
                          </span>
                          <span>{`/${r?.veiculosCor ?? ''}`}</span>
                        </TableCell>
                      )}
                      <TableCell className={classes.tableCellHistorico}>
                        <ul className={classes.listaServicos}>
                          {r?.servicos?.map(s => (
                            <li>{s?.servicosDescricao ?? ''}</li>
                          ))}
                        </ul>
                      </TableCell>
                      <TableCell
                        className={classes.tableCellHistorico}
                        align="center"
                      >
                        <span>
                          VALOR PAGO:
                          <strong>
                            {` R$ ${MoneyFormatBr(
                              parseToNum(r?.totalLiquido ?? 0)
                            )}`}
                          </strong>
                        </span>
                        <br />
                        <span>
                          VALOR DESCONTO:
                          <strong>
                            {` R$ ${MoneyFormatBr(
                              parseToNum(r?.descontoValor ?? 0)
                            )}`}
                          </strong>
                        </span>
                      </TableCell>
                      <TableCell
                        className={classes.tableCellHistorico}
                        align="center"
                      >
                        {r?.clientesNome ?? ''}
                      </TableCell>
                      <TableCell
                        className={classes.tableCellHistorico}
                        align="center"
                      >
                        {`${r?.filiaisId ?? 0} - ${r?.filiaisDescricao ?? ''}`}
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogComp>
      {/* Dialog ClientesForm (add/edit) */}
      <DialogComp
        size="md"
        open={dialogAddClientes.open}
        title={dialogAddClientes.title}
        onClose={() => handleOpenCloseDialogClientes(false)}
        actions={[
          {
            label: 'Cancelar',
            onClick: () => {
              onClientesFormClean()
              handleOpenCloseDialogClientes(false)
            },
          },
          {
            label: 'Salvar',
            onClick: () => {
              onClientesDataSave(clientes.form, payload => {
                onClientesFormClean()
                handleOpenCloseDialogClientes(false)
              })
            },
          },
        ]}
      >
        <ClientesBasicFormComp
          data={clientes.form}
          onChange={onClientesFormUpdate}
        />
      </DialogComp>
    </>
  )
}

OrdemServicoFormComp.propTypes = {
  session: PropTypes.shape().isRequired,
  loading: PropTypes.shape().isRequired,
  ordemServico: PropTypes.shape().isRequired,
  ordemServicoItem: PropTypes.shape().isRequired,
  clientes: PropTypes.shape().isRequired,
  onOrdemServicoFormNew: PropTypes.func.isRequired,
  onOrdemServicoFormUpdate: PropTypes.func.isRequired,
  onOrdemServicoFormClean: PropTypes.func.isRequired,
  onOrdemServicoItensSaveItem: PropTypes.func.isRequired,
  onOrdemServicoItensDelItem: PropTypes.func.isRequired,
  onOrdemServicoItensCalcTotal: PropTypes.func.isRequired,
  onOrdemServicoItensFormClean: PropTypes.func.isRequired,
  onOrdemServicoItensFormUpdate: PropTypes.func.isRequired,
  // Actions
  onOrdemServicoLoading: PropTypes.func.isRequired,
  onOrdemServicoFormActionSalvar: PropTypes.func.isRequired,
  onOrdemServicoFormActionCancelar: PropTypes.func.isRequired,
  // Actions clientes
  onOpen: PropTypes.func.isRequired,
  // onClose: PropTypes.func.isRequired,
  onRealoadGrid: PropTypes.func,
  // Parametros
  onParametrosGetByNome: PropTypes.func.isRequired,
  isOrcamentos: PropTypes.bool,
  onClientesDataById: PropTypes.func.isRequired,
  onClientesDataSave: PropTypes.func.isRequired,
  onClientesFormClean: PropTypes.func.isRequired,
  onClientesFormUpdate: PropTypes.func.isRequired,
}

OrdemServicoFormComp.defaultProps = {
  onRealoadGrid: null,
  isOrcamentos: false,
}

// export default OrdemServicoFormComp
const mapStateToProps = state => ({
  session: state.auth.session,
  loading: state.ordemServico.loading,
  ordemServico: state.ordemServico.form,
  ordemServicoItem: state.ordemServico.item.form,
  clientes: state.clientes,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...OrdemServicoActions,
      ...ParametrosActions,
      ...ClientesActions,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdemServicoFormComp)
