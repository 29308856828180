import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const path = '/produtos'

/**
 *
 * @param { token, filter: {
 *  id, categoriaId, tabelaPrecoId, status = 'L', search, page = 0, rows = 50
 * }} param0
 * @returns List of produtos
 */
export const listProdutosApi = ({ token, filter }) => api
  .get(path, {
    headers: { Authorization: `Bearer ${token}` },
    params: filter,
  })
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param { token, id } param0
 * @returns ProdutosDTO
 */
export const getProdutosByIdApi = ({ token, id }) => api
  .get(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param { token, dto } param0
 * @returns
 */
export const addProdutosApi = ({ token, dto }) => api
  .post(path, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {token, id, dto} param0
 * @returns
 */
export const editProdutosApi = ({ token, id, dto }) => api
  .put(`${path}/${id}`, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {token, id} param0
 * @returns
 */
export const deleteProdutosApi = ({ token, id }) => api
  .delete(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)
