import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Grid, Tabs, Tab, Paper,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
// import _ from 'lodash'

// Actions
import { Actions as ProdutosActions } from 'store/ducks/produtos'

// Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import TableDataComp from 'components/Table'
import DialogComp from 'components/Dialogs'
import { parseToNum, MoneyFormatBr } from 'utils'
import ProdutosFormComp from './components/produtos_form'
import ProdutosCategoriasFormComp from './components/produtos_categorias_form'
import ProdutosImagensComp from './components/produtos_imagens_form'
// Styles
// import useStyles from './styles'

const ProdutosPage = ({
  session,
  produtos,
  onProdutosDataList,
  onProdutosDataById,
  onProdutosFormUpdate,
  onProdutosFormClean,
  onProdutosDataSave,
  onProdutosDataDelete,
}) => {
  // const classes = useStyles()
  const [currentPage, setCurrentPage] = useState(0)
  const [search, setSearch] = useState('')
  const [dialogForm, setDialogForm] = useState({ open: false, title: '' })
  const [dialogAskDelete, setDialogAskDelete] = useState({ open: false, title: '', data: null })
  const [tabSelected, setTabSelected] = useState(0)

  const handleSelectTab = (event, newValue) => setTabSelected(newValue)
  // Header Actions >>
  const handleFilterPageData = (filter) => {
    onProdutosDataList({ ...filter })
  }
  const handleSearch = (pNewPage) => {
    handleFilterPageData({ search, page: pNewPage })
  }
  const handleOpenCloseDialogForm = (open, title = null) => {
    if (title) {
      setDialogForm((s) => ({ ...s, open, title }))
    } else {
      setDialogForm((s) => ({ ...s, open }))
    }
  }
  const handleOpenCloseDialogAskDelete = (open, title = null, data = null) => {
    setDialogAskDelete((s) => ({
      ...s, open, title, data,
    }))
  }
  const handleChangePage = (event, value) => {
    const newPage = ((value ?? 1) - 1)
    setCurrentPage(newPage)
    handleSearch(newPage)
  }
  const headerButtons = [
    {
      title: 'Adicionar',
      icon: 'add',
      action: () => {
        onProdutosFormClean()
        handleOpenCloseDialogForm(true, 'Cadastro de produto:')
      },
    },
  ]
  // Header Actions <<

  // Table Actions >>
  const tableCols = [
    { key: '', title: 'REF./SKU/U.M', format: (r) => `${r.ref}/${r.sku}/${r.um}` },
    { key: 'descricao', title: 'DESCRIÇÃO' },
    {
      key: '', title: 'PREÇO/PROMO', format: (v) => `R$ ${MoneyFormatBr(parseToNum(v.preco))}/${MoneyFormatBr(parseToNum(v.precoPromo))}`, align: 'right',
    },
    { key: 'statusDescricao', title: 'STATUS', align: 'right' },
    {
      key: '',
      title: 'CATE./IMAG.',
      format: (v) => `${v?.categorias?.length ?? 0}/${v?.imagens?.length ?? 0}`,
      align: 'right',
    },
  ]
  const tableActions = [
    {
      key: 'editar',
      title: 'Editar',
      icon: 'edit',
      action: (row) => {
        onProdutosFormUpdate(row)
        handleOpenCloseDialogForm(true, 'Edição de produto:')
      },
    },
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: (row) => handleOpenCloseDialogAskDelete(true, 'Excluir?', row),
    },
  ]
  // Table Actions <<

  useEffect(() => {
    document.title = 'Backoffice - Produtos'
    handleFilterPageData({ search, page: currentPage })
  }, [])

  return (
    <DefaultPage>
      <PageHeaderComp
        title="Produtos"
        value={search}
        onChange={(evt) => setSearch(evt.target.value)}
        onClick={() => handleSearch(currentPage)}
        onKeyPress={(evt) => {
          if (evt.charCode === 13) {
            handleSearch(currentPage)
          }
        }}
        buttons={headerButtons}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TableDataComp
            loading={produtos?.loading?.list ?? false}
            size="small"
            rows={produtos?.list?.data ?? []}
            cols={tableCols}
            actions={tableActions}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Pagination
            count={produtos?.loading?.list ? 1 : produtos.list.total}
            page={currentPage + 1}
            onChange={handleChangePage}
          />
        </Grid>
      </Grid>
      {/* DialogForm (add/edit) */}
      <DialogComp
        open={dialogForm.open}
        size="md"
        title={dialogForm.title}
        onClose={() => handleOpenCloseDialogForm(false)}
        actions={[
          /*
          {
            label: 'Cancelar',
            onClick: () => {
              onProdutosFormClean()
              handleOpenCloseDialogForm(false)
            },
          },
          {
            label: 'Salvar',
            onClick: () => {
              onProdutosDataSave({
                ...produtos.form,
                preco: parseToDec(produtos.form.preco),
                precoPromo: parseToDec(produtos.form.precoPromo),
              }, () => {
                onProdutosFormClean()
                handleOpenCloseDialogForm(false)
                handleFilterPageData({ search })
              })
            },
          },
          */
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Paper>
              <Tabs
                value={tabSelected}
                onChange={handleSelectTab}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Produto" />
                <Tab label="Categoria(s)" disabled={!(produtos?.form?.id ?? 0)} />
                <Tab label="Imagem(ns)" disabled={!(produtos?.form?.id ?? 0)} />
              </Tabs>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            {tabSelected === 0 && (
              <ProdutosFormComp
                token={session?.token ?? ''}
                // data={produtos?.form}
                data={{
                  ...produtos?.form,
                  preco: parseToNum(produtos?.form?.preco),
                  precoPromo: parseToNum(produtos?.form?.precoPromo),
                }}
                // onChange={onProdutosFormUpdate}
                onSave={onProdutosDataSave}
              />
            )}
            {tabSelected === 1 && (
              <ProdutosCategoriasFormComp
                token={session?.token ?? ''}
                data={{
                  produtos: produtos?.form ?? {},
                  categorias: produtos?.form?.categorias ?? [],
                }}
                onCallback={() => onProdutosDataById(produtos?.form?.id)}
              />
            )}
            {tabSelected === 2 && (
              <ProdutosImagensComp
                token={session?.token ?? ''}
                data={{
                  produtos: produtos?.form ?? {},
                  imagens: produtos?.form?.imagens ?? [],
                }}
                onCallback={() => onProdutosDataById(produtos?.form?.id)}
              />
            )}
          </Grid>
        </Grid>
      </DialogComp>
      {/* Dialog Ask DELETE */}
      <DialogComp
        open={dialogAskDelete.open}
        title={dialogAskDelete.title}
        onClose={() => handleOpenCloseDialogAskDelete(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDelete(false),
          },
          {
            label: 'SIM',
            onClick: () => onProdutosDataDelete(dialogAskDelete.data.id, () => {
              handleOpenCloseDialogAskDelete(false)
              handleFilterPageData({ search, page: currentPage })
            }),
          },
        ]}
      >
        {`Você deseja realmente excluir o produto "${dialogAskDelete?.data?.descricao ?? ''}"?`}
      </DialogComp>
    </DefaultPage>
  )
}

ProdutosPage.propTypes = {
  session: PropTypes.shape().isRequired,
  produtos: PropTypes.shape().isRequired,
  onProdutosDataList: PropTypes.func.isRequired,
  onProdutosDataById: PropTypes.func.isRequired,
  onProdutosFormUpdate: PropTypes.func.isRequired,
  onProdutosFormClean: PropTypes.func.isRequired,
  onProdutosDataSave: PropTypes.func.isRequired,
  onProdutosDataDelete: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
  produtos: state.produtos,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...ProdutosActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProdutosPage)
