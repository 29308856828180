import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Grid,
  TextField,
} from '@material-ui/core'
import PropTypes from 'prop-types'
// import _ from 'lodash'

// Components
import DialogComp from 'components/Dialogs'
import ButtonLoading from 'components/Buttons/ButtonLoading'
// Actions
import { Actions as UsuariosActions } from 'store/ducks/usuarios'

const TrocarSenhaComp = ({
  open,
  onClose,
  onUsuariosUpdatePassword,
}) => {
  const [formTrocarSenha, setFormTrocarSenha] = useState({
    loading: false,
    senhaAntiga: '',
    senhaNova: '',
    senhaNovaConfir: '',
  })
  const handleUpdateTrocaSenha = (evt) => {
    const { target: { value = '', id = '' } } = evt
    setFormTrocarSenha((f) => ({ ...f, [id]: value }))
  }
  const handleUpdateTrocarSenhaLoading = (loading) => setFormTrocarSenha((f) => ({ ...f, loading }))
  const handleSalvarTrocarSenha = () => {
    handleUpdateTrocarSenhaLoading(true)
    onUsuariosUpdatePassword(
      formTrocarSenha,
      () => {
        onClose()
        handleUpdateTrocarSenhaLoading(false)
      },
      () => {
        handleUpdateTrocarSenhaLoading(false)
      },
    )
  }

  return (
    <DialogComp
      scroll="body"
      size="xs"
      open={open}
      title="Trocar senha"
      onClose={onClose}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="password"
            id="senhaAntiga"
            label="Senha atual"
            margin="normal"
            variant="outlined"
            autoComplete="new-password"
            value={formTrocarSenha.senhaAntiga}
            onChange={handleUpdateTrocaSenha}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="password"
            id="senhaNova"
            label="Senha nova"
            margin="normal"
            variant="outlined"
            autoComplete="new-password"
            value={formTrocarSenha.senhaNova}
            onChange={handleUpdateTrocaSenha}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="password"
            id="senhaNovaConfir"
            label="Confirmação"
            margin="normal"
            variant="outlined"
            autoComplete="new-password"
            value={formTrocarSenha.senhaNovaConfir}
            onChange={handleUpdateTrocaSenha}
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonLoading
            loading={formTrocarSenha.loading}
            variant="contained"
            color="primary"
            onClick={handleSalvarTrocarSenha}
          >
            Salvar
          </ButtonLoading>
        </Grid>
      </Grid>
    </DialogComp>
  )
}

TrocarSenhaComp.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUsuariosUpdatePassword: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...UsuariosActions,
  },
  dispatch,
)

export default connect(mapStateToProps, mapDispatchToProps)(TrocarSenhaComp)
