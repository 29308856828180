import { httpValidState, httpValidErros } from 'utils/http'
import api from '../..'

const path = '/pedidos/vendas'

/**
 *
 * @param { token, filter: { id, usuariosId, status, search, page, rows } } param0
 * @returns List of Pedidos
 */
export const listPedidosVendasApi = ({ token, filter }) => api
  .get(path, {
    headers: { Authorization: `Bearer ${token}` },
    params: filter,
  })
  .then(httpValidState)
  .catch(httpValidErros)

export const getPedidosVendasApi = ({ token, id }) => api
  .get(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const addPedidosVendasApi = ({ token, dto }) => api
  .post(path, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const editPedidosVendasApi = ({ token, id, dto }) => api
  .put(`${path}/${id}`, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const cancelPedidosVendasApi = ({ token, id }) => api
  .put(`${path}/cancel/${id}`, {}, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const deletePedidosVendasApi = ({ token, id }) => api
  .delete(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)
