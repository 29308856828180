import React, { useEffect, useState, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core'
import { NotificationManager } from 'react-notifications'

// Api's
import { listFuncionariosAtendentesApi } from 'api/funcionarios'
import { relatCaixasMovimentos } from 'api/relatorios'

// Actions
// import { Actions as DashboarActions } from 'store/ducks/dashboard'

// Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import ButtonLoadingComp from 'components/Buttons/ButtonLoading'
// Styles
// import useStyles from './styles'

// Stateless component
const RelatoriosCaixasPage = ({ session }) => {
  // const classes = useStyles()
  const inputLabel = useRef(null)
  const inputLabelAtendentes = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  const [labelWidthAtendentes, setLabelWidthAtendentes] = useState(0)
  const { user, token } = session

  // Form
  const [loading, setLoading] = useState(false)
  const [filiais, setFiliais] = useState(session.user.filial)
  const [funcionarios, setFuncionarios] = useState([])
  const [dataInicial, setDataInicial] = useState('')
  const [dataFinal, setDataFinal] = useState('')
  const [funcionariosFiliais, setFuncionariosFiliais] = useState({
    loading: false,
    funcionarios: [],
  })
  const [funcionariosFiliaisOpen, setFuncionariosFiliaisOpen] = useState(false)
  const handleFuncionariosFiliaisLoading = (pLoading) => {
    setFuncionariosFiliais((s) => ({
      ...s,
      loading: pLoading,
    }))
  }
  const handleFuncionariosLoad = () => {
    handleFuncionariosFiliaisLoading(true)
    listFuncionariosAtendentesApi({
      token: session.token,
      filter: {
        filiais: session.user.filial,
      },
    }).then((resp) => {
      handleFuncionariosFiliaisLoading(false)
      if (resp && _.isArray(resp)) {
        setFuncionariosFiliais((s) => ({
          ...s,
          funcionarios: resp,
        }))
      }
    })
  }
  const handleSelectFuncionario = (value) => {
    setFuncionarios(value)
    const filterAll = (i) => i === 'ALL'
    const filterNotAll = (i) => i !== 'ALL'
    if (value.findIndex(filterAll) > 0) {
      setFuncionarios(value.filter(filterAll))
      setFuncionariosFiliaisOpen(false)
    }
    if (value.findIndex(filterAll) === 0 && value.length > 1) {
      setFuncionarios(value.filter(filterNotAll))
    }
  }
  const handleFiltrar = () => {
    let valid = true
    // Validações
    if (!filiais) {
      NotificationManager.warning('Filial inválida!', 'Atenção', 8000)
      valid = false
    }
    if (valid && (!dataInicial || !dataFinal)) {
      NotificationManager.warning('Data inicial ou final inválida!', 'Atenção', 8000)
      valid = false
    }
    if (valid) {
      setLoading(true)
      relatCaixasMovimentos({
        token,
        filter: {
          filiaisId: filiais,
          dataInicial,
          dataFinal,
          atendentes: funcionarios,
        },
      }).then((resp) => {
        if (resp) {
          window.open(URL.createObjectURL(new Blob([resp], { type: 'application/pdf' })))
        }
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    document.title = 'Backoffice - Relatório/Caixas'
    setLabelWidth(inputLabel.current.offsetWidth)
    setLabelWidthAtendentes(inputLabelAtendentes.current.offsetWidth)
    handleFuncionariosLoad()
  }, [])

  return (
    <DefaultPage>
      <PageHeaderComp title="Relatório - Caixa" />
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Filtro
              </Typography>
              <Grid container spacing={2}>
                {/* Filiais */}
                <Grid item xs={12} md={12}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel
                      ref={inputLabel}
                      id="relatorios-caixas-filiais-label"
                    >
                      Filiais
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="relatorios-caixas-filiais-label"
                      id="relatorios-caixas-filiais-select"
                      size="small"
                      margin="dense"
                      value={filiais || ''}
                      onChange={(evt) => setFiliais(evt.target.value)}
                      labelWidth={labelWidth}
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {user.filiais
                        .filter((f) => f.status === 'L')
                        .map((f) => (
                          <MenuItem key={f.id} value={f.id}>
                            {f.descricao}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Atendentes */}
                <Grid item xs={12} md={12}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    multiple
                  >
                    <InputLabel
                      ref={inputLabelAtendentes}
                      id="relatorios-caixas-filiais-label"
                    >
                      Atendente(s)
                    </InputLabel>
                    <Select
                      fullWidth
                      multiple
                      open={funcionariosFiliaisOpen}
                      onClose={() => setFuncionariosFiliaisOpen(false)}
                      onOpen={() => setFuncionariosFiliaisOpen(true)}
                      labelId="relatorios-caixas-filiais-label"
                      id="relatorios-caixas-filiais-select"
                      size="small"
                      margin="dense"
                      value={funcionarios}
                      onChange={(evt) => handleSelectFuncionario(evt.target.value)}
                      labelWidth={labelWidthAtendentes}
                    >
                      <MenuItem value="ALL">TODOS</MenuItem>
                      {_.orderBy(
                        funcionariosFiliais.funcionarios.filter(
                          (s) => s.id !== 'ALL',
                        ),
                        ['nome'],
                        ['asc'],
                      ).map((f) => (
                        <MenuItem key={f.id} value={f.id}>
                          {f.nome.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Data inicial */}
                <Grid item xs={6} md={6}>
                  <TextField
                    fullWidth
                    id="date"
                    label="Data inicial"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={dataInicial}
                    onChange={(evt) => setDataInicial(evt.target.value)}
                  />
                </Grid>
                {/* Data final */}
                <Grid item xs={6} md={6}>
                  <TextField
                    fullWidth
                    id="date"
                    label="Data final"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={dataFinal}
                    onChange={(evt) => setDataFinal(evt.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <ButtonLoadingComp
                loading={loading}
                size="small"
                variant="contained"
                color="primary"
                onClick={handleFiltrar}
              >
                Filtrar
              </ButtonLoadingComp>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </DefaultPage>
  )
}

RelatoriosCaixasPage.propTypes = {
  // Empresas
  session: PropTypes.shape().isRequired,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RelatoriosCaixasPage)
