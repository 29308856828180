import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  gridPagamentos: {
    marginTop: theme.spacing(1),
  },
  tituloPagamentos: {
    textAlign: 'center',
  },
  wrapper: {
    margin: 10,
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

export default useStyles
