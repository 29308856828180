const UserRoles = {
  ROLE_ADMIN: {
    id: 1,
    codigo: 'ROLE_ADMIN',
    descricao: 'ADMIN',
  },
  ROLE_FRANQUEADO: {
    id: 2,
    codigo: 'ROLE_FRANQUEADO',
    descricao: 'FRANQUEADO',
  },
  ROLE_ATENDENTE: {
    id: 3,
    codigo: 'ROLE_ATENDENTE',
    descricao: 'ATENDENTE',
  },
  ROLE_LAVADOR: {
    id: 4,
    codigo: 'ROLE_LAVADOR',
    descricao: 'LAVADOR',
  },
  ROLE_CLIENTE: {
    id: 5,
    codigo: 'ROLE_CLIENTE',
    descricao: 'CLIENTE',
  },
  ROLE_VENDAS: {
    id: 6,
    codigo: 'ROLE_VENDAS',
    descricao: 'VENDAS',
  },
  ROLE_CAIXAS: {
    id: 7,
    codigo: 'ROLE_CAIXAS',
    descricao: 'CAIXAS',
  },
  ROLE_ORDEM_SERVICO: {
    id: 8,
    codigo: 'ROLE_ORDEM_SERVICO',
    descricao: 'ORDEM_SERVICO',
  },
  ROLE_ORCAMENTOS: {
    id: 9,
    codigo: 'ROLE_ORCAMENTOS',
    descricao: 'ORCAMENTOS',
  },
}

export const getRoleById = (id) => Object.values(UserRoles).find((s) => s.id === id)
export const getRoleByCodigo = (codigo) => Object.values(UserRoles).find((s) => s.codigo === codigo)

export default UserRoles
