import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Icon,
  Tooltip,
  Fab,
} from '@material-ui/core'

import useStyles from './styles'

const PageHeaderComp = (props) => {
  const {
    title, subtitle, value, onChange, onClick, onKeyPress, buttons,
  } = props
  const classes = useStyles()
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} lg={6}>
        <Typography variant="h3" component="h3">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Grid container direction="row" justify="flex-end">
          {onChange && (
            <TextField
              id="outlined-adornment-password"
              className={classes.margin}
              variant="outlined"
              label="Pesquisar"
              value={value}
              onChange={onChange}
              onKeyPress={onKeyPress}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" aria-label="Pesquisar" onClick={onClick}>
                      <Icon>search</Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          {buttons.map((b) => (
            <Tooltip key={b.title} title={b.title}>
              <Fab
                color={b.color || 'primary'}
                aria-label={b.title}
                className={classes.margins}
                onClick={b.action}
              >
                <Icon>{b.icon}</Icon>
              </Fab>
            </Tooltip>
          ))}
        </Grid>
      </Grid>
      {subtitle && (
        <Grid item xs={12} className={classes.subtitle}>
          <Typography variant="h6" component="h6">
            {subtitle}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

PageHeaderComp.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      color: PropTypes.string,
      action: PropTypes.func.isRequired,
    }),
  ),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
}

PageHeaderComp.defaultProps = {
  title: '',
  subtitle: '',
  buttons: [],
  onChange: null,
  value: null,
  onClick: null,
  onKeyPress: null,
}

export default PageHeaderComp
