import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'

// Util's
import { UFs, validarPlaca } from 'utils'

// Api's
import { addClientesSimplesApi } from 'api/clientes'

// Components
import {
  CPFMaskCustom,
  CNPJMaskCustom,
  CelularMaskCustom,
} from 'components/Inputs/Masked'

// Styles
import useStyles from './styles'

// Stateless
const ClientesVeiculosFormComp = ({
  token,
  filial = {},
  onCallback,
  data = null,
}) => {
  const [form, setForm] = useState({
    // Cliente
    tipoPessoa: '',
    nome: '',
    cgc: '',
    nascimentoDia: '',
    nascimentoMes: '',
    nascimentoAno: '',
    email: '',
    celular: '',
    observacao: '',
    uf: '',
    // Veículo
    placa: '',
    modelo: '',
    cor: '',
    porte: '',
  })
  const [placaValid, setPlacaValid] = useState(true)
  const classes = useStyles()
  // const inputLabel = useRef(null)
  const [saving, setSaving] = useState(false)
  const [labelWidths, setLabelWidths] = useState({
    tipoPessoa: {
      width: 0,
      ref: useRef(null),
    },
    porte: {
      width: 0,
      ref: useRef(null),
    },
    uf: {
      width: 0,
      ref: useRef(null),
    },
  })
  const handleChange = payload => {
    setForm(s => ({ ...s, ...payload }))
  }
  const handleSalvar = () => {
    const isValid = true
    if (!form.uf) {
      NotificationManager.warning('A UF está inválida!', 'Atenção', 8000)
      return
    }
    if (!form.porte) {
      NotificationManager.warning('O PORTE está inválido!', 'Atenção', 8000)
      return
    }
    setSaving(true)
    if (isValid) {
      addClientesSimplesApi({ token, dto: form })
        .then(resp => {
          setSaving(false)
          if (resp && resp.status === 200 && resp.message === 'inserted') {
            NotificationManager.success(
              `Cliente ${form.nome} cadastrado com sucesso!`,
              'Sucesso!',
              8000
            )
            if (_.isFunction(onCallback)) {
              onCallback(resp)
            }
          }
        })
        .catch(() => setSaving(false))
    }
  }
  const handleValidPlaca = veiculoPlaca =>
    setPlacaValid(validarPlaca(veiculoPlaca))

  useEffect(() => {
    setLabelWidths(s => ({
      ...s,
      tipoPessoa: {
        ...s.tipoPessoa,
        width: s.tipoPessoa.ref.current.offsetWidth,
      },
      porte: {
        ...s.tipoPessoa,
        width: s.porte.ref.current.offsetWidth,
      },
      uf: {
        ...s.uf,
        width: s.uf.ref.current.offsetWidth,
      },
    }))
    if (data && data.id) {
      setForm({ ...data, porte: '', uf: data.uf || filial.estado })
    } else {
      handleChange({ tipoPessoa: 'PF', porte: '', uf: filial.estado })
    }
  }, [])

  return (
    <Grid container spacing={1}>
      {/* Clientes */}
      <Grid item xs={12} md={3}>
        <FormControl
          required
          variant="outlined"
          fullWidth
          margin="dense"
          className={classes.dataControl}
        >
          <InputLabel ref={labelWidths.tipoPessoa.ref}>
            Tipo de Pessoa
          </InputLabel>
          <Select
            id="clientes-tipoPessoa"
            value={form.tipoPessoa}
            onChange={evt =>
              handleChange({ tipoPessoa: evt.target.value.toUpperCase() })
            }
            labelWidth={labelWidths.tipoPessoa.width}
          >
            <MenuItem value="PF">FÍSICA</MenuItem>
            <MenuItem value="PJ">JURÍDICA</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          required
          id="clientes-nome"
          label="Nome"
          margin="dense"
          variant="outlined"
          value={form.nome}
          onChange={evt =>
            handleChange({ nome: evt.target.value.toUpperCase() })
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          InputProps={{
            inputComponent:
              !form.tipoPessoa || form.tipoPessoa === 'PF'
                ? CPFMaskCustom
                : CNPJMaskCustom,
          }}
          id="clientes-cgc"
          label={form.tipoPessoa === 'PF' ? 'CPF' : 'CNPJ'}
          margin="dense"
          variant="outlined"
          value={form.cgc}
          onChange={evt =>
            handleChange({ cgc: evt.target.value.toUpperCase() })
          }
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <TextField
          fullWidth
          id="clientes-email"
          label="E-mail"
          margin="dense"
          variant="outlined"
          value={form.email}
          onChange={evt =>
            handleChange({ email: evt.target.value.toUpperCase() })
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          required
          InputProps={{
            inputComponent: CelularMaskCustom,
          }}
          id="clientes-celular"
          label="Celular"
          margin="dense"
          variant="outlined"
          value={form.celular}
          onChange={evt =>
            handleChange({ celular: evt.target.value.toUpperCase() })
          }
        />
      </Grid>
      <Grid item xs={12} md={1}>
        <TextField
          fullWidth
          required
          id="clientes-nascimentoDia"
          label="Dia"
          margin="dense"
          variant="outlined"
          inputProps={{
            pattern: '\\d',
            maxLength: 2,
          }}
          value={form.nascimentoDia}
          onChange={evt =>
            handleChange({ nascimentoDia: evt.target.value.toUpperCase() })
          }
        />
      </Grid>
      <Grid item xs={12} md={1}>
        <TextField
          fullWidth
          required
          id="clientes-nascimentoMes"
          label="Mês"
          margin="dense"
          variant="outlined"
          inputProps={{
            pattern: '\\d',
            maxLength: 2,
          }}
          value={form.nascimentoMes}
          onChange={evt =>
            handleChange({ nascimentoMes: evt.target.value.toUpperCase() })
          }
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField
          fullWidth
          id="clientes-nascimentoAno"
          label="Ano"
          margin="dense"
          variant="outlined"
          inputProps={{
            pattern: '\\d',
            maxLength: 4,
          }}
          value={form.nascimentoAno}
          onChange={evt =>
            handleChange({ nascimentoAno: evt.target.value.toUpperCase() })
          }
        />
      </Grid>
      <Grid item xs={12} md={10}>
        <TextField
          fullWidth
          multiline
          rowsMax="4"
          id="clientes-observacao"
          label="Observação"
          margin="dense"
          variant="outlined"
          value={form.observacao}
          onChange={evt =>
            handleChange({ observacao: evt.target.value.toUpperCase() })
          }
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <FormControl
          variant="outlined"
          fullWidth
          required
          margin="dense"
          className={classes.dataControl}
        >
          <InputLabel ref={labelWidths.uf.ref}>UF</InputLabel>
          <Select
            id="clientes-endereco-uf"
            value={form.uf}
            onChange={evt =>
              handleChange({ uf: evt.target.value.toUpperCase() })
            }
            labelWidth={labelWidths.uf.width}
          >
            <MenuItem value="">SELECIONE</MenuItem>
            {UFs.map(u => {
              const uf = Object.keys(u)[0]
              return (
                <MenuItem key={uf} value={uf}>
                  {uf}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
      {/* Veículos */}
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          required
          id="veiculos-placa"
          label="Placa"
          margin="dense"
          variant="outlined"
          value={form.placa}
          inputProps={{
            maxLength: 7,
          }}
          onChange={evt =>
            handleChange({ placa: evt.target.value.toUpperCase() })
          }
          onBlur={() => handleValidPlaca(form.placa)}
          error={!placaValid}
          helperText={!placaValid ? 'Placa inválida' : null}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          required
          id="veiculos-modelo"
          label="Modelo"
          margin="dense"
          variant="outlined"
          value={form.modelo}
          onChange={evt =>
            handleChange({ modelo: evt.target.value.toUpperCase() })
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          required
          fullWidth
          id="veiculos-cor"
          label="Cor"
          margin="dense"
          variant="outlined"
          value={form.cor}
          onChange={evt =>
            handleChange({ cor: evt.target.value.toUpperCase() })
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl
          required
          fullWidth
          margin="dense"
          variant="outlined"
          className={classes.dataControl}
        >
          <InputLabel ref={labelWidths.porte.ref}>Porte</InputLabel>
          <Select
            id="veiculos-porte"
            value={form.porte}
            onChange={evt =>
              handleChange({ porte: evt.target.value.toUpperCase() })
            }
            labelWidth={labelWidths.porte.width}
          >
            <MenuItem value="">SELECIONE</MenuItem>
            <MenuItem value="P">PEQUENO</MenuItem>
            <MenuItem value="G">GRANDE</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {/* Ações */}
      <Grid item xs={12} md={12}>
        <Grid container justify="flex-end">
          <Grid item>
            <div className={classes.wrapper}>
              <Button
                disabled={saving || !placaValid}
                color="primary"
                variant="contained"
                onClick={handleSalvar}
              >
                Salvar
              </Button>
              {saving && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                  color="secondary"
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

ClientesVeiculosFormComp.propTypes = {
  token: PropTypes.string.isRequired,
  filial: PropTypes.shape().isRequired,
  data: PropTypes.shape(),
  onCallback: PropTypes.func,
}

ClientesVeiculosFormComp.defaultProps = {
  onCallback: null,
  data: null,
}

export default ClientesVeiculosFormComp
