/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// import _ from 'lodash'

// import DashboardPage from 'pages/dashboard'

const PrivateRoute = ({ auth: { session: { token = '' } = {} } = {}, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (token) {
        return <Redirect to={{ pathname: '/dashboard', state: { from: props.location } }} />
      }
      return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    }}
  />
)

PrivateRoute.propTypes = {
  auth: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(
  mapStateToProps,
  null,
)(PrivateRoute)
