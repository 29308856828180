import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  margins: {
    marginLeft: theme.spacing(1),
  },
  subtitle: {
    marginTop: theme.spacing(1),
  },
}))

export default styles
