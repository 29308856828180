import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
// import _ from 'lodash'

// Styles
import useStyles from './styles'

// Stateless
const TabelasPrecoFormComp = ({ data, onChange }) => {
  const {
    // id,
    descricao,
    dataInicial,
    dataFinal,
    status,
  } = data
  const classes = useStyles()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="tabelaspreco-descricao"
          label="Descrição:"
          margin="normal"
          variant="outlined"
          value={descricao}
          onChange={evt =>
            onChange({ descricao: evt.target.value.toUpperCase() })
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          required
          fullWidth
          type="date"
          id="tabelaspreco-dataInicial"
          label="Data inicial:"
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={dataInicial}
          onChange={evt =>
            onChange({ dataInicial: evt.target.value.toUpperCase() })
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          required
          fullWidth
          type="date"
          id="tabelaspreco-dataFinal"
          label="Data final:"
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={dataFinal}
          onChange={evt =>
            onChange({ dataFinal: evt.target.value.toUpperCase() })
          }
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl
          required
          variant="outlined"
          fullWidth
          margin="normal"
          className={classes.dataControl}
        >
          <InputLabel ref={inputLabel}>Status</InputLabel>
          <Select
            id="tabelaspreco-status"
            value={status}
            onChange={evt =>
              onChange({ status: evt.target.value.toUpperCase() })
            }
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="P">PENDENTE</MenuItem>
            <MenuItem value="L">LIBERADO</MenuItem>
            <MenuItem value="B">BLOQUEADO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

TabelasPrecoFormComp.propTypes = {
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}

export default TabelasPrecoFormComp
