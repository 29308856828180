import { NotificationManager } from 'react-notifications'
import _ from 'lodash'

export const httpValidState = (res, silent = false) => {
  const { log } = console
  if (silent) return res
  if (res) {
    switch (res.request.status) {
    case 200:
    case 201:
    case 202:
      log('success')
      break
    case 400:
    case 401:
    case 403:
    case 404:
      log('Erro 400', res)
      if (res.data.errorCode === 9001) {
        NotificationManager.warning(res.data.message, res.data.error, 8000)
      } else if (res.data.errorCode === 9002) {
        NotificationManager.warning(res.data.message, res.data.error, 8000)
        setTimeout(() => {
          window.location.href = '/token-expirado'
        }, 3000)
      } else {
        if (_.isEmpty(res.data.fields)) {
          NotificationManager.warning(res.data.error, 'Atenção:', 8000)
        } else {
          Object.values(res.data.fields).forEach((v, i) => {
            NotificationManager.info(`${i + 1}) ${v}`, 'Validação:', 20000)
          })
        }
        log(`Server error, ${res.request.status} =>`, res)
      }
      // return null
      break
    case 500:
    case 502:
    case 503:
      NotificationManager.warning(res.data.error, 'Falha:', 8000)
      log('Server error 500, try again', res)
      return null
    default:
      log('unhandled:', res)
      break
    }
  } else {
    const msgError = 'Ocorreu um erro na resposta do servidor'
    NotificationManager.error(msgError, 'Atenção', 8000)
    log(msgError)
  }
  return res.data || res
}

export const httpValidErros = (error, silent = false, forceCatch = false, onError = null) => {
  const { log } = console
  if (error.response) {
    log('httpValidErros', error.response)
    if (error.request.responseType === 'blob') {
      const fr = new FileReader()
      fr.onload = async (evt) => {
        const {
          message: errorMessageBlob = 'Ocorreu um erro ao tentar gerar o relatório!',
          error: errorTitleBlob = 'Atenção',
        } = JSON.parse(evt.target.result)
        NotificationManager.warning(errorMessageBlob, errorTitleBlob, 10000)
      }
      if (error.request.response) {
        fr.readAsText(error.request.response)
      } else {
        NotificationManager.warning('Ocorreu um erro ao tentar gerar o relatório!', 'Atenção', 10000)
      }
    } else {
      const { error: errorTitle, message: errorMessage } = error.response.data
      if (silent && !forceCatch) return error.response.data
      if (forceCatch) throw new Error(errorMessage)
      if (_.isEmpty(error.response.data.fields)) {
        NotificationManager.warning(errorMessage, errorTitle, 8000)
      } else {
        Object.values(error.response.data.fields).forEach((v, i) => {
          NotificationManager.info(`${i + 1}) ${v}`, 'Validação:', 20000)
        })
      }
    }
    if (_.isFunction(onError)) {
      onError(error)
    }
  }
  if (!error.response) {
    // Something happened in setting up the request that triggered an Error
    let errorMessage = ''
    if (silent) return error.message
    if (forceCatch) throw new Error(error.message)
    switch (error.message) {
    case 'Network Error':
      errorMessage = 'O servidor parece estar off-line :('
      break
    default:
      errorMessage = error.message
    }
    NotificationManager.error(errorMessage, 'Ops!', 8000)
    if (_.isFunction(onError)) {
      onError(error)
    }
  }
  return null
}
