import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  btnAzul: {
    color: theme.divnet.text.white,
    backgroundColor: theme.divnet.purple.main,
    '&:hover': {
      backgroundColor: theme.divnet.purple.dark,
    },
  },
}))

export default useStyles
