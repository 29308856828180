import axios from 'axios'

// Dev
// export const API_URL = 'http://localhost:8880'
// Lan
// export const API_URL = 'http://192.168.15.36:8081'
// Homologacao
// export const API_URL = ''
// Produção
// export const API_URL = 'http://sistema.dryjet.com.br:9500'
// export const API_URL = 'https://sistema.dryjet.com.br:9501'
// export const API_URL = 'https://g7.divnet.com.br:8443' // G7Detail
// export const API_URL = `${process.env.REACT_APP_API}` // 'http://18.210.62.228:8880' // CL VISA SAUDAVEL
export const API_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_URL_PROD
  : process.env.REACT_APP_URL_DEV

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

const api = axios.create({
  baseURL: API_URL,
  timeout: 90000,
  headers,
})

export default api
