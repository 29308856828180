import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Grid, TextField, Icon, Fab,
} from '@material-ui/core'
import _ from 'lodash'

// Actions
import { Actions as OrdemServicoActions } from 'store/ducks/ordemservico'
import { Actions as NfseRpsActions } from 'store/ducks/fisco/nfse/rps'

// Components
import ButtonLoadingComp from 'components/Buttons/ButtonLoading'
import TableDataComp from 'components/Table'

// Util's
import { parseToNum, MoneyFormatBr } from 'utils'

// Styles
// import useStyles from './styles'

// Stateless
const FiscoNfseRpsFormComp = ({
  session,
  fiscoNfseRps: { loading: fiscoLoading = {} },
  ordemServico: { loading = {}, rps: { list = [] } },
  onOrdemServicoLoading,
  onOrdemServicoRpsDataList,
  onOrdemServicoRpsListClean,
  onFiscoNfseRpsDataSave,
  onCallback,
}) => {
  // const classes = useStyles()
  const [dataInicial, setDataInicial] = useState('')
  const [dataFinal, setDataFinal] = useState('')
  const [osSelected, setOsSelected] = useState([])
  const handleFilterOrdens = () => {
    onOrdemServicoRpsDataList({ dataInicial, dataFinal })
  }
  const handleCreateLoteRps = () => {
    const dto = {
      filiaisId: session.user.filial,
      valorTotal: osSelected.reduce((a, b) => a + b.totalLiquido, 0),
      itens: osSelected.map((i) => ({
        ordemServicoId: i.id,
        ordemServicoTotalLiquido: i.totalLiquido,
      })),
    }
    onFiscoNfseRpsDataSave(dto, () => {
      handleFilterOrdens()
      if (_.isFunction(onCallback)) {
        onCallback()
      }
    })
  }
  useEffect(() => {
    onOrdemServicoRpsListClean()
    onOrdemServicoLoading({ rps: false })
  }, [])

  return (
    <Grid container spacing={1} justify="flex-start" alignItems="center">
      {/* Data Inicial */}
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="nfse-rps-dataInicial"
          label="Data Inicial"
          type="date"
          margin="dense"
          variant="outlined"
          value={dataInicial}
          onChange={(evt) => setDataInicial(evt.target.value.toUpperCase())}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      {/* Data Final */}
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          id="nfse-rps-dataFinal"
          label="Data Final"
          type="date"
          margin="dense"
          variant="outlined"
          value={dataFinal}
          onChange={(evt) => setDataFinal(evt.target.value.toUpperCase())}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      {/* Btn Action */}
      <Grid item xs={1}>
        <Fab aria-label="filtrar" size="small" color="primary" onClick={handleFilterOrdens}>
          <Icon>search</Icon>
        </Fab>
      </Grid>
      <Grid item xs={3}>
        <ButtonLoadingComp
          fullWidth
          loading={fiscoLoading.add || false}
          disabled={_.isEmpty(osSelected)}
          aria-label="Criar Lote"
          color="primary"
          variant="contained"
          startIcon={<Icon>save</Icon>}
          onClick={handleCreateLoteRps}
        >
          CRIAR
        </ButtonLoadingComp>
      </Grid>
      {/* Tabela */}
      <Grid item xs={12}>
        <TableDataComp
          checkbox
          loading={loading.rps || false}
          size="small"
          rows={list.data || []}
          cols={[
            { key: 'id', title: 'O.S', align: 'center' },
            {
              key: '',
              title: 'DESCRIÇÃO',
              format: (os) => `${os.clientesNome}, ${os.veiculosPlaca}/${os.veiculosModelo}`,
              onClick: (os) => console.log(os),
            },
            {
              key: 'totalLiquido',
              title: 'TOTAL',
              align: 'rigth',
              format: (totalLiquido) => `R$ ${MoneyFormatBr(parseToNum(totalLiquido))}`,
            },
          ]}
          onRowsSelected={(data) => setOsSelected(data)}
        />
      </Grid>
    </Grid>
  )
}

FiscoNfseRpsFormComp.propTypes = {
  session: PropTypes.shape().isRequired,
  fiscoNfseRps: PropTypes.shape().isRequired,
  ordemServico: PropTypes.shape().isRequired,
  onOrdemServicoLoading: PropTypes.func.isRequired,
  onOrdemServicoRpsDataList: PropTypes.func.isRequired,
  onOrdemServicoRpsListClean: PropTypes.func.isRequired,
  onFiscoNfseRpsDataSave: PropTypes.func.isRequired,
  onCallback: PropTypes.func,
}

FiscoNfseRpsFormComp.defaultProps = {
  onCallback: null,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
  fiscoNfseRps: state.fiscoNfseRps,
  ordemServico: state.ordemServico,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...OrdemServicoActions,
    ...NfseRpsActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FiscoNfseRpsFormComp)
