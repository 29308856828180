import React, { useEffect, useState, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core'
import { NotificationManager } from 'react-notifications'

// Api's
import { relatFaturamentosPlanilha } from 'api/relatorios'

// Actions
import { Actions as PagamentosFormaActions } from 'store/ducks/pagamentos/forma'

// Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import ButtonLoadingComp from 'components/Buttons/ButtonLoading'

// Utils
import { YearNow, listMeses } from 'utils'

// Styles
// import useStyles from './styles'

// Stateless component
const RelatoriosFaturamentosPlanilhaPage = ({ session }) => {
  // const classes = useStyles()
  const inputLabel = useRef(null)
  const inputLabelPgtos = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  const { user, token } = session

  // Form
  const [loading, setLoading] = useState(false)
  const [anos, setAnos] = useState('')
  const [listAnos, setListAnos] = useState([])
  const [mesInicial, setMesInicial] = useState('')
  const [mesFinal, setMesFinal] = useState('')

  const handleFiltrar = () => {
    let valid = true
    // Validações
    if (!anos) {
      NotificationManager.warning('Ano inválido!', 'Atenção', 8000)
      valid = false
    }
    if (valid && (!mesInicial || !mesFinal)) {
      NotificationManager.warning('Data inicial ou final inválida!', 'Atenção', 8000)
      valid = false
    }
    if (valid) {
      setLoading(true)
      relatFaturamentosPlanilha({
        token,
        filter: {
          ano: anos,
          mesInicial,
          mesFinal,
        },
      }).then((resp) => {
        if (resp) {
          window.open(
            URL.createObjectURL(new Blob([resp], { type: 'application/vnd.ms-excel' })),
          )
        }
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    document.title = 'Backoffice - Relatório/Faturamento/Planilha'
    setLabelWidth(inputLabel.current.offsetWidth)
    let currentAno = YearNow()
    while (currentAno > 2015) {
      listAnos.push(String(currentAno))
      currentAno -= 1
    }
    setListAnos(listAnos)
  }, [])

  return (
    <DefaultPage>
      <PageHeaderComp title="Relatório - Fat./Planilha" />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Filtro
              </Typography>
              <Grid container spacing={2}>
                {/* Filiais */}
                <Grid item xs={12} md={4}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel
                      ref={inputLabel}
                      id="relatorios-faturamentos-anos-label"
                    >
                      Ano
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="relatorios-faturamentos-anos-label"
                      id="relatorios-faturamentos-anos-select"
                      size="small"
                      margin="dense"
                      value={anos || ''}
                      onChange={(evt) => setAnos(evt.target.value)}
                      labelWidth={labelWidth}
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {listAnos.map((a) => (
                        <MenuItem key={a} value={a}>
                          {a}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Mes inicial */}
                <Grid item xs={12} md={4}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel
                      ref={inputLabel}
                      id="relatorios-faturamentos-mesinicial-label"
                    >
                      Mês Inicial
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="relatorios-faturamentos-mesinicial-label"
                      id="relatorios-faturamentos-mesinicial-select"
                      size="small"
                      margin="dense"
                      value={mesInicial || ''}
                      onChange={(evt) => setMesInicial(evt.target.value)}
                      labelWidth={labelWidth}
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {listMeses.map((m, idx) => (
                        <MenuItem key={m} value={idx + 1}>
                          {m}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Mes final */}
                <Grid item xs={12} md={4}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel
                      ref={inputLabel}
                      id="relatorios-faturamentos-mesfinal-label"
                    >
                      Mês Final
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="relatorios-faturamentos-mesfinal-label"
                      id="relatorios-faturamentos-mesfinal-select"
                      size="small"
                      margin="dense"
                      value={mesFinal || ''}
                      onChange={(evt) => setMesFinal(evt.target.value)}
                      labelWidth={labelWidth}
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {listMeses.map((m, idx) => (
                        <MenuItem key={m} value={idx + 1}>
                          {m}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <ButtonLoadingComp
                loading={loading}
                size="small"
                variant="contained"
                color="primary"
                onClick={handleFiltrar}
              >
                Filtrar
              </ButtonLoadingComp>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </DefaultPage>
  )
}

RelatoriosFaturamentosPlanilhaPage.propTypes = {
  session: PropTypes.shape().isRequired,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
  pagamentosForma: state.pagamentosForma,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...PagamentosFormaActions,
  },
  dispatch,
)

export default connect(mapStateToProps, mapDispatchToProps)(RelatoriosFaturamentosPlanilhaPage)
