import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Grid, IconButton, Icon, Button, ButtonGroup,
} from '@material-ui/core'
import { NotificationManager } from 'react-notifications'

// Api's
import { listClientesApi } from 'api/clientes'
// Utils
import { validarPlaca } from 'utils'
// Components
import InputLookUpComp from 'components/Inputs/LookUp'
import DialogComp from 'components/Dialogs'
import TableDataComp from 'components/Table'

const ClienteInputSearchComp = ({
  token,
  disabled,
  size,
  valueIpt,
  onClearSearch,
  onVeiculoSelected,
  onAddNewVeiculo,
}) => {
  const [iptSearch, setIptSearch] = useState('')
  const [searching, setSearching] = useState('')
  const [payload, setPayload] = useState({ page: 0, rows: 0, data: [] })
  const [dialog, setDialog] = useState(false)
  const handleClear = (external = true) => {
    setIptSearch('')
    setPayload({ page: 0, rows: 0, data: [] })
    if (external && _.isFunction(onClearSearch)) {
      onClearSearch()
    }
  }
  const handlePesquisarClientes = (external = true) => {
    setSearching(true)
    listClientesApi({
      token,
      filter: { search: iptSearch },
    }).then((resp) => {
      setSearching(false)
      if (resp) {
        if (_.isArray(resp.data) && _.isEmpty(resp.data)) {
          NotificationManager.warning(
            `Nenhum registro localizado para a pesquisa: ${iptSearch}`,
            'Atenção!',
            8000,
          )
          handleClear(true)
          return
        }
        // Update listagem
        setPayload(resp)
        let clienteSeleted = {}
        // Verifica se a pesquisa foi de uma placa...
        if (iptSearch && iptSearch.trim().length === 7 && validarPlaca(iptSearch)) {
          const listVeiculos = []
          // Criando lista apenas de veículos...
          resp.data.forEach((c) => {
            c.veiculos.forEach((v) => {
              listVeiculos.push({ ...v, clientesId: c.id })
            })
          })
          // Caso só tenha um veículo com a placa pesquisada ele já é selecionado automaticamente.
          const listVeiculosUniq = listVeiculos.filter((v) => v.placa === iptSearch)
          if (listVeiculosUniq.length === 1) {
            clienteSeleted = resp.data.find(
              (c) => c.id === listVeiculosUniq[0].clientesId,
            )
            // Retornando com cliente selecionado
            if (_.isFunction(onVeiculoSelected)) {
              onVeiculoSelected({
                ...clienteSeleted,
                veiculos: listVeiculosUniq[0],
              })
            }
          }
          // Muitas placas, possívelmente vários clientes para o mesmo veículo.
          if (listVeiculosUniq.length > 1) {
            // Abre dialog para selecionar...
            if (external) setDialog(true)
          }
          return
        }
        if (external) setDialog(true)
      }
    })
  }
  const handleVeiculoSelected = (row) => {
    if (_.isFunction(onVeiculoSelected)) {
      onVeiculoSelected(row)
      handleClear(false)
      setDialog(false)
      setIptSearch(row.veiculos.placa)
    }
  }
  const handleAddNewVeiculo = (row) => {
    const clienteData = { ...row }
    delete clienteData.veiculos
    if (_.isFunction(onAddNewVeiculo)) {
      onAddNewVeiculo(clienteData)
      handleClear(false)
      setDialog(false)
    }
  }
  useEffect(() => {
    setIptSearch(valueIpt)
  }, [valueIpt])
  return (
    <>
      <InputLookUpComp
        required
        disabled={disabled}
        iptSize={size}
        label="Buscar por placa, nome, e-mail ou cpf/cnpj."
        htmlId="iptClienteInputSearchComp"
        searching={searching}
        value={searching ? `Pesquisando por ${iptSearch}, aguarde...` : iptSearch}
        onChange={(ipt) => setIptSearch(ipt.target.value.toUpperCase())}
        onKeyPress={(evt) => {
          if (evt.charCode === 13) {
            handlePesquisarClientes()
          }
        }}
        onClickClear={handleClear}
        onClickSearch={handlePesquisarClientes}
      />
      {/* Dialog com a listagem de clientes/veículos */}
      <DialogComp
        open={dialog}
        title="Resultado de pesquisa"
        size={size}
        onClose={() => setDialog(false)}
      >
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} md={12}>
            <InputLookUpComp
              required
              disabled={disabled}
              iptSize={size}
              label="Buscar por placa, nome, e-mail ou cpf/cnpj."
              htmlId="iptClienteInputSearchComp"
              searching={searching}
              value={
                searching ? `Pesquisando por ${iptSearch}, aguarde...` : iptSearch
              }
              onChange={(ipt) => setIptSearch(ipt.target.value.toUpperCase())}
              onKeyPress={(evt) => {
                if (evt.charCode === 13) {
                  handlePesquisarClientes(false)
                }
              }}
              onClickClear={handleClear}
              onClickSearch={() => handlePesquisarClientes(false)}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TableDataComp
              loading={searching}
              size="small"
              rows={payload.data}
              cols={[
                {
                  key: '',
                  title: 'CLIENTE',
                  format: (row) => {
                    const {
                      id,
                      nome,
                      celular,
                      nascimentoDia,
                      nascimentoMes,
                      email,
                      cgc,
                    } = row
                    return (
                      <div>
                        <strong>{`[${id}] - ${nome}`}</strong>
                        <br />
                        <small>
                          <strong>CEL: </strong>
                          <span>{`${celular}, `}</span>
                          <strong>NASCIMENTO: </strong>
                          <span>{`${nascimentoDia}/${nascimentoMes} `}</span>
                          <br />
                          <strong>E-MAIL: </strong>
                          <span>{`${email}, `}</span>
                          <strong>CPF/CNPJ: </strong>
                          <span>{cgc}</span>
                        </small>
                      </div>
                    )
                  },
                },
                {
                  key: '',
                  title: 'VEÍCULO(S)',
                  format: (row) => {
                    const { veiculos = [] } = row
                    return (
                      <ButtonGroup orientation="vertical" size="small">
                        {veiculos.map((v) => (
                          <Button
                            key={`btnVeiculo${v.id}`}
                            size="small"
                            onClick={() => handleVeiculoSelected({
                              ...row,
                              veiculos: v,
                            })}
                          >
                            <strong>{`${v.modelo}`}</strong>
                            <i>{`(${v.cor}),`}</i>
                            <strong>PLACA: </strong>
                            <span>{` ${v.placa}`}</span>
                          </Button>
                        ))}
                      </ButtonGroup>
                    )
                  },
                },
                {
                  key: '',
                  title: 'ADD',
                  format: (row) => (
                    <IconButton
                      size="small"
                      color="secondary"
                      onClick={() => handleAddNewVeiculo(row)}
                    >
                      <Icon>add</Icon>
                    </IconButton>
                  ),
                },
              ]}
            />
          </Grid>
        </Grid>
      </DialogComp>
    </>
  )
}

ClienteInputSearchComp.propTypes = {
  token: PropTypes.string.isRequired,
  valueIpt: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  onClearSearch: PropTypes.func.isRequired,
  onVeiculoSelected: PropTypes.func.isRequired,
  onAddNewVeiculo: PropTypes.func.isRequired,
}
ClienteInputSearchComp.defaultProps = {
  disabled: false,
  valueIpt: '',
  size: 'normal',
}

export default ClienteInputSearchComp
