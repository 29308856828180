import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Grid,
} from '@material-ui/core'
import _ from 'lodash'

// Actions
import { Actions as ServicosActions } from 'store/ducks/servicos'

// Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import TableDataComp from 'components/Table'
import DialogComp from 'components/Dialogs'
import ServicosFormComp from './components/ServicosForm'
// Styles
// import useStyles from './styles'

const ServicosPage = ({
  session,
  servicos,
  onServicosDataList,
  onServicosFormUpdate,
  onServicosFormNew,
  onServicosFormClean,
  onServicosDataSave,
  onServicosDataDelete,
}) => {
  // const classes = useStyles()
  const [search, setSearch] = useState('')
  const [dialogForm, setDialogForm] = useState({ open: false, title: '' })
  const [dialogAskDelete, setDialogAskDelete] = useState({ open: false, title: '', data: null })

  // Header Actions >>
  const handleFilterPageData = (filter) => {
    onServicosDataList({ ...filter })
  }
  const handleSearch = () => {
    handleFilterPageData({ search })
  }
  const handleOpenCloseDialogForm = (open, title = null) => {
    if (title) {
      setDialogForm((s) => ({ ...s, open, title }))
    } else {
      setDialogForm((s) => ({ ...s, open }))
    }
  }
  const handleOpenCloseDialogAskDelete = (open, title = null, data = null) => {
    setDialogAskDelete((s) => ({
      ...s, open, title, data,
    }))
  }
  const headerButtons = [
    {
      title: 'Adicionar',
      icon: 'add',
      action: () => {
        onServicosFormClean()
        handleOpenCloseDialogForm(true, 'Cadastro de serviço:')
        onServicosFormNew({
          sequencia: _.isArray(servicos.list.data) ? (servicos.list.data.length + 1) : 1,
          status: 'L',
        })
      },
    },
  ]
  // Header Actions <<

  // Table Actions >>
  const tableCols = [
    { key: 'descricao', title: 'DESCRIÇÃO' },
    { key: 'sequencia', title: 'SEQUÊNCIA' },
    { key: 'statusDescricao', title: 'STATUS' },
  ]
  const tableActions = [
    {
      key: 'editar',
      title: 'Editar',
      icon: 'edit',
      action: (row) => {
        onServicosFormUpdate(row)
        handleOpenCloseDialogForm(true, 'Edição de serviço:')
      },
    },
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: (row) => handleOpenCloseDialogAskDelete(true, 'Excluir?', row),
    },
  ]
  // Table Actions <<

  useEffect(() => {
    document.title = 'Backoffice - Serviços'
    handleFilterPageData({ search })
  }, [])

  return (
    <DefaultPage>
      <PageHeaderComp
        title="Serviços"
        // subtitle={empresasDesc}
        value={search}
        onChange={(evt) => setSearch(evt.target.value)}
        onClick={handleSearch}
        onKeyPress={(evt) => {
          if (evt.charCode === 13) {
            handleSearch()
          }
        }}
        buttons={headerButtons}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TableDataComp
            loading={servicos.loading.list}
            size="small"
            rows={servicos.list.data}
            cols={tableCols}
            actions={tableActions}
          />
        </Grid>
      </Grid>
      {/* DialogForm (add/edit) */}
      <DialogComp
        open={dialogForm.open}
        title={dialogForm.title}
        onClose={() => handleOpenCloseDialogForm(false)}
        actions={[
          {
            label: 'Cancelar',
            onClick: () => {
              onServicosFormClean()
              handleOpenCloseDialogForm(false)
            },
          },
          {
            label: 'Salvar',
            onClick: () => {
              onServicosDataSave(servicos.form, () => {
                onServicosFormClean()
                handleOpenCloseDialogForm(false)
                handleFilterPageData({ search })
              })
            },
          },
        ]}
      >
        <ServicosFormComp
          token={session.token}
          data={servicos.form}
          onChange={onServicosFormUpdate}
        />
      </DialogComp>
      {/* Dialog Ask DELETE */}
      <DialogComp
        open={dialogAskDelete.open}
        title={dialogAskDelete.title}
        onClose={() => handleOpenCloseDialogAskDelete(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDelete(false),
          },
          {
            label: 'SIM',
            onClick: () => onServicosDataDelete(dialogAskDelete.data.id, () => {
              handleOpenCloseDialogAskDelete(false)
              handleFilterPageData({ search })
            }),
          },
        ]}
      >
        {`Você deseja realmente excluir o funcionário "${dialogAskDelete.data ? dialogAskDelete.data.nome : ''}"?`}
      </DialogComp>
    </DefaultPage>
  )
}

ServicosPage.propTypes = {
  session: PropTypes.shape().isRequired,
  servicos: PropTypes.shape().isRequired,
  onServicosDataList: PropTypes.func.isRequired,
  onServicosFormUpdate: PropTypes.func.isRequired,
  onServicosFormNew: PropTypes.func.isRequired,
  onServicosFormClean: PropTypes.func.isRequired,
  onServicosDataSave: PropTypes.func.isRequired,
  onServicosDataDelete: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
  servicos: state.servicos,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...ServicosActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServicosPage)
