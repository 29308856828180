import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const path = '/caixas'

export const listCaixasApi = ({ token, filter }) => {
  const {
    status = '', page = 0, rows = 50, id, filiais, caixasOperador, aberturaData, fechamentoData, search,
  } = filter
  let queryUrl = `?status=${status}&page=${page}&rows=${rows}`
  queryUrl = id ? `${queryUrl}&id=${id}` : queryUrl
  queryUrl = filiais ? `${queryUrl}&filiais=${filiais}` : queryUrl
  queryUrl = caixasOperador ? `${queryUrl}&caixasOperador=${caixasOperador}` : queryUrl
  queryUrl = aberturaData ? `${queryUrl}&aberturaData=${aberturaData}` : queryUrl
  queryUrl = fechamentoData ? `${queryUrl}&fechamentoData=${fechamentoData}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl
  return api
    .get(path + queryUrl, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getCaixasApi = ({ token, id }) => api
  .get(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const addCaixasApi = ({ token, dto }) => api
  .post(path, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const editCaixasApi = ({ token, id, dto }) => api
  .put(`${path}/${id}`, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const deleteCaixasApi = ({ token, id }) => api
  .delete(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

// Custom
export const getCaixasAbertoApi = ({ token }) => api
  .get(`${path}/aberto`, { headers: { Authorization: `Bearer ${token}` } })
