import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Icon,
  Badge,
  Menu,
  MenuItem,
  Backdrop,
  CircularProgress,
  Hidden,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import _ from 'lodash'

// Actions
import { Actions as AuthActions } from 'store/ducks/auth'
import { Actions as UsuariosActions } from 'store/ducks/usuarios'
// Components
import TrocarSenhaComp from 'components/Forms/TrocarSenha'
import CaixasAberturaFechamentoComp from 'components/Buttons/CaixasAberturaFechamento'
import OrdemServicoAberturaComp from 'components/Buttons/OrdemServicoAbertura'
//* Enums
import UserRoles from 'utils/enums/UserRoles'
// Utils
import { hasRole } from 'utils'
// Styles
import useStyles from './styles'

const AppBarComp = ({
  session: { user = {} },
  history,
  logoff,
  onAuthMenuToggle,
  onAuthSessionUserUpdate,
  onUsuariosUpdateFilial,
}) => {
  const classes = useStyles()
  const { filial = 0, filiais = [], nome = '', grupos = [] } = user
  const { descricao: title = '' } = filiais.find(f => f.id === filial) || {}
  const [loadingTrocaFilial, setLoadingTrocaFilial] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const handleSeleted = filialSelected => {
    handleClose()
    setLoadingTrocaFilial(true)
    onUsuariosUpdateFilial(filialSelected.id, () => {
      setLoadingTrocaFilial(false)
      onAuthSessionUserUpdate({ filial: filialSelected.id })
    })
  }
  // Usuários
  const [anchorUsuariosEl, setAnchorUsuariosEl] = useState(null)
  const [dialogTrocarSenha, setDialogTrocarSenha] = useState(open)
  const openMenuUsuarios = Boolean(anchorUsuariosEl)
  const handleMenuUsuariosOpen = event =>
    setAnchorUsuariosEl(event.currentTarget)
  const handleMenuUsuariosClose = () => setAnchorUsuariosEl(null)
  const handleMenuUsuariosTrocarSenha = () => {
    setDialogTrocarSenha(true)
    handleMenuUsuariosClose()
  }
  return (
    <AppBar position="fixed" className={classes.root}>
      <Toolbar>
        {/* Menu PopUp filiais */}
        <Hidden smDown>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu-filiais"
            onClick={handleClick}
          >
            <Badge badgeContent={filiais.length} color="secondary">
              <Icon>account_balance</Icon>
            </Badge>
          </IconButton>
        </Hidden>
        <Hidden smUp>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu-menu"
            onClick={onAuthMenuToggle}
          >
            <Icon>menu</Icon>
          </IconButton>
        </Hidden>
        <Menu
          id="menu-filiais"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: 68 * 4.5,
            },
          }}
        >
          {_.orderBy(filiais, ['descricao'], ['ASC']).map(f => (
            <MenuItem
              key={f.id}
              selected={f.id === filial}
              onClick={() => handleSeleted(f)}
            >
              {`#${f.id} - ${f.descricao} ${
                f.status === 'B' ? '<BLOQUEADA>' : ''
              }`}
            </MenuItem>
          ))}
        </Menu>
        <Hidden smDown>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Hidden>
        <Hidden smUp>
          <Typography variant="p" className={classes.title}>
            {title.substr(0, 10)}
          </Typography>
        </Hidden>
        {hasRole(grupos, UserRoles.ROLE_CAIXAS.codigo) && (
          <CaixasAberturaFechamentoComp />
        )}
        {hasRole(grupos, UserRoles.ROLE_ORDEM_SERVICO.codigo) && (
          <OrdemServicoAberturaComp />
        )}
        {hasRole(grupos, UserRoles.ROLE_ORCAMENTOS.codigo) && (
          <Button
            onClick={() => history.push('/orcamentos')}
            variant="contained"
            color="secondary"
            className={[classes.btnAzul, classes.menuButton].join(' ')}
            startIcon={<Icon>account_balance_wallet</Icon>}
          >
            ORÇAMENTOS
          </Button>
        )}
        <Button
          onClick={handleMenuUsuariosOpen}
          color="inherit"
          className={classes.menuButton}
          startIcon={<Icon>account_circle</Icon>}
        >
          {nome.split(' ')[0]}
        </Button>
        <Menu
          id="menu-usuarios"
          anchorEl={anchorUsuariosEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={openMenuUsuarios}
          onClose={handleMenuUsuariosClose}
        >
          <MenuItem onClick={handleMenuUsuariosTrocarSenha}>
            TROCAR SENHA
          </MenuItem>
        </Menu>
        <Button
          color="inherit"
          startIcon={<Icon>exit_to_app</Icon>}
          onClick={logoff}
        >
          Sair
        </Button>
      </Toolbar>
      <Backdrop className={classes.backdrop} open={loadingTrocaFilial}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <TrocarSenhaComp
        open={dialogTrocarSenha}
        onClose={() => setDialogTrocarSenha(false)}
      />
    </AppBar>
  )
}

AppBarComp.propTypes = {
  history: PropTypes.shape().isRequired,
  session: PropTypes.shape().isRequired,
  logoff: PropTypes.func.isRequired,
  onAuthMenuToggle: PropTypes.func.isRequired,
  onAuthSessionUserUpdate: PropTypes.func.isRequired,
  onUsuariosUpdateFilial: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  session: state.auth.session,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...AuthActions,
      ...UsuariosActions,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(AppBarComp)
