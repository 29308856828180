import React, { useEffect, useState, useRef } from 'react'
import { NotificationManager } from 'react-notifications'
import PropTypes from 'prop-types'
import {
  Grid, FormControl, InputLabel, Select, MenuItem, IconButton, Icon,
} from '@material-ui/core'
import _ from 'lodash'

import { listCategoriasApi } from 'api/categorias'
import { addCategoriasApi, deleteCategoriasApi } from 'api/produtos/categorias'
import TableDataComp from 'components/Table'

// Styles
import useStyles from './styles'

// Stateless
const ProdutosCategoriasFormComp = ({ token, data, onCallback }) => {
  const { produtos, categorias } = data
  const classes = useStyles()
  const [categoriaSelected, setCategoriasSelected] = useState('')
  const [listCategorias, setListCategorias] = useState([])
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)

  const handleCategoriaChange = (event) => {
    setCategoriasSelected(event.target.value)
  }

  const onHandleAddCategoria = () => {
    addCategoriasApi({
      token,
      dto: {
        produtosId: produtos.id,
        categoriasId: categoriaSelected,
        status: 'L',
      },
    }).then((resp) => {
      if (resp?.status === 200 && resp?.message === 'inserted') {
        NotificationManager.success(`A imagem foi adicionada ao produto ${produtos?.decricao ?? ''} com sucesso!`, 'Sucesso!', 8000)
        if (_.isFunction(onCallback)) {
          onCallback()
        }
      }
    })
  }

  const onHandleDeleteCategorias = (id) => {
    deleteCategoriasApi({ token, id }).then((resp) => {
      if (resp?.status === 200 && resp?.message === 'deleted') {
        NotificationManager.success(`A imagem foi excluída do produto ${produtos?.descricao ?? ''} com sucesso!`, 'Sucesso!', 8000)
        if (_.isFunction(onCallback)) {
          onCallback()
        }
      }
    })
  }

  const tableCols = [
    {
      key: '', align: 'left', title: 'SKU/DESCRIÇÃO', format: (r) => `${r.sku}/${r.descricao}`,
    },
  ]
  const tableActions = [
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: (row) => onHandleDeleteCategorias(row.id),
    },
  ]

  const loadCategorias = () => {
    listCategoriasApi({ token, filter: { status: 'L' } }).then((resp) => {
      setListCategorias(resp?.data ?? [])
    })
  }

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
    loadCategorias()
  }, [])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={11}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="list-categorias" ref={inputLabel}>Categorias</InputLabel>
          <Select
            labelId="list-categorias"
            id="list-categorias"
            value={categoriaSelected}
            labelWidth={labelWidth}
            onChange={handleCategoriaChange}
          >
            {listCategorias?.map((c) => <MenuItem key={c.id} value={c.id}>{c.descricao}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={1} className={classes.ctnBtnAddCategoria}>
        <IconButton onClick={onHandleAddCategoria}>
          <Icon>add</Icon>
        </IconButton>
      </Grid>
      <Grid item xs={12} md={12}>
        <TableDataComp
          // loading={produtos.loading.list}
          size="small"
          rows={categorias}
          cols={tableCols}
          actions={tableActions}
        />
      </Grid>
    </Grid>
  )
}

ProdutosCategoriasFormComp.propTypes = {
  token: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  onCallback: PropTypes.func.isRequired,
}

export default ProdutosCategoriasFormComp
