import _ from 'lodash'
import { NotificationManager } from 'react-notifications'
import {
  listTabelasPrecoItensApi,
  getTabelasPrecoItensApi,
  addTabelasPrecoItensApi,
  editTabelasPrecoItensApi,
  deleteTabelasPrecoItensApi,
} from 'api/tabelasprecoitens'

// State Usuario
const initialState = {
  loading: {
    list: false,
    find: false,
    add: false,
    edit: false,
    del: false,
  },
  list: { page: 0, rows: 0, data: [] },
  form: {
    id: 0,
    tabelasPreco: {},
    tabelasPrecoId: 0,
    tabelasPrecoDescricao: '',
    tabelasPrecoStatus: '',
    tabelasPrecoStatusDescricao: '',
    servicos: {},
    valor: 0,
    porte: '',
    porteDescricao: '',
    status: '',
    statusDescricao: '',
  },
}

// Actions Types
export const Types = {
  RESET: 'reset',
  TABELAS_PRECOS_ITENS_LOADING: 'tabelasPrecoItens/LOADING',
  TABELAS_PRECOS_ITENS_LIST_LOAD: 'tabelasPrecoItens/TABELAS_PRECOS_ITENS_LIST_LOAD',
  TABELAS_PRECOS_ITENS_LIST_UPDATE: 'tabelasPrecoItens/TABELAS_PRECOS_ITENS_LIST_UPDATE',
  TABELAS_PRECOS_ITENS_LIST_CLEAN: 'tabelasPrecoItens/TABELAS_PRECOS_ITENS_LIST_CLEAN',
  TABELAS_PRECOS_ITENS_FORM_UPDATE: 'tabelasPrecoItens/TABELAS_PRECOS_ITENS_FORM_UPDATE',
}

// Reducers
export default function tabelasPrecoItensReducers(state = initialState, action) {
  switch (action.type) {
  // RESET DEFAULT
  case Types.RESET:
    return {
      ...state,
      ...initialState,
    }
  case Types.TABELAS_PRECOS_ITENS_LOADING:
    return {
      ...state,
      loading: { ...state.loading, ...action.payload },
    }
  case Types.TABELAS_PRECOS_ITENS_LIST_LOAD:
    return {
      ...state,
      list: action.payload,
    }
  case Types.TABELAS_PRECOS_ITENS_LIST_UPDATE:
    return {
      ...state,
      list: state.list.map((i) => (i.id === action.payload.id ? { ...i, ...action.payload } : i)),
    }
  case Types.TABELAS_PRECOS_ITENS_LIST_CLEAN:
    return {
      ...state,
      list: initialState.list,
    }
  case Types.TABELAS_PRECOS_ITENS_FORM_UPDATE:
    return {
      ...state,
      form: { ...state.form, ...action.payload },
    }
  default:
    return state
  }
}

// Actions Creators
export const Actions = {
  // Store Update
  onTabelasPrecoItensLoading: (payload) => ({
    type: Types.TABELAS_PRECOS_ITENS_LOADING,
    payload,
  }),
  onTabelasPrecoItensListLoad: (payload) => ({
    type: Types.TABELAS_PRECOS_ITENS_LIST_LOAD,
    payload,
  }),
  onTabelasPrecoItensListUpdate: (payload) => ({
    type: Types.TABELAS_PRECOS_ITENS_LIST_UPDATE,
    payload,
  }),
  onTabelasPrecoItensListClean: () => ({ type: Types.TABELAS_PRECOS_ITENS_LIST_CLEAN }),
  onTabelasPrecoItensFormUpdate: (payload) => ({
    type: Types.TABELAS_PRECOS_ITENS_FORM_UPDATE,
    payload,
  }),
  onTabelasPrecoItensFormClean: () => ({
    type: Types.TABELAS_PRECOS_ITENS_FORM_UPDATE,
    payload: initialState.form,
  }),
  onTabelasPrecoItensDataById: (id) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    dispatch(Actions.onTabelasPrecoItensLoading({ find: true }))
    // Chamada API
    getTabelasPrecoItensApi({ token, id }).then((payload) => {
      dispatch(Actions.onTabelasPrecoItensLoading({ find: false }))
      dispatch(
        Actions.onTabelasPrecoItensFormUpdate(
          _.isObject(payload) ? payload : initialState.form,
        ),
      )
    })
  },
  onTabelasPrecoItensDataList: (filter) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    dispatch(Actions.onTabelasPrecoItensLoading({ list: true }))
    // Chamada API
    listTabelasPrecoItensApi({ token, filter }).then((payload) => {
      dispatch(Actions.onTabelasPrecoItensLoading({ list: false }))
      dispatch(Actions.onTabelasPrecoItensListLoad(payload || initialState.list))
    })
  },
  onTabelasPrecoItensDataSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    // Chamada API
    if (dto.id) {
      dispatch(Actions.onTabelasPrecoItensLoading({ edit: true }))
      editTabelasPrecoItensApi({ token, dto, id: dto.id }).then((payload) => {
        dispatch(Actions.onTabelasPrecoItensLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success(
            `Item ${dto.servicos.descricao} atualizado com sucesso!`,
            'Sucesso!',
            8000,
          )
          if (_.isFunction(callback)) {
            callback(payload.entity)
          }
        }
      })
    } else {
      dispatch(Actions.onTabelasPrecoItensLoading({ add: true }))
      addTabelasPrecoItensApi({ token, dto }).then((payload) => {
        dispatch(Actions.onTabelasPrecoItensLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success(
            `Item ${dto.servicos.descricao} cadastrada com sucesso!`,
            'Sucesso!',
            8000,
          )
          if (_.isFunction(callback)) {
            callback(payload.entity)
          }
        }
      })
    }
  },
  onTabelasPrecoItensDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    dispatch(Actions.onTabelasPrecoItensLoading({ del: true }))
    // Chamada API
    deleteTabelasPrecoItensApi({ token, id }).then((payload) => {
      dispatch(Actions.onTabelasPrecoItensLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Item excluído com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    })
  },
}
