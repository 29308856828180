import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Icon,
  Grid,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import _ from 'lodash'

import useStyles from './styles'

const FormCaixasFechamentoComp = ({
  loading,
  form,
  onChange,
  onClick,
}) => {
  const {
    filiais: {
      id: filiaisId = 0,
      descricao = '',
    },
    funcionarios: {
      id: funcionariosId = 0,
      caixasOperador: { id: caixasOperadorId = 0 },
      nome = '',
    },
    valorAbertura,
    valorSangria,
    valorDinheiro,
    valorCheque,
    valorCartaoCredito,
    valorCartaoDebito,
    valorFaturado,
    valorCortesia,
    valorPix,
    senhaAbertura,
    observacao,
  } = form
  const classes = useStyles()
  return (
    <form>
      <Grid container spacing={2}>
        {/* Filiais */}
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            id="caixas-fechamento-filiais"
            size="small"
            type="text"
            margin="dense"
            variant="outlined"
            label="Filial"
            value={filiaisId ? `${filiaisId} - ${descricao}` : ''}
            error={!filiaisId}
            helperText={!filiaisId ? 'Filial inválida!' : ''}
          />
        </Grid>
        {/* Operador de Caixa */}
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            id="caixas-fechamento-caixasOperador"
            size="small"
            type="text"
            margin="dense"
            variant="outlined"
            label="Operador de caixa"
            value={caixasOperadorId ? `${caixasOperadorId} - [${funcionariosId}] ${nome}` : ''}
            error={!caixasOperadorId}
            helperText={!caixasOperadorId ? 'Operador inválido!' : ''}
          />
        </Grid>
        {/* Valor abertura */}
        <Grid item xs={6} md={6}>
          <TextField
            id="caixas-fechamento-valorAbertura"
            fullWidth
            size="small"
            type="number"
            margin="dense"
            variant="outlined"
            label="Valor de ABERTURA"
            value={valorAbertura}
            onChange={(evt) => onChange({ valorAbertura: evt.target.value })}
          />
        </Grid>
        {/* Valor sangria */}
        <Grid item xs={6} md={6}>
          <TextField
            id="caixas-fechamento-valorSangria"
            fullWidth
            size="small"
            type="number"
            margin="dense"
            variant="outlined"
            label="Total em SANGRIA"
            value={valorSangria}
            onChange={(evt) => onChange({ valorSangria: evt.target.value })}
          />
        </Grid>
        {/* Valor dinheiro */}
        <Grid item xs={6} md={6}>
          <TextField
            id="caixas-fechamento-valorDinheiro"
            fullWidth
            size="small"
            type="number"
            margin="dense"
            variant="outlined"
            label="Total em DINHEIRO"
            value={valorDinheiro}
            onChange={(evt) => onChange({ valorDinheiro: evt.target.value })}
          />
        </Grid>
        {/* Valor valorCheque */}
        <Grid item xs={6} md={6}>
          <TextField
            id="caixas-fechamento-valorCheque"
            fullWidth
            size="small"
            type="number"
            margin="dense"
            variant="outlined"
            label="Total em CHEQUE"
            value={valorCheque}
            onChange={(evt) => onChange({ valorCheque: evt.target.value })}
          />
        </Grid>
        {/* Valor valorCartaoCredito */}
        <Grid item xs={6} md={6}>
          <TextField
            id="caixas-fechamento-valorCartaoCredito"
            fullWidth
            size="small"
            type="number"
            margin="dense"
            variant="outlined"
            label="Total em C. CRÉDITO"
            value={valorCartaoCredito}
            onChange={(evt) => onChange({ valorCartaoCredito: evt.target.value })}
          />
        </Grid>
        {/* Valor valorCartaoDebito */}
        <Grid item xs={6} md={6}>
          <TextField
            id="caixas-fechamento-valorCartaoDebito"
            fullWidth
            size="small"
            type="number"
            margin="dense"
            variant="outlined"
            label="Total em C. DEBITO"
            value={valorCartaoDebito}
            onChange={(evt) => onChange({ valorCartaoDebito: evt.target.value })}
          />
        </Grid>
        {/* Valor valorFaturado */}
        <Grid item xs={6} md={6}>
          <TextField
            id="caixas-fechamento-valorFaturado"
            fullWidth
            size="small"
            type="number"
            margin="dense"
            variant="outlined"
            label="Total FATURADO"
            value={valorFaturado}
            onChange={(evt) => onChange({ valorFaturado: evt.target.value })}
          />
        </Grid>
        {/* Valor valorCortesia */}
        <Grid item xs={6} md={6}>
          <TextField
            id="caixas-fechamento-valorCortesia"
            fullWidth
            size="small"
            type="number"
            margin="dense"
            variant="outlined"
            label="Total CORTESIA"
            value={valorCortesia}
            onChange={(evt) => onChange({ valorCortesia: evt.target.value })}
          />
        </Grid>
        {/* Valor valorPix */}
        <Grid item xs={6} md={6}>
          <TextField
            id="caixas-fechamento-valorPix"
            fullWidth
            size="small"
            type="number"
            margin="dense"
            variant="outlined"
            label="Total PIX"
            value={valorPix}
            onChange={(evt) => onChange({ valorPix: evt.target.value })}
          />
        </Grid>
        {/* Observação */}
        <Grid item xs={12} md={12}>
          <TextField
            id="caixas-fechamento-observacao"
            fullWidth
            size="small"
            margin="dense"
            type="text"
            variant="outlined"
            label="Observação"
            value={observacao}
            onChange={(evt) => onChange({ observacao: evt.target.value.toUpperCase() })}
          />
        </Grid>
        {/* Valor senhaAbertura */}
        <Grid item xs={12} md={12}>
          <TextField
            id="caixas-fechamento-senhaAbertura"
            autoComplete="new-password"
            fullWidth
            size="small"
            margin="dense"
            type="password"
            variant="outlined"
            label="Senha"
            value={senhaAbertura}
            onChange={(evt) => onChange({ senhaAbertura: evt.target.value })}
          />
        </Grid>
        {/* Botão de fechamento */}
        <Grid item xs={12} md={12}>
          <Grid container justify="flex-end">
            <Grid item>
              <div className={classes.wrapper}>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  startIcon={<Icon>money_off</Icon>}
                  onClick={onClick}
                >
                  FECHAR
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                    color="secondary"
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

FormCaixasFechamentoComp.propTypes = {
  loading: PropTypes.bool.isRequired,
  form: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default FormCaixasFechamentoComp
