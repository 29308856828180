import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Breadcrumbs,
  Link,
  Fab,
  Icon,
  TextField,
} from '@material-ui/core'
import {
  DateIsoToBr, parseToNum, MoneyFormatBr,
} from 'utils'

//* Actions
import { Actions as VendasActions } from 'store/ducks/vendas'

//* Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'

import ChipStatusPvComp from 'pages/vendas/components/chip_status_pv'

//* Styles
const useStyles = makeStyles((theme) => ({
  rootCart: {
    paddingTop: 16,
    paddingLeft: 8,
    paddingRight: 8,
  },
  tableRow: {
    cursor: 'pointer',
  },
  ctnBtnShopCart: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  btnFinalizarPedido: {
    color: theme.divnet.text.green,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 9999,
    color: '#fff',
  },
  menuPesquisa: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}))

const PedidosVendasMeusPedidosPage = ({
  history,
  session: { user },
  vendas: { data: { meuspedidos } },
  onVendasPedidoVendaList,
}) => {
  const classes = useStyles()
  const [emissaoDe, setEmissaoDe] = useState('')
  const [search, setSearch] = useState('')

  const handleSearch = () => {
    onVendasPedidoVendaList({ usuariosId: user.id, emissaoDe, search })
  }

  const handleEmissao = (evt) => setEmissaoDe(evt?.target?.value ?? '')

  useEffect(() => {
    document.title = 'Backoffice - Meus Pedidos'
    handleSearch()
  }, [user.filial])

  useEffect(() => {
    handleSearch()
  }, [emissaoDe])

  return (
    <DefaultPage>
      <Grid container spacing={2}>
        {/* Header */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <PageHeaderComp
                title="Meus Pedidos"
              />
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/vendas">
                  Vendas
                </Link>
                <Link color="inherit" href="/vendas/meus-pedidos">
                  Meus Pedidos
                </Link>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={2}
                className={classes.menuPesquisa}
              >
                <Grid item>
                  <TextField
                    id="pv-emissao"
                    label="Emissão"
                    type="date"
                    variant="outlined"
                    defaultValue={emissaoDe}
                    onChange={handleEmissao}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="pv-search"
                    label="Pesquisar"
                    type="text"
                    variant="outlined"
                    onChange={(evt) => setSearch(evt?.target?.value ?? '')}
                    onKeyPress={(evt) => {
                      if (evt.charCode === 13) {
                        handleSearch()
                      }
                    }}
                  />
                </Grid>
                <Grid item>
                  <Fab
                    color="primary"
                    aria-label="Carrinho de compras"
                    className={classes.margins}
                    onClick={handleSearch}
                  >
                    <Icon>search</Icon>
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">STATUS</TableCell>
                      <TableCell align="center">PEDIDO</TableCell>
                      <TableCell align="center">EMISSÃO</TableCell>
                      <TableCell align="center">TOTAL</TableCell>
                      <TableCell align="center">QTD. ITENS</TableCell>
                      <TableCell align="center">UNIDADE</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {meuspedidos?.list?.data?.map((p) => (
                      <TableRow
                        hover
                        key={p.id}
                        className={classes.tableRow}
                        onClick={() => history.push(`/vendas/detalhes-pedidos/${p.id}`)}
                      >
                        <TableCell align="center">
                          <ChipStatusPvComp pedidosVenda={p} />
                        </TableCell>
                        <TableCell align="center">{`${p?.id ?? ''}-${p?.ref ?? ''}`}</TableCell>
                        <TableCell align="center">{DateIsoToBr(p?.emissao ?? '')}</TableCell>
                        <TableCell align="center">{`R$ ${MoneyFormatBr(parseToNum(p?.valorTotal ?? 0))}`}</TableCell>
                        <TableCell align="center">{p?.itens?.length ?? 0}</TableCell>
                        <TableCell align="center">{p?.unidadeDescricao ?? ''}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DefaultPage>
  )
}

PedidosVendasMeusPedidosPage.propTypes = {
  history: PropTypes.shape().isRequired,
  session: PropTypes.shape().isRequired,
  vendas: PropTypes.shape().isRequired,
  onVendasPedidoVendaList: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
  vendas: state.vendas,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...VendasActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PedidosVendasMeusPedidosPage)
