import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  numOS: {
    padding: theme.spacing(0.5),
    backgroundColor: 'gold',
  },
  iptDatas: {
    marginBottom: theme.spacing(1),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  btnHistorico: {
    marginBottom: 5,
    marginRight: 5,
  },
  tableCellHistorico: {
    fontSize: 10,
  },
  listaServicos: {
    paddingInlineStart: 0,
  },
  // padding-inline-start
  btnVermelho: {
    color: theme.divnet.text.white,
    backgroundColor: theme.divnet.danger.main,
    '&:hover': {
      backgroundColor: theme.divnet.danger.dark,
    },
  },
  btnVerde: {
    color: theme.divnet.text.white,
    backgroundColor: theme.divnet.success.main,
    '&:hover': {
      backgroundColor: theme.divnet.success.dark,
    },
  },
  btnAmarelo: {
    color: theme.palette.getContrastText(theme.divnet.warning.main),
    backgroundColor: theme.divnet.warning.main,
    '&:hover': {
      backgroundColor: theme.divnet.warning.dark,
    },
  },
  btnCinza: {
    color: theme.palette.getContrastText(theme.divnet.grey.main),
    backgroundColor: theme.divnet.grey.main,
    '&:hover': {
      backgroundColor: theme.divnet.grey.dark,
    },
  },
}))

export default useStyles
