import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  CircularProgress,
} from '@material-ui/core'

import useStyles from './styles'

const ButtonLoadingComp = (props) => {
  const {
    loading = false,
    disabled = false,
    children,
  } = props
  let otherProps = { ...props }
  delete otherProps.loading
  delete otherProps.disabled
  const classes = useStyles()
  const isDisabled = (disabled || loading) ? true : undefined
  return (
    <Button
      endIcon={loading && <CircularProgress size={25} className={classes.buttonProgress} />}
      {...otherProps}
      disabled={isDisabled}
    >
      {children}
    </Button>
  )
}

ButtonLoadingComp.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
}

ButtonLoadingComp.defaultProps = {
  loading: false,
  disabled: false,
}

export default ButtonLoadingComp
