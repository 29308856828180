const styles = (theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.defaults.margin,
  },
  instructions: {
    marginTop: theme.defaults.margin,
    marginBottom: theme.defaults.margin,
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: theme.palette.secondary.main,
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: theme.divnet.success.main,
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: theme.divnet.grey.light,
    },
  },
  connectorLine: {
    transition: theme.transitions.create('border-color'),
  },
})

export default styles
