import _ from 'lodash'
import { NotificationManager } from 'react-notifications'
import {
  DateIsoNow,
  TimeNow,
  TimeNowAddHrs,
  padLeft,
  DateIsoToBrFormat,
} from 'utils'

// Api's
import {
  listOrdemServicoApi,
  getOrdemServicoApi,
  addOrdemServicoApi,
  deleteOrdemServicoApi,
  listOrdemServicoMonitoramentoApi,
  updateStatusOrdemServicoApi,
  updateObsOrdemServicoApi,
  listOrdemServicoRpsApi,
} from 'api/ordemservico'
import {
  listOrdemServicoItensApi,
  getOrdemServicoItensApi,
  addOrdemServicoItensApi,
  editOrdemServicoItensApi,
  deleteOrdemServicoItensApi,
} from 'api/ordemservicoitens'

// State
const initialState = {
  loading: {
    list: false,
    find: false,
    add: false,
    edit: false,
    del: false,
    monitoramento: false,
    rps: false,
  },
  list: { page: 0, rows: 0, data: [] },
  form: {
    id: 0,
    filiais: {},
    usuarios: {},
    vendedor: {},
    veiculos: {},
    clientes: {},
    prisma: '',
    descontoTipo: '',
    descontoTipoDescricao: '',
    descontoValor: 0.0,
    descontoPorcentagem: 0.0,
    totalBruto: 0.0,
    totalLiquido: 0.0,
    observacao: '',
    motivoCancelamento: '',
    ticket: '',
    ticketDescricao: '',
    vistoria: '',
    vistoriaDescricao: '',
    dataAbertura: '',
    horaAbertura: '',
    dataEntrega: '',
    horaEntrega: '',
    dataFechamento: '',
    horaFechamento: '',
    dataPagamento: '',
    horaPagamento: '',
    status: '',
    statusDescricao: '',
    itens: [],
  },
  item: {
    loading: {
      list: false,
      find: false,
      add: false,
      edit: false,
      del: false,
    },
    list: { page: 0, rows: 0, data: [] },
    form: {
      ordemServico: {},
      tabelasPrecoItens: {},
      funcionarios: {},
      preco: 0.0,
      desconto: 0.0,
      dataFinalizado: '',
      status: '',
      statusDescricao: '',
    },
  },
  monitoramento: { page: 0, rows: 0, data: [] },
  rps: {
    list: { page: 0, rows: 0, data: [] },
  },
}

// Actions Types
export const Types = {
  RESET: 'reset',
  ORDEM_SERVICO_LOADING: 'ordemServico/LOADING',
  ORDEM_SERVICO_LIST_LOAD: 'ordemServico/LIST_LOAD',
  ORDEM_SERVICO_LIST_UPDATE: 'ordemServico/LIST_UPDATE',
  ORDEM_SERVICO_LIST_CLEAN: 'ordemServico/LIST_CLEAN',
  ORDEM_SERVICO_FORM_UPDATE: 'ordemServico/FORM_UPDATE',
  ORDEM_SERVICO_ITEM_LOADING: 'ordemServico/ITEM_LOADING',
  ORDEM_SERVICO_ITEM_FORM_UPDATE: 'ordemServico/ITEM_FORM_UPDATE',
  ORDEM_SERVICO_ITEM_LIST_LOAD: 'ordemServico/ITEM_LIST_LOAD',
  ORDEM_SERVICO_ITEM_LIST_UPDATE: 'ordemServico/ITEM_LIST_UPDATE',
  ORDEM_SERVICO_MONIT_LIST_UPDATE: 'ordemServico/MONIT_LIST_UPDATE',
  ORDEM_SERVICO_RPS_LIST_UPDATE: 'ordemServico/RPS_LIST_UPDATE',
}

// Reducers
export default function ordemServicoReducers(state = initialState, action) {
  switch (action.type) {
    // RESET DEFAULT
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      }
    case Types.ORDEM_SERVICO_LOADING:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    case Types.ORDEM_SERVICO_LIST_LOAD:
      return {
        ...state,
        list: action.payload,
      }
    case Types.ORDEM_SERVICO_LIST_UPDATE:
      return {
        ...state,
        list: state.list.map((i) => (i.id === action.payload.id ? { ...i, ...action.payload } : i)),
      }
    case Types.ORDEM_SERVICO_LIST_CLEAN:
      return {
        ...state,
        list: initialState.list,
      }
    case Types.ORDEM_SERVICO_FORM_UPDATE:
      return {
        ...state,
        form: { ...state.form, ...action.payload },
      }
    case Types.ORDEM_SERVICO_ITEM_LOADING:
      return {
        ...state,
        item: {
          ...state.item,
          loading: { ...state.item.loading, ...action.payload },
        },
      }
    case Types.ORDEM_SERVICO_ITEM_FORM_UPDATE:
      return {
        ...state,
        item: {
          ...state.item,
          form: { ...state.item.form, ...action.payload },
        },
      }
    case Types.ORDEM_SERVICO_ITEM_LIST_LOAD:
      return {
        ...state,
        item: {
          ...state.item,
          list: action.payload,
        },
      }
    case Types.ORDEM_SERVICO_ITEM_LIST_UPDATE:
      return {
        ...state,
        item: {
          ...state.item,
          list: state.item.list.map((i) => (i.id === action.payload.id
            ? { ...i, ...action.payload }
            : i)),
        },
      }
    case Types.ORDEM_SERVICO_MONIT_LIST_UPDATE:
      return {
        ...state,
        monitoramento: {
          ...state.monitoramento,
          ...action.payload,
        },
      }
    case Types.ORDEM_SERVICO_RPS_LIST_UPDATE:
      return {
        ...state,
        rps: {
          ...state.rps,
          list: action.payload,
        },
      }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  // Store Update for OrdemServico
  onOrdemServicoLoading: (payload) => ({ type: Types.ORDEM_SERVICO_LOADING, payload }),
  onOrdemServicoListLoad: (payload) => ({ type: Types.ORDEM_SERVICO_LIST_LOAD, payload }),
  onOrdemServicoListUpdate: (payload) => ({ type: Types.ORDEM_SERVICO_LIST_UPDATE, payload }),
  onOrdemServicoListClean: () => ({ type: Types.ORDEM_SERVICO_LIST_CLEAN }),
  onOrdemServicoFormUpdate: (payload) => ({ type: Types.ORDEM_SERVICO_FORM_UPDATE, payload }),
  onOrdemServicoFormClean: () => ({
    type: Types.ORDEM_SERVICO_FORM_UPDATE,
    payload: initialState.form,
  }),
  onOrdemServicoFormNew: (payload) => ({
    type: Types.ORDEM_SERVICO_FORM_UPDATE, payload: { ...initialState.form, ...payload },
  }),
  onOrdemServicoDataById: (id) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    dispatch(Actions.onOrdemServicoLoading({ find: true }))
    // Chamada API
    getOrdemServicoApi({ token, id }).then((payload) => {
      dispatch(Actions.onOrdemServicoLoading({ find: false }))
      if (payload) {
        // Tratando dados...
        const dto = {
          ...payload,
          dataAbertura: DateIsoToBrFormat(payload.dataAbertura, 'YYYY-MM-DD'),
          horaAbertura: DateIsoToBrFormat(payload.dataAbertura, 'HH:mm'),
          dataEntrega: DateIsoToBrFormat(payload.dataEntrega, 'YYYY-MM-DD'),
          horaEntrega: DateIsoToBrFormat(payload.dataEntrega, 'HH:mm'),
        }
        dispatch(
          Actions.onOrdemServicoFormUpdate(dto),
        )
      } else {
        dispatch(
          Actions.onOrdemServicoFormUpdate(initialState.form),
        )
      }
    }).catch(() => dispatch(Actions.onOrdemServicoLoading({ find: false })))
  },
  onOrdemServicoDataList: (filter) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    dispatch(Actions.onOrdemServicoLoading({ list: true }))
    // Chamada API
    listOrdemServicoApi({ token, filter }).then((payload) => {
      dispatch(Actions.onOrdemServicoLoading({ list: false }))
      dispatch(Actions.onOrdemServicoListLoad(payload || initialState.list))
    }).catch(() => dispatch(Actions.onOrdemServicoLoading({ list: false })))
  },
  onOrdemServicoDataSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()

    // Tratando datas
    if (dto.dataAbertura && dto.horaAbertura) {
      Object.assign(dto, { dataAbertura: `${dto.dataAbertura} ${dto.horaAbertura}:00` })
    }
    if (dto.dataEntrega && dto.horaEntrega) {
      Object.assign(dto, { dataEntrega: `${dto.dataEntrega} ${dto.horaEntrega}:00` })
    }
    /*
    // Chamada API
    if (dto.id) {
      dispatch(Actions.onOrdemServicoLoading({ edit: true }))
      editOrdemServicoApi({ token, dto, id: dto.id }).then((payload) => {
        dispatch(Actions.onOrdemServicoLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success(
            `Ordem de serviço ${dto.descricao} atualizado com sucesso!`,
            'Sucesso!',
            8000,
          )
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    } else {
      dispatch(Actions.onOrdemServicoLoading({ add: true }))
      addOrdemServicoApi({ token, dto }).then((payload) => {
        dispatch(Actions.onOrdemServicoLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success(
            `Ordem de serviço ${dto.descricao} cadastrado com sucesso!`,
            'Sucesso!',
            8000,
          )
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    }
    */
  },
  onOrdemServicoDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    dispatch(Actions.onOrdemServicoLoading({ del: true }))
    // Chamada API
    deleteOrdemServicoApi({ token, id }).then((payload) => {
      dispatch(Actions.onOrdemServicoLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success(
          'Ordem de serviço excluída com sucesso!',
          'Sucesso!',
          8000,
        )
        if (_.isFunction(callback)) {
          callback()
        }
      }
    }).catch(() => dispatch(Actions.onOrdemServicoLoading({ del: false })))
  },
  // Store Update for Itens
  onOrdemServicoItensLoading: (payload) => ({ type: Types.ORDEM_SERVICO_ITEM_LOADING, payload }),
  onOrdemServicoItensFormClean: () => ({
    type: Types.ORDEM_SERVICO_ITEM_FORM_UPDATE,
    payload: initialState.item.form,
  }),
  onOrdemServicoItensFormUpdate: (payload) => ({
    type: Types.ORDEM_SERVICO_ITEM_FORM_UPDATE, payload,
  }),
  onOrdemServicoItensSaveItem: (payload) => (dispatch, getState) => {
    const {
      ordemServico: {
        form: { itens = [] },
      },
    } = getState()
    const {
      funcionarios,
      tabelasPrecoItens,
    } = payload
    const isNew = !itens.find((i) => i.tabelasPrecoItens.id === tabelasPrecoItens.id)
    const item = {
      tabelasPrecoItens,
      funcionarios,
      preco: tabelasPrecoItens.valor,
      desconto: 0,
      dataFinalizado: null,
      status: 'P',
    }
    let itensNew
    if (isNew) {
      itens.push({ ...item, item: (itens.length + 1) })
      itensNew = itens
      dispatch(Actions.onOrdemServicoFormUpdate({ itens }))
    } else {
      itensNew = itens.map((i) => (i.tabelasPrecoItens.id === tabelasPrecoItens.id
        ? { ...i, ...item }
        : i))
      dispatch(Actions.onOrdemServicoFormUpdate({ itens: itensNew }))
    }
    dispatch(Actions.onOrdemServicoItensCalcTotal(itensNew))
  },
  onOrdemServicoItensDelItem: (item) => (dispatch, getState) => {
    const {
      ordemServico: {
        form: { itens = [] },
      },
    } = getState()
    const itensNew = itens.filter((i) => i.item !== item) || []
    dispatch(Actions.onOrdemServicoFormUpdate({ itens: itensNew }))
    dispatch(Actions.onOrdemServicoItensCalcTotal(itensNew))
  },
  onOrdemServicoItensCalcTotal: (pItens = null) => (dispatch, getState) => {
    const {
      ordemServico: {
        form: {
          descontoTipo,
          descontoValor,
          descontoPorcentagem,
          itens,
        },
      },
    } = getState()
    const aItens = pItens || itens
    const totalBruto = aItens.reduce((a, b) => (a + b.preco), 0)
    let descontoVal = 0
    let descontoProc = 0
    let totalLiquido = 0
    // Para desconto em VALOR real
    if (descontoTipo === 'V') {
      descontoVal = ((descontoValor > totalBruto) ? totalBruto : descontoValor)
      descontoProc = ((descontoVal * 100) / totalBruto)
      totalLiquido = totalBruto - descontoVal
    }
    // Para desconto em PORCENTAGEM
    if (descontoTipo === 'P') {
      descontoProc = ((descontoPorcentagem > 100) ? 100 : descontoPorcentagem)
      descontoVal = (totalBruto * (descontoProc / 100))
      totalLiquido = (totalBruto * (1 - (descontoProc / 100)))
    }
    // Atualizando Store!
    dispatch(Actions.onOrdemServicoFormUpdate({
      descontoValor: descontoVal,
      descontoPorcentagem: +descontoProc,
      totalBruto,
      totalLiquido,
    }))
  },
  onOrdemServicoItensListLoad: (payload) => ({
    type: Types.ORDEM_SERVICO_ITEM_LIST_LOAD, payload,
  }),
  onOrdemServicoItensListUpdate: (payload) => ({
    type: Types.ORDEM_SERVICO_ITEM_LIST_UPDATE, payload,
  }),
  onOrdemServicoItensDataById: (id) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    dispatch(Actions.onOrdemServicoItensLoading({ find: true }))
    // Chamada API
    getOrdemServicoItensApi({ token, id }).then((payload) => {
      dispatch(Actions.onOrdemServicoItensLoading({ find: false }))
      dispatch(
        Actions.onOrdemServicoFormUpdate(_.isObject(payload) ? payload : initialState.item.form),
      )
    }).catch(() => dispatch(Actions.onOrdemServicoItensLoading({ find: false })))
  },
  onOrdemServicoItensDataList: (filter) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    dispatch(Actions.onOrdemServicoItensLoading({ list: true }))
    // Chamada API
    listOrdemServicoItensApi({ token, filter }).then((payload) => {
      dispatch(Actions.onOrdemServicoItensLoading({ list: false }))
      dispatch(Actions.onOrdemServicoItensListLoad(payload || initialState.item.list))
    }).catch(() => dispatch(Actions.onOrdemServicoItensLoading({ list: false })))
  },
  onOrdemServicoItensDataSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    // Chamada API
    if (dto.id) {
      dispatch(Actions.onOrdemServicoItensLoading({ edit: true }))
      editOrdemServicoItensApi({ token, dto, id: dto.id }).then((payload) => {
        dispatch(Actions.onOrdemServicoItensLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success(
            `Ordem de serviço ${dto.descricao} atualizado com sucesso!`,
            'Sucesso!',
            8000,
          )
          if (_.isFunction(callback)) {
            callback()
          }
        }
      }).catch(() => dispatch(Actions.onOrdemServicoItensLoading({ edit: false })))
    } else {
      dispatch(Actions.onOrdemServicoItensLoading({ add: true }))
      addOrdemServicoItensApi({ token, dto }).then((payload) => {
        dispatch(Actions.onOrdemServicoItensLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success(
            `Ordem de serviço ${dto.descricao} cadastrado com sucesso!`,
            'Sucesso!',
            8000,
          )
          if (_.isFunction(callback)) {
            callback()
          }
        }
      }).catch(() => dispatch(Actions.onOrdemServicoItensLoading({ add: false })))
    }
  },
  onOrdemServicoItensDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    dispatch(Actions.onOrdemServicoItensLoading({ del: true }))
    // Chamada API
    deleteOrdemServicoItensApi({ token, id }).then((payload) => {
      dispatch(Actions.onOrdemServicoItensLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success(
          'Ordem de serviço excluída com sucesso!',
          'Sucesso!',
          8000,
        )
        if (_.isFunction(callback)) {
          callback()
        }
      }
    }).catch(() => dispatch(Actions.onOrdemServicoItensLoading({ del: false })))
  },
  // Store Update for Monitoramento
  onOrdemServicoMonitoramentoListLoad: (payload) => ({
    type: Types.ORDEM_SERVICO_MONIT_LIST_UPDATE, payload,
  }),
  onOrdemServicoMonitoramentoDataList: (filter) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    dispatch(Actions.onOrdemServicoLoading({ monitoramento: true }))
    // Chamada API
    listOrdemServicoMonitoramentoApi({ token, filter }).then((payload) => {
      dispatch(Actions.onOrdemServicoLoading({ monitoramento: false }))
      dispatch(Actions.onOrdemServicoMonitoramentoListLoad(payload || initialState.monitoramento))
    }).catch(() => dispatch(Actions.onOrdemServicoLoading({ monitoramento: false })))
  },
  // Actions (FORM) OrdemServico
  onOrdemServicoFormActionSalvar: (callback = null) => (dispatch, getState) => {
    const {
      ordemServico: {
        form,
      },
      auth: {
        session: { user, token },
      },
    } = getState()

    // * Validações
    if (_.isEmpty(form?.itens)) {
      NotificationManager.warning(
        'Verifique se informou corretamente os serviços da O.S!',
        'Atenção!',
        8000,
      )
      return
    }
    if (!form.dataEntrega || !form.horaEntrega) {
      NotificationManager.warning(
        'Verifique se informou corretamente a data e hora da entrega da O.S!',
        'Atenção!',
        8000,
      )
      return
    }

    const dtoItens = form?.itens?.map((i) => ({
      ...i,
      tabelasPrecoItensId: i.tabelasPrecoItens.id,
      funcionariosId: i.funcionarios.id,
    })) ?? []
    const dto = {
      ...form,
      filiaisId: user.filial,
      usuariosId: user.id,
      vendedorId: form.vendedor.id,
      veiculosId: form.veiculos.id,
      dataAbertura: `${form.dataAbertura} ${form.horaAbertura}:00`,
      dataEntrega: `${form.dataEntrega} ${form.horaEntrega}:00`,
      itens: dtoItens,
      status: 'A',
      descontoTipo: 'V',
      descontoPorcentagem: 0,
    }
    // Removendo o que não serve do DTO
    delete dto.descontoTipoDescricao
    delete dto.ticket
    delete dto.ticketDescricao
    delete dto.vistoria
    delete dto.vistoriaDescricao
    delete dto.filiais
    delete dto.usuarios
    delete dto.vendedor
    delete dto.veiculos
    delete dto.horaAbertura
    delete dto.horaEntrega
    delete dto.horaPagamento
    delete dto.horaFechamento
    delete dto.statusDescricao


    // * Enviado para a API
    dispatch(Actions.onOrdemServicoLoading({ add: true }))
    addOrdemServicoApi({ token, dto }).then((payload) => {
      dispatch(Actions.onOrdemServicoLoading({ add: false }))
      if (payload && payload.status === 200 && payload.message === 'inserted') {
        NotificationManager.success(
          `Ordem de serviço ${padLeft(payload.entity.id, 6, '0')} cadastrado com sucesso!`,
          'Sucesso!',
          8000,
        )
        if (_.isFunction(callback)) {
          callback(payload.entity)
        }
      }
    }).catch(() => dispatch(Actions.onOrdemServicoLoading({ add: false })))
  },
  onOrdemServicoFormActionCancelar: () => (dispatch) => {
    dispatch(Actions.onOrdemServicoFormClean())
    dispatch(Actions.onOrdemServicoItensFormClean())
    dispatch(Actions.onOrdemServicoFormNew({
      dataAbertura: DateIsoNow(),
      horaAbertura: TimeNow(),
      dataEntrega: DateIsoNow(),
      horaEntrega: TimeNowAddHrs(1),
    }))
  },
  // Actions (GRID) OrdemServico
  // dto: { id: 0, status: '', vistoria: '', funcionarioServico: [] },
  onOrdemServicoFechamentoAction: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    updateStatusOrdemServicoApi({
      token,
      id: dto.id,
      dto,
    }).then((payload) => {
      if (payload && payload.status === 200 && payload.message === 'updated') {
        NotificationManager.success(`O.S nº ${dto.id} foi fechada com sucesso!`, 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback(payload)
        }
      }
    })
  },
  onOrdemServicoCancelamentoAction: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    updateStatusOrdemServicoApi({
      token,
      id: dto.id,
      dto,
    }).then((payload) => {
      if (payload && payload.status === 200 && payload.message === 'updated') {
        NotificationManager.success(`O.S nº ${dto.id} foi cancelada com sucesso!`, 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback(payload)
        }
      }
    })
  },
  // Observação (GRID) OrdemServico
  // dto: { id: 0, observacao: '' },
  onOrdemServicoUpdateObsAction: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    updateObsOrdemServicoApi({
      token,
      id: dto.id,
      dto,
    }).then((payload) => {
      if (payload && payload.status === 200 && payload.message === 'updated') {
        NotificationManager.success(`A observação da O.S nº ${dto.id} foi atualizada com sucesso!`, 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback(payload)
        }
      }
    })
  },
  // Rps (listOrdemServicoRpsApi)
  onOrdemServicoRpsListUpdate: (payload) => ({
    type: Types.ORDEM_SERVICO_RPS_LIST_UPDATE, payload,
  }),
  onOrdemServicoRpsListClean: () => ({
    type: Types.ORDEM_SERVICO_RPS_LIST_UPDATE, payload: initialState.rps.list,
  }),
  onOrdemServicoRpsDataList: (filter) => (dispatch, getState) => {
    const {
      auth: {
        session: { token, user },
      },
    } = getState()
    dispatch(Actions.onOrdemServicoLoading({ rps: true }))
    // Chamada API
    listOrdemServicoRpsApi({
      token,
      filter: {
        ...filter, filiais: user.filial,
      },
    }).then((payload) => {
      dispatch(Actions.onOrdemServicoLoading({ rps: false }))
      dispatch(Actions.onOrdemServicoRpsListUpdate(payload || initialState.rps.list))
    }).catch(() => dispatch(Actions.onOrdemServicoLoading({ rps: false })))
  },
}
