import { makeStyles } from '@material-ui/core/styles'
import { deepOrange, deepPurple } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  nested: {
    paddingLeft: theme.spacing(4),
    display: 'inline-flex !important',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: 'auto', // theme.defaults.drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: '15%', // theme.defaults.drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  // Avatar
  avatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: deepPurple[500],
  },
  logo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 130,
  },
  listItem: {
    display: 'inline-flex !important',
  },
}))

export default useStyles
