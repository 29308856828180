import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const path = '/usuarios'

export const listUsuariosApi = ({ token, filter }) => {
  const {
    status = 'L',
    page = 0,
    rows = 50,
    id,
    filiais,
    matricula,
    nome,
    email,
    search,
  } = filter

  let queryUrl = `?status=${status}&page=${page}&rows=${rows}`
  queryUrl = id ? `${queryUrl}&id=${id}` : queryUrl
  queryUrl = filiais ? `${queryUrl}&filiais=${filiais}` : queryUrl
  queryUrl = matricula ? `${queryUrl}&matricula=${matricula}` : queryUrl
  queryUrl = nome ? `${queryUrl}&nome=${nome}` : queryUrl
  queryUrl = email ? `${queryUrl}&email=${email}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl

  return api
    .get(path + queryUrl, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getUsuariosApi = ({ token, id }) => api
  .get(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const addUsuariosApi = ({ token, dto }) => api
  .post(path, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const editUsuariosApi = ({ token, id, dto }) => api
  .put(`${path}/${id}`, JSON.stringify(dto), {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then(httpValidState)
  .catch(httpValidErros)

export const deleteUsuariosApi = ({ token, id }) => api
  .delete(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const passwordUsuariosApi = ({ token, id, dto }) => api
  .put(`${path}/${id}/password`, JSON.stringify(dto), {
    headers: { Authorization: `Bearer ${token}` },
    timeout: 0,
  })
  .then(httpValidState)
  .catch(httpValidErros)

// Custom
export const getUsuariosPerfilApi = ({ token }) => api
  .get(`${path}/perfil`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const addUsuariosGruposApi = ({ token, dto: { pk, fk } }) => api
  .post(`${path}/${pk}/grupos`, JSON.stringify({ pk, fk }), {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then(httpValidState)
  .catch(httpValidErros)

export const deleteUsuariosGruposApi = ({ token, dto: { pk, fk } }) => api
  .delete(`${path}/${pk}/grupos/${fk}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const addUsuariosFiliaisApi = ({ token, dto: { pk, fk } }) => api
  .post(`${path}/${pk}/filiais`, JSON.stringify({ pk, fk }), {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then(httpValidState)
  .catch(httpValidErros)

export const deleteUsuariosFiliaisApi = ({ token, dto: { pk, fk } }) => api
  .delete(`${path}/${pk}/filiais/${fk}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const updateUsuariosFilialApi = ({ token, id, dto }) => api
  .put(`${path}/${id}/filiais/change`, JSON.stringify(dto), {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then(httpValidState)
  .catch(httpValidErros)

export const getForgotApi = ({ username }) => api
  .post(`${path}/forgot`, JSON.stringify({ username }))
  .then(httpValidState)
  .catch(httpValidErros)

export const getRenewPasswordApi = ({
  id, token, password, repassword,
}) => api
  .post(`${path}/renew-password`, JSON.stringify({
    id, token, password, repassword,
  }))
  .then(httpValidState)
  .catch(httpValidErros)
