import { push } from 'connected-react-router'
import { NotificationManager } from 'react-notifications'
// import _ from 'lodash'

// import { history } from '../../index'

// Api's
import { authApi } from 'api/auth'
import { getUsuariosPerfilApi, getForgotApi, getRenewPasswordApi } from 'api/usuarios'

// Actions
import { Actions as ParametrosActions } from 'store/ducks/parametros'

// State Auth
const initialState = {
  loading: {
    signIn: false,
    signUp: false,
    forgot: false,
  },
  error: {
    signin: '',
    signup: '',
    forgot: '',
  },
  signIn: {
    username: '',
    password: '',
    reCaptcha: '',
    redirectTo: '',
  },
  forgot: {
    username: '',
    reCaptcha: '',
    password: '',
    repassword: '',
  },
  session: {
    token: '',
    user: {},
  },
  menu: {
    open: false,
  },
}

// Actions Types
export const Types = {
  RESET: 'reset',
  AUTH_LOADING: 'auth/AUTH_LOADING',
  AUTH_ERROR: 'auth/AUTH_ERROR',
  AUTH_SIGNIN: 'auth/AUTH_SIGNIN',
  AUTH_FORGOT: 'auth/AUTH_FORGOT',
  AUTH_SESSION: 'auth/AUTH_SESSION',
  AUTH_USER_UPDATE: 'auth/AUTH_USER_UPDATE',
  AUTH_MENU: 'auth/AUTH_MENU',
}

// Reducers
export default function authReducers(state = initialState, action) {
  switch (action.type) {
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      }
    case Types.AUTH_LOADING:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    case Types.AUTH_ERROR:
      return {
        ...state,
        error: { ...state.error, ...action.payload },
      }
    case Types.AUTH_SIGNIN:
      return {
        ...state,
        signIn: { ...state.signIn, ...action.payload },
      }
    case Types.AUTH_FORGOT:
      return {
        ...state,
        forgot: { ...state.forgot, ...action.payload },
      }
    case Types.AUTH_SESSION:
      return {
        ...state,
        session: { ...state.session, ...action.payload },
      }
    case Types.AUTH_USER_UPDATE:
      return {
        ...state,
        session: {
          ...state.session,
          user: {
            ...state.session.user,
            ...action.payload,
          },
        },
      }
    case Types.AUTH_MENU:
      return {
        ...state,
        menu: {
          ...state.menu,
          ...action.payload,
        },
      }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  // Store Update
  onReset: () => ({ type: Types.RESET }),
  onAuthLoadingUpdate: (payload) => ({ type: Types.AUTH_LOADING, payload }),
  onAuthErrorUpdate: (payload) => ({ type: Types.AUTH_ERROR, payload }),
  onAuthSignInUpdate: (payload) => ({ type: Types.AUTH_SIGNIN, payload }),
  onAuthSignInClean: () => ({ type: Types.AUTH_SIGNIN, payload: initialState.signIn }),
  onAuthSessionUpdate: (payload) => ({ type: Types.AUTH_SESSION, payload }),
  // Any Actions
  onAuthSignInAction: (username, password, reCaptcha) => (dispatch) => {
    if (!reCaptcha) {
      NotificationManager.warning(
        'Você deve marcar a opção "NÃO SOU UM ROBÔ".',
        'Atenção!',
        8000,
      )
    } else {
      dispatch(Actions.onAuthLoadingUpdate({ signIn: true }))
      authApi({ username, password }).then((resp) => {
        if (resp) {
          const { token } = resp
          getUsuariosPerfilApi({ token }).then((user) => {
            if (user) {
              const { grupos = [] } = user
              dispatch(Actions.onAuthLoadingUpdate({ signIn: false }))
              //* Atualizando a Store
              dispatch(Actions.onAuthSignInClean())
              dispatch(Actions.onAuthSessionUpdate({ token, user }))
              dispatch(ParametrosActions.onParametrosDataList({}))
              //* Redirecionando para o dashboard
              if (grupos.find((g) => g.codigo === 'ROLE_VENDAS')) {
                dispatch(push('/vendas'))
              } else {
                dispatch(push('/dashboard'))
              }
            } else {
              dispatch(Actions.onAuthLoadingUpdate({ signIn: false }))
            }
          }).catch(() => {
            dispatch(Actions.onAuthLoadingUpdate({ signIn: false }))
          })
        } else {
          dispatch(Actions.onAuthLoadingUpdate({ signIn: false }))
        }
      }).catch(() => {
        dispatch(Actions.onAuthLoadingUpdate({ signIn: false }))
      })
    }
  },
  onAuthSignOutAction: () => (dispatch) => {
    dispatch(Actions.onReset())
    dispatch(push('/login'))
  },
  onAuthSessionUserUpdate: (payload) => ({ type: Types.AUTH_USER_UPDATE, payload }),
  onForgotFormUpdate: (payload) => ({ type: Types.AUTH_FORGOT, payload }),
  onForgotAction: (username, reCaptcha) => (dispatch) => {
    if (!reCaptcha) {
      NotificationManager.warning(
        'Você deve marcar a opção "NÃO SOU UM ROBÔ"',
        'Atenção!',
        8000,
      )
    } else {
      dispatch(Actions.onAuthLoadingUpdate({ forgot: true }))
      getForgotApi({ username })
        .then((resp) => {
          dispatch(Actions.onAuthLoadingUpdate({ forgot: false }))
          if (resp && resp.status === 200 && resp.message === 'forgot') {
            NotificationManager.success(
              'Você deve acessar sua caixa de entrada, continuar o processo de recuperação de senha.',
              'Sucesso!',
              60000,
            )
          }
        })
        .catch(() => {
          dispatch(Actions.onAuthLoadingUpdate({ forgot: false }))
        })
    }
  },
  onRenewPasswordAction: (dto) => (dispatch) => {
    const {
      reCaptcha, id, token, password, repassword,
    } = dto
    if (!reCaptcha) {
      NotificationManager.warning(
        'Você deve marcar a opção "NÃO SOU UM ROBÔ"',
        'Atenção!',
        8000,
      )
    } else {
      dispatch(Actions.onAuthLoadingUpdate({ forgot: true }))
      getRenewPasswordApi({
        id, token, password, repassword,
      })
        .then((resp) => {
          dispatch(Actions.onAuthLoadingUpdate({ forgot: false }))
          if (resp && resp.status === 200 && resp.message === 'updated') {
            NotificationManager.success(
              'Sua senha foi atualizada com sucesso!',
              'Parabéns!',
              60000,
            )
          }
        })
        .catch(() => {
          dispatch(Actions.onAuthLoadingUpdate({ forgot: false }))
        })
    }
  },
  //* Menu
  onAuthMenuUpdate: (payload) => ({ type: Types.AUTH_MENU, payload }),
  onAuthMenuToggle: () => (dispatch, getState) => {
    const { auth } = getState()
    dispatch(Actions.onAuthMenuUpdate({ open: !(auth?.menu?.open ?? false) }))
  },
}
