import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid, TextField, FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core'
// import _ from 'lodash'

// Styles
import useStyles from './styles'

// Stateless
const ServicosFormComp = ({ data, onChange }) => {
  const {
    // id,
    descricao,
    descricaoNf,
    observacao,
    sequencia,
    status,
  } = data
  const classes = useStyles()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="servicos-descricao"
          label="Descrição:"
          margin="normal"
          variant="outlined"
          value={descricao}
          onChange={(evt) => onChange({ descricao: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <TextField
          required
          fullWidth
          id="servicos-descricaoNf"
          label="Descrição NF-e:"
          margin="normal"
          variant="outlined"
          value={descricaoNf}
          onChange={(evt) => onChange({ descricaoNf: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          required
          fullWidth
          id="servicos-observacao"
          label="Observação"
          margin="normal"
          variant="outlined"
          value={observacao}
          onChange={(evt) => onChange({ observacao: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          id="servicos-sequencia"
          label="Sequência"
          margin="normal"
          variant="outlined"
          value={sequencia}
          onChange={(evt) => onChange({ sequencia: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl required variant="outlined" fullWidth margin="normal" className={classes.dataControl}>
          <InputLabel ref={inputLabel}>
            Status
          </InputLabel>
          <Select
            id="servicos-status"
            value={status}
            onChange={(evt) => onChange({ status: evt.target.value.toUpperCase() })}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="P">PENDENTE</MenuItem>
            <MenuItem value="L">LIBERADO</MenuItem>
            <MenuItem value="B">BLOQUEADO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

ServicosFormComp.propTypes = {
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ServicosFormComp
