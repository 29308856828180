import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link /* useParams */ } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  TextField,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import Person from '@material-ui/icons/Person'
import VpnKey from '@material-ui/icons/VpnKey'
import ReCAPTCHA from 'react-google-recaptcha'

import { Actions as AuthActions } from 'store/ducks/auth'
import { getPublicImage } from 'utils'
import useStyles from './styles'

// Stateless
const ForgotPage = (props) => {
  const {
    auth, onForgotFormUpdate, onReset, onForgotAction, onRenewPasswordAction,
  } = props
  const classes = useStyles()
  const btnEnviar = !auth.forgot.password || !auth.forgot.repassword || !auth.forgot.reCaptcha || auth.loading.forgot
  const [imageHeaderLogin, setImageHeaderLogin] = useState('')
  const [formForgot, setFormForgot] = useState({
    tokenForgot: '',
    idForgot: '',
  })

  const handleEnviarAction = () => {
    const {
      username, reCaptcha, password, repassword,
    } = auth.forgot
    if (!formForgot.tokenForgot) {
      onForgotAction(username, reCaptcha)
    } else {
      onRenewPasswordAction({
        reCaptcha,
        id: formForgot.idForgot,
        token: formForgot.tokenForgot,
        password,
        repassword,
      })
    }
  }

  useEffect(() => {
    document.title = 'Portal - Esqueci minha senha'
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const tokenForgot = urlParams.get('token')
    const idForgot = urlParams.get('id')
    if (tokenForgot && idForgot) {
      setFormForgot({ tokenForgot, idForgot })
    }
    getPublicImage(
      'login-card-header.png',
      require('assets/login-card-header.png'),
    ).then((image) => setImageHeaderLogin(image))
    onReset()
  }, [])

  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <Grid container direction="row" justify="center" alignItems="center" />
      </Grid>
      <Grid item xs={12} md={4}>
        <div className={[classes.content, classes.center].join(' ')}>
          <Card className={classes.card}>
            <CardMedia
              component="img"
              alt="Logo"
              className={classes.media}
              height="140"
              image={imageHeaderLogin}
              title="Logo"
            />
            <CardContent>
              <form autoComplete="off">
                <Grid item xs={12} md={12} className={classes.margin}>
                  <Typography gutterBottom variant="h5" component="h2">
                    Recuperação de senha
                  </Typography>
                </Grid>
                {!formForgot.tokenForgot && (
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-end"
                    className={classes.iptLogin}
                  >
                    <Grid item xs={2} md={2}>
                      <Person />
                    </Grid>
                    <Grid item xs={10} md={10}>
                      <TextField
                        label="Usuário"
                        fullWidth
                        value={auth.forgot.username}
                        onChange={(evt) => onForgotFormUpdate({
                          username: evt.target.value,
                        })}
                      />
                    </Grid>
                  </Grid>
                )}
                {formForgot.tokenForgot && (
                  <>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-end"
                      className={classes.iptLogin}
                    >
                      <Grid item xs={2} md={2}>
                        <VpnKey />
                      </Grid>
                      <Grid item xs={10} md={10}>
                        <TextField
                          label="Senha"
                          fullWidth
                          type="password"
                          value={auth.forgot.password}
                          onChange={(evt) => onForgotFormUpdate({
                            password: evt.target.value,
                          })}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-end"
                      className={classes.iptLogin}
                    >
                      <Grid item xs={2} md={2}>
                        <VpnKey />
                      </Grid>
                      <Grid item xs={10} md={10}>
                        <TextField
                          label="Re-Senha"
                          fullWidth
                          type="password"
                          value={auth.forgot.repassword}
                          onChange={(evt) => onForgotFormUpdate({
                            repassword: evt.target.value,
                          })}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ReCAPTCHA
                      className={classes.reCaptcha}
                      sitekey="6Lcrq_gUAAAAABTpFtH3MTeOMSxSr5cImyUTftte"
                      onChange={(reCaptcha) => onForgotFormUpdate({ reCaptcha })}
                    />
                  </Grid>
                </Grid>
              </form>
            </CardContent>
            <CardActions className={classes.containerActions}>
              <Grid
                container
                className={classes.btnContainerEntrar}
                justify="center"
                alignItems="center"
              >
                <div className={classes.wrapper}>
                  <Button
                    disabled={btnEnviar}
                    variant="contained"
                    color="primary"
                    onClick={() => handleEnviarAction()}
                  >
                    ENVIAR
                  </Button>
                  {auth.loading.forgot && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                      color="secondary"
                    />
                  )}
                </div>
              </Grid>
              <Grid container justify="flex-end" alignItems="center">
                <Link to="/login">Login</Link>
              </Grid>
            </CardActions>
          </Card>
        </div>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container direction="row" justify="center" alignItems="center" />
      </Grid>
    </Grid>
  )
}

ForgotPage.propTypes = {
  auth: PropTypes.shape().isRequired,
  onForgotFormUpdate: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onForgotAction: PropTypes.func.isRequired,
  onRenewPasswordAction: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...AuthActions,
  },
  dispatch,
)

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPage)
