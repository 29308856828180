import { httpValidState, httpValidErros } from 'utils/http'
import api from '../..'

const path = '/pagamentos/condicao'

export const listPagamentosCondicaoApi = ({ token, filter }) => {
  const {
    status = '', page = 0, rows = 50, id, pagamentosForma, taxa, search,
  } = filter

  let queryUrl = `?status=${status}&page=${page}&rows=${rows}`
  queryUrl = id ? `${queryUrl}&id=${id}` : queryUrl
  queryUrl = pagamentosForma ? `${queryUrl}&pagamentosForma=${pagamentosForma}` : queryUrl
  queryUrl = taxa ? `${queryUrl}&taxa=${taxa}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl

  return api
    .get(path + queryUrl, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getPagamentosCondicaoApi = ({ token, id }) => api
  .get(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const addPagamentosCondicaoApi = ({ token, dto }) => api
  .post(path, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const editPagamentosCondicaoApi = ({ token, id, dto }) => api
  .put(`${path}/${id}`, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const deletePagamentosCondicaoApi = ({ token, id }) => api
  .delete(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)
