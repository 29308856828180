import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const path = '/ordemservico/itens'

export const listOrdemServicoItensApi = ({ token, filter }) => {
  const {
    status = '', page = 0, rows = 50, id, ordemservico, tabelaprecoitens, funcionarios, dataFinalizado, search,
  } = filter

  let queryUrl = `?status=${status}&page=${page}&rows=${rows}`
  queryUrl = id ? `${queryUrl}&id=${id}` : queryUrl
  queryUrl = ordemservico ? `${queryUrl}&ordemservico=${ordemservico}` : queryUrl
  queryUrl = tabelaprecoitens ? `${queryUrl}&tabelaprecoitens=${tabelaprecoitens}` : queryUrl
  queryUrl = funcionarios ? `${queryUrl}&funcionarios=${funcionarios}` : queryUrl
  queryUrl = dataFinalizado ? `${queryUrl}&dataFinalizado=${dataFinalizado}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl

  return api
    .get(path + queryUrl, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getOrdemServicoItensApi = ({ token, id }) => api
  .get(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const addOrdemServicoItensApi = ({ token, dto }) => api
  .post(path, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const editOrdemServicoItensApi = ({ token, id, dto }) => api
  .put(`${path}/${id}`, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const deleteOrdemServicoItensApi = ({ token, id }) => api
  .delete(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)
