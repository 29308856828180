import _ from 'lodash'
import { NotificationManager } from 'react-notifications'
import {
  listProdutosApi,
  getProdutosByIdApi,
  addProdutosApi,
  editProdutosApi,
  deleteProdutosApi,
} from 'api/produtos'

// State
const initialState = {
  loading: {
    list: false,
    find: false,
    add: false,
    edit: false,
    del: false,
  },
  list: { page: 0, rows: 0, data: [] },
  form: {
    id: 0,
    ref: '',
    sku: '',
    ncm: '',
    um: '',
    preco: '',
    precoPromo: '',
    peso: '',
    qtdMin: '',
    descricao: '',
    status: '',
  },
}

// Actions Types
export const Types = {
  RESET: 'reset',
  PRODUTOS_LOADING: 'produtos/LOADING',
  PRODUTOS_LIST_LOAD: 'produtos/LIST_LOAD',
  PRODUTOS_LIST_UPDATE: 'produtos/LIST_UPDATE',
  PRODUTOS_LIST_CLEAN: 'produtos/LIST_CLEAN',
  PRODUTOS_FORM_UPDATE: 'produtos/FORM_UPDATE',
}

// Reducers
export default function produtosReducers(state = initialState, action) {
  switch (action.type) {
    // RESET DEFAULT
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      }
    case Types.PRODUTOS_LOADING:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    case Types.PRODUTOS_LIST_LOAD:
      return {
        ...state,
        list: action.payload,
      }
    case Types.PRODUTOS_LIST_UPDATE:
      return {
        ...state,
        list: state.list.map((i) => (i.id === action.payload.id ? { ...i, ...action.payload } : i)),
      }
    case Types.PRODUTOS_LIST_CLEAN:
      return {
        ...state,
        list: initialState.list,
      }
    case Types.PRODUTOS_FORM_UPDATE:
      return {
        ...state,
        form: { ...state.form, ...action.payload },
      }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  // Store Update
  onProdutosLoading: (payload) => ({ type: Types.PRODUTOS_LOADING, payload }),
  onProdutosListLoad: (payload) => ({ type: Types.PRODUTOS_LIST_LOAD, payload }),
  onProdutosListUpdate: (payload) => ({ type: Types.PRODUTOS_LIST_UPDATE, payload }),
  onProdutosListClean: () => ({ type: Types.PRODUTOS_LIST_CLEAN }),
  onProdutosFormUpdate: (payload) => ({ type: Types.PRODUTOS_FORM_UPDATE, payload }),
  onProdutosFormNew: (payload) => ({
    type: Types.PRODUTOS_FORM_UPDATE, payload: { ...initialState.form, ...payload },
  }),
  onProdutosFormClean: () => ({
    type: Types.PRODUTOS_FORM_UPDATE,
    payload: initialState.form,
  }),
  onProdutosDataById: (id) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onProdutosLoading({ find: true }))
    // Chamada API
    getProdutosByIdApi({ token, id }).then((payload) => {
      dispatch(Actions.onProdutosLoading({ find: false }))
      dispatch(Actions.onProdutosFormUpdate(_.isObject(payload) ? payload : initialState.form))
    })
  },
  onProdutosDataList: (filter) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onProdutosLoading({ list: true }))
    // Chamada API
    listProdutosApi({ token, filter }).then((payload) => {
      dispatch(Actions.onProdutosLoading({ list: false }))
      dispatch(Actions.onProdutosListLoad(payload || initialState.list))
    })
  },
  onProdutosDataSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    // Chamada API
    if (dto.id) {
      dispatch(Actions.onProdutosLoading({ edit: true }))
      editProdutosApi({ token, dto, id: dto.id }).then((payload) => {
        dispatch(Actions.onProdutosLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success(`Produto ${dto.descricao} atualizado com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    } else {
      dispatch(Actions.onProdutosLoading({ add: true }))
      addProdutosApi({ token, dto }).then((payload) => {
        dispatch(Actions.onProdutosLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success(`Produto ${dto.descricao} cadastrado com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    }
  },
  onProdutosDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onProdutosLoading({ del: true }))
    // Chamada API
    deleteProdutosApi({ token, id }).then((payload) => {
      dispatch(Actions.onProdutosLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Produto excluído com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    })
  },
}
