import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  btnVermelho: {
    color: theme.divnet.text.white,
    backgroundColor: theme.divnet.danger.main,
    '&:hover': {
      backgroundColor: theme.divnet.danger.dark,
    },
  },
  btnVerde: {
    color: theme.divnet.text.white,
    backgroundColor: theme.divnet.success.main,
    '&:hover': {
      backgroundColor: theme.divnet.success.dark,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

export default useStyles
