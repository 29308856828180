import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Grid, TextField, IconButton, InputAdornment,
} from '@material-ui/core'
import { YoutubeSearchedFor, Clear } from '@material-ui/icons'
import _ from 'lodash'

import TableDataComp from 'components/Table'

const FormSearchComp = ({
  onSelectItem, onSearch, searching = false, data = [],
}) => {
  const [iptSearch, setIptSearch] = useState('')

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <TextField
          id="generic-search"
          fullWidth
          margin="normal"
          variant="outlined"
          type="text"
          label="Buscar: "
          value={iptSearch}
          onChange={(evt) => setIptSearch(evt.target.value.toUpperCase())}
          onKeyPress={(evt) => {
            if (evt.charCode === 13) {
              onSearch(iptSearch)
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!_.isEmpty(iptSearch) && (
                  <IconButton aria-label="Limpar" onClick={() => setIptSearch('')}>
                    <Clear />
                  </IconButton>
                )}
                <IconButton aria-label="Buscar" onClick={() => onSearch(iptSearch)}>
                  <YoutubeSearchedFor />
                </IconButton>
              </InputAdornment>),
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TableDataComp
          loading={searching}
          size="small"
          rows={data}
          cols={[
            { key: 'id', title: 'ID' },
            { key: 'descricao', title: 'DESCRIÇÃO', onClick: onSelectItem },
          ]}
        />
      </Grid>
    </Grid>
  )
}

FormSearchComp.propTypes = {
  searching: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSelectItem: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}

export default FormSearchComp
