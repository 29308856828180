import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
// import { grayColor } from 'components/Vendor/CreativeTim/assets/material-dashboard-react'
import PropTypes from 'prop-types'
import {
  Button,
  Grid,
  Breadcrumbs,
  Typography,
  Link,
  Divider,
  Icon,
  Fab,
  Tooltip,
  Badge,
  CircularProgress,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TextField,
  IconButton,
  Backdrop,
} from '@material-ui/core'
import {
  MoneyFormatBr, parseToNum, parseToDec, getThumbnailProdutos,
} from 'utils'

// Actions
import { Actions as VendasActions } from 'store/ducks/vendas'

// Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import DialogComp from 'components/Dialogs'
// Styles
const useStyles = makeStyles((theme) => ({
  rootCart: {
    paddingTop: 16,
    paddingLeft: 8,
    paddingRight: 8,
  },
  ctnBtnShopCart: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  btnFinalizarPedido: {
    color: theme.divnet.text.green,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 9999,
    color: '#fff',
  },
}))

const PedidosVendasCarrinhoPage = ({
  vendas: { loading, data: { cart } },
  session: { user },
  onVendasFinalizarPedidoVenda,
  onVendasUpdateCart,
  onVendasCartItensUpdate,
  onVendasCartItensDelete,
  onVendasCartClean,
}) => {
  const classes = useStyles()
  const [dialogAskCancelPedido, setDialogAskCancelPedido] = useState({ open: false, title: '', data: null })
  const [dialogAskDeleteItem, setDialogAskDeleteItem] = useState({ open: false, title: '', data: null })
  const [dialogAskFinalizarPedido, setDialogAskFinalizarPedido] = useState({ open: false, title: '', data: null })

  const handleOpenCloseDialogAskCancelPedido = (open, title = null, data = null) => {
    setDialogAskCancelPedido((s) => ({
      ...s, open, title, data,
    }))
  }

  const handleOpenCloseDialogAskDeleteItem = (open, title = null, data = null) => {
    setDialogAskDeleteItem((s) => ({
      ...s, open, title, data,
    }))
  }

  const handleOpenCloseDialogAskFinalizarPedido = (open, title = null, data = null) => {
    setDialogAskFinalizarPedido((s) => ({
      ...s, open, title, data,
    }))
  }

  const handleUpdateCartQtd = (e, item) => {
    let qtd = parseToDec(+(e?.target?.value ?? 1))
    if (qtd <= 0) qtd = parseToDec(1)
    onVendasCartItensUpdate({
      ...item, qtd,
    })
  }

  useEffect(() => {
    document.title = 'Backoffice - Carrinho'
  }, [user.filial])

  return (
    <DefaultPage>
      <Grid container spacing={2}>
        {/* Header */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <PageHeaderComp
                title="Carrinho"
              />
              <Breadcrumbs aria-label="breadcrumb">
                <Typography color="textPrimary">Vendas</Typography>
                <Link color="inherit" href="/vendas">
                  Dashboard
                </Link>
                <Link color="inherit" href="/vendas/carrinho">
                  Carrinho
                </Link>
              </Breadcrumbs>
            </Grid>
            <Grid
              item
              className={classes.ctnBtnShopCart}
            >
              <Tooltip title="Carrinho de compras">
                <Badge badgeContent={cart.itens.length} color="secondary">
                  <Fab
                    color="primary"
                    aria-label="Carrinho de compras"
                    className={classes.margins}
                    onClick={() => null}
                  >
                    <Icon>shopping_cart</Icon>
                  </Fab>
                </Badge>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {/* Itens Carrinho */}
        <Grid item xs={12}>
          {_.isEmpty(cart?.itens) && (
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Typography align="center" variant="h4" gutterBottom>
                  Seu carrinho está vazio
                </Typography>
              </Grid>
            </Grid>
          )}
          {!_.isEmpty(cart?.itens) && (
            <Grid container spacing={2} className={classes.rootCart}>
              {/* COL.: ITENS DO CARRINHO */}
              <Grid item xs={12} md={9}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ textAlign: 'center' }}> </TableCell>
                        <TableCell>Produto</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Peso</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Quantidade</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Preço</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cart?.itens?.map((i) => {
                        const {
                          sku, descricao, um, preco, precoPromo, peso = 1, qtd = 1,
                        } = i
                        return (
                          <TableRow key={i.id}>
                            {/* IMAGEM */}
                            <TableCell align="center">
                              <img
                                src={getThumbnailProdutos(i)}
                                style={{ maxWidth: 60, maxHeight: 80, overflow: 'hidden' }}
                                alt={descricao}
                              />
                            </TableCell>
                            {/* DESCRICAO */}
                            <TableCell align="left">
                              <Typography
                                variant="body2"
                                component="strong"
                                color="secondary"
                                gutterBottom
                              >
                                {descricao}
                              </Typography>
                              <Typography variant="caption" display="block">
                                {'SKU: '}
                                <strong>{sku}</strong>
                              </Typography>
                              <Typography variant="caption" display="block" gutterBottom>
                                {'U.M.: '}
                                <strong>{um}</strong>
                              </Typography>
                            </TableCell>
                            {/* PESO */}
                            <TableCell align="center">
                              <Typography
                                variant="body2"
                                gutterBottom
                                style={{ fontSize: 17, fontWeight: '900', color: '#888' }}
                              >
                                {`${MoneyFormatBr(parseToNum(qtd) * parseToNum(peso))}kg`}
                              </Typography>
                              {`${MoneyFormatBr(parseToNum(peso))}kg`}
                            </TableCell>
                            {/* QTD */}
                            <TableCell align="center">
                              <TextField
                                type="number"
                                style={{ width: 80 }}
                                className={classes.qtdCarrinho}
                                value={parseToNum(i.qtd)}
                                pattern="[0-9]*"
                                onChange={(e) => handleUpdateCartQtd(e, i)}
                                margin="normal"
                              />
                            </TableCell>
                            {/* PRECO */}
                            <TableCell align="center">
                              <Typography
                                variant="body2"
                                gutterBottom
                                style={{ fontSize: 17, fontWeight: '900', color: '#888' }}
                              >
                                {`R$ ${MoneyFormatBr((parseToNum(qtd) * parseToNum(precoPromo || preco)))}`}
                              </Typography>
                              {`R$ ${MoneyFormatBr(parseToNum(precoPromo || preco))}`}
                            </TableCell>
                            {/* BTN DELETE */}
                            <TableCell>
                              <IconButton
                                aria-label="delete"
                                color="secondary"
                                onClick={() => handleOpenCloseDialogAskDeleteItem(true, 'Remover?', i)}
                              >
                                <Icon>delete</Icon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {/* COL.: AÇÕES DO PEDIDO */}
              <Grid item xs={12} md={3}>
                <Grid container spacing={1}>
                  {/* TOTAL PEDIDO */}
                  <Grid item xs={12}>
                    <Typography align="right" variant="h4" gutterBottom>
                      Total
                    </Typography>
                    <Typography align="right" variant="h5" gutterBottom>
                      {`R$ ${MoneyFormatBr(parseToNum(cart?.itens?.reduce((i, o) => i + parseToNum((o.qtd * (o.precoPromo || o.preco))), 0)))}`}
                    </Typography>
                  </Grid>
                  {/* BTN FINALIZAR PEDIDO */}
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="outlined"
                      endIcon={<Icon>send</Icon>}
                      className={classes.btnFinalizarPedido}
                      onClick={() => handleOpenCloseDialogAskFinalizarPedido(true, 'Finalizar o pedido?')}
                    >
                      Finalizar Pedido
                    </Button>
                  </Grid>
                  {/* BTN CANCELAR PEDIDO */}
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="outlined"
                      endIcon={<Icon>delete</Icon>}
                      onClick={() => handleOpenCloseDialogAskCancelPedido(true, 'Cancelar?')}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  {/* OBSERVACAO DO PEDIDO */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="outlined-multiline-static"
                      label="Observações"
                      multiline
                      rowsMax={6}
                      variant="outlined"
                      value={cart?.observacoes}
                      onChange={(e) => onVendasUpdateCart({ observacoes: e?.target?.value ?? '' })}
                      inputProps={{
                        maxlength: 250,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* Dialog CANCEL Pedido */}
      <DialogComp
        open={dialogAskCancelPedido?.open ?? false}
        title={dialogAskCancelPedido?.title ?? ''}
        onClose={() => handleOpenCloseDialogAskCancelPedido(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskCancelPedido(false),
          },
          {
            label: 'SIM',
            onClick: () => {
              onVendasCartClean()
              handleOpenCloseDialogAskCancelPedido(false)
            },
          },
        ]}
      >
        Você deseja realmente deseja cancelar o pedido?
      </DialogComp>
      {/* Dialog ITEM Pedido */}
      <DialogComp
        open={dialogAskDeleteItem?.open ?? false}
        title={dialogAskDeleteItem?.title ?? ''}
        onClose={() => handleOpenCloseDialogAskDeleteItem(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDeleteItem(false),
          },
          {
            label: 'SIM',
            onClick: () => {
              onVendasCartItensDelete(dialogAskDeleteItem?.data)
              handleOpenCloseDialogAskDeleteItem(false)
            },
          },
        ]}
      >
        {`Você deseja realmente deseja remover o item "${dialogAskDeleteItem?.data?.descricao ?? ''}" ?`}
      </DialogComp>
      {/* Dialog Confirma Pedido */}
      <DialogComp
        open={dialogAskFinalizarPedido?.open ?? false}
        title={dialogAskFinalizarPedido?.title ?? ''}
        onClose={() => handleOpenCloseDialogAskFinalizarPedido(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskFinalizarPedido(false),
          },
          {
            label: 'SIM',
            onClick: () => {
              onVendasFinalizarPedidoVenda(() => handleOpenCloseDialogAskFinalizarPedido(false))
            },
          },
        ]}
      >
        Você realmete que finalizar o pedido ?
      </DialogComp>
      <Backdrop className={classes.backdrop} open={loading.insert}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DefaultPage>
  )
}

PedidosVendasCarrinhoPage.propTypes = {
  session: PropTypes.shape().isRequired,
  vendas: PropTypes.shape().isRequired,
  onVendasFinalizarPedidoVenda: PropTypes.func.isRequired,
  onVendasUpdateCart: PropTypes.func.isRequired,
  onVendasCartItensUpdate: PropTypes.func.isRequired,
  onVendasCartItensDelete: PropTypes.func.isRequired,
  onVendasCartClean: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
  vendas: state.vendas,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...VendasActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PedidosVendasCarrinhoPage)
