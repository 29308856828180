import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const path = '/funcionarios'

export const listFuncionariosApi = ({ token, filter }) => {
  const {
    status = '',
    page = 0,
    rows = 50,
    id,
    filiais,
    matricula,
    nome,
    email,
    search,
    lavador,
    atendente,
  } = filter
  let queryUrl = `?status=${status}&page=${page}&rows=${rows}`
  queryUrl = id ? `${queryUrl}&id=${id}` : queryUrl
  queryUrl = filiais ? `${queryUrl}&filiais=${filiais}` : queryUrl
  queryUrl = matricula ? `${queryUrl}&matricula=${matricula}` : queryUrl
  queryUrl = nome ? `${queryUrl}&nome=${nome}` : queryUrl
  queryUrl = email ? `${queryUrl}&email=${email}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl
  queryUrl = lavador ? `${queryUrl}&lavador=${lavador}` : queryUrl
  queryUrl = atendente ? `${queryUrl}&atendente=${atendente}` : queryUrl

  return api
    .get(path + queryUrl, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const listFuncionariosBasicApi = ({ token, filter }) => {
  const {
    status = '',
    page = 0,
    rows = 50,
    id,
    filiais,
    matricula,
    nome,
    email,
    search,
    lavador,
    atendente,
  } = filter
  let queryUrl = `?status=${status}&page=${page}&rows=${rows}`
  queryUrl = id ? `${queryUrl}&id=${id}` : queryUrl
  queryUrl = filiais ? `${queryUrl}&filiais=${filiais}` : queryUrl
  queryUrl = matricula ? `${queryUrl}&matricula=${matricula}` : queryUrl
  queryUrl = nome ? `${queryUrl}&nome=${nome}` : queryUrl
  queryUrl = email ? `${queryUrl}&email=${email}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl
  queryUrl = lavador ? `${queryUrl}&lavador=${lavador}` : queryUrl
  queryUrl = atendente ? `${queryUrl}&atendente=${atendente}` : queryUrl

  return api
    .get(`${path}/basic${queryUrl}`, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const listFuncionariosAtendentesApi = ({ token, filter }) => {
  const { filiais } = filter
  const queryUrl = `?filiais=${filiais}`
  return api
    .get(`${path}/atendentes${queryUrl}`, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getFuncionariosApi = ({ token, id }) => api
  .get(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const addFuncionariosApi = ({ token, dto }) => api
  .post(path, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const editFuncionariosApi = ({ token, id, dto }) => api
  .put(`${path}/${id}`, JSON.stringify(dto), {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then(httpValidState)
  .catch(httpValidErros)

export const deleteFuncionariosApi = ({ token, id }) => api
  .delete(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)
