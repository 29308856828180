import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const path = '/empresas'

export const listEmpresasApi = ({ token, filter }) => {
  const {
    status = '', page = 0, rows = 50, id, cnpj, razao, search,
  } = filter
  let queryUrl = `?status=${status}&page=${page}&rows=${rows}`
  queryUrl = id ? `${queryUrl}&id=${id}` : queryUrl
  queryUrl = cnpj ? `${queryUrl}&cnpj=${cnpj}` : queryUrl
  queryUrl = razao ? `${queryUrl}&razao=${razao}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl
  return api
    .get(path + queryUrl, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getEmpresasApi = ({ token, id }) => api
  .get(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const addEmpresasApi = ({ token, dto }) => api
  .post(path, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const editEmpresasApi = ({ token, id, dto }) => api
  .put(`${path}/${id}`, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const deleteEmpresasApi = ({ token, id }) => api
  .delete(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)
