import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Grid, Hidden,
} from '@material-ui/core'

// Actions
import { Actions as AuthActions } from 'store/ducks/auth'

// Components
import AppBarComp from 'components/AppBar'
import DrawerComp from 'components/Drawer'
import DrawerMobileComp from 'components/Drawer/mobile'
import useStyles from './styles'

const DefaultPage = (props) => {
  const {
    history,
    menu,
    session,
    children,
    onAuthSignOutAction,
    onAuthMenuToggle,
  } = props
  const classes = useStyles()
  const [drawerData, setDrawerData] = useState([])
  const [empresasId, setEmpresasId] = useState(0)

  useEffect(() => {
    if (_.isNil(session.token) || session.token === '') {
      history.push('/login')
    }
    if (!_.isNil(session.user) && _.isArray(session.user.grupos)) {
      let aPaginas = []
      session.user.grupos.forEach((g) => {
        aPaginas = _.unionBy(g.paginas, aPaginas, 'id')
      })
      setDrawerData(aPaginas)
    }
    const currentFilialId = session?.user?.filial ?? 0
    const currentFilial = session?.user?.filiais.find((f) => f.id === currentFilialId)
    if (currentFilial) {
      setEmpresasId(currentFilial.empresasId)
    }
  }, [])

  return (
    <Grid container>
      <Grid item>
        <AppBarComp history={history} logoff={onAuthSignOutAction} />
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="flex-start">
          <Grid item xs={12} md={2}>
            <>
              <Hidden smDown>
                <DrawerComp empresasId={empresasId} data={drawerData} />
              </Hidden>
              <Hidden smUp>
                <DrawerMobileComp
                  open={menu?.open ?? false}
                  onMenuToggle={onAuthMenuToggle}
                  empresasId={empresasId}
                  data={drawerData}
                />
              </Hidden>
            </>
          </Grid>
          <Grid item xs={12} md={10} className={classes.content}>
            <div className={classes.toolbar} />
            {children}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <span style={{ margin: 4, fontSize: 8 }}>
          &copy;
          {` Divnet - Backoffice v${process.env.REACT_APP_VERSION}`}
        </span>
      </Grid>
    </Grid>
  )
}

DefaultPage.propTypes = {
  history: PropTypes.shape().isRequired,
  menu: PropTypes.shape().isRequired,
  session: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
  onAuthSignOutAction: PropTypes.func.isRequired,
  onAuthMenuToggle: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
  menu: state.auth.menu,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...AuthActions,
  },
  dispatch,
)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(DefaultPage))
