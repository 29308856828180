import _ from 'lodash'
import { NotificationManager } from 'react-notifications'
import {
  listClientesApi,
  getClientesApi,
  addClientesApi,
  editClientesApi,
  deleteClientesApi,
} from 'api/clientes'

// State Usuario
const initialState = {
  loading: {
    list: false,
    find: false,
    add: false,
    edit: false,
    del: false,
  },
  list: { page: 0, rows: 0, data: [] },
  form: {
    id: 0,
    tipoPessoa: '',
    nome: '',
    cgc: '',
    rg: '',
    nascimentoDia: '',
    nascimentoMes: '',
    nascimentoAno: '',
    email: '',
    celular: '',
    telefone: '',
    endereco: '',
    bairro: '',
    cidade: '',
    estado: '',
    uf: '',
    observacao: '',
    status: '',
    statusDescricao: '',
    veiculos: [],
  },
}

// Actions Types
export const Types = {
  RESET: 'reset',
  CLIENTES_LOADING: 'clientes/LOADING',
  CLIENTES_LIST_LOAD: 'clientes/LIST_LOAD',
  CLIENTES_LIST_UPDATE: 'clientes/LIST_UPDATE',
  CLIENTES_LIST_CLEAN: 'clientes/LIST_CLEAN',
  CLIENTES_FORM_UPDATE: 'clientes/FORM_UPDATE',
}

// Reducers
export default function clientesReducers(state = initialState, action) {
  switch (action.type) {
    // RESET DEFAULT
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      }
    case Types.CLIENTES_LOADING:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    case Types.CLIENTES_LIST_LOAD:
      return {
        ...state,
        list: action.payload,
      }
    case Types.CLIENTES_LIST_UPDATE:
      return {
        ...state,
        list: state.list.map(i =>
          i.id === action.payload.id ? { ...i, ...action.payload } : i
        ),
      }
    case Types.CLIENTES_LIST_CLEAN:
      return {
        ...state,
        list: initialState.list,
      }
    case Types.CLIENTES_FORM_UPDATE:
      return {
        ...state,
        form: { ...state.form, ...action.payload },
      }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  // Store Update
  onClientesLoading: payload => ({ type: Types.CLIENTES_LOADING, payload }),
  onClientesListLoad: payload => ({ type: Types.CLIENTES_LIST_LOAD, payload }),
  onClientesListUpdate: payload => ({
    type: Types.CLIENTES_LIST_UPDATE,
    payload,
  }),
  onClientesListClean: () => ({ type: Types.CLIENTES_LIST_CLEAN }),
  onClientesFormUpdate: payload => ({
    type: Types.CLIENTES_FORM_UPDATE,
    payload,
  }),
  onClientesFormClean: () => ({
    type: Types.CLIENTES_FORM_UPDATE,
    payload: initialState.form,
  }),
  onClientesDataById: id => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    dispatch(Actions.onClientesLoading({ find: true }))
    // Chamada API
    getClientesApi({ token, id }).then(payload => {
      dispatch(Actions.onClientesLoading({ find: false }))
      dispatch(
        Actions.onClientesFormUpdate(
          _.isObject(payload) ? payload : initialState.form
        )
      )
    })
  },
  onClientesDataList: filter => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    dispatch(Actions.onClientesLoading({ list: true }))
    // Chamada API
    listClientesApi({ token, filter }).then(payload => {
      dispatch(Actions.onClientesLoading({ list: false }))
      dispatch(Actions.onClientesListLoad(payload || initialState.list))
    })
  },
  onClientesDataSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    // * Chamada API
    if (dto.id) {
      dispatch(Actions.onClientesLoading({ edit: true }))
      editClientesApi({ token, dto, id: dto.id }).then(payload => {
        dispatch(Actions.onClientesLoading({ edit: false }))
        if (
          payload &&
          payload.status === 200 &&
          payload.message === 'updated'
        ) {
          NotificationManager.success(
            `Cliente ${dto.nome} atualizado com sucesso!`,
            'Sucesso!',
            8000
          )
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    } else {
      dispatch(Actions.onClientesLoading({ add: true }))
      addClientesApi({ token, dto }).then(payload => {
        dispatch(Actions.onClientesLoading({ add: false }))
        if (
          payload &&
          payload.status === 200 &&
          payload.message === 'inserted'
        ) {
          NotificationManager.success(
            `Cliente ${dto.nome} cadastrado com sucesso!`,
            'Sucesso!',
            8000
          )
          if (_.isFunction(callback)) {
            callback(payload.entity)
          }
        }
      })
    }
  },
  onClientesDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    dispatch(Actions.onClientesLoading({ del: true }))
    // Chamada API
    deleteClientesApi({ token, id }).then(payload => {
      dispatch(Actions.onClientesLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success(
          'Cliente excluído com sucesso!',
          'Sucesso!',
          8000
        )
        if (_.isFunction(callback)) {
          callback()
        }
      }
    })
  },
}
