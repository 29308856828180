import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid, TextField, FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core'

// Styles
import useStyles from './styles'

// Stateless
const PagamentosCondicaoFormComp = ({ data, onChange }) => {
  const {
    pagamentosForma = {},
    descricao = '',
    taxa = '',
    status = '',
  } = data
  const classes = useStyles()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="pagamentosCondicao-pagamentosForma"
          label="Forma de Pagamento"
          margin="normal"
          variant="outlined"
          value={pagamentosForma.descricao}
          // onChange={(evt) => onChange({ cnpj: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          id="pagamentosCondicao-taxa"
          label="Taxa"
          margin="normal"
          variant="outlined"
          type="number"
          value={taxa}
          onChange={(evt) => onChange({ taxa: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
          className={classes.dataControl}
        >
          <InputLabel ref={inputLabel}>Status</InputLabel>
          <Select
            id="pagamentosCondicao-status"
            value={status}
            onChange={(evt) => onChange({ status: evt.target.value.toUpperCase() })}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="P">PENDENTE</MenuItem>
            <MenuItem value="L">LIBERADO</MenuItem>
            <MenuItem value="B">BLOQUEADO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="pagamentosCondicao-descricao"
          label="Descrição"
          margin="normal"
          variant="outlined"
          value={descricao}
          onChange={(evt) => onChange({ descricao: evt.target.value.toUpperCase() })}
        />
      </Grid>
    </Grid>
  )
}

PagamentosCondicaoFormComp.propTypes = {
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}

export default PagamentosCondicaoFormComp
