import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Numeral from 'numeral'
import 'numeral/locales/pt-br'

import { store, persitor, history } from 'store'
import Router from 'router'

Numeral.locale('pt-br')

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persitor}>
      <Router history={history} />
    </PersistGate>
  </Provider>
)

export default App
