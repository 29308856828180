import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Grid, Paper, Tabs, Tab,
} from '@material-ui/core'

// Actions
import { Actions as PagamentosFormaActions } from 'store/ducks/pagamentos/forma'
import { Actions as PagamentosCondicaoActions } from 'store/ducks/pagamentos/condicao'

// Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import TableDataComp from 'components/Table'
import DialogComp from 'components/Dialogs'
import PagamentosFormaFormComp from './components/PagamentosFormaForm'
import PagamentosCondicaoFormComp from './components/PagamentosCondicaoForm'

// Styles
import useStyles from './styles'

// Stateless component
const PagamentosFormaPage = ({
  // Pagamentos Forma
  pagamentosForma,
  onPagamentosFormaFormUpdate,
  onPagamentosFormaFormClean,
  onPagamentosFormaDataList,
  onPagamentosFormaDataSave,
  onPagamentosFormaDataDelete,
  // Pagamentos Condição
  pagamentosCondicao,
  onPagamentosCondicaoFormUpdate,
  onPagamentosCondicaoFormClean,
  onPagamentosCondicaoDataList,
  onPagamentosCondicaoDataSave,
  onPagamentosCondicaoDataDelete,
}) => {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const [dialogForm, setDialogForm] = useState({ open: false, title: '' })
  const [dialogAskDelete, setDialogAskDelete] = useState({ open: false, title: '', data: null })
  const [dialogFormPagamentosCondicao, setDialogFormPagamentosCondicao] = useState({
    open: false,
    title: '',
  })
  const [dialogAskDeletePagamentosCondicao, setDialogAskDeletePagamentosCondicao] = useState({
    open: false,
    title: '',
    data: null,
  })
  const [tabSelected, setTabSelected] = useState(0)
  const [pagamentosFormaSelected, setPagamentosFormaSelected] = useState({
    id: 0,
    cnpj: '',
    descricao: '',
    status: '',
    pagamentosCondicao: [],
  })

  const handleFiltraPagamentosForma = (filter) => {
    onPagamentosFormaDataList(filter)
  }

  const handleFiltraPagamentosCondicao = (filter) => {
    onPagamentosCondicaoDataList({
      ...filter,
      pagamentosForma: filter.pagamentosForma || pagamentosFormaSelected.id,
    })
  }

  const handleSelectTab = (event, newValue) => setTabSelected(newValue)
  const handleSearch = () => {
    switch (tabSelected) {
    case 0:
      handleFiltraPagamentosForma({ search })
      break
    case 1:
      handleFiltraPagamentosCondicao({ search })
      break
    default:
      handleFiltraPagamentosForma({ search })
    }
  }

  // Table/Aba pagamentosForma (INICIO)

  const handleOpenCloseDialogPagamentosForma = (open, title = null) => {
    if (title) {
      setDialogForm((s) => ({ ...s, open, title }))
    } else {
      setDialogForm((s) => ({ ...s, open }))
    }
  }

  const handleOpenCloseDialogAskDelete = (open, title = null, data = null) => {
    setDialogAskDelete((s) => ({
      ...s,
      open,
      title,
      data,
    }))
  }

  const handleSelectPagamentosForma = (pPagamentosForma) => {
    setPagamentosFormaSelected(pPagamentosForma)
    setTabSelected(1)
    handleFiltraPagamentosCondicao({ pagamentosForma: pPagamentosForma.id, search })
  }

  const tablePagamentosFormaCols = [
    { key: 'codigo', title: 'CÓDIGO', onClick: (row) => handleSelectPagamentosForma(row) },
    { key: 'bandeira', title: 'BANDEIRA' },
    { key: 'descricao', title: 'DESCRIÇÃO' },
    { key: 'statusDescricao', title: 'STATUS' },
    { key: 'condicoes.length', title: 'CONDIÇÕES', align: 'center' },
  ]

  const tablePagamentosFormaActions = [
    {
      key: 'editar',
      title: 'Editar',
      icon: 'edit',
      action: (row) => {
        onPagamentosFormaFormUpdate(row)
        handleOpenCloseDialogPagamentosForma(true, 'Edição de empresa:')
      },
    },
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: (row) => handleOpenCloseDialogAskDelete(true, 'Excluir?', row),
    },
  ]
  // Table/Aba pagamentosForma (FIM)

  // Table/Aba pagamentosCondicao (INICIO)

  const handleOpenCloseDialogPagamentosCondicao = (open, title = null) => {
    if (title) {
      setDialogFormPagamentosCondicao((s) => ({ ...s, open, title }))
    } else {
      setDialogFormPagamentosCondicao((s) => ({ ...s, open }))
    }
  }

  const handleOpenCloseDialogAskDeletePagamentosCondicao = (open, title = null, data = null) => {
    setDialogAskDeletePagamentosCondicao((s) => ({
      ...s,
      open,
      title,
      data,
    }))
  }

  const tablePagamentosCondicaoCols = [
    { key: 'descricao', title: 'DESCRIÇÃO' },
    { key: 'taxa', title: 'TAXA' },
    { key: 'statusDescricao', title: 'STATUS' },
  ]

  const tablePagamentosCondicaoActions = [
    {
      key: 'editar',
      title: 'Editar',
      icon: 'edit',
      action: (row) => {
        onPagamentosCondicaoFormUpdate(row)
        handleOpenCloseDialogPagamentosCondicao(true, 'Edição de filiai:')
      },
    },
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: (row) => handleOpenCloseDialogAskDeletePagamentosCondicao(true, 'Excluir?', row),
    },
  ]

  // Table/Aba pagamentosCondicao (FIM)

  // Header button
  const headerButtons = [
    {
      title: 'Adicionar',
      icon: 'add',
      action: () => {
        if (!tabSelected) {
          onPagamentosFormaFormClean()
          handleOpenCloseDialogPagamentosForma(true, 'Cadastro de formas:')
        } else {
          onPagamentosCondicaoFormClean()
          handleOpenCloseDialogPagamentosCondicao(true, 'Cadastro de condições:')
        }
      },
    },
  ]
  // Header button

  useEffect(() => {
    document.title = 'Backoffice - Pagamentos'
    handleFiltraPagamentosForma({ search })
  }, [])

  const pagamentosFormaDesc = tabSelected === 1 && pagamentosFormaSelected.id
    ? `[${pagamentosFormaSelected.id}] - ${pagamentosFormaSelected.descricao}`
    : ''

  return (
    <DefaultPage>
      <PageHeaderComp
        title="Pagamentos"
        subtitle={pagamentosFormaDesc}
        value={search}
        onChange={(evt) => setSearch(evt.target.value)}
        onClick={handleSearch}
        onKeyPress={(evt) => {
          if (evt.charCode === 13) {
            handleSearch()
          }
        }}
        buttons={headerButtons}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Paper className={classes.root}>
            <Tabs
              value={tabSelected}
              onChange={handleSelectTab}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Formas" />
              <Tab label="Condições" disabled={!pagamentosFormaSelected.id} />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* Listagem das pagamentosForma */}
          {tabSelected === 0 && (
            <TableDataComp
              loading={pagamentosForma.loading.list}
              size="small"
              rows={pagamentosForma.list.data}
              cols={tablePagamentosFormaCols}
              actions={tablePagamentosFormaActions}
            />
          )}
          {/* Listagem das pagamentosCondicao */}
          {tabSelected === 1 && (
            <TableDataComp
              loading={pagamentosCondicao.loading.list}
              size="small"
              rows={pagamentosCondicao.list.data}
              cols={tablePagamentosCondicaoCols}
              actions={tablePagamentosCondicaoActions}
            />
          )}
        </Grid>
      </Grid>
      {/* Dialog PagamentosFormaForm (add/edit) */}
      <DialogComp
        open={dialogForm.open}
        title={dialogForm.title}
        onClose={() => handleOpenCloseDialogPagamentosForma(false)}
        actions={[
          {
            label: 'Cancelar',
            onClick: () => {
              onPagamentosFormaFormClean()
              handleOpenCloseDialogPagamentosForma(false)
            },
          },
          {
            label: 'Salvar',
            onClick: () => {
              onPagamentosFormaDataSave(pagamentosForma.form, () => {
                onPagamentosFormaFormClean()
                handleOpenCloseDialogPagamentosForma(false)
                handleFiltraPagamentosForma({ search })
              })
            },
          },
        ]}
      >
        <PagamentosFormaFormComp
          data={pagamentosForma.form}
          onChange={onPagamentosFormaFormUpdate}
        />
      </DialogComp>
      {/* Dialog Pergunta DELETE */}
      <DialogComp
        open={dialogAskDelete.open}
        title={dialogAskDelete.title}
        onClose={() => handleOpenCloseDialogAskDelete(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDelete(false),
          },
          {
            label: 'SIM',
            onClick: () => onPagamentosFormaDataDelete(dialogAskDelete.data.id, () => {
              handleOpenCloseDialogAskDelete(false)
              handleFiltraPagamentosForma({ search })
            }),
          },
        ]}
      >
        {`Você deseja realmente excluir a empresa "${
          dialogAskDelete.data ? dialogAskDelete.data.descricao : ''
        }"?`}
      </DialogComp>
      {/* Dialog PagamentosCondicao */}
      <DialogComp
        open={dialogFormPagamentosCondicao.open}
        title={dialogFormPagamentosCondicao.title}
        size="md"
        onClose={() => handleOpenCloseDialogPagamentosCondicao(false)}
        actions={[
          {
            label: 'Cancelar',
            onClick: () => {
              onPagamentosCondicaoFormClean()
              handleOpenCloseDialogPagamentosCondicao(false)
            },
          },
          {
            label: 'Salvar',
            onClick: () => {
              onPagamentosCondicaoDataSave(
                {
                  ...pagamentosCondicao.form,
                  pagamentosForma: pagamentosFormaSelected,
                },
                () => {
                  onPagamentosCondicaoFormClean()
                  handleOpenCloseDialogPagamentosCondicao(false)
                  onPagamentosCondicaoDataList({
                    pagamentosForma: pagamentosFormaSelected.id,
                  })
                },
              )
            },
          },
        ]}
      >
        <PagamentosCondicaoFormComp
          data={pagamentosCondicao.form}
          onChange={onPagamentosCondicaoFormUpdate}
        />
      </DialogComp>
      {/* Dialog Pergunta DELETE */}
      <DialogComp
        open={dialogAskDeletePagamentosCondicao.open}
        title={dialogAskDeletePagamentosCondicao.title}
        onClose={() => handleOpenCloseDialogAskDeletePagamentosCondicao(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDeletePagamentosCondicao(false),
          },
          {
            label: 'SIM',
            onClick: () => onPagamentosCondicaoDataDelete(
              dialogAskDeletePagamentosCondicao.data.id,
              () => {
                handleOpenCloseDialogAskDeletePagamentosCondicao(false)
                handleFiltraPagamentosCondicao({ search })
              },
            ),
          },
        ]}
      >
        {`Você deseja realmente excluir a filial "${
          dialogAskDeletePagamentosCondicao.data
            ? dialogAskDeletePagamentosCondicao.data.descricao
            : ''
        }"?`}
      </DialogComp>
    </DefaultPage>
  )
}

PagamentosFormaPage.propTypes = {
  // PagamentosForma
  pagamentosForma: PropTypes.shape().isRequired,
  onPagamentosFormaFormUpdate: PropTypes.func.isRequired,
  onPagamentosFormaFormClean: PropTypes.func.isRequired,
  onPagamentosFormaDataList: PropTypes.func.isRequired,
  onPagamentosFormaDataSave: PropTypes.func.isRequired,
  onPagamentosFormaDataDelete: PropTypes.func.isRequired,
  // PagamentosCondicao
  pagamentosCondicao: PropTypes.shape().isRequired,
  onPagamentosCondicaoFormUpdate: PropTypes.func.isRequired,
  onPagamentosCondicaoFormClean: PropTypes.func.isRequired,
  onPagamentosCondicaoDataList: PropTypes.func.isRequired,
  onPagamentosCondicaoDataSave: PropTypes.func.isRequired,
  onPagamentosCondicaoDataDelete: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  pagamentosForma: state.pagamentosForma,
  pagamentosCondicao: state.pagamentosCondicao,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...PagamentosFormaActions,
    ...PagamentosCondicaoActions,
  },
  dispatch,
)

export default connect(mapStateToProps, mapDispatchToProps)(PagamentosFormaPage)
