import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const path = '/clientes/veiculos'

export const listVeiculosApi = ({ token, filter }) => {
  const {
    status = '', page = 0, rows = 50, id, clientes, placa, modelo, porte, proprietario, search,
  } = filter

  let queryUrl = `?status=${status}&page=${page}&rows=${rows}`
  queryUrl = id ? `${queryUrl}&id=${id}` : queryUrl
  queryUrl = clientes ? `${queryUrl}&clientes=${clientes}` : queryUrl
  queryUrl = placa ? `${queryUrl}&placa=${placa}` : queryUrl
  queryUrl = modelo ? `${queryUrl}&modelo=${modelo}` : queryUrl
  queryUrl = porte ? `${queryUrl}&porte=${porte}` : queryUrl
  queryUrl = proprietario ? `${queryUrl}&proprietario=${proprietario}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl

  return api
    .get(path + queryUrl, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getVeiculosApi = ({ token, id }) => api
  .get(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const addVeiculosApi = ({ token, dto }) => api
  .post(path, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const editVeiculosApi = ({ token, id, dto }) => api
  .put(`${path}/${id}`, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const deleteVeiculosApi = ({ token, id }) => api
  .delete(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)
