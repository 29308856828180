import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, Icon } from '@material-ui/core'
import PropTypes from 'prop-types'
import _ from 'lodash'

// Actions
import { Actions as OrdemServicoActions } from 'store/ducks/ordemservico'

// Components
import DialogComp from 'components/Dialogs'
import ClientesVeiculosFormComp from 'pages/clientes/components/ClientesVeiculosForm'
import OrdemServicoFormComp from 'pages/ordemservico/components/OrdemServicoForm'

import { hasRole } from 'utils'
import UserRoles from 'utils/enums/UserRoles'

// Styles
import useStyles from './styles'

const OrdemServicoAberturaComp = ({
  session,
  onOrdemServicoFormClean,
  onOrdemServicoFormUpdate,
  onOrdemServicoMonitoramentoDataList,
}) => {
  const classes = useStyles()
  const {
    user: { filiais = [], filial = 0 },
  } = session || {}
  const currentFilial = filiais.find(f => f.id === filial)
  const [dialogForm, setDialogForm] = useState({ open: false, title: '' })
  const [dialogClientesForm, setDialogClientesForm] = useState({
    open: false,
    title: '',
    data: {},
  })

  // * Usado para filtrar O.S para usuários da SofaComZelo
  const isOrcamentos = hasRole(
    session?.user?.grupos ?? [],
    UserRoles?.ROLE_ORCAMENTOS?.codigo ?? ''
  )

  const handleOpenCloseDialogForm = (open, title = null) => {
    if (title) {
      setDialogForm(s => ({ ...s, open, title }))
    } else {
      setDialogForm(s => ({ ...s, open }))
    }
  }
  const handleOpenCloseDialogClientesForm = (open, title = null, data) => {
    if (title) {
      setDialogClientesForm(s => ({ ...s, open, title, data }))
    } else {
      setDialogClientesForm(s => ({ ...s, open }))
    }
  }
  return (
    <>
      <Button
        onClick={() => {
          onOrdemServicoFormClean()
          handleOpenCloseDialogForm(true, 'Ordem de Serviço')
        }}
        variant="contained"
        color="secondary"
        className={[classes.btnAzul, classes.menuButton].join(' ')}
        startIcon={<Icon>assignment</Icon>}
      >
        Nova O.S
      </Button>
      {/* DialogForm (add/edit) ordem de serviço */}
      <DialogComp
        scroll="body"
        size="lg"
        open={dialogForm.open}
        title={dialogForm.title}
        onClose={() => {
          onOrdemServicoFormClean()
          handleOpenCloseDialogForm(false)
        }}
      >
        <OrdemServicoFormComp
          isOrcamentos={isOrcamentos}
          onOpen={data =>
            handleOpenCloseDialogClientesForm(true, 'Novo cliente:', data)
          }
          onClose={() => handleOpenCloseDialogClientesForm(false)}
          onRealoadGrid={() => {
            onOrdemServicoMonitoramentoDataList({ filiais: filial })
            handleOpenCloseDialogForm(false)
          }}
        />
      </DialogComp>
      {/* DialogForm novo CLIENTE (add/edit) */}
      <DialogComp
        scroll="body"
        size="md"
        open={dialogClientesForm.open}
        title={dialogClientesForm.title}
        onClose={() => {
          handleOpenCloseDialogClientesForm(false)
        }}
        actions={[]}
      >
        <ClientesVeiculosFormComp
          token={session.token}
          data={dialogClientesForm.data}
          filial={currentFilial}
          onCallback={payload => {
            const clientes = payload.entity
            const veiculo =
              _.isArray(clientes.veiculos) && !_.isEmpty(clientes.veiculos)
                ? clientes.veiculos[0]
                : null
            delete clientes.veiculos
            const veiculos = { ...veiculo, clientes }
            onOrdemServicoFormUpdate({ veiculos })
            handleOpenCloseDialogClientesForm(false)
          }}
        />
      </DialogComp>
    </>
  )
}

OrdemServicoAberturaComp.propTypes = {
  session: PropTypes.shape().isRequired,
  onOrdemServicoFormClean: PropTypes.func.isRequired,
  onOrdemServicoFormUpdate: PropTypes.func.isRequired,
  onOrdemServicoMonitoramentoDataList: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  session: state.auth.session,
  ordemServico: state.ordemServico,
  caixas: state.caixas,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...OrdemServicoActions,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdemServicoAberturaComp)
