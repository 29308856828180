import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Breadcrumbs,
  Link,
  Icon,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'
import {
  DateIsoToBr, parseToNum, MoneyFormatBr, downloadLinkFile,
} from 'utils'

//* Actions
import { Actions as VendasActions } from 'store/ducks/vendas'

//* Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import ButtonLoadingComp from 'components/Buttons/ButtonLoading'
import PedidoHeaderComp from 'pages/vendas/components/header_pv_updates'
import DialogComp from 'components/Dialogs'

//* Styles
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 9999,
    color: '#fff',
  },
  menuSuperior: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  cardHeader: {
    paddingTop: 20,
  },
  cardDivider: {
    height: 30,
  },
  cardBody: {
    marginTop: 20,
    paddingTop: 20,
  },
}))

const PedidosVendasDetalhesPedidosPage = ({
  session: { user },
  vendas: { loading, data: { meuspedidos: { pedido } } },
  onVendasPedidosById,
  onVendasPedidoVendaCancelar,
}) => {
  const classes = useStyles()
  const [dialogAskCancelPedido, setDialogAskCancelPedido] = useState({ open: false, title: '', data: null })
  const { id: pedidosVendasId } = useParams()

  const handleOpenCloseDialogAskCancelPedido = (open, title = null, data = null) => {
    setDialogAskCancelPedido((s) => ({
      ...s, open, title, data,
    }))
  }

  const handleLoadData = () => {
    onVendasPedidosById(pedidosVendasId)
  }

  useEffect(() => {
    document.title = `Backoffice - Detalhe do Pedido ${pedidosVendasId} `
    handleLoadData()
  }, [user.filial])

  return (
    <DefaultPage>
      <Grid container spacing={2}>
        {/* Header */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <PageHeaderComp
                title="Detalhe do Pedido"
              />
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/vendas">
                  Vendas
                </Link>
                <Link color="inherit" href="/vendas/meus-pedidos">
                  Meus Pedidos
                </Link>
                <Link color="inherit" href={`/vendas/detalhes-pedidos/${pedidosVendasId}`}>
                  {`${pedido?.id ?? ''}-${pedido?.ref ?? ''}`}
                </Link>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={2}
                className={classes.menuSuperior}
              >
                <Grid item>
                  <Tooltip title="Baixar PDF do Pedido">
                    <ButtonLoadingComp
                      variant="outlined"
                      color="primary"
                      startIcon={<Icon>print</Icon>}
                      onClick={() => downloadLinkFile(pedido.urlLinkPdf, 'pedido.pdf')}
                    >
                      IMPRIMIR
                    </ButtonLoadingComp>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Cancelar o Pedido">
                    <ButtonLoadingComp
                      disabled={pedido?.pedidosStatusId === 3}
                      loading={loading.cancel}
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleOpenCloseDialogAskCancelPedido(true, 'Cancelar?')}
                      startIcon={<Icon>cancel</Icon>}
                    >
                      CANCELAR
                    </ButtonLoadingComp>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Content */}
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Detalhe do Pedido" />
            <CardContent>
              <Grid container spacing={2}>
                {/* - Resumo do pedido */}
                <Grid item xs={12}>
                  {/* - Etapas do pedido (GRAFICO) */}
                  <Paper>
                    <PedidoHeaderComp
                      activeStep={pedido?.pedidosStatusId ?? 0}
                    />
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">EMISSÃO</TableCell>
                          <TableCell align="center">PEDIDO</TableCell>
                          <TableCell align="center">UNIDADE</TableCell>
                          <TableCell align="center">QTD. TOTAL</TableCell>
                          <TableCell align="center">PESO TOTAL</TableCell>
                          <TableCell align="center">TOTAL</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="center">{DateIsoToBr(pedido?.emissao ?? '')}</TableCell>
                          <TableCell align="center">{`${pedido?.id ?? ''}-${pedido?.ref ?? ''}`}</TableCell>
                          <TableCell align="center">{pedido?.unidadeDescricao ?? ''}</TableCell>
                          <TableCell align="center">
                            {`${parseToNum(pedido?.itens?.reduce((a, b) => a + b.quantidade, 0) ?? 0)}`}
                          </TableCell>
                          <TableCell align="center">
                            {`${parseToNum(pedido?.itens?.reduce((a, b) => a + b.peso, 0) ?? 0)}kg`}
                          </TableCell>
                          <TableCell align="center">
                            {`R$ ${MoneyFormatBr(parseToNum(pedido?.valorTotal ?? 0))}`}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={6}>{`OBSERVAÇÃO: ${pedido?.observacoes ?? ''}`}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
                <Grid item xs={12} className={classes.cardDivider} />
                {/* - Itens do pedido */}
                <Grid item xs={12}>
                  <Card>
                    <CardHeader title="Itens do Pedido" />
                    <CardContent>
                      <Table className={classes.cardBody}>
                        <TableHead>
                          <TableRow>
                            <TableCell>PRODUTO</TableCell>
                            <TableCell align="center">U.M.</TableCell>
                            <TableCell align="center">PESO</TableCell>
                            <TableCell align="center">QTD.</TableCell>
                            <TableCell align="center">VLR. UNIT</TableCell>
                            <TableCell align="center">VLR. TOTAL</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pedido?.itens?.map((i) => (
                            <TableRow key={i.id}>
                              <TableCell>{i.descricao}</TableCell>
                              <TableCell align="center">{i.um}</TableCell>
                              <TableCell align="center">{`${parseToNum(i.peso)}kg`}</TableCell>
                              <TableCell align="center">{parseToNum(i.quantidade)}</TableCell>
                              <TableCell align="center">{`R$ ${MoneyFormatBr(parseToNum(i?.valorUnitario ?? 0))}`}</TableCell>
                              <TableCell align="center">{`R$ ${MoneyFormatBr(parseToNum(i?.valorTotal ?? 0))}`}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Dialog CANCEL Pedido */}
      <DialogComp
        open={dialogAskCancelPedido?.open ?? false}
        title={dialogAskCancelPedido?.title ?? ''}
        onClose={() => handleOpenCloseDialogAskCancelPedido(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskCancelPedido(false),
          },
          {
            label: 'SIM',
            onClick: () => onVendasPedidoVendaCancelar(
              pedidosVendasId,
              () => {
                handleOpenCloseDialogAskCancelPedido(false)
                handleLoadData()
              },
            ),
          },
        ]}
      >
        Você deseja realmente deseja cancelar o pedido?
      </DialogComp>
      <Backdrop className={classes.backdrop} open={loading.cancel}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DefaultPage>
  )
}

PedidosVendasDetalhesPedidosPage.propTypes = {
  session: PropTypes.shape().isRequired,
  vendas: PropTypes.shape().isRequired,
  onVendasPedidosById: PropTypes.func.isRequired,
  onVendasPedidoVendaCancelar: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
  vendas: state.vendas,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...VendasActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PedidosVendasDetalhesPedidosPage)
