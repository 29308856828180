import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const path = '/ordemservico'

export const listOrdemServicoApi = ({ token, filter }) => {
  const {
    status = '', page = 0, rows = 50, id, filiais, usuarios, vendedor, veiculos,
    placa, dataAberturaIni, dataAberturaFim, dataEntregaIni, dataEntregaFim,
    dataFechamentoIni, dataFechamentoFim, dataPagamentoIni, dataPagamentoFim
    , comVeiculos = 'true', search,
  } = filter

  let queryUrl = `?status=${status}&page=${page}&rows=${rows}`
  queryUrl = id ? `${queryUrl}&id=${id}` : queryUrl
  queryUrl = filiais ? `${queryUrl}&filiais=${filiais}` : queryUrl
  queryUrl = usuarios ? `${queryUrl}&usuarios=${usuarios}` : queryUrl
  queryUrl = vendedor ? `${queryUrl}&vendedor=${vendedor}` : queryUrl
  queryUrl = veiculos ? `${queryUrl}&veiculos=${veiculos}` : queryUrl
  queryUrl = placa ? `${queryUrl}&placa=${placa}` : queryUrl
  queryUrl = dataAberturaIni ? `${queryUrl}&dataAberturaIni=${dataAberturaIni}` : queryUrl
  queryUrl = dataAberturaFim ? `${queryUrl}&dataAberturaFim=${dataAberturaFim}` : queryUrl
  queryUrl = dataEntregaIni ? `${queryUrl}&dataEntregaIni=${dataEntregaIni}` : queryUrl
  queryUrl = dataEntregaFim ? `${queryUrl}&dataEntregaFim=${dataEntregaFim}` : queryUrl
  queryUrl = dataFechamentoIni ? `${queryUrl}&dataFechamentoIni=${dataFechamentoIni}` : queryUrl
  queryUrl = dataFechamentoFim ? `${queryUrl}&dataFechamentoFim=${dataFechamentoFim}` : queryUrl
  queryUrl = dataPagamentoIni ? `${queryUrl}&dataPagamentoIni=${dataPagamentoIni}` : queryUrl
  queryUrl = dataPagamentoFim ? `${queryUrl}&dataPagamentoFim=${dataPagamentoFim}` : queryUrl
  queryUrl = comVeiculos ? `${queryUrl}&comVeiculos=${comVeiculos}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl

  return api
    .get(path + queryUrl, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getOrdemServicoApi = ({ token, id }) => api
  .get(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const addOrdemServicoApi = ({ token, dto }) => api
  .post(path, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const editOrdemServicoApi = ({ token, id, dto }) => api
  .put(`${path}/${id}`, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const deleteOrdemServicoApi = ({ token, id }) => api
  .delete(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

// Custon's resource's

export const listOrdemServicoMonitoramentoApi = ({ token, filter }) => {
  const {
    status = '', page = 0, rows = 50, id, filiais, usuarios, vendedor, veiculos, placa, search, comVeiculos = 'true'
  } = filter

  let queryUrl = `/monitoramento?status=${status}&page=${page}&rows=${rows}`
  queryUrl = id ? `${queryUrl}&id=${id}` : queryUrl
  queryUrl = filiais ? `${queryUrl}&filiais=${filiais}` : queryUrl
  queryUrl = usuarios ? `${queryUrl}&usuarios=${usuarios}` : queryUrl
  queryUrl = vendedor ? `${queryUrl}&vendedor=${vendedor}` : queryUrl
  queryUrl = veiculos ? `${queryUrl}&veiculos=${veiculos}` : queryUrl
  queryUrl = placa ? `${queryUrl}&placa=${placa}` : queryUrl
  queryUrl = comVeiculos ? `${queryUrl}&comVeiculos=${comVeiculos}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl

  return api
    .get(path + queryUrl, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

// dto: { id: Int, status: Enum(A,F,P,C,PF,ST) }
export const updateStatusOrdemServicoApi = ({ token, id, dto }) => api
  .put(`${path}/${id}/status`, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

// dto: { id: Int, status: String }
export const updateObsOrdemServicoApi = ({ token, id, dto }) => api
  .put(`${path}/${id}/observacao`, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const listOrdemServicoRpsApi = ({ token, filter }) => {
  const {
    page = 0, rows = 50, filiais, dataInicial, dataFinal,
  } = filter

  let queryUrl = `/rps?page=${page}&rows=${rows}`
  queryUrl = filiais ? `${queryUrl}&filiais=${filiais}` : queryUrl
  queryUrl = dataInicial ? `${queryUrl}&dataInicial=${dataInicial}` : queryUrl
  queryUrl = dataFinal ? `${queryUrl}&dataFinal=${dataFinal}` : queryUrl

  return api
    .get(path + queryUrl, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getOrdemServicoImpressaoApi = ({ token, id }) => api
  .get(`${path}/${id}/impressao`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)
