import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

// import dialog from './dialog'
// import configs from './configs'
import auth from './auth'
import empresas from './empresas'
import filiais from './filiais'
import funcionarios from './funcionarios'
import usuarios from './usuarios'
import servicos from './servicos'
import produtos from './produtos'
import categorias from './categorias'
import tabelaspreco from './tabelaspreco'
import tabelasprecoitens from './tabelasprecoitens'
import clientes from './clientes'
import veiculos from './veiculos'
import pagamentosForma from './pagamentos/forma'
import pagamentosCondicao from './pagamentos/condicao'
import ordemServico from './ordemservico'
import caixas from './caixas'
import dashboard from './dashboard'
import parametros from './parametros'
import fiscoNfseRps from './fisco/nfse/rps'
import vendas from './vendas'
import orcamentos from './orcamentos'

export default (history) => combineReducers({
  router: connectRouter(history),
  auth,
  empresas,
  filiais,
  funcionarios,
  usuarios,
  servicos,
  produtos,
  categorias,
  tabelaspreco,
  tabelasprecoitens,
  clientes,
  veiculos,
  pagamentosForma,
  pagamentosCondicao,
  ordemServico,
  caixas,
  dashboard,
  parametros,
  fiscoNfseRps,
  vendas,
  orcamentos,
  // dialog,
  // configs,
})
