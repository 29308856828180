import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const path = '/tabelaspreco/itens'

export const listTabelasPrecoItensApi = ({ token, filter }) => {
  const {
    status = '', page = 0, rows = 5000, id, filiais, tabelaspreco, servicos, porte, virgente, search,
  } = filter

  let queryUrl = `?status=${status}&page=${page}&rows=${rows}`
  queryUrl = id ? `${queryUrl}&id=${id}` : queryUrl
  queryUrl = filiais ? `${queryUrl}&filiais=${filiais}` : queryUrl
  queryUrl = tabelaspreco ? `${queryUrl}&tabelaspreco=${tabelaspreco}` : queryUrl
  queryUrl = servicos ? `${queryUrl}&servicos=${servicos}` : queryUrl
  queryUrl = porte ? `${queryUrl}&porte=${porte}` : queryUrl
  queryUrl = virgente ? `${queryUrl}&virgente=${virgente}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl

  return api
    .get(path + queryUrl, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const listTabelasPrecoItensBasicApi = ({ token, filter }) => {
  const {
    status = '', page = 0, rows = 5000, id, filiais, tabelaspreco, servicos, porte, virgente, search,
  } = filter

  let queryUrl = `?status=${status}&page=${page}&rows=${rows}`
  queryUrl = id ? `${queryUrl}&id=${id}` : queryUrl
  queryUrl = filiais ? `${queryUrl}&filiais=${filiais}` : queryUrl
  queryUrl = tabelaspreco ? `${queryUrl}&tabelaspreco=${tabelaspreco}` : queryUrl
  queryUrl = servicos ? `${queryUrl}&servicos=${servicos}` : queryUrl
  queryUrl = porte ? `${queryUrl}&porte=${porte}` : queryUrl
  queryUrl = virgente ? `${queryUrl}&virgente=${virgente}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl

  return api
    .get(`${path}/basic${queryUrl}`, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getTabelasPrecoItensApi = ({ token, id }) => api
  .get(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const addTabelasPrecoItensApi = ({ token, dto }) => api
  .post(path, JSON.stringify(dto), {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then(httpValidState)
  .catch(httpValidErros)

export const editTabelasPrecoItensApi = ({ token, id, dto }) => api
  .put(`${path}/${id}`, JSON.stringify(dto), {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then(httpValidState)
  .catch(httpValidErros)

export const deleteTabelasPrecoItensApi = ({ token, id }) => api
  .delete(`${path}/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then(httpValidState)
  .catch(httpValidErros)
