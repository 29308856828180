import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid, TextField, FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core'

// Util's
import { UFs, digitsOnly } from 'utils'

// Components
import {
  TelefoneMaskCustom2,
} from 'components/Inputs/Masked'

// Styles
import useStyles from './styles'

// Stateless
const FiliaisFormComp = ({ data, onChange }) => {
  const classes = useStyles()
  const [labelWidths, setLabelWidths] = useState({
    status: {
      width: 0,
      ref: useRef(null),
    },
    uf: {
      width: 0,
      ref: useRef(null),
    },
  })
  useEffect(() => {
    setLabelWidths((s) => ({
      ...s,
      status: {
        ...s.tipoPessoa,
        width: s.status.ref.current.offsetWidth,
      },
      uf: {
        ...s.uf,
        width: s.uf.ref.current.offsetWidth,
      },
    }))
  }, [])
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={3}>
        <TextField
          fullWidth
          id="filiais-cnpj"
          label="CNPJ"
          margin="normal"
          variant="outlined"
          inputProps={{
            maxLength: 14,
          }}
          value={data.cnpj}
          onChange={(evt) => onChange({ cnpj: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          id="filiais-razao"
          label="RAZÃO"
          margin="normal"
          variant="outlined"
          value={data.razao}
          onChange={(evt) => onChange({ razao: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
          className={classes.dataControl}
        >
          <InputLabel ref={labelWidths.status.ref}>Status</InputLabel>
          <Select
            id="filiais-status"
            value={data.status}
            onChange={(evt) => onChange({ status: evt.target.value.toUpperCase() })}
            labelWidth={labelWidths.uf.width}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="P">PENDENTE</MenuItem>
            <MenuItem value="L">LIBERADO</MenuItem>
            <MenuItem value="B">BLOQUEADO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="filiais-descricao"
          label="DESCRIÇÃO"
          margin="normal"
          variant="outlined"
          value={data.descricao}
          onChange={(evt) => onChange({ descricao: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          id="filiais-telefone"
          label="TELEFONE"
          margin="normal"
          variant="outlined"
          value={data.telefone}
          inputProps={{
            pattern: '\\d',
            maxLength: 11,
          }}
          onChange={(evt) => onChange({ telefone: digitsOnly(evt.target.value.toUpperCase()) })}
        />
      </Grid>
      <Grid item xs={7}>
        <TextField
          fullWidth
          id="filiais-logradouro"
          label="LOGRADOURO"
          margin="normal"
          variant="outlined"
          value={data.logradouro}
          onChange={(evt) => onChange({ logradouro: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          id="filiais-numero"
          label="NUMERO"
          margin="normal"
          variant="outlined"
          value={data.numero}
          onChange={(evt) => onChange({ numero: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          id="filiais-bairro"
          label="BAIRRO"
          margin="normal"
          variant="outlined"
          value={data.bairro}
          onChange={(evt) => onChange({ bairro: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          id="filiais-cidade"
          label="CIDADE"
          margin="normal"
          variant="outlined"
          value={data.cidade}
          onChange={(evt) => onChange({ cidade: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          id="filiais-complemento"
          label="COMPLEMENTO"
          margin="normal"
          variant="outlined"
          value={data.complemento}
          onChange={(evt) => onChange({ complemento: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
          className={classes.dataControl}
        >
          <InputLabel ref={labelWidths.uf.ref}>UF</InputLabel>
          <Select
            id="filiais-estado"
            value={data.estado}
            onChange={(evt) => onChange({ estado: evt.target.value.toUpperCase() })}
            labelWidth={labelWidths.uf.width}
          >
            <MenuItem value="">SELECIONE</MenuItem>
            {UFs.map((u) => {
              const uf = Object.keys(u)[0]
              return (<MenuItem key={uf} value={uf}>{uf}</MenuItem>)
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          id="filiais-cep"
          label="CEP"
          margin="normal"
          variant="outlined"
          value={data.cep}
          onChange={(evt) => onChange({ cep: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="filiais-rodape1"
          label="RODAPÉ 1"
          margin="normal"
          variant="outlined"
          value={data.rodape1}
          onChange={(evt) => onChange({ rodape1: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="filiais-rodape2"
          label="RODAPÉ 2"
          margin="normal"
          variant="outlined"
          value={data.rodape2}
          onChange={(evt) => onChange({ rodape2: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="filiais-rodape3"
          label="RODAPÉ 3"
          margin="normal"
          variant="outlined"
          value={data.rodape3}
          onChange={(evt) => onChange({ rodape3: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="filiais-url-nfe"
          label="URL NFE"
          margin="normal"
          variant="outlined"
          value={data.urlNfse}
          onChange={(evt) => onChange({ urlNfse: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          id="filiais-ie"
          label="I.E."
          margin="normal"
          variant="outlined"
          value={data.inscricaoEstadual}
          onChange={(evt) => onChange({ inscricaoEstadual: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          id="filiais-cod-mun-ibge"
          label="CÓD. MUN. IBGE"
          margin="normal"
          variant="outlined"
          value={data.codigoMunicipioIbge}
          onChange={(evt) => onChange({ codigoMunicipioIbge: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          id="filiais-item-lista-servico"
          label="ITEM LISTA SERVIÇO"
          margin="normal"
          variant="outlined"
          value={data.itemListaServico}
          onChange={(evt) => onChange({ itemListaServico: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          id="filiais-cod-trib-mun"
          label="CÓD. TRIB. MUN."
          margin="normal"
          variant="outlined"
          value={data.codigoTributacaoMunicipio}
          onChange={(evt) => onChange({
            codigoTributacaoMunicipio: evt.target.value.toUpperCase(),
          })}
        />
      </Grid>
    </Grid>
  )
}

FiliaisFormComp.propTypes = {
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}

export default FiliaisFormComp
