import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, Icon,
} from '@material-ui/core'
// import _ from 'lodash'

import { parseToDec } from 'utils'

// Styles
import useStyles from './styles'

// Stateless
const CategoriasFormComp = ({ data, onSave }) => {
  const classes = useStyles()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  const [formData, setFormData] = useState(data)
  const {
    sku,
    descricao,
    status,
  } = formData

  const onChange = (value) => setFormData((s) => ({ ...s, ...value }))

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          id="sku"
          label="SKU:"
          margin="normal"
          variant="outlined"
          value={sku}
          onChange={(evt) => onChange({ sku: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          id="descricao"
          label="DESCRIÇÃO:"
          margin="normal"
          variant="outlined"
          value={descricao}
          onChange={(evt) => onChange({ descricao: evt?.target?.value?.toUpperCase() ?? '' })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl required variant="outlined" fullWidth margin="normal" className={classes.dataControl}>
          <InputLabel ref={inputLabel}>
            Status
          </InputLabel>
          <Select
            id="servicos-status"
            value={status}
            onChange={(evt) => onChange({ status: evt.target.value.toUpperCase() })}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="P">PENDENTE</MenuItem>
            <MenuItem value="L">LIBERADO</MenuItem>
            <MenuItem value="B">BLOQUEADO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.ctnActions}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon>save</Icon>}
            onClick={() => onSave({
              ...formData,
              preco: parseToDec(formData.preco),
              precoPromo: parseToDec(formData.precoPromo),
            })}
          >
            SALVAR
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

CategoriasFormComp.propTypes = {
  data: PropTypes.shape().isRequired,
  onSave: PropTypes.func.isRequired,
}

export default CategoriasFormComp
