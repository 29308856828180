import _ from 'lodash'
import { NotificationManager } from 'react-notifications'
import {
  listFiliaisApi,
  getFiliaisApi,
  addFiliaisApi,
  editFiliaisApi,
  deleteFiliaisApi,
  // Metas
  listFiliaisMetasApi,
  getFiliaisMetasApi,
  addFiliaisMetasApi,
  editFiliaisMetasApi,
  deleteFiliaisMetasApi,
} from 'api/filiais'

// State Filiais
const initialState = {
  loading: {
    list: false,
    find: false,
    add: false,
    edit: false,
    del: false,
  },
  list: { page: 0, rows: 0, data: [] },
  form: {
    id: 0,
    empresas: {},
    cnpj: '',
    razao: '',
    descricao: '',
    telefone: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    cep: '',
    rodape1: '',
    rodape2: '',
    rodape3: '',
    status: '',
    statusDescricao: '',
    urlNfse: '',
    inscricaoEstadual: '',
    codigoMunicipioIbge: '',
    itemListaServico: '',
    codigoTributacaoMunicipio: '',
  },
  meta: {
    loading: {
      list: false,
      find: false,
      add: false,
      edit: false,
      del: false,
    },
    form: {
      id: 0,
      filiaisId: 0,
      filiaisDescricao: '',
      metaFim: '',
      metaInicio: '',
      quantDias: 0,
      status: '',
      valorDiario: 0,
      valorTotal: 0,
    },
    list: { page: 0, rows: 0, data: [] },
  },
}

// Actions Types
export const Types = {
  RESET: 'reset',
  FILIAIS_LOADING: 'filiais/LOADING',
  FILIAIS_LIST_LOAD: 'filiais/LIST_LOAD',
  FILIAIS_LIST_UPDATE: 'filiais/LIST_UPDATE',
  FILIAIS_LIST_CLEAN: 'filiais/LIST_CLEAN',
  FILIAIS_FORM_UPDATE: 'filiais/FORM_UPDATE',
  // Metas
  FILIAIS_METAS_LOADING: 'filiaisMetas/LOADING',
  FILIAIS_METAS_LIST_LOAD: 'filiaisMetas/LIST_LOAD',
  FILIAIS_METAS_LIST_UPDATE: 'filiaisMetas/LIST_UPDATE',
  FILIAIS_METAS_LIST_CLEAN: 'filiaisMetas/LIST_CLEAN',
  FILIAIS_METAS_FORM_UPDATE: 'filiaisMetas/FORM_UPDATE',
}

// Reducers
export default function filiaisReducers(state = initialState, action) {
  switch (action.type) {
    // RESET DEFAULT
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      }
    case Types.FILIAIS_LOADING:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    case Types.FILIAIS_LIST_LOAD:
      return {
        ...state,
        list: action.payload,
      }
    case Types.FILIAIS_LIST_UPDATE:
      return {
        ...state,
        list: state.list.map((i) => (i.id === action.payload.id ? { ...i, ...action.payload } : i)),
      }
    case Types.FILIAIS_LIST_CLEAN:
      return {
        ...state,
        list: initialState.list,
      }
    case Types.FILIAIS_FORM_UPDATE:
      return {
        ...state,
        form: { ...state.form, ...action.payload },
      }
    // Metas
    case Types.FILIAIS_METAS_LOADING:
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: { ...state.meta.loading, ...action.payload },
        },
      }
    case Types.FILIAIS_METAS_LIST_LOAD:
      return {
        ...state,
        meta: {
          ...state.meta,
          list: action.payload,
        },
      }
    case Types.FILIAIS_METAS_LIST_UPDATE:
      return {
        ...state,
        meta: {
          ...state.meta,
          list: state.meta.list.map((i) => (i.id === action.payload.id
            ? { ...i, ...action.payload }
            : i)),
        },
      }
    case Types.FILIAIS_METAS_LIST_CLEAN:
      return {
        ...state,
        meta: {
          ...state.meta,
          list: initialState.meta.list,
        },
      }
    case Types.FILIAIS_METAS_FORM_UPDATE:
      return {
        ...state,
        meta: {
          ...state.meta,
          form: { ...state.meta.form, ...action.payload },
        },
      }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  // Store Update
  onFiliaisLoading: (payload) => ({ type: Types.FILIAIS_LOADING, payload }),
  onFiliaisListLoad: (payload) => ({ type: Types.FILIAIS_LIST_LOAD, payload }),
  onFiliaisListUpdate: (payload) => ({ type: Types.FILIAIS_LIST_UPDATE, payload }),
  onFiliaisListClean: () => ({ type: Types.FILIAIS_LIST_CLEAN }),
  onFiliaisFormUpdate: (payload) => ({ type: Types.FILIAIS_FORM_UPDATE, payload }),
  onFiliaisFormClean: () => ({ type: Types.FILIAIS_FORM_UPDATE, payload: initialState.form }),
  onFiliaisDataById: (id) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onFiliaisLoading({ find: true }))
    // Chamada API
    getFiliaisApi({ token, id }).then((payload) => {
      dispatch(Actions.onFiliaisLoading({ find: false }))
      dispatch(Actions.onFiliaisFormUpdate(_.isObject(payload) ? payload : initialState.form))
    })
  },
  onFiliaisDataList: (filter) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onFiliaisLoading({ list: true }))
    // Chamada API
    listFiliaisApi({ token, filter }).then((payload) => {
      dispatch(Actions.onFiliaisLoading({ list: false }))
      dispatch(Actions.onFiliaisListLoad(payload || initialState.list))
    })
  },
  onFiliaisDataSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    // Chamada API
    if (dto.id) {
      dispatch(Actions.onFiliaisLoading({ edit: true }))
      editFiliaisApi({ token, dto, id: dto.id }).then((payload) => {
        dispatch(Actions.onFiliaisLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success(`Filial ${dto.razao} atualizada com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    } else {
      dispatch(Actions.onFiliaisLoading({ add: true }))
      addFiliaisApi({ token, dto }).then((payload) => {
        dispatch(Actions.onFiliaisLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success(`Filial ${dto.razao} cadastrada com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    }
  },
  onFiliaisDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onFiliaisLoading({ del: true }))
    // Chamada API
    deleteFiliaisApi({ token, id }).then((payload) => {
      dispatch(Actions.onFiliaisLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Filial excluída com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    })
  },
  // Metas
  onFiliaisMetasLoading: (payload) => ({ type: Types.FILIAIS_METAS_LOADING, payload }),
  onFiliaisMetasListLoad: (payload) => ({ type: Types.FILIAIS_METAS_LIST_LOAD, payload }),
  onFiliaisMetasListClean: () => ({ type: Types.FILIAIS_METAS_LIST_CLEAN }),
  onFiliaisMetasFormUpdate: (payload) => ({ type: Types.FILIAIS_METAS_FORM_UPDATE, payload }),
  onFiliaisMetasFormClean: () => ({
    type: Types.FILIAIS_METAS_FORM_UPDATE, payload: initialState.meta.form,
  }),
  onFiliaisMetasList: (filter) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onFiliaisMetasLoading({ list: true }))
    // Chamada API
    listFiliaisMetasApi({ token, filter }).then((payload) => {
      dispatch(Actions.onFiliaisMetasLoading({ list: false }))
      dispatch(Actions.onFiliaisMetasListLoad(payload || initialState.meta.list))
    })
  },
  onFiliaisMetasById: (id) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onFiliaisMetasLoading({ find: true }))
    // Chamada API
    getFiliaisMetasApi({ token, id }).then((payload) => {
      dispatch(Actions.onFiliaisMetasLoading({ find: false }))
      dispatch(Actions.onFiliaisMetasFormUpdate(_.isObject(payload)
        ? payload
        : initialState.meta.form))
    })
  },
  onFiliaisMetasSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    // Chamada API
    if (dto.id) {
      dispatch(Actions.onFiliaisMetasLoading({ edit: true }))
      editFiliaisMetasApi({ token, dto, id: dto.id }).then((payload) => {
        dispatch(Actions.onFiliaisMetasLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success('Meta atualizada com sucesso!', 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      }).catch(() => dispatch(Actions.onFiliaisMetasLoading({ edit: false })))
    } else {
      dispatch(Actions.onFiliaisMetasLoading({ add: true }))
      addFiliaisMetasApi({ token, dto }).then((payload) => {
        dispatch(Actions.onFiliaisMetasLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success('Meta cadastrada com sucesso!', 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      }).catch(() => dispatch(Actions.onFiliaisMetasLoading({ add: false })))
    }
  },
  onFiliaisMetasDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onFiliaisMetasLoading({ del: true }))
    // Chamada API
    deleteFiliaisMetasApi({ token, id }).then((payload) => {
      dispatch(Actions.onFiliaisMetasLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Meta excluída com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    })
  },
}
