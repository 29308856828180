import _ from 'lodash'
import { NotificationManager } from 'react-notifications'
import {
  listFiscoRpsLotesApi,
  getFiscoRpsLotesApi,
  addFiscoRpsLotesApi,
  editFiscoRpsLotesApi,
  deleteFiscoRpsLotesApi,
  // Itens
  listFiscoRpsLotesItensApi,
  getFiscoRpsLotesItensApi,
  addFiscoRpsLotesItensApi,
  editFiscoRpsLotesItensApi,
  deleteFiscoRpsLotesItensApi,
} from 'api/fisco/nfse/rps'

// State
const initialState = {
  loading: {
    list: false,
    find: false,
    add: false,
    edit: false,
    del: false,
  },
  list: { page: 0, rows: 0, data: [] },
  form: {
    id: 0,
    filiais: {},
    qtdItens: 0,
    valorTotal: 0,
    sucesso: 0,
    erros: 0,
    itens: [],
  },
  itens: {
    loading: {
      list: false,
      find: false,
      add: false,
      edit: false,
      del: false,
    },
    list: { page: 0, rows: 0, data: [] },
    form: {
      id: 0,
      fiscoRpsLotes: {},
      ordemServico: {},
      nf: '',
      codigoVerificacao: '',
      transmissao: '',
      logTransmisssao: '',
      emailEnviado: '',
    },
  },
}

// Actions Types
export const Types = {
  RESET: 'reset',
  FISCO_NFSE_RPS_LOADING: 'fiscoNfseRps/LOADING',
  FISCO_NFSE_RPS_LIST_LOAD: 'fiscoNfseRps/LIST_LOAD',
  FISCO_NFSE_RPS_LIST_UPDATE: 'fiscoNfseRps/LIST_UPDATE',
  FISCO_NFSE_RPS_LIST_CLEAN: 'fiscoNfseRps/LIST_CLEAN',
  FISCO_NFSE_RPS_FORM_UPDATE: 'fiscoNfseRps/FORM_UPDATE',
}

// Reducers
export default function fiscoNfseRpsReducers(state = initialState, action) {
  switch (action.type) {
  // RESET DEFAULT
  case Types.RESET:
    return {
      ...state,
      ...initialState,
    }
  // RPS
  case Types.FISCO_NFSE_RPS_LOADING:
    return {
      ...state,
      loading: { ...state.loading, ...action.payload },
    }
  case Types.FISCO_NFSE_RPS_LIST_LOAD:
    return {
      ...state,
      list: action.payload,
    }
  case Types.FISCO_NFSE_RPS_LIST_UPDATE:
    return {
      ...state,
      list: state.list.map((i) => (i.id === action.payload.id ? { ...i, ...action.payload } : i)),
    }
  case Types.FISCO_NFSE_RPS_LIST_CLEAN:
    return {
      ...state,
      list: initialState.list,
    }
  case Types.FISCO_NFSE_RPS_FORM_UPDATE:
    return {
      ...state,
      form: { ...state.form, ...action.payload },
    }
  // ITENS
  case Types.FISCO_NFSE_RPS_ITENS_LOADING:
    return {
      ...state,
      itens: {
        ...state.itens,
        loading: { ...state.itens.loading, ...action.payload },
      },
    }
  case Types.FISCO_NFSE_RPS_ITENS_LIST_LOAD:
    return {
      ...state,
      itens: {
        ...state.itens,
        list: action.payload,
      },
    }
  case Types.FISCO_NFSE_RPS_ITENS_LIST_UPDATE:
    return {
      ...state,
      itens: {
        ...state.itens,
        list: state.itens.list.map((i) => (i.id === action.payload.id
          ? { ...i, ...action.payload }
          : i)),
      },
    }
  case Types.FISCO_NFSE_RPS_ITENS_LIST_CLEAN:
    return {
      ...state,
      itens: {
        ...state.itens,
        list: initialState.list,
      },
    }
  case Types.FISCO_NFSE_RPS_ITENS_FORM_UPDATE:
    return {
      ...state,
      itens: {
        ...state.itens,
        form: { ...state.itens.form, ...action.payload },
      },
    }
  default:
    return state
  }
}

// Actions Creators
export const Actions = {
  // Store Update
  onFiscoNfseRpsLoading: (payload) => ({ type: Types.FISCO_NFSE_RPS_LOADING, payload }),
  onFiscoNfseRpsListLoad: (payload) => ({ type: Types.FISCO_NFSE_RPS_LIST_LOAD, payload }),
  onFiscoNfseRpsListUpdate: (payload) => ({ type: Types.FISCO_NFSE_RPS_LIST_UPDATE, payload }),
  onFiscoNfseRpsListClean: () => ({ type: Types.FISCO_NFSE_RPS_LIST_CLEAN }),
  onFiscoNfseRpsFormUpdate: (payload) => ({ type: Types.FISCO_NFSE_RPS_FORM_UPDATE, payload }),
  onFiscoNfseRpsFormClean: () => ({
    type: Types.FISCO_NFSE_RPS_FORM_UPDATE,
    payload: initialState.form,
  }),
  onFiscoNfseRpsDataById: (id) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onFiscoNfseRpsLoading({ find: true }))
    // Chamada API
    getFiscoRpsLotesApi({ token, id }).then((payload) => {
      dispatch(Actions.onFiscoNfseRpsLoading({ find: false }))
      dispatch(Actions.onFiscoNfseRpsFormUpdate(_.isObject(payload) ? payload : initialState.form))
    })
  },
  onFiscoNfseRpsDataList: (filter) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onFiscoNfseRpsLoading({ list: true }))
    // Chamada API
    listFiscoRpsLotesApi({ token, filter }).then((payload) => {
      dispatch(Actions.onFiscoNfseRpsLoading({ list: false }))
      dispatch(Actions.onFiscoNfseRpsListLoad(payload || initialState.list))
    })
  },
  onFiscoNfseRpsDataSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    // Chamada API
    if (dto.id) {
      dispatch(Actions.onFiscoNfseRpsLoading({ edit: true }))
      editFiscoRpsLotesApi({ token, dto, id: dto.id }).then((payload) => {
        dispatch(Actions.onFiscoNfseRpsLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success('Registro atualizado com sucesso!', 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    } else {
      dispatch(Actions.onFiscoNfseRpsLoading({ add: true }))
      addFiscoRpsLotesApi({ token, dto }).then((payload) => {
        dispatch(Actions.onFiscoNfseRpsLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success('Cadastrado com sucesso!', 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    }
  },
  onFiscoNfseRpsDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onFiscoNfseRpsLoading({ del: true }))
    // Chamada API
    deleteFiscoRpsLotesApi({ token, id }).then((payload) => {
      dispatch(Actions.onFiscoNfseRpsLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Registro excluído com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    })
  },
  // Itens
  onFiscoNfseRpsItensLoading: (payload) => ({ type: Types.FISCO_NFSE_RPS_ITENS_LOADING, payload }),
  onFiscoNfseRpsItensListLoad: (payload) => ({
    type: Types.FISCO_NFSE_RPS_ITENS_LIST_LOAD, payload,
  }),
  onFiscoNfseRpsItensListUpdate: (payload) => ({
    type: Types.FISCO_NFSE_RPS_ITENS_LIST_UPDATE, payload,
  }),
  onFiscoNfseRpsItensListClean: () => ({ type: Types.FISCO_NFSE_RPS_ITENS_LIST_CLEAN }),
  onFiscoNfseRpsItensFormUpdate: (payload) => ({
    type: Types.FISCO_NFSE_RPS_ITENS_FORM_UPDATE, payload,
  }),
  onFiscoNfseRpsItensFormClean: () => ({
    type: Types.FISCO_NFSE_RPS_ITENS_FORM_UPDATE,
    payload: initialState.form,
  }),
  onFiscoNfseRpsItensDataById: (id) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onFiscoNfseRpsItensLoading({ find: true }))
    // Chamada API
    getFiscoRpsLotesItensApi({ token, id }).then((payload) => {
      dispatch(Actions.onFiscoNfseRpsItensLoading({ find: false }))
      dispatch(Actions.onFiscoNfseRpsItensFormUpdate(_.isObject(payload)
        ? payload
        : initialState.form))
    })
  },
  onFiscoNfseRpsItensDataList: (filter) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onFiscoNfseRpsItensLoading({ list: true }))
    // Chamada API
    listFiscoRpsLotesItensApi({ token, filter }).then((payload) => {
      dispatch(Actions.onFiscoNfseRpsItensLoading({ list: false }))
      dispatch(Actions.onFiscoNfseRpsItensListLoad(payload || initialState.list))
    })
  },
  onFiscoNfseRpsItensDataSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    // Chamada API
    if (dto.id) {
      dispatch(Actions.onFiscoNfseRpsItensLoading({ edit: true }))
      editFiscoRpsLotesItensApi({ token, dto, id: dto.id }).then((payload) => {
        dispatch(Actions.onFiscoNfseRpsItensLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success('Registro atualizado com sucesso!', 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    } else {
      dispatch(Actions.onFiscoNfseRpsItensLoading({ add: true }))
      addFiscoRpsLotesItensApi({ token, dto }).then((payload) => {
        dispatch(Actions.onFiscoNfseRpsItensLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success('Cadastrado com sucesso!', 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    }
  },
  onFiscoNfseRpsItensDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onFiscoNfseRpsItensLoading({ del: true }))
    // Chamada API
    deleteFiscoRpsLotesItensApi({ token, id }).then((payload) => {
      dispatch(Actions.onFiscoNfseRpsItensLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Registro excluído com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    })
  },
}
