import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog, DialogTitle, DialogContent, DialogActions, IconButton, CircularProgress,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

// Components
import ButtonLoadingComp from 'components/Buttons/ButtonLoading'

// Styles
import useStyles from './styles'

// Stateless
const DialogComp = ({
  open, scroll, title, size, children, actions, onClose, loading,
}) => {
  const classes = useStyles()
  return (
    <Dialog fullWidth scroll={scroll} onClose={onClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={size}>
      <DialogTitle id="customized-dialog-title" className={classes.dialogTitle}>
        {title}
        <IconButton aria-label="close" className={classes.dialogCloseButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {loading ? <CircularProgress color="inherit" size={20} /> : children}
      </DialogContent>
      <DialogActions>
        {actions.map((a) => (
          <ButtonLoadingComp
            key={a.label}
            onClick={a.onClick}
            color={a.color || 'primary'}
            loading={a.loading}
            variant={a.variant || null}
          >
            {a.label}
          </ButtonLoadingComp>
        ))}
      </DialogActions>
    </Dialog>
  )
}

DialogComp.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  scroll: PropTypes.string,
  size: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape()),
  onClose: PropTypes.func.isRequired,
}

DialogComp.defaultProps = {
  scroll: 'paper',
  size: 'sm',
  loading: false,
  actions: [],
}

export default DialogComp
