import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const path = '/tabelaspreco'

export const listTabelasPrecoApi = ({ token, filter }) => {
  const {
    status = '', page = 0, rows = 50, id, filiais, descricao, search,
  } = filter

  let queryUrl = `?status=${status}&page=${page}&rows=${rows}`
  queryUrl = id ? `${queryUrl}&id=${id}` : queryUrl
  queryUrl = filiais ? `${queryUrl}&filiais=${filiais}` : queryUrl
  queryUrl = descricao ? `${queryUrl}&descricao=${descricao}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl

  return api
    .get(path + queryUrl, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getTabelasPrecoApi = ({ token, id }) => api
  .get(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const addTabelasPrecoApi = ({ token, dto }) => api
  .post(path, JSON.stringify(dto), {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then(httpValidState)
  .catch(httpValidErros)

export const editTabelasPrecoApi = ({ token, id, dto }) => api
  .put(`${path}/${id}`, JSON.stringify(dto), {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then(httpValidState)
  .catch(httpValidErros)

export const copyTabelasPrecoApi = ({ token, dto }) => api
  .post(`${path}/copy`, JSON.stringify(dto), {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then(httpValidState)
  .catch(httpValidErros)

export const deleteTabelasPrecoApi = ({ token, id }) => api
  .delete(`${path}/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then(httpValidState)
  .catch(httpValidErros)

export const addTabelasPrecoFiliaisApi = ({ token, dto: { pk, fk } }) => api
  .post(`${path}/${pk}/filiais`, JSON.stringify({ pk, fk }), {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then(httpValidState)
  .catch(httpValidErros)

export const deleteTabelasPrecoFiliaisApi = ({ token, dto: { pk, fk } }) => api
  .delete(`${path}/${pk}/filiais/${fk}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
  .then(httpValidState)
  .catch(httpValidErros)
