import _ from 'lodash'
import { NotificationManager } from 'react-notifications'
import {
  listPagamentosFormaApi,
  getPagamentosFormaApi,
  addPagamentosFormaApi,
  editPagamentosFormaApi,
  deletePagamentosFormaApi,
} from 'api/pagamentos/forma'

// State
const initialState = {
  loading: {
    list: false,
    find: false,
    add: false,
    edit: false,
    del: false,
  },
  list: { page: 0, rows: 0, data: [] },
  form: {
    id: 0,
    codigo: '',
    codigoDescricao: '',
    bandeira: '',
    bandeiraDescricao: '',
    descricao: '',
    status: '',
    statusDescricao: '',
  },
}

// Actions Types
export const Types = {
  RESET: 'reset',
  PAGAMENTOS_FORMA_LOADING: 'pagamentosForma/LOADING',
  PAGAMENTOS_FORMA_LIST_LOAD: 'pagamentosForma/LIST_LOAD',
  PAGAMENTOS_FORMA_LIST_UPDATE: 'pagamentosForma/LIST_UPDATE',
  PAGAMENTOS_FORMA_LIST_CLEAN: 'pagamentosForma/LIST_CLEAN',
  PAGAMENTOS_FORMA_FORM_UPDATE: 'pagamentosForma/FORM_UPDATE',
}

// Reducers
export default function pagamentosFormaReducers(state = initialState, action) {
  switch (action.type) {
  // RESET DEFAULT
  case Types.RESET:
    return {
      ...state,
      ...initialState,
    }
  case Types.PAGAMENTOS_FORMA_LOADING:
    return {
      ...state,
      loading: { ...state.loading, ...action.payload },
    }
  case Types.PAGAMENTOS_FORMA_LIST_LOAD:
    return {
      ...state,
      list: action.payload,
    }
  case Types.PAGAMENTOS_FORMA_LIST_UPDATE:
    return {
      ...state,
      list: state.list.map((i) => (i.id === action.payload.id ? { ...i, ...action.payload } : i)),
    }
  case Types.PAGAMENTOS_FORMA_LIST_CLEAN:
    return {
      ...state,
      list: initialState.list,
    }
  case Types.PAGAMENTOS_FORMA_FORM_UPDATE:
    return {
      ...state,
      form: { ...state.form, ...action.payload },
    }
  default:
    return state
  }
}

// Actions Creators
export const Actions = {
  // Store Update
  onPagamentosFormaLoading: (payload) => ({ type: Types.PAGAMENTOS_FORMA_LOADING, payload }),
  onPagamentosFormaListLoad: (payload) => ({ type: Types.PAGAMENTOS_FORMA_LIST_LOAD, payload }),
  onPagamentosFormaListUpdate: (payload) => ({ type: Types.PAGAMENTOS_FORMA_LIST_UPDATE, payload }),
  onPagamentosFormaListClean: () => ({ type: Types.PAGAMENTOS_FORMA_LIST_CLEAN }),
  onPagamentosFormaFormUpdate: (payload) => ({ type: Types.PAGAMENTOS_FORMA_FORM_UPDATE, payload }),
  onPagamentosFormaFormClean: () => ({
    type: Types.PAGAMENTOS_FORMA_FORM_UPDATE,
    payload: initialState.form,
  }),
  onPagamentosFormaDataById: (id) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onPagamentosFormaLoading({ find: true }))
    // Chamada API
    getPagamentosFormaApi({ token, id }).then((payload) => {
      dispatch(Actions.onPagamentosFormaLoading({ find: false }))
      dispatch(Actions.onPagamentosFormaFormUpdate(
        _.isObject(payload)
          ? payload
          : initialState.form,
      ))
    })
  },
  onPagamentosFormaDataList: (filter = {}) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onPagamentosFormaLoading({ list: true }))
    // Chamada API
    listPagamentosFormaApi({ token, filter }).then((payload) => {
      dispatch(Actions.onPagamentosFormaLoading({ list: false }))
      dispatch(Actions.onPagamentosFormaListLoad(payload || initialState.list))
    })
  },
  onPagamentosFormaDataSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    // Chamada API
    if (dto.id) {
      dispatch(Actions.onPagamentosFormaLoading({ edit: true }))
      editPagamentosFormaApi({ token, dto, id: dto.id }).then((payload) => {
        dispatch(Actions.onPagamentosFormaLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success(`Forma de pagamento ${dto.descricao} atualizado com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    } else {
      dispatch(Actions.onPagamentosFormaLoading({ add: true }))
      addPagamentosFormaApi({ token, dto }).then((payload) => {
        dispatch(Actions.onPagamentosFormaLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success(`Forma de pagamento ${dto.descricao} cadastrado com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    }
  },
  onPagamentosFormaDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onPagamentosFormaLoading({ del: true }))
    // Chamada API
    deletePagamentosFormaApi({ token, id }).then((payload) => {
      dispatch(Actions.onPagamentosFormaLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Forma de pagamento excluído com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    })
  },
}
