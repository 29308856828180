import React from 'react'
import { SvgIcon } from '@material-ui/core'

const IconPorcentagemComp = () => (
  <SvgIcon style={{ marginLeft: 5, marginTop: 5, height: 22 }}>
    <path
      d="M17.217,10.131c-0.64-0.742-1.517-1.113-2.632-1.113c-0.749,0-1.401,0.174-1.957,0.525
      c-0.554,0.352-0.966,0.85-1.23,1.494C11.133,11.68,11,12.365,11,13.089s0.134,1.407,0.398,2.051s0.677,1.142,1.23,1.492
      c0.556,0.351,1.208,0.528,1.957,0.528c1.115,0,1.992-0.373,2.632-1.114s0.961-1.727,0.961-2.957
      C18.178,11.86,17.857,10.87,17.217,10.131z M15.693,15.283c-0.242,0.275-0.596,0.414-1.057,0.414c-0.467,0-0.811-0.139-1.045-0.414
      c-0.372-0.429-0.556-1.117-0.556-2.063c0-0.949,0.184-1.639,0.556-2.065c0.234-0.282,0.578-0.423,1.045-0.423
      c0.461,0,0.814,0.136,1.057,0.411c0.371,0.437,0.557,1.128,0.557,2.077C16.25,14.166,16.064,14.855,15.693,15.283z M6.217,2.131
      C5.577,1.389,4.7,1.018,3.585,1.018c-0.749,0-1.401,0.174-1.957,0.525c-0.554,0.352-0.966,0.85-1.23,1.494
      C0.133,3.68,0,4.365,0,5.089S0.134,6.497,0.398,7.14c0.265,0.644,0.677,1.142,1.23,1.492c0.556,0.351,1.208,0.529,1.957,0.529
      c1.115,0,1.992-0.373,2.632-1.115c0.64-0.741,0.961-1.727,0.961-2.957S6.857,2.87,6.217,2.131z M4.693,7.283
      C4.451,7.558,4.097,7.697,3.636,7.697c-0.467,0-0.811-0.139-1.045-0.414C2.219,6.854,2.035,6.166,2.035,5.22
      c0-0.948,0.184-1.638,0.556-2.065c0.234-0.283,0.578-0.423,1.045-0.423c0.461,0,0.814,0.136,1.057,0.412
      C5.064,3.58,5.25,4.272,5.25,5.22C5.25,6.167,5.064,6.855,4.693,7.283z M14.548,1.09L5.867,17.142H3.723L12.382,1.09H14.548z"
    />
  </SvgIcon>
)

export default IconPorcentagemComp
