import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Icon,
  Grid,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import _ from 'lodash'

import useStyles from './styles'

const FormCaixasAberturaComp = ({
  loading,
  form,
  onChange,
  onClick,
}) => {
  const {
    filiais: {
      id: filiaisId = 0,
      descricao = '',
    },
    funcionarios: {
      id: funcionariosId = 0,
      caixasOperador: { id: caixasOperadorId = 0 },
      nome = '',
    },
    aberturaValor,
    senhaAbertura,
  } = form
  const classes = useStyles()
  return (
    <form>
      <Grid container spacing={2}>
        {/* Filiais */}
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            id="caixas-abertura-filiais"
            size="small"
            type="text"
            margin="dense"
            variant="outlined"
            label="Filial"
            value={filiaisId ? `${filiaisId} - ${descricao}` : ''}
            error={!filiaisId}
            helperText={!filiaisId ? 'Filial inválida!' : ''}
          />
        </Grid>
        {/* Operador de Caixa */}
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            id="caixas-abertura-caixasOperador"
            size="small"
            type="text"
            margin="dense"
            variant="outlined"
            label="Operador de caixa"
            value={caixasOperadorId ? `${caixasOperadorId} - [${funcionariosId}] ${nome}` : ''}
            error={!caixasOperadorId}
            helperText={!caixasOperadorId ? 'Operador inválido!' : ''}
          />
        </Grid>
        {/* Valor de Abertura */}
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            id="caixas-abertura-aberturaValor"
            type="number"
            size="small"
            margin="dense"
            variant="outlined"
            label="Valor de abertura"
            value={aberturaValor}
            onChange={(evt) => onChange({ aberturaValor: evt.target.value })}
            error={_.isNumber(aberturaValor) && aberturaValor <= 0}
            helperText={_.isNumber(aberturaValor) && aberturaValor <= 0 ? 'Valor de abertura inválido!' : ''}
          />
        </Grid>
        {/* Senha do operador de caixa */}
        <Grid item xs={12} md={12}>
          <TextField
            fullWidth
            autoComplete="new-password"
            id="caixas-abertura-senha"
            size="small"
            type="password"
            margin="dense"
            variant="outlined"
            label="Senha"
            value={senhaAbertura}
            onChange={(evt) => onChange({ senhaAbertura: evt.target.value })}
          />
        </Grid>
        {/* Botão de abertura */}
        <Grid item xs={12} md={12}>
          <Grid container justify="flex-end">
            <Grid item>
              <div className={classes.wrapper}>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  startIcon={<Icon>attach_money</Icon>}
                  onClick={onClick}
                >
                  ABRIR
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                    color="secondary"
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

FormCaixasAberturaComp.propTypes = {
  loading: PropTypes.bool.isRequired,
  form: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default FormCaixasAberturaComp
