import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const path = '/clientes'

export const listClientesApi = ({ token, filter }) => {
  const {
    status = '',
    page = 0,
    rows = 50,
    id,
    nome,
    cgc,
    rg,
    email,
    search,
  } = filter

  let queryUrl = `?status=${status}&page=${page}&rows=${rows}`
  queryUrl = id ? `${queryUrl}&id=${id}` : queryUrl
  queryUrl = nome ? `${queryUrl}&nome=${nome}` : queryUrl
  queryUrl = cgc ? `${queryUrl}&cgc=${cgc}` : queryUrl
  queryUrl = rg ? `${queryUrl}&rg=${rg}` : queryUrl
  queryUrl = email ? `${queryUrl}&email=${email}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl

  return api
    .get(path + queryUrl, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getClientesApi = ({ token, id }) =>
  api
    .get(`${path}/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
      timeout: 0,
    })
    .then(httpValidState)
    .catch(httpValidErros)

export const addClientesApi = ({ token, dto }) =>
  api
    .post(path, JSON.stringify(dto), {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(httpValidState)
    .catch(httpValidErros)

export const addClientesSimplesApi = ({ token, dto }) =>
  api
    .post(`${path}/simples`, JSON.stringify(dto), {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(httpValidState)
    .catch(httpValidErros)

export const editClientesApi = ({ token, id, dto }) =>
  api
    .put(`${path}/${id}`, JSON.stringify(dto), {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(httpValidState)
    .catch(httpValidErros)

export const editClientesPathApi = ({ token, id, clientesPath, dto }) =>
  api
    .put(`${path}/${id}/${clientesPath}`, JSON.stringify(dto), {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(httpValidState)
    .catch(httpValidErros)

export const deleteClientesApi = ({ token, id }) =>
  api
    .delete(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)

export const getClientesHistoricoApi = ({ token, id = 0, placa = '' }) =>
  api
    .get(`${path}/${id}/historico?placa=${placa}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(httpValidState)
    .catch(httpValidErros)
