import React from 'react'
import moment from 'moment'
import numeral from 'numeral'
import { NotificationManager } from 'react-notifications'
import _ from 'lodash'
import api, { API_URL } from 'api'
import { getOrdemServicoImpressaoApi } from 'api/ordemservico'

export const maskCGC = cgc => {
  if (cgc && cgc.length === 11) {
    return cgc.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  }
  if (cgc && cgc.length === 14) {
    return cgc.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  }
  return null
}
export const validarCPF = pCpf => {
  const cpf = pCpf.replace(/[^\d]+/g, '')
  if (cpf === '') return false
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    // || cpf === '00000000000'
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false
  // Valida 1o digito
  let add = 0
  for (let i = 0; i < 9; i += 1) add += parseInt(cpf.charAt(i), 10) * (10 - i)
  let rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(cpf.charAt(9), 10)) return false
  // Valida 2o digito
  add = 0
  for (let i = 0; i < 10; i += 1) add += parseInt(cpf.charAt(i), 10) * (11 - i)
  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) rev = 0
  if (rev !== parseInt(cpf.charAt(10), 10)) return false
  return true
}
export const validarCNPJ = pCnpj => {
  const cnpj = pCnpj.replace(/[^\d]+/g, '')
  if (cnpj === '') return false

  if (cnpj.length !== 14) return false

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj.length !== 14 ||
    // cnpj === '00000000000000'
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return false

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  const digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i -= 1) {
    soma += numeros.charAt(tamanho - i) * (pos -= 1)
    if (pos < 2) pos = 9
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado !== digitos.charAt(0)) return false

  tamanho += 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i -= 1) {
    soma += numeros.charAt(tamanho - i) * (pos -= 1)
    if (pos < 2) pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado !== digitos.charAt(1)) return false

  return true
}

export const downloadBlobFile = (blob, type, fileName) => {
  // window.open(URL.createObjectURL(blob), fileName, '_blank')
  // URL.revokeObjectURL(blob)
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = URL.createObjectURL(
    new Blob([blob], {
      type,
    })
  )
  a.download = fileName
  a.click()
}

export const downloadLinkFile = (link, fileName) => {
  window.open(link, fileName, '_blank')
  URL.revokeObjectURL(link)
}

export const downloadStringToFile = (stringData, filename) => {
  const pom = document.createElement('a')
  const bb = new Blob([stringData], { type: 'text/plain' })
  pom.setAttribute('href', window.URL.createObjectURL(bb))
  pom.setAttribute('download', filename)
  pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':')
  pom.draggable = true
  pom.classList.add('dragout')
  pom.click()
}

export const downloadExcelFile = (blob, fileName) => {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = URL.createObjectURL(
    new Blob([blob], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }),
  )
  a.download = fileName
  a.click()
}

export const DateIsoNow = () => moment().format('YYYY-MM-DD')
export const DateTimeIsoNow = () => moment().format('YYYY-MM-DD HH:mm:ss')
export const DateBrNow = () => moment().format('DD/MM/YYYY')
export const YearNow = () => moment().format('YYYY')
export const TimeNow = () => moment().format('HH:mm')
export const TimeNowAddHrs = hrs =>
  moment()
    .add(hrs, 'h')
    .format('HH:mm')
export const ParseIptDateTimeToIso = iptValue =>
  moment(iptValue, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD HH:mm:ss')
export const ParseIsoToIsoTimeTimeZone = iptValue =>
  moment(iptValue, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss')
export const DateIsoToBr = dateIso =>
  moment(dateIso, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')
export const DateIsoToBrFormat = (dateIso, format) =>
  moment(dateIso, 'YYYY-MM-DD HH:mm:ss').format(format)
export const DateIsoToDateOnly = dateIso =>
  moment(dateIso, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
export const DateIsoToDateBrOnly = dateIso =>
  moment(dateIso, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
export const DateIsoToHours = dateIso =>
  moment(dateIso, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
export const DateIsoDiff = (dateIsoStart, dateIsoEnd, typeDiff) =>
  moment(dateIsoEnd, 'YYYY-MM-DD HH:mm:ss').diff(moment(dateIsoStart), typeDiff)
export const DateBrToIso = dateTimeBR =>
  moment(dateTimeBR, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
export const DateBrToIsoFormat = (dateTimeBR, format) =>
  moment(dateTimeBR, 'DD/MM/YYYY HH:mm:ss').format(format)
export const StringDateIsoToDate = stringDate => moment(stringDate)
export const StringDateBrToDate = stringDate => moment(stringDate, 'DD-MM-YYYY')

export const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}

export const digitsOnly = value => value.replace(/\D/g, '')
export const MoneyFormatBr = money => numeral(money).format('0,0.00')
export const parseNumber = pValor => {
  let retorno
  const valor = String(pValor)
  const qtdDot = valor.split(',').length
  try {
    if (qtdDot > 1) {
      retorno = Number(
        valor
          .trim()
          .replace(/\./g, '')
          .replace(/ /g, '')
          .replace(',', '.')
      )
    } else {
      retorno = Number(valor)
    }
  } catch (err) {
    retorno = Number(valor)
  }
  return retorno
}
export const parseToDec = n => parseNumber(n) * 100
export const parseToNum = n => parseNumber(n) / 100.0

export const padLeft = (nr, n, str) =>
  Array(n - String(nr).length + 1).join(str || '0') + nr
export const textStrong = text => <strong>{text}</strong>

export const mesByNum = nNum => {
  switch (nNum) {
    case 1:
      return 'Janeiro'
    case 2:
      return 'Fevereiro'
    case 3:
      return 'Março'
    case 4:
      return 'Abril'
    case 5:
      return 'Maio'
    case 6:
      return 'Junho'
    case 7:
      return 'Julho'
    case 8:
      return 'Agosto'
    case 9:
      return 'Setembro'
    case 10:
      return 'Outubro'
    case 11:
      return 'Novembro'
    case 12:
      return 'Dezembro'
    default:
      return 'Mês inválido'
  }
}

export const getSemestre = () => {
  const anoAtual = moment().years()
  const mesAtual = moment().month()
  // Primeiro semestre
  if (mesAtual <= 5) {
    return {
      dataInicial: `${anoAtual}-01-01`,
      dataFinal: `${anoAtual}-06-30`,
    }
  }
  // Segundo semestre
  return {
    dataInicial: `${anoAtual}-07-01`,
    dataFinal: `${anoAtual}-12-31`,
  }
}

export const handleImprimirOrdemServico = async (
  token,
  id,
  onSucess = null,
  onError = null
) => {
  const dataPrint = await getOrdemServicoImpressaoApi({ token, id })
  let wsConn = null
  if (dataPrint) {
    wsConn = new WebSocket('ws://127.0.0.1:9000')
    wsConn.onopen = () => {
      console.log('Iniciando abertura do WebSocket com a impressora...')
      wsConn.send(JSON.stringify(dataPrint))
    }
    wsConn.onmessage = evt => {
      const retorno = JSON.parse(evt.data)
      switch (retorno.msg) {
        case 'connected':
          // NAO FAZ NADA
          console.log('Conectado na impressora com sucesso!')
          break
        case 'printed':
          console.log('A impressora respondeu que imprimiu com sucesso!')
          NotificationManager.success(
            `O.S Nº ${id} enviada para impressão com sucesso`,
            'Sucesso!',
            8000
          )
          if (_.isFunction(onSucess)) {
            onSucess()
          }
          break
        case 'error':
          console.log('A impressora respondeu que NÃO imprimiu!')
          NotificationManager.error(
            `Não foi possível imprimir a O.S Nº ${id}.`,
            'Atenção!',
            8000
          )
          if (_.isFunction(onError)) {
            onError()
          }
          break
        default:
          break
      }
      // Fecha a conexão...
      if (wsConn) {
        console.log('Fechando a conexão com a impressora!')
        wsConn.close()
      }
    }
    wsConn.onclose = e => {
      console.log('Conexao fechada. ', e.reason ? `Motivo: ${e}` : '')
    }
    wsConn.onerror = err => {
      console.log('WS: onError', err)
      if (err.type === 'error') {
        NotificationManager.warning(
          'Erro ao conectar na impressora',
          'Atenção!',
          8000
        )
      } else {
        NotificationManager.error(`Ocorreu: ${err.data}.`, 'Atenção!', 8000)
      }
      if (_.isFunction(onError)) {
        onError()
      }
      wsConn.close()
    }
  }
}

export const UFs = [
  { AC: 'Acre' },
  { AL: 'Alagoas' },
  { AP: 'Amapá' },
  { AM: 'Amazonas' },
  { BA: 'Bahia' },
  { CE: 'Ceará' },
  { DF: 'Distrito Federal' },
  { ES: 'Espírito Santo' },
  { GO: 'Goías' },
  { MA: 'Maranhão' },
  { MT: 'Mato Grosso' },
  { MS: 'Mato Grosso do Sul' },
  { MG: 'Minas Gerais' },
  { PA: 'Pará' },
  { PB: 'Paraíba' },
  { PR: 'Paraná' },
  { PE: 'Pernambuco' },
  { PI: 'Piauí' },
  { RJ: 'Rio de Janeiro' },
  { RN: 'Rio Grande do Norte' },
  { RS: 'Rio Grande do Sul' },
  { RO: 'Rondônia' },
  { RR: 'Roraíma' },
  { SC: 'Santa Catarina' },
  { SP: 'São Paulo' },
  { SE: 'Sergipe' },
  { TO: 'Tocantins' },
]

export const listMeses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]

export const validarPlaca = placa => {
  const regexPlaca = /^[a-zA-Z]{3}[0-9]{4}$/
  const regexPlacaMercosulCarro = /^[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}[0-9]{2}$/
  const regexPlacaMercosulMoto = /^[a-zA-Z]{3}[0-9]{2}[a-zA-Z]{1}[0-9]{1}$/
  let qtdErros = 0

  if (!regexPlaca.test(placa)) {
    qtdErros += 1
  }
  if (!regexPlacaMercosulCarro.test(placa)) {
    qtdErros += 1
  }
  if (!regexPlacaMercosulMoto.test(placa)) {
    qtdErros += 1
  }
  return qtdErros < 3
}

export const calculateBusinessDays = (d1, d2) => {
  const days = d1 && d1 ? d2.diff(d1, 'days') + 1 : 0
  let newDay = d1.toDate()
  let workingDays = 0
  let sundays = 0
  let saturdays = 0
  for (let i = 0; i < days; i += 1) {
    const day = newDay.getDay()
    const isWeekend = day % 6 === 0
    if (!isWeekend) {
      workingDays += 1
    } else {
      if (day === 6) saturdays += 1
      if (day === 0) sundays += 1
    }
    // Incrementa
    newDay = d1.add(1, 'days').toDate()
  }
  return {
    days,
    workingDays,
    sundays,
    saturdays,
  }
}

export const checkUrlImage = async url =>
  api
    .get(url)
    .then(() => true)
    .catch(() => false)

export const getPublicImage = (descricao = null, pDefault = null) =>
  new Promise(resolve => {
    const img = new Image()
    img.src = `${API_URL}/public/media/images/${descricao}`
    img.onload = () => resolve(`${API_URL}/public/media/images/${descricao}`)
    img.onerror = () => resolve(pDefault)
  })

export const hasRole = (pUserRoles = [], pRole = '') =>
  pUserRoles.find(r => r.codigo === pRole) ? true : false

export const getThumbnailProdutos = produtos => {
  const produtosImagens =
    produtos?.imagens?.find(i => i.type === 'thumbnail') ?? ''
  if (produtosImagens) {
    return produtosImagens?.uri
  }
  return ''
}

export const isCelularMask = (pNumero = '') => {
  if (!pNumero) return false
  const numero = String(digitsOnly(pNumero))
  console.log(`Numero: ${numero}`)
  const size = numero.length
  let isBr = false
  let isCelular = false
  if (size >= 2) {
    isBr = numero.substring(0, 2) == "55"
  }
  if ((size >= 5 && isBr) || (size >= 3 && !isBr)) {
    if (isBr) {
      isCelular = numero.substring(4, 5) == "9"
    } else {
      isCelular = numero.substring(2, 3) == "9"
    }
  }
  return isCelular
}
