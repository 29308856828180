import React, { useEffect, useRef, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  IconButton,
  Icon,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import _ from 'lodash'

// Util's
import {
  parseToNum,
  parseToDec,
  MoneyFormatBr,
  handleImprimirOrdemServico,
} from 'utils'

// Actions
import { Actions as OrdemServicoActions } from 'store/ducks/ordemservico'
import { Actions as CaixasActions } from 'store/ducks/caixas'
import { Actions as PagamentosFormaActions } from 'store/ducks/pagamentos/forma'
import { Actions as ParametrosActions } from 'store/ducks/parametros'

// Components
import TableDataComp from 'components/Table'
import ButtonLoadingComp from 'components/Buttons/ButtonLoading'

// Styles
import useStyles from './styles'

const FormCaixasMovimentoComp = ({
  session,
  item,
  caixas,
  pagamentosForma,
  onReload,
  onPagamentosFormaDataList,
  onCaixasAbertoData,
  onCaixasMovimentosDataSave,
  onParametrosGetByNome,
}) => {
  const classes = useStyles()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  const [formaPagamento, setFormaPagamento] = useState('')
  const [condicaoPagamento, setCondicaoPagamento] = useState('')
  const [isCortesia, setCortesia] = useState(false)
  const [valor, setValor] = useState('')
  const [pagamentos, setPagamentos] = useState([])
  const [loadingFinalizarPgto, setLoadingFinalizarPgto] = useState(false)
  const [kPrintTicketAfterPayment, setPrintTicketAfterPayment] = useState(false)
  // const aClientName = item && item.clientesNome ? item.clientesNome.split(' ') : ['', '']
  const aClientName = item?.clientesNome?.split(' ') ?? ['', '']
  const clienteFirstName = aClientName[0]
  const clienteLastName =
    aClientName.length > 1 ? aClientName[aClientName.length - 1] : ''
  const listFormasPgto = _.orderBy(pagamentosForma.list.data, ['id'], ['asc'])
  const listCondicoesPgto = formaPagamento
    ? _.orderBy(
        pagamentosForma.list.data.find(f => f.id === formaPagamento).condicoes,
        ['id'],
        ['asc']
      )
    : []
  const totalPagamentos = Math.round(
    pagamentos.reduce((a, b) => a + b.valor, 0)
  )
  const saldo = item.totalLiquido - totalPagamentos
  const hasSaldo = saldo > 0
  const hasPagamentos = _.isArray(pagamentos) && pagamentos.length > 0

  const onLoadParameters = () => {
    const pPrint = onParametrosGetByNome('IMPRIME_TICKE_PAGAMENTO', 'NAO')
    setPrintTicketAfterPayment(pPrint.toUpperCase() === 'SIM')
  }

  const handleBloqueiaAddPagamento = () => {
    // Cortesia
    if (formaPagamento && isCortesia && !hasPagamentos) {
      return false
    }

    if (formaPagamento && !isCortesia && hasSaldo) {
      return false
    }

    return true
  }
  const handleBloqueiaFinalizarPagamento = () => {
    // Cortesia
    if (isCortesia && hasPagamentos && !loadingFinalizarPgto) {
      return false
    }
    // Para os resto
    if (!isCortesia && hasPagamentos && !hasSaldo && !loadingFinalizarPgto) {
      return false
    }

    return true
  }
  const handleAddPagamento = () => {
    let forma = null
    let condicao = null

    if (!formaPagamento) {
      NotificationManager.warning(
        'Você deve selecionar uma forma de pagamento.',
        'Atenção!',
        8000
      )
      return
    }

    if (!condicaoPagamento) {
      NotificationManager.warning(
        'Você deve selecionar uma condição de pagamento.',
        'Atenção!',
        8000
      )
      return
    }

    if (!valor) {
      NotificationManager.warning(
        'Você deve informar um valor para realizar o pagamento.',
        'Atenção!',
        8000
      )
      return
    }

    if (
      (valor && formaPagamento && condicaoPagamento) ||
      (totalPagamentos === 0 && valor === 0)
    ) {
      forma = listFormasPgto.find(f => f.id === formaPagamento)
      condicao = listCondicoesPgto.find(c => c.id === condicaoPagamento)
      // Adicionando pagamento
      setPagamentos(s => [
        ...s,
        {
          item: pagamentos.length + 1,
          pagamentosCondicaoId: condicao.id,
          descricao: `${forma.descricao}, ${condicao.descricao}`,
          valor: Math.round(parseToDec(valor)),
        },
      ])
    }
  }
  const handleDelPagamento = pItem =>
    setPagamentos(s => s.filter(i => i.item !== pItem))
  const handleFinalizarPagamento = () => {
    setLoadingFinalizarPgto(true)
    const dto = pagamentos.map(p => ({
      caixasId: caixas.aberto.id,
      caixasMovimentosTiposId: 1,
      caixasMovimentosOrigensId: 1,
      caixasOperadorId: caixas.aberto.caixasOperadorId,
      pagamentosCondicaoId: p.pagamentosCondicaoId,
      referencias: item.id,
      valor: p.valor,
      troco: 0,
      descontoReais: 0,
      descontoPorcentagem: 0,
    }))
    onCaixasMovimentosDataSave(
      dto,
      () => {
        onReload()
        onCaixasAbertoData()
        setLoadingFinalizarPgto(false)
        // Imprimir Ticket
        if (kPrintTicketAfterPayment) {
          handleImprimirOrdemServico(session.token, item.id)
        }
      },
      () => {
        setLoadingFinalizarPgto(false)
      }
    )
  }

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
    onPagamentosFormaDataList()
    onLoadParameters()
  }, [])
  useEffect(() => {
    setValor(MoneyFormatBr(parseToNum(saldo)))
  }, [saldo])
  useEffect(() => {
    if (formaPagamento) {
      const pgtoCortesia = listFormasPgto.find(f => f.id === formaPagamento)
      setCortesia(pgtoCortesia.codigo === 'CO')
    }
  }, [formaPagamento])

  return (
    <Grid container spacing={2}>
      {/* Tabelas */}
      <Grid item xs={8} md={8}>
        {/* Itens (Serviços) */}
        <Typography variant="h6" gutterBottom>
          Item(ns)
        </Typography>
        <TableDataComp
          loading={false}
          size="small"
          rows={item.itens}
          cols={[
            {
              key: 'tabelasPrecoItensServicosDescricao',
              title: 'DESCRIÇÃO',
            },
            {
              key: 'desconto',
              title: 'DESCONTO',
              align: 'right',
              format: desconto => MoneyFormatBr(parseToNum(desconto)),
            },
            {
              key: 'preco',
              title: 'VALOR',
              align: 'right',
              format: v => `R$ ${MoneyFormatBr(parseToNum(v))}`,
            },
          ]}
        />
        {/* PAGAMENTOS */}
        <Typography
          variant="h6"
          gutterBottom
          className={classes.gridPagamentos}
        >
          Pagamentos
        </Typography>
        <TableDataComp
          keyList="item"
          loading={false}
          size="small"
          rows={pagamentos}
          cols={[
            { key: 'descricao', title: 'DESCRIÇÃO' },
            {
              key: 'valor',
              title: 'VALOR',
              align: 'right',
              format: v => `R$ ${MoneyFormatBr(parseToNum(v))}`,
            },
            {
              key: 'item',
              title: '',
              align: 'right',
              format: i => (
                <IconButton
                  onClick={() => handleDelPagamento(i)}
                  size="small"
                  color="secondary"
                >
                  <Icon>delete</Icon>
                </IconButton>
              ),
            },
          ]}
        />
      </Grid>
      {/* Ações */}
      <Grid item xs={4} md={4}>
        <Grid container spacing={1} justify="center" alignItems="center">
          {/* TOTAL, CLIENTE, VEÍCULO */}
          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="h6">Total:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {`R$ ${MoneyFormatBr(parseToNum(item.totalLiquido))}`}
                </Typography>
              </Grid>
            </Grid>
            <hr />
            {clienteFirstName && (
              <div>
                <strong>CLIENTE: </strong>
                {`${clienteFirstName} ${clienteLastName}`}
                <br />
              </div>
            )}
            {item.veiculosPlaca && (
              <div>
                <strong>VEÍCULO: </strong>
                {`${item.veiculosModelo}/${item.veiculosPlaca}/${item.veiculosCor}`}
              </div>
            )}
            <hr />
          </Grid>
          {/* PAGAMENTOS */}
          <Grid item xs={12}>
            <Typography variant="button" className={classes.tituloPagamentos}>
              Adicionar pagamentos
            </Typography>
          </Grid>
          {/* FORMA DE PAGAMENTO */}
          <Grid item xs={12}>
            <FormControl
              fullWidth
              margin="dense"
              variant="outlined"
              disabled={pagamentosForma.loading.list}
            >
              <InputLabel
                ref={inputLabel}
                id="caixas-movimentos-pagamentos-formas-label"
              >
                {pagamentosForma.loading.list
                  ? 'Carregando...'
                  : 'Forma de pagamento'}
              </InputLabel>
              <Select
                fullWidth
                labelId="caixas-movimentos-pagamentos-formas-label"
                id="caixas-movimentos-pagamentos-formas-select"
                size="small"
                margin="dense"
                value={formaPagamento}
                onChange={evt => setFormaPagamento(evt.target.value)}
                labelWidth={labelWidth}
              >
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>
                {listFormasPgto.map(f => (
                  <MenuItem
                    key={f.id}
                    value={f.id}
                    // disabled={f.codigo === 'CO' && hasSaldo}
                  >
                    {f.descricao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* CONDIÇÃO DE PAGAMENTO */}
          <Grid item xs={12}>
            <FormControl
              fullWidth
              margin="dense"
              variant="outlined"
              disabled={pagamentosForma.loading.list}
            >
              <InputLabel
                ref={inputLabel}
                id="caixas-movimentos-pagamentos-condicao-label"
              >
                {pagamentosForma.loading.list
                  ? 'Carregando...'
                  : 'Condição de pagamento'}
              </InputLabel>
              <Select
                fullWidth
                labelId="caixas-movimentos-pagamentos-condicao-label"
                id="caixas-movimentos-pagamentos-condicao-select"
                size="small"
                margin="dense"
                value={condicaoPagamento}
                onChange={evt => setCondicaoPagamento(evt.target.value)}
                labelWidth={labelWidth}
              >
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>
                {listCondicoesPgto.map(f => (
                  <MenuItem key={f.id} value={f.id}>
                    {f.descricao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* SALDO e TROCO */}
          <Grid item xs={12} md={12}>
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="h6">Saldo</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {`R$ ${MoneyFormatBr(parseToNum(saldo))}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography variant="h6">Troco</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">
                    {`R$ ${MoneyFormatBr(parseToNum(0))}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* VALOR / ADD NO PAGAMENTO */}
          <Grid item xs={12} md={12}>
            <FormControl
              disabled={!hasSaldo}
              variant="outlined"
              fullWidth
              margin="dense"
            >
              <InputLabel htmlFor="outlined-adornment-valor">
                Valor R$
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-valor"
                type="text"
                value={valor}
                disabled={handleBloqueiaAddPagamento()}
                onChange={evt => setValor(evt.target.value)}
                onKeyPress={evt => {
                  if (evt.key.toUpperCase() === 'ENTER') {
                    handleAddPagamento()
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Adicionar novo pagamento"
                      size="small"
                      color="secondary"
                      onClick={handleAddPagamento}
                      edge="end"
                      disabled={handleBloqueiaAddPagamento()}
                    >
                      <Icon>add</Icon>
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          </Grid>
          {/* BOTÃO FINALIZAR */}
          <Grid item xs={12} md={12}>
            <ButtonLoadingComp
              fullWidth
              variant="contained"
              color="primary"
              disabled={handleBloqueiaFinalizarPagamento()}
              onClick={handleFinalizarPagamento}
              loading={loadingFinalizarPgto}
            >
              Finalizar
            </ButtonLoadingComp>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              size="small"
              control={
                <Checkbox
                  checked={kPrintTicketAfterPayment}
                  onChange={() =>
                    setPrintTicketAfterPayment(!kPrintTicketAfterPayment)
                  }
                  name="printTicketAfterPayment"
                />
              }
              label="Imprimir ticker após pagamento?"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

FormCaixasMovimentoComp.propTypes = {
  session: PropTypes.shape().isRequired,
  item: PropTypes.shape().isRequired,
  caixas: PropTypes.shape().isRequired,
  pagamentosForma: PropTypes.shape().isRequired,
  onReload: PropTypes.func.isRequired,
  onPagamentosFormaDataList: PropTypes.func.isRequired,
  onCaixasAbertoData: PropTypes.func.isRequired,
  onCaixasMovimentosDataSave: PropTypes.func.isRequired,
  onParametrosGetByNome: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  session: state.auth.session,
  ordemServico: state.ordemServico,
  caixas: state.caixas,
  pagamentosForma: state.pagamentosForma,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CaixasActions,
      ...OrdemServicoActions,
      ...PagamentosFormaActions,
      ...ParametrosActions,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormCaixasMovimentoComp)
