import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import {
  // Button,
  Icon,
  Tooltip,
} from '@material-ui/core'

// Actions
import { Actions as CaixasActions } from 'store/ducks/caixas'

// Util's
import { parseToNum, parseToDec, MoneyFormatBr } from 'utils'

// Componentes
import DialogComp from 'components/Dialogs'
import FormCaixasAberturaComp from 'components/Forms/Caixas/Abertura'
import FormCaixasFechamentoComp from 'components/Forms/Caixas/Fechamento'
import ButtonLoadingComp from 'components/Buttons/ButtonLoading'

import useStyles from './styles'

const CaixasAberturaFechamentoComp = ({
  session,
  caixas,
  onCaixasAbertoData,
  onCaixasFormUpdate,
  onCaixasFormClean,
  onCaixasDataSave,
  onCaixasFechamentosFormUpdate,
  onCaixasFechamentosFormClean,
  onCaixasFechamentosDataSave,
}) => {
  const classes = useStyles()
  const [caixa, setCaixa] = useState({
    isOperador: true,
    filiais: {},
    funcionarios: {},
    dialogAbertura: false,
    dialogFechamento: false,
    status: 'F',
    movimentos: 0,
  })
  const handleOpenDgAbertura = (dialogAbertura = true) => {
    if (dialogAbertura) {
      onCaixasFormClean()
      onCaixasFormUpdate({
        filiais: caixa.filiais,
        funcionarios: caixa.funcionarios,
        aberturaValor: '',
        senhaAbertura: '',
      })
    }
    setCaixa((s) => ({ ...s, dialogAbertura }))
  }
  const handleOpenDgFechamento = (dialogFechamento = true) => {
    if (dialogFechamento) {
      onCaixasFechamentosFormClean()
      onCaixasFechamentosFormUpdate({
        filiais: caixa.filiais,
        funcionarios: caixa.funcionarios,
        valorAbertura: '',
        valorSangria: '',
        valorDinheiro: '',
        valorCheque: '',
        valorCartaoCredito: '',
        valorCartaoDebito: '',
        valorFaturado: '',
        valorPix: '',
        valorCortesia: '',
      })
    }
    setCaixa((s) => ({ ...s, dialogFechamento }))
  }
  const handleIsOperadorCaixa = (isOperador) => setCaixa((s) => ({ ...s, isOperador }))
  const handleUpdateStatusCaixa = (status) => setCaixa((s) => ({ ...s, status }))
  // const handleUpdateMovimentosCaixa = (movimentos) => setCaixa((s) => ({ ...s, movimentos }))
  const handleAbrirCaixasAction = () => {
    const {
      form: {
        filiais,
        funcionarios,
        aberturaValor,
        senhaAbertura,
      },
    } = caixas
    const dto = {
      filiaisId: filiais.id,
      caixasOperadorId: funcionarios.caixasOperador.id,
      aberturaValor: parseToDec(aberturaValor),
      senhaAbertura,
    }
    onCaixasDataSave(dto, () => {
      handleOpenDgAbertura(false)
      onCaixasAbertoData()
    })
  }
  const handleFecharCaixasAction = () => {
    const {
      aberto: {
        id: caixasId,
      },
      fechamentos: {
        form: {
          valorAbertura,
          valorSangria,
          valorDinheiro,
          valorCheque,
          valorCartaoCredito,
          valorCartaoDebito,
          valorFaturado,
          valorCortesia,
          valorPix,
          observacao,
          senhaAbertura,
        },
      },
    } = caixas
    const dto = {
      caixasId,
      valorAbertura: parseToDec(valorAbertura),
      valorSangria: parseToDec(valorSangria),
      valorDinheiro: parseToDec(valorDinheiro),
      valorCheque: parseToDec(valorCheque),
      valorCartaoCredito: parseToDec(valorCartaoCredito),
      valorCartaoDebito: parseToDec(valorCartaoDebito),
      valorFaturado: parseToDec(valorFaturado),
      valorCortesia: parseToDec(valorCortesia),
      valorPix: parseToDec(valorPix),
      observacao,
      senhaAbertura,
    }
    onCaixasFechamentosDataSave(dto, () => {
      handleOpenDgFechamento(false)
      onCaixasAbertoData()
    })
  }

  useEffect(() => {
    const { user: { funcionarios = {}, filial = '', filiais = [] } } = session || {}
    const isOperador = funcionarios.caixasOperador && funcionarios.caixasOperador.id
    handleIsOperadorCaixa(isOperador)
    if (isOperador) {
      setCaixa((s) => ({
        ...s,
        filiais: filiais.find((f) => f.id === filial),
        funcionarios,
      }))
      // Verificando status do caixa...
      onCaixasAbertoData()
    }
  }, [session?.user?.filial])

  useEffect(() => {
    handleUpdateStatusCaixa(caixas.aberto.status)
  }, [caixas?.aberto?.status])

  return caixa.isOperador ? (
    <>
      {caixa.status === 'A' && (
        <Tooltip title={`MOVIMENTOS: R$ ${MoneyFormatBr(parseToNum(caixas.aberto.movimentos))}`}>
          <span>
            <ButtonLoadingComp
              loading={false || (caixas?.loading?.aberto ?? false)}
              variant="contained"
              color="secondary"
              className={[classes.btnVermelho, classes.menuButton].join(' ')}
              startIcon={<Icon>money_off</Icon>}
              onClick={caixas.loading.aberto ? null : handleOpenDgFechamento}
            >
              FECHAR CAIXA
            </ButtonLoadingComp>
          </span>
        </Tooltip>
      )}
      {caixa.status === 'F' && (
        <ButtonLoadingComp
          loading={false || (caixas?.loading?.aberto ?? false)}
          variant="contained"
          color="secondary"
          className={[classes.btnVerde, classes.menuButton].join(' ')}
          startIcon={<Icon>attach_money</Icon>}
          onClick={caixas.loading.aberto ? null : handleOpenDgAbertura}
        >
          ABRIR CAIXA
        </ButtonLoadingComp>
      )}
      {/* Dialog abertura de caixa */}
      <DialogComp
        scroll="body"
        size="sm"
        open={caixa.dialogAbertura}
        title="Abertura de caixa"
        onClose={() => {
          handleOpenDgAbertura(false)
        }}
      >
        <FormCaixasAberturaComp
          loading={caixas.loading.add}
          form={caixas.form}
          onChange={onCaixasFormUpdate}
          onClick={handleAbrirCaixasAction}
        />
      </DialogComp>
      {/* Dialog fechamento de caixa */}
      <DialogComp
        scroll="body"
        size="sm"
        open={caixa.dialogFechamento}
        title={`Fechamento de caixa: ${caixas.aberto.id}`}
        onClose={() => {
          handleOpenDgFechamento(false)
        }}
      >
        <FormCaixasFechamentoComp
          loading={caixas.fechamentos.loading.add}
          form={caixas.fechamentos.form}
          onChange={onCaixasFechamentosFormUpdate}
          onClick={handleFecharCaixasAction}
        />
      </DialogComp>
    </>
  ) : null
}

CaixasAberturaFechamentoComp.propTypes = {
  session: PropTypes.shape().isRequired,
  caixas: PropTypes.shape().isRequired,
  onCaixasAbertoData: PropTypes.func.isRequired,
  onCaixasFormUpdate: PropTypes.func.isRequired,
  onCaixasFormClean: PropTypes.func.isRequired,
  onCaixasDataSave: PropTypes.func.isRequired,
  onCaixasFechamentosFormUpdate: PropTypes.func.isRequired,
  onCaixasFechamentosFormClean: PropTypes.func.isRequired,
  onCaixasFechamentosDataSave: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
  caixas: state.caixas,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...CaixasActions,
  },
  dispatch,
)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CaixasAberturaFechamentoComp))
