import React, { useEffect, useState, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  Grid, FormControl, InputLabel, Select, MenuItem, TextField, Typography,
  Card, CardContent, CardActions,
} from '@material-ui/core'
import { NotificationManager } from 'react-notifications'

// Api's
import { listFuncionariosAtendentesApi } from 'api/funcionarios'
import { listTabelasPrecoApi } from 'api/tabelaspreco'
import { relatServicos } from 'api/relatorios'

// Actions
import { Actions as PagamentosFormaActions } from 'store/ducks/pagamentos/forma'

// Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import ButtonLoadingComp from 'components/Buttons/ButtonLoading'

// Styles
// import useStyles from './styles'

// Stateless component
const RelatoriosServicosPage = ({
  session,
}) => {
  // const classes = useStyles()
  const inputLabel = useRef(null)
  const inputLabelServicos = useRef(null)
  const inputLabelAtendentes = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  const [labelWidthServicos, setLabelWidthServicos] = useState(0)
  const [labelWidthAtendentes, setLabelWidthAtendentes] = useState(0)
  const {
    user,
    token,
  } = session

  // Form
  const [loading, setLoading] = useState(false)
  const [filiais, setFiliais] = useState(session.user.filial)
  const [dataInicial, setDataInicial] = useState('')
  const [dataFinal, setDataFinal] = useState('')
  const [servicos, setServicos] = useState([])
  const [servicosOpen, setServicosOpen] = useState(false)
  const [funcionarios, setFuncionarios] = useState([])
  const [servicosFiliais, setServicosFiliais] = useState({
    loading: false,
    servicos: [],
  })
  const [funcionariosFiliais, setFuncionariosFiliais] = useState({
    loading: false,
    funcionarios: [],
  })
  const [funcionariosFiliaisOpen, setFuncionariosFiliaisOpen] = useState(false)
  // const listFormasPgto = _.orderBy(pagamentosForma.list.data, ['id'], ['asc'])

  const handleServicosFiliaisLoading = (pLoading) => {
    setServicosFiliais((s) => ({
      ...s,
      loading: pLoading,
    }))
  }

  const handleServicosFiliaisLoadData = (pServicos) => {
    setServicosFiliais((s) => ({
      ...s,
      servicos: pServicos,
    }))
  }

  const handleServicosLoad = () => {
    handleServicosFiliaisLoading(true)
    listTabelasPrecoApi({
      token: session.token,
      filter: {
        filiais: session.user.filial,
      },
    }).then((payload) => {
      handleServicosFiliaisLoading(false)
      if (!_.isEmpty(payload) && _.isArray(payload.data)) {
        const aServicos = []
        payload.data.forEach((tabela) => {
          tabela.tabelasPrecoItens.forEach((tpi) => {
            if (!aServicos.find((s) => s.id === tpi.servicos.id)) {
              aServicos.push(tpi.servicos)
            }
          })
        })
        aServicos.push({ id: 'ALL', descricao: 'TODOS' })
        handleServicosFiliaisLoadData(aServicos)
      }
    })
  }

  const handleFuncionariosFiliaisLoading = (pLoading) => {
    setFuncionariosFiliais((s) => ({
      ...s,
      loading: pLoading,
    }))
  }

  const handleFuncionariosFiliaisLoadData = (pFuncionarios) => {
    setFuncionariosFiliais((s) => ({
      ...s,
      funcionarios: pFuncionarios,
    }))
  }

  const handleFuncionariosLoad = () => {
    handleFuncionariosFiliaisLoading(true)
    listFuncionariosAtendentesApi({
      token: session.token,
      filter: {
        filiais: session.user.filial,
      },
    }).then((resp) => {
      handleFuncionariosFiliaisLoading(false)
      if (resp && _.isArray(resp)) {
        const aFuncionarios = resp
        aFuncionarios.push({ id: 'ALL', descricao: 'TODOS' })
        handleFuncionariosFiliaisLoadData(aFuncionarios)
      }
    })
  }
  const handleSelectServico = (value) => {
    setServicos(value)
    const filterAll = (i) => i === 'ALL'
    const filterNotAll = (i) => i !== 'ALL'
    if (value.findIndex(filterAll) > 0) {
      setServicos(value.filter(filterAll))
      setServicosOpen(false)
    }
    if (value.findIndex(filterAll) === 0 && value.length > 1) {
      setServicos(value.filter(filterNotAll))
    }
  }

  const handleSelectFuncionario = (value) => {
    setFuncionarios(value)
    const filterAll = (i) => i === 'ALL'
    const filterNotAll = (i) => i !== 'ALL'
    if (value.findIndex(filterAll) > 0) {
      setFuncionarios(value.filter(filterAll))
      setFuncionariosFiliaisOpen(false)
    }
    if (value.findIndex(filterAll) === 0 && value.length > 1) {
      setFuncionarios(value.filter(filterNotAll))
    }
  }
  const handleFiltrar = () => {
    let valid = true
    // Validações
    if (!filiais) {
      NotificationManager.warning('Filial inválida!', 'Atenção', 8000)
      valid = false
    }
    if (valid && (!dataInicial || !dataFinal)) {
      NotificationManager.warning('Data inicial ou final inválida!', 'Atenção', 8000)
      valid = false
    }
    if (valid) {
      setLoading(true)
      relatServicos({
        token,
        filter: {
          filiaisId: filiais,
          dataInicial,
          dataFinal,
          servicos,
          atendentes: funcionarios,
        },
      }).then((resp) => {
        if (resp) {
          window.open(URL.createObjectURL(new Blob([resp], { type: 'application/pdf' })))
        }
        setLoading(false)
      })
    }
  }
  useEffect(() => {
    document.title = 'Backoffice - Relatório/Serviços'
    setLabelWidth(inputLabel.current.offsetWidth)
    setLabelWidthServicos(inputLabelServicos.current.offsetWidth)
    setLabelWidthAtendentes(inputLabelAtendentes.current.offsetWidth)
    handleServicosLoad()
    handleFuncionariosLoad()
  }, [])

  return (
    <DefaultPage>
      <PageHeaderComp
        title="Relatório - Serviços"
      />
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Filtro
              </Typography>
              <Grid container spacing={2}>
                {/* Filiais */}
                <Grid item xs={12} md={12}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel ref={inputLabel} id="relatorios-servicos-filiais-label">
                      Filiais
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="relatorios-servicos-filiais-label"
                      id="relatorios-servicos-filiais-select"
                      size="small"
                      margin="dense"
                      value={filiais || ''}
                      onChange={(evt) => setFiliais(evt.target.value)}
                      labelWidth={labelWidth}
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {user.filiais
                        .filter((f) => f.status === 'L')
                        .map((f) => (
                          <MenuItem key={f.id} value={f.id}>
                            {f.descricao}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Serviços */}
                <Grid item xs={12} md={12}>
                  <FormControl variant="outlined" fullWidth margin="dense" multiple>
                    <InputLabel ref={inputLabelServicos} id="relatorios-servicos-pgtos-label">
                      Serviços
                    </InputLabel>
                    <Select
                      fullWidth
                      multiple
                      open={servicosOpen}
                      onClose={() => setServicosOpen(false)}
                      onOpen={() => setServicosOpen(true)}
                      labelId="relatorios-servicos-pgtos-label"
                      id="relatorios-servicos-pgtos-select"
                      size="small"
                      margin="dense"
                      value={servicos}
                      onChange={(evt) => handleSelectServico(evt.target.value)}
                      labelWidth={labelWidthServicos}
                    >
                      <MenuItem value="ALL">
                        TODOS
                      </MenuItem>
                      {_.orderBy(servicosFiliais.servicos.filter((s) => s.id !== 'ALL'), ['descricao'], ['asc']).map((f) => <MenuItem key={f.id} value={f.id}>{f.descricao}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Atendentes */}
                <Grid item xs={12} md={12}>
                  <FormControl variant="outlined" fullWidth margin="dense" multiple>
                    <InputLabel ref={inputLabelAtendentes} id="relatorios-funcionarios-filiais-label">
                      Atendente(s)
                    </InputLabel>
                    <Select
                      fullWidth
                      multiple
                      open={funcionariosFiliaisOpen}
                      onClose={() => setFuncionariosFiliaisOpen(false)}
                      onOpen={() => setFuncionariosFiliaisOpen(true)}
                      labelId="relatorios-funcionarios-filiais-label"
                      id="relatorios-funcionarios-filiais-select"
                      size="small"
                      margin="dense"
                      value={funcionarios}
                      onChange={(evt) => handleSelectFuncionario(evt.target.value)}
                      labelWidth={labelWidthAtendentes}
                    >
                      <MenuItem value="ALL">
                        TODOS
                      </MenuItem>
                      {_.orderBy(funcionariosFiliais.funcionarios.filter((s) => s.id !== 'ALL'), ['nome'], ['asc']).map((f) => <MenuItem key={f.id} value={f.id}>{f.nome.toUpperCase()}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Data inicial */}
                <Grid item xs={6} md={6}>
                  <TextField
                    fullWidth
                    id="date"
                    label="Data inicial"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={dataInicial}
                    onChange={(evt) => setDataInicial(evt.target.value)}
                  />
                </Grid>
                {/* Data final */}
                <Grid item xs={6} md={6}>
                  <TextField
                    fullWidth
                    id="date"
                    label="Data final"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={dataFinal}
                    onChange={(evt) => setDataFinal(evt.target.value)}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <ButtonLoadingComp loading={loading} variant="contained" color="primary" onClick={handleFiltrar}>
                Filtrar
              </ButtonLoadingComp>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </DefaultPage>
  )
}

RelatoriosServicosPage.propTypes = {
  session: PropTypes.shape().isRequired,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
  pagamentosForma: state.pagamentosForma,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...PagamentosFormaActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RelatoriosServicosPage)
