import _ from 'lodash'
import { NotificationManager } from 'react-notifications'
import {
  listTabelasPrecoApi,
  getTabelasPrecoApi,
  addTabelasPrecoApi,
  editTabelasPrecoApi,
  copyTabelasPrecoApi,
  deleteTabelasPrecoApi,
  addTabelasPrecoFiliaisApi,
  deleteTabelasPrecoFiliaisApi,
} from 'api/tabelaspreco'

// State Usuario
const initialState = {
  loading: {
    list: false,
    find: false,
    add: false,
    edit: false,
    del: false,
    delFiliais: false,
  },
  list: { page: 0, rows: 0, data: [] },
  form: {
    id: 0,
    descricao: '',
    dataInicial: '',
    dataFinal: '',
    status: '',
    statusDescricao: '',
    filiais: [],
    tabelasPrecoItens: [],
  },
}

// Actions Types
export const Types = {
  RESET: 'reset',
  TABELAS_PRECOS_LOADING: 'tabelasPreco/LOADING',
  TABELAS_PRECOS_LIST_LOAD: 'tabelasPreco/TABELAS_PRECOS_LIST_LOAD',
  TABELAS_PRECOS_LIST_UPDATE: 'tabelasPreco/TABELAS_PRECOS_LIST_UPDATE',
  TABELAS_PRECOS_LIST_CLEAN: 'tabelasPreco/TABELAS_PRECOS_LIST_CLEAN',
  TABELAS_PRECOS_FORM_UPDATE: 'tabelasPreco/TABELAS_PRECOS_FORM_UPDATE',
}

// Reducers
export default function tabelasPrecoReducers(state = initialState, action) {
  switch (action.type) {
  // RESET DEFAULT
  case Types.RESET:
    return {
      ...state,
      ...initialState,
    }
  case Types.TABELAS_PRECOS_LOADING:
    return {
      ...state,
      loading: { ...state.loading, ...action.payload },
    }
  case Types.TABELAS_PRECOS_LIST_LOAD:
    return {
      ...state,
      list: action.payload,
    }
  case Types.TABELAS_PRECOS_LIST_UPDATE:
    return {
      ...state,
      list: state.list.map((i) => (i.id === action.payload.id ? { ...i, ...action.payload } : i)),
    }
  case Types.TABELAS_PRECOS_LIST_CLEAN:
    return {
      ...state,
      list: initialState.list,
    }
  case Types.TABELAS_PRECOS_FORM_UPDATE:
    return {
      ...state,
      form: { ...state.form, ...action.payload },
    }
  default:
    return state
  }
}

// Actions Creators
export const Actions = {
  // Store Update
  onTabelasPrecoLoading: (payload) => ({ type: Types.TABELAS_PRECOS_LOADING, payload }),
  onTabelasPrecoListLoad: (payload) => ({ type: Types.TABELAS_PRECOS_LIST_LOAD, payload }),
  onTabelasPrecoListUpdate: (payload) => ({ type: Types.TABELAS_PRECOS_LIST_UPDATE, payload }),
  onTabelasPrecoListClean: () => ({ type: Types.TABELAS_PRECOS_LIST_CLEAN }),
  onTabelasPrecoFormUpdate: (payload) => ({ type: Types.TABELAS_PRECOS_FORM_UPDATE, payload }),
  onTabelasPrecoFormClean: () => ({
    type: Types.TABELAS_PRECOS_FORM_UPDATE,
    payload: initialState.form,
  }),
  onTabelasPrecoDataById: (id) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    dispatch(Actions.onTabelasPrecoLoading({ find: true }))
    // Chamada API
    getTabelasPrecoApi({ token, id }).then((payload) => {
      dispatch(Actions.onTabelasPrecoLoading({ find: false }))
      dispatch(
        Actions.onTabelasPrecoFormUpdate(_.isObject(payload) ? payload : initialState.form),
      )
    })
  },
  onTabelasPrecoDataList: (filter) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    dispatch(Actions.onTabelasPrecoLoading({ list: true }))
    // Chamada API
    listTabelasPrecoApi({ token, filter }).then((payload) => {
      dispatch(Actions.onTabelasPrecoLoading({ list: false }))
      dispatch(Actions.onTabelasPrecoListLoad(payload || initialState.list))
    })
  },
  onTabelasPrecoDataSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    // Chamada API
    if (dto.id && !dto.isCopy) {
      dispatch(Actions.onTabelasPrecoLoading({ edit: true }))
      editTabelasPrecoApi({ token, dto, id: dto.id }).then((payload) => {
        dispatch(Actions.onTabelasPrecoLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success(
            `Tabela de preço ${dto.descricao} atualizada com sucesso!`,
            'Sucesso!',
            8000,
          )
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    } else if (dto.id && dto.isCopy) {
      dispatch(Actions.onTabelasPrecoLoading({ edit: true }))
      copyTabelasPrecoApi({
        token,
        dto: {
          copyId: dto.id,
          novaDescricao: dto.descricao,
          dataInicial: dto.dataInicial,
          dataFinal: dto.dataFinal,
          status: dto.status,
        },
      })
        .then((payload) => {
          dispatch(Actions.onTabelasPrecoLoading({ edit: false }))
          if (payload && payload.status === 200 && payload.message === 'inserted') {
            NotificationManager.success(
              `Tabela de preço ${dto.descricao} criada com sucesso!`,
              'Sucesso!',
              8000,
            )
            if (_.isFunction(callback)) {
              callback()
            }
          }
        })
    } else {
      dispatch(Actions.onTabelasPrecoLoading({ add: true }))
      addTabelasPrecoApi({ token, dto }).then((payload) => {
        dispatch(Actions.onTabelasPrecoLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success(
            `Empresa ${dto.descricao} cadastrada com sucesso!`,
            'Sucesso!',
            8000,
          )
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    }
  },
  onTabelasPrecoDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: {
        session: { token },
      },
    } = getState()
    dispatch(Actions.onTabelasPrecoLoading({ del: true }))
    // Chamada API
    deleteTabelasPrecoApi({ token, id }).then((payload) => {
      dispatch(Actions.onTabelasPrecoLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Tabela de preço excluída com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    })
  },
  onTabelasPrecoAddFiliais: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onTabelasPrecoLoading({ add: true }))
    addTabelasPrecoFiliaisApi({ token, dto }).then((payload) => {
      dispatch(Actions.onTabelasPrecoLoading({ add: false }))
      if (payload && payload.status === 200 && payload.message === 'inserted') {
        NotificationManager.success('Filial adicionada com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback(payload.entity)
        }
      }
    })
  },
  onTabelasPrecoDelFiliais: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()

    const dtoDelete = { token, dto }
    dispatch(Actions.onTabelasPrecoLoading({ delFiliais: true }))
    deleteTabelasPrecoFiliaisApi(dtoDelete).then((payload) => {
      dispatch(Actions.onTabelasPrecoLoading({ delFiliais: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Filial removida com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback(payload.entity)
        }
      }
    })
  },
}
