import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const path = '/relatorios'

export const relatClientes = ({ token, filter }) => {
  const { filiaisId, uf } = filter
  let queryUrl = `/clientes?filiaisId=${filiaisId}`
  queryUrl = uf ? `${queryUrl}&uf=${uf}` : queryUrl
  return api
    .get(path + queryUrl, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob',
      timeout: 0,
    })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const relatClientesDiasOff = ({ token, filter }) => {
  const { filiaisId, dias } = filter
  let queryUrl = `/clientes/dias-off?dias=${dias}`
  queryUrl = filiaisId ? `${queryUrl}&filiaisId=${filiaisId}` : queryUrl
  return api
    .get(path + queryUrl, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob',
      timeout: 0,
    })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const relatCaixasMovimentos = ({ token, filter }) => {
  const {
    filiaisId, dataInicial, dataFinal, atendentes,
  } = filter
  let queryUrl = `/caixas/movimentos?filiaisId=${filiaisId}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`
  queryUrl = atendentes ? `${queryUrl}&atendentes=${atendentes}` : queryUrl
  return api
    .get(path + queryUrl, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob',
      timeout: 0,
    })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const relatServicos = ({ token, filter }) => {
  const {
    filiaisId, dataInicial, dataFinal, servicos, funcionarios, atendentes,
  } = filter
  let queryUrl = `/servicos?filiaisId=${filiaisId}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`
  queryUrl = servicos ? `${queryUrl}&servicos=${servicos}` : queryUrl
  queryUrl = funcionarios ? `${queryUrl}&funcionarios=${funcionarios}` : queryUrl
  queryUrl = atendentes ? `${queryUrl}&atendentes=${atendentes}` : queryUrl
  return api
    .get(path + queryUrl, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob',
      timeout: 0,
    })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const relatFaturamentos = ({ token, filter }, onReqError = null) => {
  const {
    filiaisId, dataInicial, dataFinal, formasPagamentos, formato,
  } = filter
  let queryUrl = `/faturamentos?filiaisId=${filiaisId}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`
  queryUrl = formasPagamentos ? `${queryUrl}&formasPagamentos=${formasPagamentos}` : queryUrl
  queryUrl = formato ? `${queryUrl}&formato=${formato}` : queryUrl
  return api
    .get(path + queryUrl, {
      headers: { 
        Authorization: `Bearer ${token}`, 
        // 'Content-Type': formato == 'excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf', 
      },
      responseType: 'blob',
      timeout: 0,
    })
    .then(httpValidState)
    .catch((err) => httpValidErros(err, false, false, onReqError))
}

export const relatFaturamentosPlanilha = ({ token, filter }, onReqError = null) => {
  const { ano, mesInicial, mesFinal } = filter
  let queryUrl = `/faturamentos/planilha-faturamentos?ano=${ano}`
  queryUrl = mesInicial ? `${queryUrl}&mesInicial=${mesInicial}` : queryUrl
  queryUrl = mesFinal ? `${queryUrl}&mesFinal=${mesFinal}` : queryUrl
  return api
    .get(path + queryUrl, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob',
      timeout: 0,
    })
    .then(httpValidState)
    .catch((err) => httpValidErros(err, false, false, onReqError))
}
