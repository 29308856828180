import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

// Actions
import { Actions as CategoriasActions } from 'store/ducks/categorias'

// Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import TableDataComp from 'components/Table'
import DialogComp from 'components/Dialogs'
import { parseToNum } from 'utils'
import ProdutosFormComp from './components/categorias_form'

const CatregoriasPage = ({
  session,
  categorias,
  onCategoriasDataList,
  onCategoriasFormUpdate,
  onCategoriasFormClean,
  onCategoriasDataSave,
  onCategoriasDataDelete,
}) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [search, setSearch] = useState('')
  const [dialogForm, setDialogForm] = useState({ open: false, title: '' })
  const [dialogAskDelete, setDialogAskDelete] = useState({ open: false, title: '', data: null })

  // Header Actions >>
  const handleFilterPageData = (filter) => {
    onCategoriasDataList({ ...filter })
  }
  const handleSearch = (pNewPage) => {
    handleFilterPageData({ search, page: pNewPage })
  }
  const handleOpenCloseDialogForm = (open, title = null) => {
    if (title) {
      setDialogForm((s) => ({ ...s, open, title }))
    } else {
      setDialogForm((s) => ({ ...s, open }))
    }
  }
  const handleOpenCloseDialogAskDelete = (open, title = null, data = null) => {
    setDialogAskDelete((s) => ({
      ...s, open, title, data,
    }))
  }
  const handleChangePage = (event, value) => {
    const newPage = ((value ?? 1) - 1)
    setCurrentPage(newPage)
    handleSearch(newPage)
  }
  const headerButtons = [
    {
      title: 'Adicionar',
      icon: 'add',
      action: () => {
        onCategoriasFormClean()
        handleOpenCloseDialogForm(true, 'Cadastro de categoria:')
      },
    },
  ]
  // Header Actions <<

  // Table Actions >>
  const tableCols = [
    { key: '', title: 'ID/SKU', format: (r) => `${r.id}/${r.sku}` },
    { key: 'descricao', title: 'DESCRIÇÃO' },
    { key: 'statusDescricao', title: 'STATUS' },
  ]
  const tableActions = [
    {
      key: 'editar',
      title: 'Editar',
      icon: 'edit',
      action: (row) => {
        onCategoriasFormUpdate(row)
        handleOpenCloseDialogForm(true, 'Edição de produto:')
      },
    },
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: (row) => handleOpenCloseDialogAskDelete(true, 'Excluir?', row),
    },
  ]
  // Table Actions <<

  useEffect(() => {
    document.title = 'Backoffice - Categorias'
    handleFilterPageData({ search, page: currentPage })
  }, [])

  return (
    <DefaultPage>
      <PageHeaderComp
        title="Categorias"
        value={search}
        onChange={(evt) => setSearch(evt.target.value)}
        onClick={() => handleSearch(currentPage)}
        onKeyPress={(evt) => {
          if (evt.charCode === 13) {
            handleSearch(currentPage)
          }
        }}
        buttons={headerButtons}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TableDataComp
            loading={categorias?.loading?.list ?? false}
            size="small"
            rows={categorias?.list?.data ?? []}
            cols={tableCols}
            actions={tableActions}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Pagination
            count={categorias?.loading?.list ? 1 : categorias.list.total}
            page={currentPage + 1}
            onChange={handleChangePage}
          />
        </Grid>
      </Grid>
      {/* DialogForm (add/edit) */}
      <DialogComp
        open={dialogForm.open}
        size="md"
        title={dialogForm.title}
        onClose={() => handleOpenCloseDialogForm(false)}
      // actions={}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <ProdutosFormComp
              token={session?.token ?? ''}
              data={{
                ...categorias?.form,
                preco: parseToNum(categorias?.form?.preco),
                precoPromo: parseToNum(categorias?.form?.precoPromo),
              }}
              onSave={(data) => onCategoriasDataSave(
                data,
                () => handleFilterPageData({ search, page: currentPage }),
              )}
            />
          </Grid>
        </Grid>
      </DialogComp>
      {/* Dialog Ask DELETE */}
      <DialogComp
        open={dialogAskDelete.open}
        title={dialogAskDelete.title}
        onClose={() => handleOpenCloseDialogAskDelete(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDelete(false),
          },
          {
            label: 'SIM',
            onClick: () => onCategoriasDataDelete(dialogAskDelete.data.id, () => {
              handleOpenCloseDialogAskDelete(false)
              handleFilterPageData({ search, page: currentPage })
            }),
          },
        ]}
      >
        {`Você deseja realmente excluir a categoria "${dialogAskDelete?.data?.descricao ?? ''}"?`}
      </DialogComp>
    </DefaultPage>
  )
}

CatregoriasPage.propTypes = {
  session: PropTypes.shape().isRequired,
  categorias: PropTypes.shape().isRequired,
  onCategoriasDataList: PropTypes.func.isRequired,
  onCategoriasFormUpdate: PropTypes.func.isRequired,
  onCategoriasFormClean: PropTypes.func.isRequired,
  onCategoriasDataSave: PropTypes.func.isRequired,
  onCategoriasDataDelete: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
  categorias: state.categorias,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...CategoriasActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CatregoriasPage)
