import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Typography,
  Grid,
  TextField,
  Button,
  Icon,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  CircularProgress,
} from '@material-ui/core'
import _ from 'lodash'

// * Utils
import {
  MoneyFormatBr,
  parseToDec,
  parseToNum,
  DateTimeIsoNow,
  ParseIptDateTimeToIso,
  ParseIsoToIsoTimeTimeZone,
  downloadBlobFile,
  isCelularMask,
} from 'utils'

// * Actions
import { Actions as ClientesActions } from 'store/ducks/clientes'

// * Api's
import {
  addOrcamentosApi,
  editOrcamentosApi,
  getOrcamentosApi,
  cancelOrcamentosApi,
  relatOrcamentosPrint,
} from 'api/orcamentos'
import { listClientesApi } from 'api/clientes'
import { listTabelasPrecoApi } from 'api/tabelaspreco'
import { listTabelasPrecoItensBasicApi } from 'api/tabelasprecoitens'

// * Components
import InputSearchComp from 'components/Inputs/Search'
import DialogComp from 'components/Dialogs'
import ClientesBasicFormComp from 'pages/clientes/components/ClientesBasicForm'
import ButtonLoadingComp from 'components/Buttons/ButtonLoading'
import {
  CelularMaskCustom,
  TelefoneMaskCustom,
} from 'components/Inputs/Masked'

// * Styles
import useStyles from './styles'

// * Stateless
const OrcamentosFormComp = ({
  session,
  emergencial = false,
  orcamentosId,
  onSuccess,
  onClose,
  clientes,
  onClientesFormUpdate,
  onClientesFormClean,
  onClientesDataSave,
}) => {
  const { token, user } = session
  const formDataInicial = {
    id: 0,
    clientesNome: '',
    clientesTelefone: '',
    cliente: { id: 0, descricao: '' },
    tabelaPreco: { id: 0, descricao: '' },
    servico: { id: 0, valor: 0, descricao: '' },
    quantidade: 1,
    desconto: 0,
    prazo: '',
    observacao: '',
    dataCriacao: '',
  }

  const [isEmergencial, setEmergencial] = useState(false)
  const [readonly, setReadonly] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingAdd, setLoadingAdd] = useState(false)
  const [loadingPrint, setLoadingPrint] = useState(false)
  const [loadingClientes, setLoadingClientes] = useState(false)
  const [loadingTabelasPrecos, setLoadingTabelasPrecos] = useState(false)
  const [loadingServicos, setLoadingServicos] = useState(false)
  const [mskTelefone, setMskTelefone] = useState(false)
  const [form, setForm] = useState(formDataInicial)
  const [dialogAddClientes, setDialogAddClientes] = useState({
    open: false,
    title: '',
  })
  const [listClientes, setListClientes] = useState([])
  const [listTabelasPrecos, setListTabelasPrecos] = useState([])
  const [listServicos, setListServicos] = useState([])
  const [motivoCancelamento, setMotivoCancelamento] = useState('')
  const [descontos, setDescontos] = useState(0)
  const [total, setTotal] = useState(0)
  const [itens, setItens] = useState([]) // * List of form
  const [dialogAskDelete, setDialogAskDelete] = useState({
    open: false,
    title: '',
    data: null,
  })

  const onUpdateForm = (ipt, value) => setForm(s => ({ ...s, [ipt]: value }))

  const handleOpenCloseDialogClientes = (open, title = null) => {
    if (title) {
      setDialogAddClientes(s => ({ ...s, open, title }))
    } else {
      setDialogAddClientes(s => ({ ...s, open }))
    }
  }

  // * Call Api's
  const onHandleSearchClientes = async (pSearch = '') => {
    setLoadingClientes(true)
    try {
      const respHttp = await listClientesApi({
        token,
        filter: { search: pSearch, rows: 10, withveiculos: 'N' },
      })
      if (respHttp) {
        setListClientes(
          respHttp?.data?.map(({ id = 0, nome = '' }) => ({
            id,
            descricao: nome?.trim(),
          }))
        )
      }
    } catch (e) {
      console.log('Error on call listClientesApi: ', e)
    } finally {
      setLoadingClientes(false)
    }
  }
  const onHandleSearchTabelasPrecos = async (pSearch = '') => {
    setLoadingTabelasPrecos(true)
    try {
      const respHttp = await listTabelasPrecoApi({
        token,
        filter: {
          filiais: user?.filial,
          status: 'L',
          search: pSearch,
          rows: 10,
        },
      })
      if (respHttp) {
        setListTabelasPrecos(
          respHttp?.data?.map(({ id = 0, descricao = '' }) => ({
            id,
            descricao: descricao?.trim(),
          }))
        )
      }
    } catch (e) {
      console.log('Error on call listTabelasPrecoApi: ', e)
    } finally {
      setLoadingTabelasPrecos(false)
    }
  }
  const onHandleSearchServicos = async (pSearch = '') => {
    setLoadingServicos(true)
    try {
      const respHttp = await listTabelasPrecoItensBasicApi({
        token,
        filter: {
          filiais: user?.filial,
          tabelaspreco: form?.tabelaPreco?.id ?? '',
          status: 'L',
          virgente: 'S',
          search: pSearch,
          rows: 10,
        },
      })
      if (respHttp) {
        setListServicos(
          respHttp?.data?.map(
            ({ id = 0, valor = 0, servicosDescricao = '' }) => ({
              id,
              valor,
              descricao: servicosDescricao?.trim(),
            })
          )
        )
      }
    } catch (e) {
      console.log('Error on call listTabelasPrecoApi: ', e)
    } finally {
      setLoadingServicos(false)
    }
  }
  const onCleanFormAfterAddItem = () => {
    setForm(s => ({ ...s, servico: {}, quantidade: 1, desconto: 0 }))
  }
  const onAddItem = () => {
    if (!form.servico.id) {
      NotificationManager.warning(
        'Você deve selecionar um serviço!',
        'Atenção!',
        8000
      )
      return
    }
    if (!form.quantidade || form.quantidade <= 0) {
      NotificationManager.warning(
        'Você deve informar uma quantidade!',
        'Atenção!',
        8000
      )
      return
    }

    const newItem = {
      item: itens?.length + 1 ?? 1,
      servico: form.servico,
      tabelaPreco: form.tabelaPreco,
      quantidade: form?.quantidade ?? 1,
      desconto: parseToDec(form?.desconto ?? 0),
    }
    setItens(s => [...s, newItem])
    onCleanFormAfterAddItem()
  }
  const onHandleOpenCloseDialogAskDelete = (open, title = '', data = null) => {
    setDialogAskDelete(s => ({
      ...s,
      open,
      title,
      data,
    }))
  }
  const onHandleDeleteItem = pItem => {
    setItens(its =>
      its
        .filter(i => i.item !== pItem.item)
        .map(d => {
          if (d.item > pItem.item) {
            return { ...d, item: (d.item -= 1) }
          }
          return d
        })
    )
    onHandleOpenCloseDialogAskDelete(false)
  }
  const onHandleCancelOrcamentos = async pOrcamentosId => {
    if (pOrcamentosId) {
      // * Send cancel to Api
      try {
        const respHttp = await cancelOrcamentosApi({ token, id: pOrcamentosId })
        if (respHttp && respHttp.code == 'canceled') {
          NotificationManager.warning(respHttp.entity, 'Atenção!', 8000)
        }
      } catch (e) {
        console.log('Error on cancel orçamento ID: ', pOrcamentosId)
      }
    } else {
      // * Clean form data
      setForm(formDataInicial)
      if (_.isFunction(onClose)) {
        onClose()
      }
    }
  }
  const onHandleSaveOrcamentos = async (pOrcamentosId = null) => {
    const filiaisId = user?.filial ?? 0
    const usuariosId = user?.id ?? 0
    const vendedorId = user?.funcionarios?.id ?? 0
    const clientesId = form?.cliente?.id ?? 0
    const tabelaPrecoId = form?.tabelaPreco?.id ?? 0
    const prazo = form?.prazo ?? ''

    if (!isEmergencial && (!clientesId || clientesId <= 0)) {
      NotificationManager.warning(
        'Você deve informar um cliente!',
        'Atenção!',
        8000
      )
      return
    }
    if (!tabelaPrecoId || tabelaPrecoId <= 0) {
      NotificationManager.warning(
        'Você deve informar uma tabela de preço!',
        'Atenção!',
        8000
      )
      return
    }
    if (_.isEmpty(prazo)) {
      NotificationManager.warning(
        'Você deve informar um prazo para esse orçamento!',
        'Atenção!',
        8000
      )
      return
    }
    if (_.isEmpty(itens)) {
      NotificationManager.warning(
        'Você deve informar um serviço!',
        'Atenção!',
        8000
      )
      return
    }

    try {
      setLoadingAdd(true)
      const dto = {
        emergencial: isEmergencial,
        filiaisId,
        usuariosId, // Usuarios
        vendedorId, // Funcionarios
        clientesId,
        clientesNome: form?.clientesNome,
        clientesTelefone: form?.clientesTelefone,
        descontoTipo: 'V',
        descontoValor: descontos,
        descontoPorcentagem: 0,
        totalBruto: total + descontos,
        totalLiquido: total,
        observacao: form?.observacao ?? '',
        dataCriacao: DateTimeIsoNow(),
        dataPrazo: ParseIptDateTimeToIso(prazo),
        motivoCancelamento,
        status: 'AB',
        itens: itens.map(i => ({
          tabelasPrecoItensId: i?.servico?.id ?? 0,
          funcionariosId: vendedorId,
          quantidade: i?.quantidade ?? 1,
          preco: i?.servico?.valor ?? 0,
          total: i?.servico?.valor * i?.quantidade - i?.desconto,
          desconto: i?.desconto ?? 0,
          descricao: i?.servico?.descricaoOri ?? '',
          descricao2: '',
          status: 'P',
        })),
      }
      let respHttp
      if (!pOrcamentosId) {
        respHttp = await addOrcamentosApi({ token, dto })
        if (respHttp && respHttp.code === 'inserted') {
          NotificationManager.success(
            `Orcamento nº ${respHttp.id} criando com sucesso!`,
            'Sucesso!',
            8000
          )
          setForm(s => ({ ...s, id: respHttp.id }))
          if (_.isFunction(onSuccess)) {
            onSuccess(respHttp.entity)
          }
        }
      } else {
        respHttp = await editOrcamentosApi({ token, id: pOrcamentosId, dto })
        if (respHttp && respHttp.code === 'updated') {
          NotificationManager.success(
            `Orcamento nº ${respHttp.id} criando com sucesso!`,
            'Sucesso!',
            8000
          )
          if (_.isFunction(onSuccess)) {
            onSuccess(respHttp.entity)
          }
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingAdd(false)
    }
  }
  const timeout = ms => new Promise(resolve => setTimeout(resolve, ms))
  const onHandlePrintOrcamentos = async pOrcamentosId => {
    try {
      setLoadingPrint(true)
      const respHttp = await relatOrcamentosPrint({ token, id: pOrcamentosId })
      downloadBlobFile(
        respHttp,
        'application/pdf',
        `Orcamento-${pOrcamentosId}.pdf`
      )
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingPrint(false)
    }
  }
  const onLoadData = async pOrcamentosId => {
    try {
      setReadonly(false)
      setLoading(true)
      if (pOrcamentosId) {
        setReadonly(true)
        const respHttp = await getOrcamentosApi({ token, id: pOrcamentosId })
        if (respHttp) {
          // * Header
          setForm({
            id: pOrcamentosId,
            cliente: {
              id: respHttp?.clientesId ?? '',
              descricao: respHttp?.clientesNome ?? '',
            },
            tabelaPreco: { id: 0, descricao: '' },
            servico: { id: 0, valor: 0, descricao: '' },
            quantidade: 1,
            desconto: 0,
            prazo: ParseIsoToIsoTimeTimeZone(respHttp?.dataPrazo ?? ''),
            dataCriacao: respHttp?.dataCriacao ?? '',
            observacao: respHttp?.observacao ?? ''
          })
          // * Itens
          setItens(
            respHttp?.itens?.map((i, idx) => ({
              id: i?.id,
              item: idx + 1,
              servico: {
                descricaoOri: i?.descricao ?? '',
                valor: i?.preco ?? 0,
              },
              quantidade: i?.quantidade ?? 1,
              desconto: i?.desconto ?? 0,
            })) ?? []
          )
        }
      }
    } catch (e) {
      console.log('Error on load data from orcamentos id: ', pOrcamentosId)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    onLoadData(orcamentosId)
  }, [orcamentosId])

  useEffect(() => {
    setEmergencial(emergencial ?? false)
  }, [emergencial])

  useEffect(() => {
    setDescontos(itens.reduce((a, i) => a + i?.desconto ?? 0, 0))
    setTotal(
      itens.reduce(
        (a, i) =>
          a +
          (i?.servico?.valor ?? 0) * (i?.quantidade ?? 0) -
          (i?.desconto ?? 0),
        0
      )
    )
  }, [itens])

  return (
    <>
      {loading && <CircularProgress />}
      {!loading && (
        <Grid container spacing={1}>
          {/* Filtros: Cliente, Tabela, Servico, Qtd, Desconto */}
          <Grid item xs={12} md={8}>
            <Grid container spacing={1}>
              {/* Clientes */}
              {isEmergencial ? (
                <>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      disabled={readonly}
                      id="cliente-nome"
                      label="Nome:*"
                      margin="normal"
                      variant="outlined"
                      value={form.clientesNome}
                      onChange={evt =>
                        onUpdateForm('clientesNome', evt?.target?.value ?? '')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      disabled={readonly}
                      InputProps={{
                        inputComponent: mskTelefone ? TelefoneMaskCustom : CelularMaskCustom,
                      }}
                      id="cliente-telefone"
                      label="Celular:*"
                      margin="normal"
                      variant="outlined"
                      value={form.clientesTelefone}
                      onChange={evt => {
                        onUpdateForm('clientesTelefone', evt?.target?.value ?? '')
                      }}
                      onFocus={() => {
                        setMskTelefone(false)
                      }}
                      onBlur={() => {
                        setMskTelefone(!isCelularMask(form?.clientesTelefone ?? ''))                        
                      }}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} md={8}>
                  <InputSearchComp
                    title="Buscar de clientes:"
                    label="Cliente:"
                    htmlId="iptSearchCliente"
                    size="md"
                    disabled={readonly}
                    searching={loadingClientes}
                    value={form?.cliente?.descricao ?? ''}
                    data={listClientes.map(f => ({
                      id: f?.id ?? 0,
                      descricao: f?.descricao ?? '',
                    }))}
                    onSearch={onHandleSearchClientes}
                    onSelectItem={cliente => setForm(s => ({ ...s, cliente }))}
                    onClickSearch={onHandleSearchClientes}
                    onClickClear={() => setForm(s => ({ ...s, cliente: {} }))}
                  />
                </Grid>
              )}
              {/* Tabela de Preço */}
              <Grid item xs={12} md={4}>
                <InputSearchComp
                  title="Buscar de tabela de preço:"
                  label="Tabela de Preço:"
                  htmlId="iptSearchTabelaPreco"
                  size="md"
                  disabled={readonly}
                  searching={loadingTabelasPrecos}
                  value={form?.tabelaPreco?.descricao ?? ''}
                  data={listTabelasPrecos.map(f => ({
                    id: f?.id ?? 0,
                    descricao: f?.descricao ?? '',
                  }))}
                  onSearch={onHandleSearchTabelasPrecos}
                  onSelectItem={tabelaPreco =>
                    setForm(s => ({ ...s, tabelaPreco }))
                  }
                  onClickSearch={onHandleSearchTabelasPrecos}
                  onClickClear={() => setForm(s => ({ ...s, tabelaPreco: {} }))}
                />
              </Grid>
              {/* Busca de Serviços */}
              <Grid item xs={12} md={8}>
                <InputSearchComp
                  title="Buscar de Serviços:"
                  label="Serviço:"
                  htmlId="iptSearchServico"
                  size="md"
                  disabled={readonly}
                  searching={loadingServicos}
                  value={form?.servico?.descricao ?? ''}
                  data={listServicos.map(f => ({
                    id: f?.id ?? 0,
                    valor: f?.valor ?? 0,
                    descricaoOri: f?.descricao ?? '',
                    descricao: `R$ ${MoneyFormatBr(
                      parseToNum(f.valor ?? 0)
                    )} - ${f?.descricao ?? ''}`,
                  }))}
                  onSearch={onHandleSearchServicos}
                  onSelectItem={s => {
                    const servicoAdd = {
                      id: s.id,
                      valor: s.valor,
                      descricaoOri: s.descricaoOri,
                      descricao: s.descricao,
                    }
                    console.log('iptSearchServico=>', servicoAdd)
                    setForm(s => ({
                      ...s,
                      servico: servicoAdd,
                    }))
                  }}
                  onClickSearch={onHandleSearchServicos}
                  onClickClear={() => setForm(s => ({ ...s, servico: {} }))}
                />
              </Grid>
              {/* Quantidade */}
              <Grid item xs={12} md={2}>
                <TextField
                  fullWidth
                  disabled={readonly}
                  id="servico-quantidade"
                  label="Quantidade:*"
                  margin="normal"
                  variant="outlined"
                  value={form.quantidade}
                  onChange={evt =>
                    onUpdateForm('quantidade', evt?.target?.value ?? '')
                  }
                />
              </Grid>
              {/* Desconto */}
              <Grid item xs={12} md={2}>
                <TextField
                  fullWidth
                  disabled={readonly}
                  id="servico-desconto"
                  label="Desconto:"
                  margin="normal"
                  variant="outlined"
                  value={form.desconto}
                  onChange={evt =>
                    onUpdateForm('desconto', evt?.target?.value ?? '')
                  }
                />
              </Grid>
              {/* BTN: Adicionar Item */}
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  // justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item xs={12}>
                    <Button
                      disabled={readonly}
                      variant="contained"
                      color="primary"
                      startIcon={<Icon>add</Icon>}
                      onClick={onAddItem}
                    >
                      Adicionar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Ações: Cancelar, Novo Cliente, Salvar, Imprimir, Totais, Prazo */}
          <Grid item xs={12} md={4}>
            <Grid
              container
              direction="row"
              // justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              {/* Btn Salvar */}
              <Grid item xs={6}>
                <ButtonLoadingComp
                  loading={loadingAdd}
                  fullWidth
                  disabled={readonly}
                  variant="contained"
                  size="small"
                  color="primary"
                  startIcon={<Icon>save</Icon>}
                  onClick={() => onHandleSaveOrcamentos(orcamentosId)}
                >
                  Salvar
                </ButtonLoadingComp>
              </Grid>
              {/* Btn Cancelar */}
              <Grid item xs={6}>
                <Button
                  fullWidth
                  disabled={readonly}
                  variant="contained"
                  size="small"
                  color="secondary"
                  startIcon={<Icon>cancel</Icon>}
                  onClick={() => onHandleCancelOrcamentos(orcamentosId)}
                >
                  Cancelar
                </Button>
              </Grid>
              {/* Btn Imprimir */}
              <Grid item xs={6}>
                <ButtonLoadingComp
                  fullWidth
                  loading={loadingPrint}
                  disabled={!form?.id}
                  variant="contained"
                  size="small"
                  color="default"
                  startIcon={<Icon>print</Icon>}
                  onClick={() => onHandlePrintOrcamentos(form?.id)}
                >
                  Imprimir
                </ButtonLoadingComp>
              </Grid>
              {/* Btn Add Cliente */}
              {!isEmergencial && (
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    disabled={readonly}
                    variant="contained"
                    size="small"
                    startIcon={<Icon>add_circle</Icon>}
                    onClick={() => {
                      onClientesFormClean()
                      handleOpenCloseDialogClientes(true, 'Cadastro de Cliente:')
                    }}
                  >
                    Cliente
                  </Button>
                </Grid>
              )}
              {/* Totais */}
              <Grid item xs={12}>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  alignContent="space-between"
                >
                  <Grid item xs={12} md={8}>
                    <Typography variant="h6">Desconto</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">
                      {`R$ ${MoneyFormatBr(parseToNum(descontos))}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography gutterBottom variant="h4">
                      Total
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="h6">
                      {`R$ ${MoneyFormatBr(parseToNum(total))}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Prazo */}
              <Grid item xs={12}>
                <TextField
                  
                  fullWidth
                  disabled={readonly}
                  type="datetime-local"
                  id="prazo"
                  label="Prazo:"
                  margin="normal"
                  variant="outlined"
                  value={form.prazo}
                  onChange={evt =>
                    onUpdateForm('prazo', evt?.target?.value ?? '')
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {/* Observação */}
              <Grid item xs={12}>
                <TextField
                  
                  multiline
                  fullWidth
                  disabled={readonly}
                  id="observacao"
                  label="Observação:"
                  margin="normal"
                  variant="outlined"
                  value={form.observacao}
                  onChange={evt => {
                    onUpdateForm('observacao', evt?.target?.value ?? '')
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Tabela de itens do Orcamento */}
          <Grid item xs={12} md={12}>
            <Typography variant="h6" gutterBottom>
              Item(ns)
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={12}>
            <TableContainer>
              <Table
                stickyHeader
                padding="none"
                aria-label="sticky table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>ITEM</TableCell>
                    <TableCell>SERVIÇO</TableCell>
                    <TableCell>QUANTIDADE</TableCell>
                    <TableCell>PREÇO</TableCell>
                    <TableCell>DESCONTO</TableCell>
                    <TableCell>TOTAL</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itens?.map(i => (
                    <TableRow key={i.item}>
                      <TableCell>{i.item}</TableCell>
                      <TableCell>{i?.servico?.descricaoOri ?? ''}</TableCell>
                      <TableCell>{i?.quantidade ?? '0'}</TableCell>
                      <TableCell>{`R$ ${MoneyFormatBr(
                        parseToNum(i?.servico?.valor)
                      )}`}</TableCell>
                      <TableCell>{`R$ ${MoneyFormatBr(
                        parseToNum(i?.desconto)
                      )}`}</TableCell>
                      <TableCell>{`R$ ${MoneyFormatBr(
                        parseToNum(
                          i?.servico?.valor * i?.quantidade - i?.desconto
                        )
                      )}`}</TableCell>
                      <TableCell>
                        <IconButton
                          disabled={readonly}
                          aria-label="delete"
                          onClick={() =>
                            onHandleOpenCloseDialogAskDelete(
                              true,
                              'Excluir?',
                              i
                            )
                          }
                          color="secondary"
                        >
                          <Icon fontSize="small">delete</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
      {/* Dialog Ask DELETE Item */}
      <DialogComp
        open={dialogAskDelete.open}
        title={dialogAskDelete.title}
        onClose={() => onHandleOpenCloseDialogAskDelete(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => onHandleOpenCloseDialogAskDelete(false),
          },
          {
            label: 'SIM',
            onClick: () => onHandleDeleteItem(dialogAskDelete?.data),
          },
        ]}
      >
        {`Você deseja realmente excluir o ITEM Nº: ${dialogAskDelete?.data
          ?.item ?? 0} - ${dialogAskDelete?.data?.servico?.descricaoOri ??
          ''} ?`}
      </DialogComp>
      {/* Dialog ClientesForm (add/edit) */}
      <DialogComp
        size="md"
        open={dialogAddClientes.open}
        title={dialogAddClientes.title}
        onClose={() => handleOpenCloseDialogClientes(false)}
        actions={[
          {
            label: 'Cancelar',
            onClick: () => {
              onClientesFormClean()
              handleOpenCloseDialogClientes(false)
            },
          },
          {
            label: 'Salvar',
            onClick: () => {
              onClientesDataSave(clientes.form, payload => {
                setForm(s => ({
                  ...s,
                  cliente: { id: payload.id, descricao: payload.nome },
                }))
                onClientesFormClean()
                handleOpenCloseDialogClientes(false)
              })
            },
          },
        ]}
      >
        <ClientesBasicFormComp
          data={clientes.form}
          onChange={onClientesFormUpdate}
        />
      </DialogComp>
    </>
  )
}

const mapStateToProps = state => ({
  clientes: state.clientes,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ClientesActions,
    },
    dispatch
  )

OrcamentosFormComp.propTypes = {
  // data: PropTypes.shape().isRequired,
  clientes: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClientesDataSave: PropTypes.func.isRequired,
  onClientesFormClean: PropTypes.func.isRequired,
  onClientesFormUpdate: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrcamentosFormComp)
