import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import _ from 'lodash'

// Api's
import { listServicosApi } from 'api/servicos'
// Components
import InputSearchComp from 'components/Inputs/Search'
// Styles
import useStyles from './styles'

// Stateless
const TabelasPrecoItensFormComp = ({ isOrcamentos, token, data, onChange }) => {
  const {
    tabelasPreco = {},
    tabelasPrecoId = '',
    tabelasPrecoDescricao = '',
    servicos = {},
    valor = 0,
    porte = 'P',
    status = '',
  } = data
  const classes = useStyles()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  const [searchServicos, setSearchServicos] = useState({
    loading: false,
    payload: { page: 0, rows: 0, data: [] },
  })

  // Generic Search Serviços (INICIO)
  const handleSearchLoadingServicos = loading =>
    setSearchServicos(s => ({ ...s, loading }))
  const handleSearchLoadDataServicos = payload =>
    setSearchServicos(s => ({ ...s, payload }))
  const handleSearchActionServicos = pSearch => {
    handleSearchLoadingServicos(true)
    return listServicosApi({ token, filter: { search: pSearch } }).then(
      payload => {
        if (payload && !_.isEmpty(payload.data)) {
          const dtos = payload.data.map(({ id, descricao }) => ({
            id,
            descricao,
          }))
          handleSearchLoadDataServicos({
            page: 0,
            rows: dtos.length,
            data: dtos,
          })
          handleSearchLoadingServicos(false)
        } else {
          handleSearchLoadDataServicos({ page: 0, rows: 0, data: [] })
          handleSearchLoadingServicos(false)
        }
      }
    )
  }
  // Generic Search Serviços (FIM)

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          id="tabelasprecoitens-descricao"
          label="Tabelas de preço:"
          margin="normal"
          variant="outlined"
          value={`[${tabelasPrecoId}] ${tabelasPrecoDescricao}`}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <InputSearchComp
          required
          fullWidth
          title="Buscar serviços:"
          label="Descrição do serviço:"
          value={_.isEmpty(servicos) ? '' : servicos.descricao}
          htmlId="iptSearchServicos"
          size="sm"
          searching={searchServicos.loading}
          data={searchServicos.payload.data}
          onSearch={handleSearchActionServicos}
          onSelectItem={item => {
            onChange({ servicos: item })
          }}
          onClickClear={() => onChange({ funcionarios: {} })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          required
          fullWidth
          id="tabelasprecoitens-valor"
          label="Valor:"
          margin="normal"
          variant="outlined"
          value={!valor ? '' : valor}
          onChange={evt => onChange({ valor: evt.target.value.toUpperCase() })}
        />
      </Grid>
      {!isOrcamentos && (
        <Grid item xs={12} md={4}>
          <FormControl
            required
            variant="outlined"
            fullWidth
            margin="normal"
            className={classes.dataControl}
          >
            <InputLabel ref={inputLabel}>Porte</InputLabel>
            <Select
              id="tabelasprecoitens-porte"
              value={porte}
              onChange={evt =>
                onChange({ porte: evt.target.value.toUpperCase() })
              }
              labelWidth={labelWidth}
            >
              <MenuItem value="">
                <em>SELECIONE</em>
              </MenuItem>
              <MenuItem value="P">PEQUENO</MenuItem>
              <MenuItem value="G">GRANDE</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} md={4}>
        <FormControl
          required
          variant="outlined"
          fullWidth
          margin="normal"
          className={classes.dataControl}
        >
          <InputLabel ref={inputLabel}>Status</InputLabel>
          <Select
            id="tabelaspreco-status"
            value={status}
            onChange={evt =>
              onChange({ status: evt.target.value.toUpperCase() })
            }
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="P">PENDENTE</MenuItem>
            <MenuItem value="L">LIBERADO</MenuItem>
            <MenuItem value="B">BLOQUEADO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

TabelasPrecoItensFormComp.propTypes = {
  isOrcamentos: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}

export default TabelasPrecoItensFormComp
