import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogCloseButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  progress: {
    margin: theme.spacing(2),
  },
  font12: {
    fontSize: 13,
  },
  container: {
    maxHeight: 'auto',
  },
  heading: {
    marginLeft: theme.spacing(1),
  },
  statusA: {
    backgroundColor: '#618bff',
    color: '#FFF',
  },
  statusF: {
    backgroundColor: '#FF0000',
    color: '#FFF',
  },
  statusP: {
    backgroundColor: '#3c763d',
    color: '#FFF',
  },
  statusC: {
    backgroundColor: '#ff6600',
    color: '#FFF',
  },
  statusPF: {
    backgroundColor: '#3c763d',
    color: '#FFF',
  },
}))

export default useStyles
