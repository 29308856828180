import _ from 'lodash'
import { NotificationManager } from 'react-notifications'
import {
  listCaixasApi,
  getCaixasApi,
  addCaixasApi,
  editCaixasApi,
  deleteCaixasApi,
  getCaixasAbertoApi,
} from 'api/caixas'
import {
  listCaixasMovimentosApi,
  getCaixasMovimentosApi,
  addCaixasMovimentosApi,
  editCaixasMovimentosApi,
  deleteCaixasMovimentosApi,
} from 'api/caixas/movimentos'
import {
  listCaixasFechamentosApi,
  getCaixasFechamentosApi,
  addCaixasFechamentosApi,
  editCaixasFechamentosApi,
  deleteCaixasFechamentosApi,
} from 'api/caixas/fechamentos'

// State Usuario
const initialState = {
  loading: {
    list: false,
    find: false,
    add: false,
    edit: false,
    del: false,
    aberto: false,
  },
  list: { page: 0, rows: 0, data: [] },
  form: {
    id: 0,
    filiais: {},
    funcionarios: {},
    aberturaData: '',
    aberturaValor: 0,
    fechamentoData: '',
    fechamentoValor: 0,
    status: '',
    statusDescricao: '',
    senhaAbertura: '',
  },
  aberto: {
    id: 0,
    caixasOperadorId: 0,
    caixasOperadorNome: '',
    movimentos: 0,
    aberturaData: '',
    status: 'F',
  },
  movimentos: {
    loading: {
      list: false,
      find: false,
      add: false,
      edit: false,
      del: false,
    },
    list: { page: 0, rows: 0, data: [] },
    form: {
      caixasId: 0,
      caixasMovimentosTiposId: 0,
      caixasMovimentosOrigensId: 0,
      caixasOperadorId: 0,
      pagamentosCondicaoId: 0,
      referencias: 0,
      valor: 0, // Em decimal
      troco: 0, // Em decimal
      descontoReais: 0,
      descontoPorcentagem: 0,
    },
  },
  fechamentos: {
    loading: {
      list: false,
      find: false,
      add: false,
      edit: false,
      del: false,
    },
    list: { page: 0, rows: 0, data: [] },
    form: {
      filiais: {},
      funcionarios: {},
      // Usado no DTO para fechamento do caixa...
      caixasId: 0,
      valorAbertura: 0,
      valorSangria: 0,
      valorDinheiro: 0,
      valorCheque: 0,
      valorCartaoCredito: 0,
      valorCartaoDebito: 0,
      valorFaturado: 0,
      valorCortesia: 0,
      valorPix: 0,
      observacao: '',
      senhaAbertura: '',
    },
  },
}

// Actions Types
export const Types = {
  // RESET DEFAULT
  RESET: 'reset',
  // CAIXAS
  CAIXAS_LOADING: 'caixas/LOADING',
  CAIXAS_LIST_UPDATE: 'caixas/LIST_UPDATE',
  CAIXAS_FORM_UPDATE: 'caixas/FORM_UPDATE',
  // ABERTO
  CAIXAS_ABERTO_UPDATE: 'caixas/ABERTO_UPDATE',
  // MOVIMENTOS
  CAIXAS_MOVIMENTOS_LOADING: 'caixas/MOVIMENTOS_LOADING',
  CAIXAS_MOVIMENTOS_LIST_UPDATE: 'caixas/MOVIMENTOS_IST_UPDATE',
  CAIXAS_MOVIMENTOS_FORM_UPDATE: 'caixas/MOVIMENTOS_FORM_UPDATE',
  // FECHAMENTOS
  CAIXAS_FECHAMENTOS_LOADING: 'caixas/FECHAMENTOS_LOADING',
  CAIXAS_FECHAMENTOS_LIST_UPDATE: 'caixas/FECHAMENTOS_LIST_UPDATE',
  CAIXAS_FECHAMENTOS_FORM_UPDATE: 'caixas/FECHAMENTOS_FORM_UPDATE',
}

// Reducers
export default function caixasReducers(state = initialState, action) {
  switch (action.type) {
  // RESET DEFAULT
  case Types.RESET:
    return {
      ...state,
      ...initialState,
    }
  // CAIXAS
  case Types.CAIXAS_LOADING:
    return {
      ...state,
      loading: { ...state.loading, ...action.payload },
    }
  case Types.CAIXAS_LIST_UPDATE:
    return {
      ...state,
      list: action.payload,
    }
  case Types.CAIXAS_FORM_UPDATE:
    return {
      ...state,
      form: { ...state.form, ...action.payload },
    }
  // ABERTO
  case Types.CAIXAS_ABERTO_UPDATE:
    return {
      ...state,
      aberto: { ...state.aberto, ...action.payload },
    }
  // MOVIMENTOS
  case Types.CAIXAS_MOVIMENTOS_LOADING:
    return {
      ...state,
      movimentos: {
        ...state.movimentos,
        loading: { ...state.movimentos.loading, ...action.payload },
      },
    }
  case Types.CAIXAS_MOVIMENTOS_LIST_UPDATE:
    return {
      ...state,
      movimentos: { ...state.movimentos, list: action.payload },
    }
  case Types.CAIXAS_MOVIMENTOS_FORM_UPDATE:
    return {
      ...state,
      movimentos: {
        ...state.movimentos,
        form: { ...state.movimentos.form, ...action.payload },
      },
    }
  // FECHAMENTOS
  case Types.CAIXAS_FECHAMENTOS_LOADING:
    return {
      ...state,
      fechamentos: {
        ...state.fechamentos,
        loading: { ...state.fechamentos.loading, ...action.payload },
      },
    }
  case Types.CAIXAS_FECHAMENTOS_LIST_UPDATE:
    return {
      ...state,
      fechamentos: { ...state.fechamentos, list: action.payload },
    }
  case Types.CAIXAS_FECHAMENTOS_FORM_UPDATE:
    return {
      ...state,
      fechamentos: {
        ...state.fechamentos,
        form: { ...state.fechamentos.form, ...action.payload },
      },
    }
  default:
    return state
  }
}

// Actions Creators
export const Actions = {
  // Store Update (CAIXAS)
  onCaixasLoading: (payload) => ({ type: Types.CAIXAS_LOADING, payload }),
  onCaixasListLoad: (payload) => ({ type: Types.CAIXAS_LIST_UPDATE, payload }),
  onCaixasListUpdate: (itemlist) => (dispatch, getState) => {
    const { caixas: { list } } = getState()
    dispatch({
      type: Types.CAIXAS_LIST_UPDATE,
      payload: list.map((i) => (i.id === itemlist.id ? { ...i, ...itemlist } : i)),
    })
  },
  onCaixasListClean: () => ({ type: Types.CAIXAS_LIST_CLEAN, payload: [] }),
  onCaixasFormUpdate: (payload) => ({ type: Types.CAIXAS_FORM_UPDATE, payload }),
  onCaixasFormClean: () => ({ type: Types.CAIXAS_FORM_UPDATE, payload: initialState.form }),
  onCaixasDataById: (id) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onCaixasLoading({ find: true }))
    // Chamada API
    getCaixasApi({ token, id }).then((payload) => {
      dispatch(Actions.onCaixasLoading({ find: false }))
      dispatch(Actions.onCaixasFormUpdate(_.isObject(payload) ? payload : initialState.form))
    })
  },
  onCaixasDataList: (filter) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onCaixasLoading({ list: true }))
    // Chamada API
    listCaixasApi({ token, filter }).then((payload) => {
      dispatch(Actions.onCaixasLoading({ list: false }))
      dispatch(Actions.onCaixasListLoad(payload || initialState.list))
    })
  },
  onCaixasDataSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    // Chamada API
    if (dto.id) {
      dispatch(Actions.onCaixasLoading({ edit: true }))
      editCaixasApi({ token, dto, id: dto.id }).then((payload) => {
        dispatch(Actions.onCaixasLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success(`Caixa ${dto.id} atualizado com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    } else {
      dispatch(Actions.onCaixasLoading({ add: true }))
      addCaixasApi({ token, dto }).then((payload) => {
        dispatch(Actions.onCaixasLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success(`Caixa n° "${payload.id}" aberto com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    }
  },
  onCaixasDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onCaixasLoading({ del: true }))
    // Chamada API
    deleteCaixasApi({ token, id }).then((payload) => {
      dispatch(Actions.onCaixasLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Empresa excluída com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    })
  },
  // * ABERTO
  onCaixasAbertoUpdate: (payload) => ({ type: Types.CAIXAS_ABERTO_UPDATE, payload }),
  onCaixasAbertoData: () => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onCaixasLoading({ aberto: true }))
    getCaixasAbertoApi({ token }).then((res) => {
      dispatch(Actions.onCaixasLoading({ aberto: false }))
      if (res && res.request.status === 200) {
        dispatch(Actions.onCaixasAbertoUpdate(res.data))
      } else {
        dispatch(Actions.onCaixasAbertoUpdate(initialState.aberto))
      }
    }).catch(() => {
      dispatch(Actions.onCaixasLoading({ aberto: false }))
      dispatch(Actions.onCaixasAbertoUpdate(initialState.aberto))
    })
  },
  // * MOVIMENTOS
  onCaixasMovimentosLoading: (payload) => ({ type: Types.CAIXAS_MOVIMENTOS_LOADING, payload }),
  onCaixasMovimentosListLoad: (payload) => ({ type: Types.CAIXAS_MOVIMENTOS_LIST_UPDATE, payload }),
  onCaixasMovimentosListUpdate: (itemlist) => (dispatch, getState) => {
    const { caixas: { movimentos: { list } } } = getState()
    dispatch({
      type: Types.CAIXAS_MOVIMENTOS_LIST_UPDATE,
      payload: list.map((i) => (i.id === itemlist.id ? { ...i, ...itemlist } : i)),
    })
  },
  onCaixasMovimentosListClean: () => ({ type: Types.CAIXAS_MOVIMENTOS_LIST_CLEAN, payload: [] }),
  onCaixasMovimentosFormUpdate: (payload) => ({
    type: Types.CAIXAS_MOVIMENTOS_FORM_UPDATE, payload,
  }),
  onCaixasMovimentosFormClean: () => ({
    type: Types.CAIXAS_MOVIMENTOS_FORM_UPDATE, payload: initialState.movimentos.form,
  }),
  onCaixasMovimentosDataById: (id) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onCaixasLoading({ find: true }))
    // Chamada API
    getCaixasMovimentosApi({ token, id }).then((payload) => {
      dispatch(Actions.onCaixasLoading({ find: false }))
      dispatch(Actions.onCaixasFormUpdate(_.isObject(payload) ? payload : initialState.form))
    })
  },
  onCaixasMovimentosDataList: (filter) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onCaixasLoading({ list: true }))
    // Chamada API
    listCaixasMovimentosApi({ token, filter }).then((payload) => {
      dispatch(Actions.onCaixasLoading({ list: false }))
      dispatch(Actions.onCaixasListLoad(payload || initialState.list))
    }).catch(() => dispatch(Actions.onCaixasLoading({ list: false })))
  },
  // * FINALIZAR PAGAMENTO
  onCaixasMovimentosDataSave: (dto, callback = null, onError = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    // Chamada API
    if (dto.id) {
      dispatch(Actions.onCaixasLoading({ edit: true }))
      editCaixasMovimentosApi({ token, dto, id: dto.id }).then((payload) => {
        dispatch(Actions.onCaixasLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success('Pagamento atualizado com sucesso!', 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      }).catch(() => dispatch(Actions.onCaixasLoading({ edit: false })))
    } else {
      dispatch(Actions.onCaixasLoading({ add: true }))
      addCaixasMovimentosApi({ token, dto }).then((payload) => {
        dispatch(Actions.onCaixasLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success('Pagamento finalizado com sucesso!', 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
        // Deu ruim
        if (!payload && _.isFunction(onError)) {
          onError()
        }
      }).catch(() => {
        if (_.isFunction(onError)) {
          onError()
        }
        dispatch(Actions.onCaixasLoading({ add: false }))
      })
    }
  },
  onCaixasMovimentosDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onCaixasLoading({ del: true }))
    // Chamada API
    deleteCaixasMovimentosApi({ token, id }).then((payload) => {
      dispatch(Actions.onCaixasLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Empresa excluída com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    }).catch(() => dispatch(Actions.onCaixasLoading({ del: false })))
  },
  // * FECHAMENTOS
  onCaixasFechamentosLoading: (payload) => ({ type: Types.CAIXAS_FECHAMENTOS_LOADING, payload }),
  onCaixasFechamentosListLoad: (payload) => ({
    type: Types.CAIXAS_FECHAMENTOS_LIST_UPDATE, payload,
  }),
  onCaixasFechamentosListUpdate: (itemlist) => (dispatch, getState) => {
    const { caixas: { movimentos: { list } } } = getState()
    dispatch({
      type: Types.CAIXAS_FECHAMENTOS_LIST_UPDATE,
      payload: list.map((i) => (i.id === itemlist.id ? { ...i, ...itemlist } : i)),
    })
  },
  onCaixasFechamentosListClean: () => ({ type: Types.CAIXAS_FECHAMENTOS_LIST_CLEAN, payload: [] }),
  onCaixasFechamentosFormUpdate: (payload) => ({
    type: Types.CAIXAS_FECHAMENTOS_FORM_UPDATE, payload,
  }),
  onCaixasFechamentosFormClean: () => ({
    type: Types.CAIXAS_FECHAMENTOS_FORM_UPDATE, payload: initialState.fechamentos.form,
  }),
  onCaixasFechamentosDataById: (id) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onCaixasFechamentosLoading({ find: true }))
    // Chamada API
    getCaixasFechamentosApi({ token, id }).then((payload) => {
      dispatch(Actions.onCaixasFechamentosLoading({ find: false }))
      dispatch(Actions.onCaixasFormUpdate(_.isObject(payload) ? payload : initialState.form))
    }).catch(() => dispatch(Actions.onCaixasFechamentosLoading({ find: false })))
  },
  onCaixasFechamentosDataList: (filter) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onCaixasFechamentosLoading({ list: true }))
    // Chamada API
    listCaixasFechamentosApi({ token, filter }).then((payload) => {
      dispatch(Actions.onCaixasFechamentosLoading({ list: false }))
      dispatch(Actions.onCaixasListLoad(payload || initialState.list))
    }).catch(() => dispatch(Actions.onCaixasFechamentosLoading({ list: false })))
  },
  // * FECHAMENTO
  onCaixasFechamentosDataSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    // Chamada API
    if (dto.id) {
      dispatch(Actions.onCaixasFechamentosLoading({ edit: true }))
      editCaixasFechamentosApi({ token, dto, id: dto.id }).then((payload) => {
        dispatch(Actions.onCaixasFechamentosLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success(`Caixa ${dto.caixasId} atualizado com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      }).catch(() => dispatch(Actions.onCaixasFechamentosLoading({ edit: false })))
    } else {
      dispatch(Actions.onCaixasFechamentosLoading({ add: true }))
      addCaixasFechamentosApi({ token, dto }).then((payload) => {
        dispatch(Actions.onCaixasFechamentosLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success(`Caixa n° "${dto.caixasId}" fechado com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      }).catch(() => dispatch(Actions.onCaixasFechamentosLoading({ add: false })))
    }
  },
  onCaixasFechamentosDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onCaixasLoading({ del: true }))
    // Chamada API
    deleteCaixasFechamentosApi({ token, id }).then((payload) => {
      dispatch(Actions.onCaixasLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Empresa excluída com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    }).catch(() => dispatch(Actions.onCaixasFechamentosLoading({ del: false })))
  },
}
