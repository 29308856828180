import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid, Paper, Tabs, Tab } from '@material-ui/core'
import _ from 'lodash'

// * Utils
import {
  hasRole,
  DateIsoToBrFormat,
  MoneyFormatBr,
  parseToNum,
  parseToDec,
  DateIsoToDateOnly,
} from 'utils'
import UserRoles from 'utils/enums/UserRoles'

// * Api's
import { listFiliaisApi } from 'api/filiais'
// * Actions
import { Actions as TabelasPrecoActions } from 'store/ducks/tabelaspreco'
import { Actions as TabelasPrecoItensActions } from 'store/ducks/tabelasprecoitens'
import { Actions as FiliaisActions } from 'store/ducks/filiais'
// * Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import TableDataComp from 'components/Table'
import DialogComp from 'components/Dialogs'
import FormSearchComp from 'components/Forms/Search'
import TabelasPrecoFormComp from './components/TabelasPrecoForm'
import TabelasPrecoItensFormComp from './components/TabelasPrecoItensForm'
// * Styles
import useStyles from './styles'

// * Stateless component
const TabelasPrecoPage = ({
  token,
  user,
  // Tabela de preço (root)
  tabelaspreco,
  onTabelasPrecoFormUpdate,
  onTabelasPrecoFormClean,
  onTabelasPrecoDataList,
  onTabelasPrecoDataSave,
  onTabelasPrecoDataDelete,
  onTabelasPrecoAddFiliais,
  // Filiais
  filiais,
  // onFiliaisFormUpdate,
  onFiliaisFormClean,
  // onFiliaisDataList,
  onFiliaisListLoad,
  // Itens da tabela de preço
  tabelasprecoitens,
  onTabelasPrecoItensFormUpdate,
  onTabelasPrecoItensFormClean,
  onTabelasPrecoItensListLoad,
  // onTabelasPrecoItensListUpdate,
  onTabelasPrecoDelFiliais,
  onTabelasPrecoItensDataSave,
  onTabelasPrecoItensDataDelete,
}) => {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const [dialogForm, setDialogForm] = useState({ open: false, title: '' })
  const [dialogAskDelete, setDialogAskDelete] = useState({
    open: false,
    title: '',
    data: null,
  })
  const [dialogFormFiliais, setDialogFormFiliais] = useState({
    open: false,
    title: '',
  })
  const [dialogAskDeleteFiliais, setDialogAskDeleteFiliais] = useState({
    open: false,
    title: '',
    data: null,
  })
  const [dialogFormItens, setDialogFormItens] = useState({
    open: false,
    title: '',
  })
  const [dialogAskDeleteItens, setDialogAskDeleteItens] = useState({
    open: false,
    title: '',
    data: null,
  })
  const [tabSelected, setTabSelected] = useState(0)
  const [tabelasPrecoSelected, setTabelasPrecoSelected] = useState({
    id: 0,
    filiais: [],
    descricao: '',
    dataInicial: '',
    dataFinal: '',
    tabelasPrecoItens: [],
    status: '',
    statusDescricao: '',
  })
  const [genericSearch, setGenericSearch] = useState({
    iptSearch: '',
    loading: false,
    selected: {},
    data: [],
  })
  const isOrcamentos = hasRole(
    user?.grupos ?? [],
    UserRoles?.ROLE_ORCAMENTOS?.codigo ?? ''
  )

  const handleFiltraTabelasPreco = filter => {
    onTabelasPrecoDataList(filter)
  }
  const handleFiltraFiliais = pTabelaPreco => {
    const { filiais: data = [] } = { ...pTabelaPreco }
    onFiliaisListLoad({ page: 0, rows: data.length, data })
  }

  const handleFiltraTabelasPrecoItens = pTabelaPreco => {
    const { tabelasPrecoItens: data = [] } = { ...pTabelaPreco }
    onTabelasPrecoItensListLoad({ page: 0, rows: data.length, data })
  }
  const handleRemoveTabelasPrecoItens = tabelasPrecosItensId => {
    const { tabelasPrecoItens: data = [] } = { ...tabelasPrecoSelected }
    const tabelasPrecoItensNew = data.filter(i => i.id !== tabelasPrecosItensId)
    setTabelasPrecoSelected(s => ({
      ...s,
      tabelasPrecoItens: tabelasPrecoItensNew,
    }))
    handleFiltraTabelasPrecoItens({ tabelasPrecoItens: tabelasPrecoItensNew })
    handleFiltraTabelasPreco({ search })
  }

  const handleSelectTab = (event, newValue) => setTabSelected(newValue)
  const handleSearch = () => {
    switch (tabSelected) {
      case 0:
        handleFiltraTabelasPreco({ search })
        break
      case 1:
        handleFiltraFiliais(tabelasPrecoSelected)
        break
      default:
        handleFiltraTabelasPrecoItens(tabelasPrecoSelected)
    }
  }

  // Table/Aba tabelaspreco (INICIO)

  const handleOpenCloseDialogTabelasPreco = (open, title = null) => {
    if (title) {
      setDialogForm(s => ({ ...s, open, title }))
    } else {
      setDialogForm(s => ({ ...s, open }))
    }
  }

  const handleOpenCloseDialogAskDelete = (open, title = null, data = null) => {
    setDialogAskDelete(s => ({
      ...s,
      open,
      title,
      data,
    }))
  }

  const handleSelectTabelasPreco = pTabelasPreco => {
    setTabelasPrecoSelected(pTabelasPreco)
    setTabSelected(1)
    handleFiltraFiliais(pTabelasPreco)
    handleFiltraTabelasPrecoItens(pTabelasPreco)
  }

  const tableTabelasPrecoCols = [
    {
      key: 'descricao',
      title: 'DESCRIÇÃO',
      onClick: row => handleSelectTabelasPreco(row),
    },
    {
      key: 'dataInicial',
      title: 'Data Inicial',
      format: date => DateIsoToBrFormat(date, 'DD/MM/YYYY'),
    },
    {
      key: 'dataFinal',
      title: 'Data Final',
      format: date => DateIsoToBrFormat(date, 'DD/MM/YYYY'),
    },
    { key: 'statusDescricao', title: 'STATUS' },
  ]

  const tableTabelasPrecoActions = [
    {
      key: 'editar',
      title: 'Editar',
      icon: 'edit',
      action: row => {
        onTabelasPrecoFormUpdate({
          ...row,
          isCopy: false,
          dataInicial: DateIsoToDateOnly(row.dataInicial),
          dataFinal: DateIsoToDateOnly(row.dataFinal),
        })
        handleOpenCloseDialogTabelasPreco(true, 'Edição:')
      },
    },
    {
      key: 'copiar',
      title: 'Copiar',
      icon: 'content_copy',
      action: row => {
        onTabelasPrecoFormUpdate({
          ...row,
          isCopy: true,
          descricao: `${row.descricao} COPIA`,
          dataInicial: DateIsoToDateOnly(row.dataInicial),
          dataFinal: DateIsoToDateOnly(row.dataFinal),
        })
        handleOpenCloseDialogTabelasPreco(true, 'Copiar:')
      },
    },
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: row => handleOpenCloseDialogAskDelete(true, 'Excluir?', row),
    },
  ]
  // Table/Aba tabelaspreco (FIM)

  // Table/Aba filiais (INICIO)

  const handleOpenCloseDialogFiliais = (open, title = null) => {
    if (title) {
      setDialogFormFiliais(s => ({ ...s, open, title }))
    } else {
      setDialogFormFiliais(s => ({ ...s, open }))
    }
  }

  const handleOpenCloseDialogAskDeleteFiliais = (
    open,
    title = null,
    data = null
  ) => {
    setDialogAskDeleteFiliais(s => ({
      ...s,
      open,
      title,
      data,
    }))
  }

  const tableFiliaisCols = [
    { key: 'cnpj', title: 'CNPJ' },
    { key: 'descricao', title: 'DESCRIÇÃO' },
    { key: 'cidade', title: 'CIDADE' },
    { key: 'estado', title: 'UF' },
    { key: 'statusDescricao', title: 'STATUS' },
  ]

  const tableFiliaisActions = [
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: row =>
        handleOpenCloseDialogAskDeleteFiliais(true, 'Excluir?', row),
    },
  ]

  // Table/Aba filiais (FIM)

  // Table/Aba Tabelas Preco Itens (INICIO)

  const handleOpenCloseDialogItens = (open, title = null) => {
    if (title) {
      setDialogFormItens(s => ({ ...s, open, title }))
    } else {
      setDialogFormItens(s => ({ ...s, open }))
    }
  }

  const handleOpenCloseDialogAskDeleteItens = (
    open,
    title = null,
    data = null
  ) => {
    setDialogAskDeleteItens(s => ({
      ...s,
      open,
      title,
      data,
    }))
  }

  const tableTabelasPrecoItensCols = [
    { key: 'servicos', title: 'DESCRIÇÃO', format: row => row.descricao },
    {
      key: 'valor',
      title: 'VALOR',
      format: valor => `R$ ${MoneyFormatBr(parseToNum(valor))}`,
    },
    { key: 'porteDescricao', title: 'PORTE' },
    { key: 'statusDescricao', title: 'STATUS' },
  ]

  const tableTabelasPrecoItensActions = [
    {
      key: 'editar',
      title: 'Editar',
      icon: 'edit',
      action: row => {
        onTabelasPrecoItensFormUpdate({ ...row, valor: parseToNum(row.valor) })
        handleOpenCloseDialogItens(true, 'Edição de item da tabela de preço:')
      },
    },
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: row => handleOpenCloseDialogAskDeleteItens(true, 'Excluir?', row),
    },
  ]

  // Table/Aba Tabelas Preco Itens (FIM)

  // Generic Search Filials (add/edit) >> (INICIO)
  const handleGenericSearchClean = () =>
    setGenericSearch({
      iptSearch: '',
      loading: false,
      selected: {},
      data: [],
    })
  const handleGenericSearchLoadData = data =>
    setGenericSearch(s => ({ ...s, data }))
  const handleGenericSearchLoading = loading =>
    setGenericSearch(s => ({ ...s, loading }))
  const handleGenericSearchItemSelected = itemSelected => {
    if (_.isEmpty(tabelasPrecoSelected) || !tabSelected) {
      NotificationManager.warning(
        'Não foi possível localizar a tabela de preço.',
        'Ops...',
        8000
      )
    } else {
      const dto = {
        pk: tabelasPrecoSelected.id,
        fk: itemSelected.id,
      }
      onTabelasPrecoAddFiliais(dto, payload => {
        handleOpenCloseDialogFiliais(false)
        setTabelasPrecoSelected(t => ({ ...t, filiais: payload.filiais }))
        handleFiltraFiliais({
          ...tabelasPrecoSelected,
          filiais: payload.filiais,
        })
      })
    }
  }
  const onSearchFiliais = pSearch => {
    handleGenericSearchLoading(true)
    const { empresasId } = user.filiais.find(f => f.id === user.filial)
    return listFiliaisApi({
      token,
      filter: { empresas: empresasId, search: pSearch },
    }).then(payload => {
      handleGenericSearchLoading(false)
      handleGenericSearchLoadData(
        !_.isEmpty(payload) && _.isArray(payload.data)
          ? payload.data.map(({ id, descricao }) => ({ id, descricao }))
          : []
      )
    })
  }
  const handleGenericSearch = pSearch => {
    onSearchFiliais(pSearch)
  }
  // Generic Search << (FIM)

  // Header button Action
  const headerButtons = [
    {
      title: 'Adicionar',
      icon: 'add',
      action: () => {
        switch (tabSelected) {
          case 1:
            // onFiliaisFormClean()
            handleGenericSearchClean()
            handleOpenCloseDialogFiliais(true, 'Cadastro de filial:')
            break
          case 2:
            onTabelasPrecoItensFormClean()
            onTabelasPrecoItensFormUpdate({
              tabelasPrecoId: tabelasPrecoSelected.id,
              tabelasPrecoDescricao: tabelasPrecoSelected.descricao,
              porte: 'P',
            })
            handleOpenCloseDialogItens(true, 'Cadastro de itens:')
            break
          default:
            onTabelasPrecoFormClean()
            handleOpenCloseDialogTabelasPreco(true, 'Cadastro de empresa:')
        }
      },
    },
  ]
  // Header button Action

  useEffect(() => {
    document.title = 'Backoffice - Tabelas de Preço'
    handleFiltraTabelasPreco({ search })
  }, [])

  const tabelasPrecoDesc =
    tabSelected === 1 && tabelasPrecoSelected.id
      ? `[${tabelasPrecoSelected.id}] - ${tabelasPrecoSelected.descricao}`
      : ''

  return (
    <DefaultPage>
      <PageHeaderComp
        title="Tabelas de Preço"
        subtitle={tabelasPrecoDesc}
        value={search}
        onChange={evt => setSearch(evt.target.value)}
        onClick={handleSearch}
        onKeyPress={evt => {
          if (evt.charCode === 13) {
            handleSearch()
          }
        }}
        buttons={headerButtons}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Paper className={classes.root}>
            <Tabs
              value={tabSelected}
              onChange={handleSelectTab}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Tabelas de Preço" />
              <Tab label="Filiais" disabled={!tabelasPrecoSelected.id} />
              <Tab label="Itens" disabled={!tabelasPrecoSelected.id} />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* Listagem das tabelaspreco */}
          {tabSelected === 0 && (
            <TableDataComp
              loading={tabelaspreco.loading.list}
              size="small"
              rows={tabelaspreco.list.data}
              cols={tableTabelasPrecoCols}
              actions={tableTabelasPrecoActions}
            />
          )}
          {/* Listagem das filiais */}
          {tabSelected === 1 && (
            <TableDataComp
              loading={filiais.loading.list}
              size="small"
              rows={filiais.list.data}
              cols={tableFiliaisCols}
              actions={tableFiliaisActions}
            />
          )}
          {/* Listagem das itens */}
          {tabSelected === 2 && (
            <TableDataComp
              loading={tabelasprecoitens.loading.list}
              size="small"
              rows={tabelasprecoitens.list.data}
              cols={tableTabelasPrecoItensCols}
              actions={tableTabelasPrecoItensActions}
            />
          )}
        </Grid>
      </Grid>
      {/* Dialog TabelasPrecoForm (add/edit) */}
      <DialogComp
        open={dialogForm.open}
        title={dialogForm.title}
        onClose={() => handleOpenCloseDialogTabelasPreco(false)}
        actions={[
          {
            label: 'Cancelar',
            onClick: () => {
              onTabelasPrecoFormClean()
              handleOpenCloseDialogTabelasPreco(false)
            },
          },
          {
            label: 'Salvar',
            onClick: () => {
              onTabelasPrecoDataSave(tabelaspreco.form, () => {
                onTabelasPrecoFormClean()
                handleOpenCloseDialogTabelasPreco(false)
                handleFiltraTabelasPreco({ search })
              })
            },
          },
        ]}
      >
        <TabelasPrecoFormComp
          data={tabelaspreco.form}
          onChange={onTabelasPrecoFormUpdate}
        />
      </DialogComp>
      {/* Dialog TabelasPrecoForm Pergunta DELETE */}
      <DialogComp
        open={dialogAskDelete.open}
        title={dialogAskDelete.title}
        onClose={() => handleOpenCloseDialogAskDelete(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDelete(false),
          },
          {
            label: 'SIM',
            onClick: () =>
              onTabelasPrecoDataDelete(dialogAskDelete.data.id, () => {
                handleOpenCloseDialogAskDelete(false)
                handleFiltraTabelasPreco({ search })
              }),
          },
        ]}
      >
        {`Você deseja realmente excluir a tabela de preço "${
          dialogAskDelete.data ? dialogAskDelete.data.descricao : ''
        }"?`}
      </DialogComp>
      {/* Dialog Filiais */}
      <DialogComp
        open={dialogFormFiliais.open}
        title={dialogFormFiliais.title}
        size="md"
        onClose={() => handleOpenCloseDialogFiliais(false)}
        actions={[
          {
            label: 'Cancelar',
            onClick: () => {
              onFiliaisFormClean()
              handleOpenCloseDialogFiliais(false)
            },
          },
        ]}
      >
        {/* FormSearch */}
        <FormSearchComp
          onSelectItem={handleGenericSearchItemSelected}
          onSearch={handleGenericSearch}
          searching={genericSearch.loading}
          data={genericSearch.data || []}
        />
      </DialogComp>
      {/* Dialog Filiais Pergunta DELETE */}
      <DialogComp
        open={dialogAskDeleteFiliais.open}
        title={dialogAskDeleteFiliais.title}
        onClose={() => handleOpenCloseDialogAskDeleteFiliais(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDeleteFiliais(false),
          },
          {
            label: 'SIM',
            onClick: () =>
              onTabelasPrecoDelFiliais(
                {
                  pk: tabelasPrecoSelected.id,
                  fk: dialogAskDeleteFiliais.data.id,
                },
                payload => {
                  handleOpenCloseDialogAskDeleteFiliais(false)
                  setTabelasPrecoSelected(payload)
                  handleFiltraFiliais(payload)
                }
              ),
          },
        ]}
      >
        {`Você deseja realmente excluir a filial "${
          dialogAskDeleteFiliais.data
            ? dialogAskDeleteFiliais.data.descricao
            : ''
        }"?`}
      </DialogComp>
      {/* Dialog TabelasPrecoItensForm (add/edit) */}
      <DialogComp
        open={dialogFormItens.open}
        title={dialogFormItens.title}
        onClose={() => handleOpenCloseDialogItens(false)}
        actions={[
          {
            label: 'Cancelar',
            onClick: () => {
              onTabelasPrecoItensFormClean()
              handleOpenCloseDialogItens(false)
            },
          },
          {
            label: 'Salvar',
            onClick: () => {
              const dto = {
                ...tabelasprecoitens.form,
                valor: parseToDec(tabelasprecoitens.form.valor),
                porte: tabelasprecoitens?.form?.porte ?? 'P',
              }
              onTabelasPrecoItensDataSave(dto, payload => {
                onTabelasPrecoItensFormClean()
                handleOpenCloseDialogItens(false)
                handleFiltraTabelasPreco({ search })
                const { tabelasPrecoItens = [] } = { ...tabelasPrecoSelected }
                if (dto.id) {
                  // Atualizando o item
                  const condicionalUpdate = i =>
                    i.id === payload.id ? payload : i
                  const tabelasPrecoItensNew = tabelasPrecoItens.map(
                    condicionalUpdate
                  )
                  setTabelasPrecoSelected({
                    ...tabelasPrecoSelected,
                    tabelasPrecoItens: tabelasPrecoItensNew,
                  })
                  handleFiltraTabelasPrecoItens({
                    ...tabelasPrecoSelected,
                    tabelasPrecoItens: tabelasPrecoItensNew,
                  })
                } else {
                  // Add novo item
                  setTabelasPrecoSelected({
                    ...tabelasPrecoSelected,
                    tabelasPrecoItens: [...tabelasPrecoItens, payload],
                  })
                  handleFiltraTabelasPrecoItens({
                    ...tabelasPrecoSelected,
                    tabelasPrecoItens: [...tabelasPrecoItens, payload],
                  })
                }
              })
            },
          },
        ]}
      >
        <TabelasPrecoItensFormComp
          token={token}
          isOrcamentos={isOrcamentos}
          data={tabelasprecoitens.form}
          onChange={onTabelasPrecoItensFormUpdate}
        />
      </DialogComp>
      {/* Dialog TabelasPrecoItensForm Pergunta DELETE */}
      <DialogComp
        open={dialogAskDeleteItens.open}
        title={dialogAskDeleteItens.title}
        onClose={() => handleOpenCloseDialogAskDeleteItens(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDeleteItens(false),
          },
          {
            label: 'SIM',
            onClick: () =>
              onTabelasPrecoItensDataDelete(
                dialogAskDeleteItens.data.id,
                () => {
                  handleOpenCloseDialogAskDeleteItens(false)
                  handleRemoveTabelasPrecoItens(dialogAskDeleteItens.data.id)
                }
              ),
          },
        ]}
      >
        {`Você deseja realmente excluir o item "${
          dialogAskDeleteItens.data
            ? `${
                dialogAskDeleteItens.data.servicos.descricao
              }/ ${`R$ ${MoneyFormatBr(
                parseToNum(dialogAskDeleteItens.data.valor)
              )}`}/ ${dialogAskDeleteItens.data.porteDescricao}`
            : ''
        }"?`}
      </DialogComp>
    </DefaultPage>
  )
}

TabelasPrecoPage.propTypes = {
  token: PropTypes.string.isRequired,
  user: PropTypes.shape().isRequired,
  // Tabelas de preços
  tabelaspreco: PropTypes.shape().isRequired,
  onTabelasPrecoFormUpdate: PropTypes.func.isRequired,
  onTabelasPrecoFormClean: PropTypes.func.isRequired,
  onTabelasPrecoDataList: PropTypes.func.isRequired,
  onTabelasPrecoDataSave: PropTypes.func.isRequired,
  onTabelasPrecoDataDelete: PropTypes.func.isRequired,
  onTabelasPrecoAddFiliais: PropTypes.func.isRequired,
  onTabelasPrecoDelFiliais: PropTypes.func.isRequired,
  // Filiais
  filiais: PropTypes.shape().isRequired,
  // onFiliaisFormUpdate: PropTypes.func.isRequired,
  onFiliaisFormClean: PropTypes.func.isRequired,
  // onFiliaisDataList: PropTypes.func.isRequired,
  onFiliaisListLoad: PropTypes.func.isRequired,
  // onFiliaisDataDelete: PropTypes.func.isRequired,
  // Itens da tabela de preço
  tabelasprecoitens: PropTypes.shape().isRequired,
  onTabelasPrecoItensFormUpdate: PropTypes.func.isRequired,
  onTabelasPrecoItensFormClean: PropTypes.func.isRequired,
  onTabelasPrecoItensListLoad: PropTypes.func.isRequired,
  onTabelasPrecoItensDataSave: PropTypes.func.isRequired,
  onTabelasPrecoItensDataDelete: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  token: state.auth.session.token,
  user: state.auth.session.user,
  tabelaspreco: state.tabelaspreco,
  filiais: state.filiais,
  tabelasprecoitens: state.tabelasprecoitens,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...TabelasPrecoActions,
      ...TabelasPrecoItensActions,
      ...FiliaisActions,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(TabelasPrecoPage)
