import React from 'react'
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types'

export const PlacaMaskCustom = (props) => {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /\d/, /[a-zA-Z0-9]/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

PlacaMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

export const CPFMaskCustom = (props) => {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

CPFMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

export const CNPJMaskCustom = (props) => {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

CNPJMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

export const CelularMaskCustom = (props) => {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

CelularMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

export const TelefoneMaskCustom = (props) => {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

TelefoneMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

export const TelefoneMaskCustom2 = (props) => {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/[1-9]/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

TelefoneMaskCustom2.propTypes = {
  inputRef: PropTypes.func.isRequired,
}
