import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Icon,
  Card,
  CardMedia,
  CardActions,
  CardContent,
  CardActionArea,
  IconButton,
  Typography,
  Tooltip,
} from '@material-ui/core'

import { parseToNum, MoneyFormatBr } from 'utils'

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    // maxWidth: 275,
    minHeight: 580
  },
  media: {
    maxWidth: 420,
    maxHeight: 300,
    minHeight: 300,
  },
  title: {
    // fontSize: 16,
    lineHeight: 1.2,
    minHeight: 40,
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
  },
  pos: {
    marginBottom: 12,
  },
  priceBlock: {
    paddingTop: 8,
  },
  price: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#000',
  },
  btnAdd: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 16,
  },
})

const CardProdutoComp = (props) => {
  const {
    imageUrl,
    title,
    subtitle,
    value,
    valuePromo,
    addItemToFavorite,
    addItemToCart,
  } = props
  const classes = useStyles()
  return (
    <Card className={classes.root} variant="outlined">
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={imageUrl}
          title={title}
        />
        <CardContent>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h2" className={classes.title}>
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {subtitle}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" className={classes.priceBlock}>
              {valuePromo ? (
                <>
                  <del className={classes.priceOld}>{`R$ ${MoneyFormatBr(parseToNum(value))}`}</del>
                  <br />
                  <span className={classes.price}>{`R$ ${MoneyFormatBr(parseToNum(valuePromo))}`}</span>
                </>
              ) : <span className={classes.price}>{`R$ ${MoneyFormatBr(parseToNum(value))}`}</span>}
            </Typography>
          </CardContent>
        </CardContent>
      </CardActionArea>
      <CardActions disableSpacing className={classes.btnAdd}>
        <Tooltip title="Salvar para mais tarde">
          <IconButton size="small" color="primary" onClick={addItemToFavorite}>
            <Icon>star_outline</Icon>
          </IconButton>
        </Tooltip>
        <Tooltip title="Adicionar no carrinho">
          <IconButton size="small" color="secondary" onClick={addItemToCart}>
            <Icon>add_shopping_cart</Icon>
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  )
}

CardProdutoComp.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  valuePromo: PropTypes.number,
  addItemToFavorite: PropTypes.func.isRequired,
  addItemToCart: PropTypes.func.isRequired,
}
CardProdutoComp.defaultProps = {
  valuePromo: 0,
}

export default CardProdutoComp
