import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
    marginTop: 50,
  },
  card: {
    maxWidth: 345,
    maxHeight: 800,
  },
  media: {
    // ⚠️ object-fit is not supported by IE11.
    objectFit: 'cover',
  },
  iptLogin: {
    marginBottom: 10,
  },
  reCaptcha: {
    marginTop: 15,
    marginLeft: 5,
  },
  margin: {
    marginTop: 10,
  },
  containerActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  center: {
    display: 'flex',
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  btnContainerEntrar: {
    marginBottom: 15,
  },
  wrapper: {
    margin: 10,
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

export default useStyles
