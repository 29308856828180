import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { NotificationContainer } from 'react-notifications'
import { MuiThemeProvider } from '@material-ui/core/styles'

// ? Components
import { theme } from 'constants/theme'
// import DialogComp from 'components/Dialogs'
import PrivateRoute from 'components/PrivateRoute'
// * Auth
import SignInPage from 'pages/auth/signin'
import ForgotPage from 'pages/auth/forgot'
// * Dashboard
import DashboardPage from 'pages/dashboard'
// * Empresas
import EmpresasPage from 'pages/empresas'
// * Funcionarios
import FuncionariosPage from 'pages/funcionarios'
// * Usuários
import UsuariosPage from 'pages/usuarios'
// * Serviços
import ServicosPage from 'pages/servicos'
// * Tabelas de preço
import TabelasPrecoPage from 'pages/tabelaspreco'
// * Clientes/Veículos
import ClientesPage from 'pages/clientes'
// * Pagamentos (Condição e forma)
import PagamentosPage from 'pages/pagamentos'
// * Ordem de Serviço
import OrdemServicoPage from 'pages/ordemservico'
// * Caixas
// import CaixasPage from 'pages/caixas'
// * Fisco
import FiscoNfseRpsPage from 'pages/fiscal/nfse/rps'

// * Vendas
import PedidosVendasDashboardPage from 'pages/vendas/pages/dashboard'
import PedidosVendasCarrinhoPage from 'pages/vendas/pages/carrinho'
import PedidosVendasMeusPedidosPage from 'pages/vendas/pages/meuspedidos'
import PedidosVendasDetalhesPedidosPage from 'pages/vendas/pages/detalhespedidos'

// ? Relatórios
// * Caixas
import RelatoriosCaixasPage from 'pages/relatorios/caixas'
// * Faturamentos
import RelatoriosFaturamentosPage from 'pages/relatorios/faturamentos'
import RelatoriosFaturamentosPlanilhaPage from 'pages/relatorios/faturamentos/planilha'
// * Serviços
import RelatoriosServicosPage from 'pages/relatorios/servicos'
// * Clientes
import RelatoriosClientesPage from 'pages/relatorios/clientes'
import RelatoriosClientesOffPage from 'pages/relatorios/clientes/off'
// * Orcamentos
import OrcamentosListPage from 'pages/orcamentos/pages/list'

//* Produtos
import ProdutosPage from 'pages/produtos'
//* Categorias
import CategoriasPage from 'pages/categorias'

const Router = ({ history }) => (
  <ConnectedRouter history={history}>
    <MuiThemeProvider theme={theme}>
      <>
        <Switch>
          <PrivateRoute exact path="/" />
          <Route path="/relatorios/caixas" component={RelatoriosCaixasPage} />
          <Route path="/relatorios/faturamentos/planilha" component={RelatoriosFaturamentosPlanilhaPage} />
          <Route path="/relatorios/faturamentos" component={RelatoriosFaturamentosPage} />
          <Route path="/relatorios/servicos" component={RelatoriosServicosPage} />
          <Route path="/relatorios/clientes/off" component={RelatoriosClientesOffPage} />
          <Route path="/relatorios/clientes" component={RelatoriosClientesPage} />
          {/* Vendas */}
          <Route path="/vendas/detalhes-pedidos/:id" component={PedidosVendasDetalhesPedidosPage} />
          <Route path="/vendas/meus-pedidos" component={PedidosVendasMeusPedidosPage} />
          <Route path="/vendas/carrinho" component={PedidosVendasCarrinhoPage} />
          <Route path="/vendas" exact component={PedidosVendasDashboardPage} />
          <Route path="/fisco/nfse/rps" component={FiscoNfseRpsPage} />
          <Route path="/orcamentos" component={OrcamentosListPage} />
          <Route path="/ordemservico" component={OrdemServicoPage} />
          <Route path="/pagamentos" component={PagamentosPage} />
          <Route path="/clientes" component={ClientesPage} />
          <Route path="/tabelaspreco" component={TabelasPrecoPage} />
          <Route path="/categorias" component={CategoriasPage} />
          <Route path="/produtos" component={ProdutosPage} />
          <Route path="/servicos" component={ServicosPage} />
          <Route path="/usuarios" component={UsuariosPage} />
          <Route path="/funcionarios" component={FuncionariosPage} />
          <Route path="/empresas" component={EmpresasPage} />
          <Route path="/dashboard" component={DashboardPage} />
          <Route path="/forgot" component={ForgotPage} />
          <Route path="/login" component={SignInPage} />
        </Switch>
        <NotificationContainer />
      </>
    </MuiThemeProvider>
  </ConnectedRouter>
)

Router.propTypes = {
  history: PropTypes.shape().isRequired,
}

export default Router
