import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const path = '/seguranca/grupos'

export const listGruposApi = ({ token, filter }) => {
  const {
    status = 'L', page = 0, rows = 50, search,
  } = filter

  let queryUrl = `?status=${status}&page=${page}&rows=${rows}`
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl

  return api
    .get(path + queryUrl, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getGruposApi = ({ token, id }) => api
  .get(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const addGruposApi = ({ token, dto }) => api
  .post(path, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const editGruposApi = ({ token, id, dto }) => api
  .put(`${path}/${id}`, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const deleteGruposApi = ({ token, id }) => api
  .delete(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)
