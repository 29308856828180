import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  TextField,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import Person from '@material-ui/icons/Person'
import VpnKey from '@material-ui/icons/VpnKey'
import ReCAPTCHA from 'react-google-recaptcha'

import { Actions as AuthActions } from 'store/ducks/auth'
import { getPublicImage } from 'utils'
import useStyles from './styles'

// Stateless
const SignInPage = ({
  auth, onAuthSignInUpdate, onReset, onAuthSignInAction,
}) => {
  const classes = useStyles()
  const btnLogin = !auth?.signIn?.username || !auth?.signIn?.password
  || !auth.signIn.reCaptcha || auth.loading.signIn
  const [imageHeaderLogin, setImageHeaderLogin] = useState('')

  const handleSignInAction = () => {
    const { username, password, reCaptcha } = auth.signIn
    onAuthSignInAction(username, password, reCaptcha)
  }

  useEffect(() => {
    document.title = 'Portal'
    getPublicImage('login-card-header.png', require('assets/login-card-header.png')).then((image) => setImageHeaderLogin(image))
    onReset()
  }, [])

  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <Grid container direction="row" justify="center" alignItems="center" />
      </Grid>
      <Grid item xs={12} md={4}>
        <div className={[classes.content, classes.center].join(' ')}>
          <Card className={classes.card}>
            <CardMedia
              component="img"
              alt="Logo"
              className={classes.media}
              height="140"
              image={imageHeaderLogin}
              title="Logo"
            />
            <CardContent>
              <form autoComplete="off">
                <Grid item xs={12} md={12} className={classes.margin}>
                  <Typography gutterBottom variant="h5" component="h2">
                    Login
                  </Typography>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="flex-end" className={classes.iptLogin}>
                  <Grid item xs={2} md={2}>
                    <Person />
                  </Grid>
                  <Grid item xs={10} md={10}>
                    <TextField
                      label="Usuário"
                      fullWidth
                      value={auth?.signIn?.username || ''}
                      onChange={(evt) => onAuthSignInUpdate({ username: evt.target.value })}
                    />
                  </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="flex-end">
                  <Grid item xs={2} md={2}>
                    <VpnKey />
                  </Grid>
                  <Grid item xs={10} md={10}>
                    <TextField
                      label="Senha"
                      type="password"
                      value={auth?.signIn?.password || ''}
                      fullWidth
                      autoComplete="new-password"
                      onChange={(evt) => onAuthSignInUpdate({ password: evt.target.value })}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <ReCAPTCHA
                      className={classes.reCaptcha}
                      sitekey="6Lcrq_gUAAAAABTpFtH3MTeOMSxSr5cImyUTftte"
                      onChange={(reCaptcha) => onAuthSignInUpdate({ reCaptcha })}
                    />
                  </Grid>
                </Grid>
              </form>
            </CardContent>
            <CardActions className={classes.containerActions}>
              <Grid
                container
                className={classes.btnContainerEntrar}
                justify="center"
                alignItems="center"
              >
                <div className={classes.wrapper}>
                  <Button
                    disabled={btnLogin}
                    variant="contained"
                    color="primary"
                    onClick={() => handleSignInAction()}
                  >
                    Entrar
                  </Button>
                  {auth?.loading?.signIn && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                      color="secondary"
                    />
                  )}
                </div>
              </Grid>
              <Grid container justify="flex-end" alignItems="center">
                <Link to="/forgot">Esqueceu a senha?</Link>
              </Grid>
              {/*
              <Grid container className={classes.margin} justify="flex-end" alignItems="center">
                <Link to="/signup">Criar conta</Link>
              </Grid>
              */}
            </CardActions>
          </Card>
        </div>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container direction="row" justify="center" alignItems="center" />
      </Grid>
    </Grid>
  )
}

SignInPage.propTypes = {
  auth: PropTypes.shape().isRequired,
  onAuthSignInUpdate: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onAuthSignInAction: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...AuthActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignInPage)
