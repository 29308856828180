import { httpValidState, httpValidErros } from 'utils/http'
import api from '..'

const path = '/empresas/filiais'

export const listFiliaisApi = ({ token, filter }) => {
  const {
    status = '', page = 0, rows = 50, id, empresas, cnpj, razao, search,
  } = filter

  let queryUrl = `?status=${status}&page=${page}&rows=${rows}`
  queryUrl = id ? `${queryUrl}&id=${id}` : queryUrl
  queryUrl = empresas ? `${queryUrl}&empresas=${empresas}` : queryUrl
  queryUrl = cnpj ? `${queryUrl}&cnpj=${cnpj}` : queryUrl
  queryUrl = razao ? `${queryUrl}&razao=${razao}` : queryUrl
  queryUrl = search ? `${queryUrl}&search=${search}` : queryUrl

  return api
    .get(path + queryUrl, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getFiliaisApi = ({ token, id }) => api
  .get(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const addFiliaisApi = ({ token, dto }) => api
  .post(path, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const editFiliaisApi = ({ token, id, dto }) => api
  .put(`${path}/${id}`, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const deleteFiliaisApi = ({ token, id }) => api
  .delete(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

// Metas
export const listFiliaisMetasApi = ({ token, filter }) => {
  const {
    status = '', page = 0, rows = 50, id, filiais, metaInicio, metaFim,
  } = filter

  let queryUrl = `?status=${status}&page=${page}&rows=${rows}`
  queryUrl = id ? `${queryUrl}&id=${id}` : queryUrl
  queryUrl = filiais ? `${queryUrl}&filiais=${filiais}` : queryUrl
  queryUrl = metaInicio ? `${queryUrl}&metaInicio=${metaInicio}` : queryUrl
  queryUrl = metaFim ? `${queryUrl}&metaFim=${metaFim}` : queryUrl

  return api
    .get(`${path}/metas${queryUrl}`, { headers: { Authorization: `Bearer ${token}` } })
    .then(httpValidState)
    .catch(httpValidErros)
}

export const getFiliaisMetasApi = ({ token, id }) => api
  .get(`${path}/metas/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const addFiliaisMetasApi = ({ token, dto }) => api
  .post(`${path}/metas`, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const editFiliaisMetasApi = ({ token, id, dto }) => api
  .put(`${path}/metas/${id}`, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

export const deleteFiliaisMetasApi = ({ token, id }) => api
  .delete(`${path}/metas/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)
