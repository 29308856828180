import { httpValidState, httpValidErros } from 'utils/http'
import api from '../..'

const path = '/produtos/categorias'

/**
 *
 * @param { token, dto } param0
 * @returns
 */
export const addCategoriasApi = ({ token, dto }) => api
  .post(path, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {token, id} param0
 * @returns
 */
export const deleteCategoriasApi = ({ token, id }) => api
  .delete(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)
