// import _ from 'lodash'
// import { NotificationManager } from 'react-notifications'
import {
  getDashboardModel1,
  getDashboardVendasV1,
} from 'api/dashboard'

// State Usuario
const initialState = {
  loading: {
    list: false,
    find: false,
    add: false,
    edit: false,
    del: false,
  },
  model1: {
    clientes: {
      quantNovosClientes: 0,
    },
    ordensservico: {
      quantOsInMonitoramento: 100,
      avgTempoVidaPrevisto: 6000,
      avgTempoVidaRealizado: 6500,
    },
    faturamentos: {
      sumFaturamentoDia: 0,
      faturamentoPorPeriodo: [],
    },
    servicos: {
      top5: [],
    },
  },
  vendasV1: {
    usuariosId: 0,
    usuariosNome: '',
    totalPedidos: 0,
    totalPedidosConfirmados: 0,
    totalPedidosConfirmadosMes: 0,
    totalPedidosEmAberto: 0,
    totalPedidosEmAbertoMes: 0,
    totalPedidosCancelados: 0,
    totalPedidosCanceladosMes: 0,
  },
}

// Actions Types
export const Types = {
  RESET: 'reset',
  DASHBOARD_LOADING: 'dashboard/LOADING',
  DASHBOARD_MODEL1_UPDATE: 'dashboard/MODEL1_UPDATE',
  DASHBOARD_VENDASV1_UPDATE: 'dashboard/VENDASV1_UPDATE',
}

// Reducers
export default function dashboardReducers(state = initialState, action) {
  switch (action.type) {
    // RESET DEFAULT
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      }
    case Types.DASHBOARD_LOADING:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    case Types.DASHBOARD_MODEL1_UPDATE:
      return {
        ...state,
        model1: {
          ...state.model1,
          ...action.payload,
        },
      }
    case Types.DASHBOARD_VENDASV1_UPDATE:
      return {
        ...state,
        vendasV1: {
          ...state.vendasV1,
          ...action.payload,
        },
      }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  // Store Update
  onDashboardLoading: (payload) => ({ type: Types.DASHBOARD_LOADING, payload }),
  onDashboardDataUpdate: (payload) => ({ type: Types.DASHBOARD_MODEL1_UPDATE, payload }),
  onDashboardVendasV1DataUpdate: (payload) => ({ type: Types.DASHBOARD_VENDASV1_UPDATE, payload }),
  onDashboardDataLoad: (filter) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onDashboardLoading({ list: true }))
    // Chamada API
    getDashboardModel1({ token, filter }).then((payload) => {
      dispatch(Actions.onDashboardLoading({ list: false }))
      dispatch(Actions.onDashboardDataUpdate(payload || initialState.model1))
    })
  },
  onDashboardDataLoadVendasV1: (filter) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onDashboardLoading({ list: true }))
    // Chamada API
    getDashboardVendasV1({ token, filter }).then((payload) => {
      dispatch(Actions.onDashboardLoading({ list: false }))
      dispatch(Actions.onDashboardVendasV1DataUpdate(payload || initialState.vendasV1))
    }).catch(() => dispatch(Actions.onDashboardLoading({ list: false })))
  },
}
