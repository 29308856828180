import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  ctnUpload: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  ctnUploadActions: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

export default useStyles
