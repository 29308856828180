import { httpValidState, httpValidErros } from 'utils/http'
import api from '../..'

const path = '/produtos/imagens'
/**
 *
 * @param { token, filter: {
 *  id, categoriaId, tabelaPrecoId, status = 'L', search, page = 0, rows = 50
 * }} param0
 * @returns List of produtos
 */
export const listProdutosImagensApi = ({ token, filter }) => api
  .get(path, {
    headers: { Authorization: `Bearer ${token}` },
    params: filter,
  })
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param { token, id } param0
 * @returns ProdutosDTO
 */
export const getProdutosImagensByIdApi = ({ token, id }) => api
  .get(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param { token, dto } param0
 * @returns
 */
export const addProdutosImagensApi = ({ token, formData }) => api
  .post(path, formData, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } })
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {token, id, dto} param0
 * @returns
 */
export const editProdutosImagensApi = ({ token, id, dto }) => api
  .put(`${path}/${id}`, JSON.stringify(dto), { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

/**
 *
 * @param {token, id} param0
 * @returns
 */
export const deleteProdutosImagensApi = ({ token, id }) => api
  .delete(`${path}/${id}`, { headers: { Authorization: `Bearer ${token}` } })
  .then(httpValidState)
  .catch(httpValidErros)

//* Imagens
