import React from 'react'
import PropTypes from 'prop-types'
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

// Styles
import useStyles from './styles'

// Stateless
const DialogPerguntaComp = ({
  open, scroll, size, title, ask, labelOk, labelCancel, onOk, onCancel, onClose,
}) => {
  const classes = useStyles()
  return (
    <Dialog fullWidth scroll={scroll} onClose={onClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={size}>
      <DialogTitle id="customized-dialog-title" className={classes.dialogTitle}>
        {title}
        <IconButton aria-label="close" className={classes.dialogCloseButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" component="p">
          {ask}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel || onClose} color="primary">
          {labelCancel}
        </Button>
        <Button onClick={onOk} color="primary">
          {labelOk}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogPerguntaComp.propTypes = {
  open: PropTypes.bool.isRequired,
  size: PropTypes.string,
  scroll: PropTypes.string,
  title: PropTypes.string.isRequired,
  ask: PropTypes.string.isRequired,
  labelOk: PropTypes.string,
  labelCancel: PropTypes.string,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onClose: PropTypes.func.isRequired,
}

DialogPerguntaComp.defaultProps = {
  scroll: 'paper',
  size: 'sm',
  labelOk: 'Ok',
  labelCancel: 'Cancelar',
  onCancel: null,
}

export default DialogPerguntaComp
