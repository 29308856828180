import _ from 'lodash'
import { NotificationManager } from 'react-notifications'
import {
  listUsuariosApi,
  getUsuariosApi,
  addUsuariosApi,
  editUsuariosApi,
  deleteUsuariosApi,
  addUsuariosGruposApi,
  deleteUsuariosGruposApi,
  addUsuariosFiliaisApi,
  deleteUsuariosFiliaisApi,
  updateUsuariosFilialApi,
  passwordUsuariosApi,
} from 'api/usuarios'

//* State
const initialState = {
  loading: {
    list: false,
    find: false,
    add: false,
    edit: false,
    del: false,
    updateFilial: false,
  },
  list: { page: 0, rows: 0, data: [] },
  form: {
    id: 0,
    funcionariosId: 0,
    funcionariosNome: '',
    funcionarios: {},
    nome: '',
    email: '',
    usuario: '',
    senha: '',
    resenha: '',
    status: '',
    statusDescricao: '',
    grupos: [],
    filial: {},
    filiais: [],
  },
}

//* Actions Types
export const Types = {
  RESET: 'reset',
  USUARIOS_LOADING: 'usuarios/LOADING',
  USUARIOS_LIST_LOAD: 'usuarios/LIST_LOAD',
  USUARIOS_LIST_UPDATE: 'usuarios/LIST_UPDATE',
  USUARIOS_LIST_CLEAN: 'usuarios/LIST_CLEAN',
  USUARIOS_FORM_UPDATE: 'usuarios/FORM_UPDATE',
}

//* Reducers
export default function usuariosReducers(state = initialState, action) {
  switch (action.type) {
    //* RESET DEFAULT
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      }
    case Types.USUARIOS_LOADING:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    case Types.USUARIOS_LIST_LOAD:
      return {
        ...state,
        list: action.payload,
      }
    case Types.USUARIOS_LIST_UPDATE:
      return {
        ...state,
        list: state.list.map((i) => (i.id === action.payload.id ? { ...i, ...action.payload } : i)),
      }
    case Types.USUARIOS_LIST_CLEAN:
      return {
        ...state,
        list: initialState.list,
      }
    case Types.USUARIOS_FORM_UPDATE:
      return {
        ...state,
        form: { ...state.form, ...action.payload },
      }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  // Store Update
  onUsuariosLoading: (payload) => ({ type: Types.USUARIOS_LOADING, payload }),
  onUsuariosListLoad: (payload) => ({ type: Types.USUARIOS_LIST_LOAD, payload }),
  onUsuariosListUpdate: (payload) => ({ type: Types.USUARIOS_LIST_UPDATE, payload }),
  onUsuariosListClean: () => ({ type: Types.USUARIOS_LIST_CLEAN }),
  onUsuariosFormUpdate: (payload) => ({ type: Types.USUARIOS_FORM_UPDATE, payload }),
  onUsuariosFormClean: () => ({ type: Types.USUARIOS_FORM_UPDATE, payload: initialState.form }),
  onUsuariosDataById: (id) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onUsuariosLoading({ find: true }))
    // Chamada API
    getUsuariosApi({ token, id }).then((payload) => {
      dispatch(Actions.onUsuariosLoading({ find: false }))
      dispatch(Actions.onUsuariosFormUpdate(_.isObject(payload) ? payload : initialState.form))
    })
  },
  onUsuariosDataList: (filter) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onUsuariosLoading({ list: true }))
    // Chamada API
    listUsuariosApi({ token, filter }).then((payload) => {
      dispatch(Actions.onUsuariosLoading({ list: false }))
      dispatch(Actions.onUsuariosListLoad(payload || initialState.list))
    })
  },
  onUsuariosDataSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    // Chamada API
    const usuarioDTO = {
      id: dto.id || 0,
      nome: dto.nome,
      email: dto.email,
      usuario: dto.usuario,
      senha: dto.senha,
      resenha: dto.resenha,
      status: dto.status,
      funcionariosId: dto.funcionarios.id,
      funcionariosDescricao: dto.funcionarios.descricao,
    }
    if (usuarioDTO.id) {
      dispatch(Actions.onUsuariosLoading({ edit: true }))
      editUsuariosApi({ token, dto: usuarioDTO, id: dto.id }).then((payload) => {
        dispatch(Actions.onUsuariosLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success(`Usuário ${dto.nome} atualizado com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    } else {
      dispatch(Actions.onUsuariosLoading({ add: true }))
      addUsuariosApi({ token, dto: usuarioDTO }).then((payload) => {
        dispatch(Actions.onUsuariosLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success(`Usuário ${dto.nome} cadastrado com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    }
  },
  onUsuariosDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onUsuariosLoading({ del: true }))
    // Chamada API
    deleteUsuariosApi({ token, id }).then((payload) => {
      dispatch(Actions.onUsuariosLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Usuário excluído com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    })
  },
  onUsuariosAddGrupos: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onUsuariosLoading({ add: true }))
    addUsuariosGruposApi({ token, dto }).then((payload) => {
      dispatch(Actions.onUsuariosLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'inserted') {
        NotificationManager.success('Grupo adicionado com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback(payload.entity)
        }
      }
    })
  },
  onUsuariosDelGrupos: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onUsuariosLoading({ del: true }))
    // Chamada API
    deleteUsuariosGruposApi({ token, dto }).then((payload) => {
      dispatch(Actions.onUsuariosLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Grupo excluído com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback(dto)
        }
      }
    })
  },
  onUsuariosAddFiliais: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onUsuariosLoading({ add: true }))
    addUsuariosFiliaisApi({ token, dto }).then((payload) => {
      dispatch(Actions.onUsuariosLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'inserted') {
        NotificationManager.success('Filial adicionada com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback(payload.entity)
        }
      }
    })
  },
  onUsuariosDelFiliais: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onUsuariosLoading({ del: true }))
    // Chamada API
    deleteUsuariosFiliaisApi({ token, dto }).then((payload) => {
      dispatch(Actions.onUsuariosLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Filial excluído com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback(dto)
        }
      }
    })
  },
  //* Troca de filial
  onUsuariosUpdateFilial: (filial, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token, user } },
    } = getState()
    dispatch(Actions.onUsuariosLoading({ updateFilial: true }))
    updateUsuariosFilialApi({
      token,
      id: user.id,
      dto: {
        pk: user.id,
        fk: filial,
      },
    }).then((resp) => {
      dispatch(Actions.onUsuariosLoading({ updateFilial: false }))
      if (resp && resp.status === 200 && resp.message === 'updated') {
        NotificationManager.success('Filial atualizada com sucesso!', 'Troca de filial', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    }).catch(() => {
      dispatch(Actions.onUsuariosLoading({ updateFilial: false }))
      if (_.isFunction(callback)) {
        callback()
      }
    })
  },
  //* Troca de senha
  onUsuariosUpdatePassword: (dto, onSuccess = null, onError = null) => (dispatch, getState) => {
    const {
      auth: { session: { token, user } },
    } = getState()
    passwordUsuariosApi({
      token,
      id: user.id,
      dto,
    }).then((resp) => {
      if (resp && resp.message === 'updated') {
        NotificationManager.success('Senha atualizada com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(onSuccess)) {
          onSuccess(resp)
        }
      }
    }).catch((err) => {
      if (_.isFunction(onSuccess)) {
        onError(err)
      }
    })
  },
}
