import React from 'react'
import PropTypes from 'prop-types'
import {
  Icon,
  Chip,
} from '@material-ui/core'

const ChipStatusPvComp = (props) => {
  const { pedidosVenda: { pedidosStatusId, pedidosStatusDescricao } } = props
  switch (pedidosStatusId) {
    case 2:
      return <Chip icon={<Icon>hourglass_empty</Icon>} label={pedidosStatusDescricao} />
    case 3:
      return <Chip icon={<Icon>beenhere</Icon>} label={pedidosStatusDescricao} />
    case 4:
      return <Chip icon={<Icon>cancel</Icon>} label={pedidosStatusDescricao} />
    default:
      return <Chip icon={<Icon>done</Icon>} label={pedidosStatusDescricao} />
  }
}

ChipStatusPvComp.propTypes = {
  pedidosVenda: PropTypes.shape().isRequired,
}

export default ChipStatusPvComp
