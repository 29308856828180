import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Grid, Paper, Tabs, Tab,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import _ from 'lodash'

// Actions
import { Actions as UsuariosActions } from 'store/ducks/usuarios'

// Api's
import { listFiliaisApi } from 'api/filiais'
import { listGruposApi } from 'api/grupos'

// Any components
import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import TableDataComp from 'components/Table'
import DialogComp from 'components/Dialogs'
import FormSearchComp from 'components/Forms/Search'
import UsuariosFormComp from './components/UsuariosForm'
// Styles
import useStyles from './styles'

const UsuariosPage = ({
  token,
  user,
  usuarios,
  onUsuariosDataList,
  onUsuariosFormUpdate,
  onUsuariosFormClean,
  onUsuariosDataSave,
  onUsuariosDataDelete,
  onUsuariosAddGrupos,
  onUsuariosDelGrupos,
  onUsuariosAddFiliais,
  onUsuariosDelFiliais,
}) => {
  const classes = useStyles()
  const [currentPage, setCurrentPage] = useState(0)
  const [search, setSearch] = useState('')
  const [dialogForm, setDialogForm] = useState({ open: false, title: '' })
  const [dialogFormSearch, setDialogFormSearch] = useState({ open: false, title: '' })
  const [dialogAskDelete, setDialogAskDelete] = useState({ open: false, title: '', data: null })
  const [dialogAskDeleteGrupos, setDialogAskDeleteGrupos] = useState({ open: false, title: '', data: null })
  const [dialogAskDeleteFiliais, setDialogAskDeleteFiliais] = useState({ open: false, title: '', data: null })
  const [tabSelected, setTabSelected] = useState(0)
  const [genericSearch, setGenericSearch] = useState({
    iptSearch: '', loading: false, selected: {}, data: [],
  })
  const [usuariosSelected, setUsuariosSelected] = useState({})

  // Header Actions >>
  const handleOpenCloseDialogForm = (open, title = null) => {
    if (title) {
      setDialogForm((s) => ({ ...s, open, title }))
    } else {
      setDialogForm((s) => ({ ...s, open }))
    }
  }
  const handleOpenCloseDialogFormSearch = (open, title = null) => {
    if (title) {
      setDialogFormSearch((s) => ({ ...s, open, title }))
    } else {
      setDialogFormSearch((s) => ({ ...s, open }))
    }
  }
  const handleOpenCloseDialogAskDelete = (open, title = null, data = null) => {
    setDialogAskDelete((s) => ({
      ...s, open, title, data,
    }))
  }
  const handleOpenCloseDialogAskDeleteGrupos = (open, title = null, data = null) => {
    setDialogAskDeleteGrupos((s) => ({
      ...s, open, title, data,
    }))
  }
  const handleOpenCloseDialogAskDeleteFiliais = (open, title = null, data = null) => {
    setDialogAskDeleteFiliais((s) => ({
      ...s, open, title, data,
    }))
  }

  // Header Actions <<
  // Generic Search Filials (add/edit) >>
  const handleGenericSearchClean = () => setGenericSearch({
    iptSearch: '', loading: false, selected: {}, data: [],
  })
  const handleGenericSearchLoadData = (data) => setGenericSearch((s) => ({ ...s, data }))
  const handleGenericSearchLoading = (loading) => setGenericSearch((s) => ({ ...s, loading }))
  const handleGenericSearchItemSelected = (itemSelected) => {
    if (_.isEmpty(usuariosSelected) || !tabSelected) {
      NotificationManager.warning('Não foi possível localizar o usuário ou a aba para adicionar.', 'Ops...', 8000)
      return
    }
    // Classificando a adição
    switch (tabSelected) {
      case 1:
        // Adicionar GRUPO ao USUÁRIO selecionado
        onUsuariosAddGrupos({ pk: usuariosSelected.id, fk: itemSelected.id }, ({ grupos = [] }) => {
          handleOpenCloseDialogFormSearch(false)
          onUsuariosFormUpdate({ grupos })
          setUsuariosSelected((u) => ({ ...u, grupos }))
        })
        break
      case 2:
        onUsuariosAddFiliais({
          pk: usuariosSelected.id, fk: itemSelected.id,
        }, ({ filiais = [] }) => {
          handleOpenCloseDialogFormSearch(false)
          onUsuariosFormUpdate({ filiais })
          setUsuariosSelected((u) => ({ ...u, filiais }))
        })
        break
      default:
      // console.log(itemSelected)
    }
  }
  const onSearchGrupos = (pSearch) => {
    handleGenericSearchLoading(true)
    listGruposApi({ token, filter: { search: pSearch } }).then((payload) => {
      handleGenericSearchLoading(false)
      if (payload && _.isArray(payload.data)) {
        const { data = [] } = payload
        handleGenericSearchLoadData(data.map(({ id, descricao }) => ({ id, descricao })))
      } else {
        handleGenericSearchLoadData([])
      }
    }).catch(() => handleGenericSearchLoading(true))
  }
  const onSearchFiliais = (pSearch) => {
    handleGenericSearchLoading(true)
    const { empresasId: empresas } = user.filiais.find((f) => f.id === user.filial)
    const filter = { empresas, search: pSearch }
    listFiliaisApi({
      token,
      filter,
    })
      .then((payload) => {
        handleGenericSearchLoading(false)
        if (payload && _.isArray(payload.data)) {
          const { data = [] } = payload
          handleGenericSearchLoadData(data.map(({ id, descricao }) => ({ id, descricao })))
        } else {
          handleGenericSearchLoadData([])
        }
      })
  }
  const handleGenericSearch = (pSearch) => {
    if (tabSelected === 1) {
      onSearchGrupos(pSearch)
    } else {
      onSearchFiliais(pSearch)
    }
  }
  // Generic Search <<

  // Table Actions >>
  // USUARIOS >>
  const handleSelectUsuarios = (pUsuarios) => {
    setUsuariosSelected(pUsuarios)
    setTabSelected(1)
  }
  const tableCols = [
    { key: 'nome', title: 'NOME', onClick: (row) => handleSelectUsuarios(row) },
    { key: 'usuario', title: 'USUÁRIO' },
    { key: 'email', title: 'E-MAIL' },
    { key: 'statusDescricao', title: 'STATUS' },
  ]
  const tableActions = [
    {
      key: 'editar',
      title: 'Editar',
      icon: 'edit',
      action: (row) => {
        onUsuariosFormUpdate(row)
        handleOpenCloseDialogForm(true, 'Edição de usuário')
      },
    },
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: (row) => handleOpenCloseDialogAskDelete(true, 'Excluir?', row),
    },
  ]
  // Table Usuários Actions <<
  const handleFiltraUsuarios = (filter) => {
    onUsuariosDataList(filter)
  }
  // USUARIOS <<

  // GRUPOS >>
  const tableGruposCols = [
    { key: 'codigo', title: 'CÓDIGO' },
    { key: 'descricao', title: 'DESCRIÇÃO' },
    { key: 'statusDescricao', title: 'STATUS' },
  ]
  const tableGruposActions = [
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: (row) => handleOpenCloseDialogAskDeleteGrupos(true, 'Excluir?', row),
    },
  ]
  // GRUPOS <<

  // FILIAIS >>
  const tableFiliaisCols = [
    { key: 'cnpj', title: 'CNPJ' },
    { key: 'descricao', title: 'DESCRIÇÃO' },
    { key: 'statusDescricao', title: 'STATUS' },
  ]
  const tableFiliaisActions = [
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: (row) => handleOpenCloseDialogAskDeleteFiliais(true, 'Excluir?', row),
    },
  ]
  // FILIAIS <<

  // Header Action
  const handleBtnAdd = () => {
    switch (tabSelected) {
      case 1:
        handleGenericSearchClean()
        handleOpenCloseDialogFormSearch(true, 'Cadastro de grupo')
        break
      case 2:
        handleGenericSearchClean()
        handleOpenCloseDialogFormSearch(true, 'Cadastro de filial')
        break
      default:
        onUsuariosFormClean()
        handleOpenCloseDialogForm(true, 'Cadastro de usuário')
    }
  }
  const headerButtons = [
    {
      title: 'Adicionar',
      icon: 'add',
      action: () => handleBtnAdd(),
    },
  ]

  // Tabs Actions >>
  const handleSelectTab = (event, newValue) => setTabSelected(newValue)
  const handleSearch = (pNewPage) => {
    switch (tabSelected) {
      case 1:
        // Grupos
        // handleFiltraEmpresas({ search })
        break
      case 2:
        // Filiais
        // handleFiltraFiliais({ search })
        break
      default:
        // Usuários
        handleFiltraUsuarios({ search, page: pNewPage })
    }
  }
  // Tabs Actions <<

  const handleChangePage = (event, value) => {
    const newPage = ((value ?? 1) - 1)
    setCurrentPage(newPage)
    handleSearch(newPage)
  }

  useEffect(() => {
    document.title = 'Backoffice - Usuários'
    handleFiltraUsuarios({ search })
  }, [])

  const usuariosDesc = tabSelected > 0 && !_.isEmpty(usuariosSelected)
    ? `[${usuariosSelected.id}] - ${usuariosSelected.nome}`
    : ''

  return (
    <DefaultPage>
      <PageHeaderComp
        title="Usuários"
        subtitle={usuariosDesc}
        value={search}
        onChange={(evt) => setSearch(evt.target.value)}
        onClick={handleSearch}
        onKeyPress={(evt) => {
          if (evt.charCode === 13) {
            handleSearch()
          }
        }}
        buttons={headerButtons}
      />
      <Grid container spacing={2}>
        {/* Abas */}
        <Grid item xs={12} md={12}>
          <Paper className={classes.root}>
            <Tabs
              value={tabSelected}
              onChange={handleSelectTab}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Usuários" />
              <Tab label="Grupos" disabled={_.isEmpty(usuariosSelected)} />
              <Tab label="Filiais" disabled={_.isEmpty(usuariosSelected)} />
            </Tabs>
          </Paper>
        </Grid>
        {/* Content */}
        <Grid item xs={12} md={12}>
          {/* Listagem das usuários */}
          {tabSelected === 0 && (
            <TableDataComp
              loading={usuarios.loading.list}
              size="small"
              rows={usuarios.list.data}
              cols={tableCols}
              actions={tableActions}
            />
          )}
          {/* Listagem das GRUPOS do usuário selecionado */}
          {tabSelected === 1 && !_.isEmpty(usuariosSelected) && (
            <TableDataComp
              loading={usuarios.loading.list}
              size="small"
              rows={usuariosSelected.grupos || []}
              cols={tableGruposCols}
              actions={tableGruposActions}
            />
          )}
          {/* Listagem das FILIAIS do usuário selecionado */}
          {tabSelected === 2 && !_.isEmpty(usuariosSelected) && (
            <TableDataComp
              loading={usuarios.loading.list}
              size="small"
              rows={usuariosSelected.filiais || []}
              cols={tableFiliaisCols}
              actions={tableFiliaisActions}
            />
          )}
        </Grid>
        {tabSelected === 0 && (
          <Grid item xs={12} md={12}>
            <Pagination
              count={usuarios?.loading?.list ? 1 : usuarios?.list?.total ?? 0}
              page={currentPage + 1}
              onChange={handleChangePage}
            />
          </Grid>
        )}
      </Grid>
      {/* DialogForm (add/edit) */}
      <DialogComp
        size="md"
        open={dialogForm.open}
        title={dialogForm.title}
        onClose={() => handleOpenCloseDialogForm(false)}
        actions={[
          {
            label: 'Cancelar',
            onClick: () => {
              onUsuariosFormClean()
              handleOpenCloseDialogForm(false)
            },
          },
          {
            label: 'Salvar',
            onClick: () => {
              onUsuariosDataSave(usuarios.form, () => {
                onUsuariosFormClean()
                handleOpenCloseDialogForm(false)
                handleFiltraUsuarios({ search })
              })
            },
          },
        ]}
      >
        <UsuariosFormComp token={token} data={usuarios.form} onChange={onUsuariosFormUpdate} />
      </DialogComp>
      {/* Dialog Buscas (Grupos/Filiais) */}
      <DialogComp
        open={dialogFormSearch.open}
        title={dialogFormSearch.title}
        onClose={() => handleOpenCloseDialogFormSearch(false)}
        actions={[
          {
            label: 'Cancelar',
            onClick: () => handleOpenCloseDialogFormSearch(false),
          },
        ]}
      >
        {/* FormSearch */}
        <FormSearchComp
          onSelectItem={handleGenericSearchItemSelected}
          onSearch={handleGenericSearch}
          searching={genericSearch.loading}
          data={genericSearch.data || []}
        />
      </DialogComp>
      {/* Dialog Ask DELETE (Usuários) */}
      <DialogComp
        open={dialogAskDelete.open}
        title={dialogAskDelete.title}
        onClose={() => handleOpenCloseDialogAskDelete(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDelete(false),
          },
          {
            label: 'SIM',
            onClick: () => onUsuariosDataDelete(dialogAskDelete.data.id, () => {
              handleOpenCloseDialogAskDelete(false)
              handleFiltraUsuarios({ search })
            }),
          },
        ]}
      >
        {`Você deseja realmente excluir o usuário "${dialogAskDelete.data ? dialogAskDelete.data.nome : ''}"?`}
      </DialogComp>
      {/* Dialog Ask DELETE (Grupos/Usuários) */}
      <DialogComp
        open={dialogAskDeleteGrupos.open}
        title={dialogAskDeleteGrupos.title}
        onClose={() => handleOpenCloseDialogAskDeleteGrupos(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDeleteGrupos(false),
          },
          {
            label: 'SIM',
            onClick: () => onUsuariosDelGrupos({
              pk: usuariosSelected.id,
              fk: dialogAskDeleteGrupos.data.id,
            }, (resp) => {
              handleOpenCloseDialogAskDeleteGrupos(false)
              const grupos = usuariosSelected.grupos.filter((g) => g.id !== resp.fk)
              setUsuariosSelected((u) => ({ ...u, grupos }))
              onUsuariosFormUpdate({ grupos })
            }),
          },
        ]}
      >
        {`Você deseja realmente excluir o grupo "${dialogAskDeleteGrupos.data ? dialogAskDeleteGrupos.data.descricao : ''}"?`}
      </DialogComp>
      {/* Dialog Ask DELETE (Grupos/Filiais) */}
      <DialogComp
        open={dialogAskDeleteFiliais.open}
        title={dialogAskDeleteFiliais.title}
        onClose={() => handleOpenCloseDialogAskDeleteFiliais(false)}
        actions={[
          {
            label: 'NÃO',
            onClick: () => handleOpenCloseDialogAskDeleteFiliais(false),
          },
          {
            label: 'SIM',
            onClick: () => onUsuariosDelFiliais({
              pk: usuariosSelected.id,
              fk: dialogAskDeleteFiliais.data.id,
            }, (resp) => {
              handleOpenCloseDialogAskDeleteFiliais(false)
              const filiais = usuariosSelected.filiais.filter((f) => f.id !== resp.fk)
              setUsuariosSelected((u) => ({ ...u, filiais }))
              onUsuariosFormUpdate({ filiais })
            }),
          },
        ]}
      >
        {`Você deseja realmente excluir a filial "${dialogAskDeleteFiliais.data ? dialogAskDeleteFiliais.data.descricao : ''}"?`}
      </DialogComp>
    </DefaultPage>
  )
}

UsuariosPage.propTypes = {
  token: PropTypes.string.isRequired,
  user: PropTypes.shape().isRequired,
  usuarios: PropTypes.shape().isRequired,
  onUsuariosDataList: PropTypes.func.isRequired,
  onUsuariosFormUpdate: PropTypes.func.isRequired,
  onUsuariosFormClean: PropTypes.func.isRequired,
  onUsuariosDataSave: PropTypes.func.isRequired,
  onUsuariosDataDelete: PropTypes.func.isRequired,
  onUsuariosAddGrupos: PropTypes.func.isRequired,
  onUsuariosDelGrupos: PropTypes.func.isRequired,
  onUsuariosAddFiliais: PropTypes.func.isRequired,
  onUsuariosDelFiliais: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  token: state.auth.session.token,
  user: state.auth.session.user,
  usuarios: state.usuarios,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...UsuariosActions,
  },
  dispatch,
)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsuariosPage)
