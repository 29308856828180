import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid, TextField, FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core'

// Styles
import useStyles from './styles'

// Stateless
const EmpresasFormComp = ({ data, onChange }) => {
  const classes = useStyles()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <TextField
          fullWidth
          id="empresas-cnpj"
          label="CNPJ"
          margin="normal"
          variant="outlined"
          type="number"
          inputProps={{
            maxlength: 14,
          }}
          value={data.cnpj}
          onChange={(evt) => onChange({ cnpj: evt.target.value.toUpperCase() })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl variant="outlined" fullWidth margin="normal" className={classes.dataControl}>
          <InputLabel ref={inputLabel}>
            Status
          </InputLabel>
          <Select
            id="empresas-status"
            value={data.status}
            onChange={(evt) => onChange({ status: evt.target.value.toUpperCase() })}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="L">LIBERADO</MenuItem>
            <MenuItem value="B">BLOQUEADO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="empresas-razao"
          label="RAZÃO"
          margin="normal"
          variant="outlined"
          value={data.razao}
          onChange={(evt) => onChange({ razao: evt.target.value.toUpperCase() })}
        />
      </Grid>

    </Grid>
  )
}

EmpresasFormComp.propTypes = {
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}

export default EmpresasFormComp
