/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react'
import { NotificationManager } from 'react-notifications'
import PropTypes from 'prop-types'
import {
  Grid, IconButton, Icon,
} from '@material-ui/core'
import _ from 'lodash'

import { addProdutosImagensApi, deleteProdutosImagensApi } from 'api/produtos/imagens'
import TableDataComp from 'components/Table'

// Styles
import useStyles from './styles'

// Stateless
const ProdutosImagensFormComp = ({ token, data, onCallback }) => {
  const { produtos, imagens } = data
  const classes = useStyles()

  const onHandleDeleteImage = (id) => {
    deleteProdutosImagensApi({ token, id }).then((resp) => {
      if (resp?.status === 200 && resp?.message === 'deleted') {
        NotificationManager.success(`A imagem foi excluída do produto ${produtos?.descricao ?? ''} com sucesso!`, 'Sucesso!', 8000)
        if (_.isFunction(onCallback)) {
          onCallback()
        }
      }
    })
  }
  const onHandleUploadImage = (evt) => {
    evt.preventDefault()
    const formData = new FormData()
    formData.append('id', produtos.id)
    formData.append('file', evt.target[0].files[0])
    formData.append('type', evt.target[1].value)
    addProdutosImagensApi({ token, formData }).then((resp) => {
      if (resp?.status === 200 && resp?.message === 'inserted') {
        NotificationManager.success(`A imagem foi adicionada ao produto ${produtos?.decricao ?? ''} com sucesso!`, 'Sucesso!', 8000)
        if (_.isFunction(onCallback)) {
          onCallback()
        }
      }
    })
  }
  const tableCols = [
    { key: 'type', title: 'TIPO' },
    { key: 'description', title: 'DESCRIÇÃO' },
    { key: 'mimeType', title: 'MIME-TYPE', align: 'right' },
    {
      key: '', title: '', align: 'right', format: (r) => (<IconButton onClick={() => window.open(r.uri)}><Icon>visibility</Icon></IconButton>),
    },
  ]
  const tableActions = [
    {
      key: 'excluir',
      title: 'Excluir',
      icon: 'delete',
      action: (row) => onHandleDeleteImage(row.id),
    },
  ]
  useEffect(() => {
  }, [])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <form className={classes.ctnUpload} onSubmit={onHandleUploadImage}>
          <input
            accept="image/*"
            id="ipt-upload"
            type="file"
          />
          <div className={classes.ctnUploadActions}>
            <label htmlFor="type-thumbnail">Thumbnail</label>
            <input type="radio" id="type-thumbnail" name="type" value="thumbnail" defaultChecked />
            <label htmlFor="type-normal">Normal</label>
            <input type="radio" id="type-normal" name="type" value="normal" />
            <input type="submit" value="Enviar" />
          </div>
        </form>
      </Grid>
      <Grid item xs={12} md={12}>
        <TableDataComp
          // loading={produtos.loading.list}
          size="small"
          rows={imagens ?? []}
          cols={tableCols}
          actions={tableActions}
        />
      </Grid>
    </Grid>
  )
}

ProdutosImagensFormComp.propTypes = {
  token: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  onCallback: PropTypes.func.isRequired,
}

export default ProdutosImagensFormComp
