import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { Grid, Icon, Typography } from '@material-ui/core'
import {
  ComposedChart,
  Bar,
  Line,
  Cell,
  Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
} from 'recharts'

// Util's
import {
  parseToNum, MoneyFormatBr, mesByNum, getSemestre,
} from 'utils'

// Actions
import { Actions as DashboardActions } from 'store/ducks/dashboard'

// Vendor Components
import Card from 'components/Vendor/CreativeTim/Card/Card'
import CardHeader from 'components/Vendor/CreativeTim/Card/CardHeader'
import CardIcon from 'components/Vendor/CreativeTim/Card/CardIcon'
import CardFooter from 'components/Vendor/CreativeTim/Card/CardFooter'
// import Danger from 'components/Vendor/CreativeTim/Typography/Danger'

import DefaultPage from 'components/DefaultPage'
import PageHeaderComp from 'components/PageHeader'
import useStyles from './styles'

// Stateless
const DashboardPage = ({ session, dashboard, onDashboardDataLoad }) => {
  const classes = useStyles()

  // Semestre
  const { dataInicial, dataFinal } = getSemestre()

  // DADOS para o Dashboard: Model 1
  const quantNovosClientes = parseToNum(dashboard?.clientes?.quantNovosClientes || '0')
  const quantNovosClientesFiliais = parseToNum(dashboard?.clientes?.quantNovosClientesFiliais || '0')
  const quantOsInMonitoramento = parseToNum(dashboard?.ordensservico?.quantOsInMonitoramento || '0')
  const avgTempoVidaPrevisto = parseToNum(dashboard?.ordensservico?.avgTempoVidaPrevisto || '')
  const avgTempoVidaRealizado = parseToNum(dashboard?.ordensservico?.avgTempoVidaRealizado || '')
  const sumFaturamentoDia = parseToNum(dashboard?.faturamentos?.sumFaturamentoDia || '')
  const metaFaturamentoDia = parseToNum(dashboard?.faturamentos?.metaFaturamentoDia || '')
  const faturamentoPorPeriodo = (dashboard?.faturamentos?.faturamentoPorPeriodo || '')
  const top5 = (dashboard?.servicos?.top5 || [])

  // Gráfico de faturamento
  const mesesFat = _.uniq(faturamentoPorPeriodo.map((m) => m.mes))
  const anoPassado = moment().years() - 1
  const anoAtual = moment().years()
  const data = mesesFat
    .sort((a, b) => a.mes < b.mes)
    .map((m) => {
      const totalPassado = faturamentoPorPeriodo.find(
        (s) => s.mes === m && s.ano === anoPassado,
      )
      const totalAtual = faturamentoPorPeriodo.find((s) => s.mes === m && s.ano === anoAtual)
      return {
        name: mesByNum(m),
        passado: parseToNum(totalPassado ? totalPassado.total : 0),
        metaPassado: parseToNum(totalPassado ? totalPassado.meta : 0),
        atual: parseToNum(totalAtual ? totalAtual.total : 0),
        meta: parseToNum(totalAtual ? totalAtual.meta : 0),
      }
    })
    // Descrição do serviço com no maximo 28 caracteres
  const dataPie = top5.map((s) => ({ name: s.descricao.substr(0, 20), value: s.quant }))
  const colorsPie = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FA4568']
  const radianPie = Math.PI / 180
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    outerRadius,
    percent,
    // innerRadius,
    // index,
  }) => {
    const radius = outerRadius * 1.1
    const x = cx + radius * Math.cos(-midAngle * radianPie)
    const y = cy + radius * Math.sin(-midAngle * radianPie)
    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  renderCustomizedLabel.propTypes = {
    cx: PropTypes.number.isRequired,
    cy: PropTypes.number.isRequired,
    midAngle: PropTypes.number.isRequired,
    outerRadius: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }

  // Similar to componentDidMount and componentDidUpdate:
  /*
  useEffect(() => {
    document.title = 'Backoffice - Dashboard'
    onDashboardDataLoad({
      filiaisId: session.user.filial,
      usuariosId: session.user.id,
      dataInicial,
      dataFinal,
    })
  }, [])
  */

  useEffect(() => {
    document.title = 'Backoffice - Dashboard'
    onDashboardDataLoad({
      filiaisId: session?.user?.filial || 0,
      usuariosId: session?.user?.id || 0,
      dataInicial,
      dataFinal,
    })
  }, [session?.user?.filial])

  return (
    <DefaultPage>
      <PageHeaderComp title="Dashboard" />
      <Grid container spacing={2}>
        {/* Faturado Hoje */}
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Icon>attach_money</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Faturado Hoje</p>
              <Typography variant="h5" gutterBottom className={classes.cardTitle}>
                {`R$ ${MoneyFormatBr(sumFaturamentoDia)}`}
              </Typography>
              <p className={classes.cardCategory}>
                {`Meta diária R$ ${MoneyFormatBr(metaFaturamentoDia)}`}
              </p>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Icon>sentiment_satisfied_alt</Icon>
                Soma das O.S pagas hoje
              </div>
            </CardFooter>
          </Card>
        </Grid>
        {/* Tempo de O.S */}
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>av_timer</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Tempo de O.S</p>
              <Typography variant="h5" gutterBottom className={classes.cardTitle}>
                {`${avgTempoVidaRealizado}min`}
              </Typography>
              <p className={classes.cardCategory}>
                {`${avgTempoVidaPrevisto}min (previsão)`}
              </p>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Icon>alarm</Icon>
                Média tempo fechamento (O.S/MÊS)
              </div>
            </CardFooter>
          </Card>
        </Grid>
        {/* Monitoramento */}
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>assignment</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Monitoramento</p>
              <Typography variant="h5" gutterBottom className={classes.cardTitle}>
                {`${quantOsInMonitoramento} O.S`}
              </Typography>
              <p className={classes.cardCategory}>&nbsp;</p>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Icon>bookmark</Icon>
                Abertas, Fechadas ou Pagas
              </div>
            </CardFooter>
          </Card>
        </Grid>
        {/* Novos clientes (filial vs rede) */}
        <Grid item xs={12} md={3}>
          <Card>
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <Icon>person</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Novos clientes (filial)</p>
              <Typography variant="h5" gutterBottom className={classes.cardTitle}>
                {quantNovosClientesFiliais}
              </Typography>
              <p className={classes.cardCategory}>
                {`Novos clientes (rede) ${quantNovosClientes}`}
              </p>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Icon>calendar_today</Icon>
                Novos clientes mês (Filial vs Rede)
              </div>
            </CardFooter>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {/* Faturamento por periodo */}
        <Grid item xs={6} md={6}>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
              <ComposedChart
                data={data}
                margin={{
                  top: 30,
                  right: 80,
                  left: 0,
                  bottom: 30,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="name">
                  <Label
                    value="Faturamento por periodo"
                    offset={0}
                    position="bottom"
                  />
                </XAxis>
                <YAxis />
                <Tooltip />
                <Legend verticalAlign="top" align="right" height={36} />
                <Bar dataKey="passado" fill="#82ca9d" name={anoPassado} />
                <Bar dataKey="atual" fill="#8884d8" name={anoAtual} />
                <Line
                  type="monotone"
                  dataKey="metaPassado"
                  name={`Meta ${anoPassado}`}
                  stroke="#00C49F"
                />
                <Line
                  type="monotone"
                  dataKey="meta"
                  name={`Meta ${anoAtual}`}
                  stroke="#ff7300"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </Grid>
        {/* Grafico de pizza de SERVIÇOS */}
        <Grid item xs={6} md={6}>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={dataPie}
                  cx={140}
                  cy={140}
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {dataPie.map((entry, index) => (
                    <Cell
                      // eslint-disable-next-line react/no-array-index-key
                      key={`cell-${index}`}
                      fill={colorsPie[index % colorsPie.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend
                  layout="vertical"
                  verticalAlign="top"
                  align="right"
                  height={12}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Grid>
      </Grid>
    </DefaultPage>
  )
}

DashboardPage.propTypes = {
  session: PropTypes.shape().isRequired,
  dashboard: PropTypes.shape().isRequired,
  onDashboardDataLoad: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  session: state.auth.session,
  dashboard: state.dashboard.model1,
})

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    ...DashboardActions,
  },
  dispatch,
)

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
