import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Grid, TextField, FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core'

import { calculateBusinessDays, StringDateIsoToDate } from 'utils'

// Styles
import useStyles from './styles'

// Stateless
const MetasFormComp = ({ data, onChange }) => {
  const classes = useStyles()
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  const handleCalcValorDiario = (valorTotal = 0, quantDias = 0) => (valorTotal / quantDias).toFixed(2)
  const handleCalcQuantDias = (dataInicio, dataFim, valorTotal) => {
    let valorDiario = 0
    const {
      days = 0,
      workingDays = 0,
      sundays = 0,
      saturdays = 0,
    } = calculateBusinessDays(StringDateIsoToDate(dataInicio), StringDateIsoToDate(dataFim))
    if (workingDays && valorTotal) {
      valorDiario = handleCalcValorDiario(+valorTotal, +workingDays)
    }
    // Update da porra toda
    onChange({ quantDias: workingDays, valorDiario })
  }
  const handleQuantDiasChange = (quantDias = 0, valorTotal = 0) => {
    const valorDiario = handleCalcValorDiario(+valorTotal, +quantDias)
    if (quantDias > 0 && quantDias <= 31) {
      onChange({ quantDias, valorDiario })
    } else {
      onChange({ quantDias: 0, valorDiario: 0 })
    }
  }
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [])
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={4}>
        <TextField
          fullWidth
          id="filiaisMetas-metaInicio"
          label="Meta Início"
          margin="normal"
          variant="outlined"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={data.metaInicio}
          onChange={(evt) => onChange({ metaInicio: evt.target.value.toUpperCase() })}
          onBlur={() => handleCalcQuantDias(data.metaInicio, data.metaFim, data.valorTotal)}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <TextField
          fullWidth
          id="filiaisMetas-metaFim"
          label="Meta Fim"
          margin="normal"
          variant="outlined"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={data.metaFim}
          onChange={(evt) => {
            onChange({ metaFim: evt.target.value.toUpperCase() })
            handleCalcQuantDias(data.metaInicio, evt.target.value.toUpperCase(), data.valorTotal)
          }}
          // onBlur={() => handleCalcQuantDias(data.metaInicio, data.metaFim, data.valorTotal)}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <TextField
          fullWidth
          disabled={!data.metaInicio || !data.metaFim}
          id="filiaisMetas-quantDias"
          label="Dias"
          margin="normal"
          variant="outlined"
          type="number"
          value={data.quantDias}
          onChange={(evt) => handleQuantDiasChange(evt.target.value, data.valorTotal)}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <TextField
          fullWidth
          disabled={!data.quantDias || +data.quantDias === 0 || data.quantDias > 31}
          id="filiaisMetas-valorTotal"
          label="Valor Meta"
          margin="normal"
          variant="outlined"
          type="number"
          value={data.valorTotal || ''}
          onChange={(evt) => {
            onChange({ valorTotal: evt.target.value })
            handleQuantDiasChange(data.quantDias, evt.target.value)
          }}
          onKeyPress={(evt) => {
            if (evt.charCode === 13) {
              handleQuantDiasChange(data.quantDias, data.valorTotal)
              // handleCalcQuantDias(data.metaInicio, data.metaFim, data.valorTotal)
            }
          }}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <TextField
          fullWidth
          disabled
          id="filiaisMetas-valorDiario"
          label="Valor Diario"
          margin="normal"
          variant="outlined"
          type="number"
          value={data.valorDiario || ''}
          // onChange={(evt) => onChange({ valorDiario: evt.target.value })}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl variant="outlined" fullWidth margin="normal" className={classes.dataControl}>
          <InputLabel ref={inputLabel}>
            Status
          </InputLabel>
          <Select
            id="empresas-status"
            value={data.status}
            onChange={(evt) => onChange({ status: evt.target.value.toUpperCase() })}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>SELECIONE</em>
            </MenuItem>
            <MenuItem value="L">LIBERADO</MenuItem>
            <MenuItem value="B">BLOQUEADO</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

MetasFormComp.propTypes = {
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}

export default MetasFormComp
