import _ from 'lodash'
import { NotificationManager } from 'react-notifications'
import {
  listCategoriasApi,
  getCategoriasByIdApi,
  addCategoriasApi,
  editCategoriasApi,
  deleteCategoriasApi,
} from 'api/categorias'

// State
const initialState = {
  loading: {
    list: false,
    find: false,
    add: false,
    edit: false,
    del: false,
  },
  list: { page: 0, rows: 0, data: [] },
  form: {
    id: 0,
    ref: '',
    sku: '',
    ncm: '',
    um: '',
    preco: '',
    precoPromo: '',
    peso: '',
    qtdMin: '',
    descricao: '',
    status: '',
  },
}

// Actions Types
export const Types = {
  RESET: 'reset',
  CATEGORIAS_LOADING: 'categorias/LOADING',
  CATEGORIAS_LIST_LOAD: 'categorias/LIST_LOAD',
  CATEGORIAS_LIST_UPDATE: 'categorias/LIST_UPDATE',
  CATEGORIAS_LIST_CLEAN: 'categorias/LIST_CLEAN',
  CATEGORIAS_FORM_UPDATE: 'categorias/FORM_UPDATE',
}

// Reducers
export default function produtosReducers(state = initialState, action) {
  switch (action.type) {
    // RESET DEFAULT
    case Types.RESET:
      return {
        ...state,
        ...initialState,
      }
    case Types.CATEGORIAS_LOADING:
      return {
        ...state,
        loading: { ...state.loading, ...action.payload },
      }
    case Types.CATEGORIAS_LIST_LOAD:
      return {
        ...state,
        list: action.payload,
      }
    case Types.CATEGORIAS_LIST_UPDATE:
      return {
        ...state,
        list: state.list.map((i) => (i.id === action.payload.id ? { ...i, ...action.payload } : i)),
      }
    case Types.CATEGORIAS_LIST_CLEAN:
      return {
        ...state,
        list: initialState.list,
      }
    case Types.CATEGORIAS_FORM_UPDATE:
      return {
        ...state,
        form: { ...state.form, ...action.payload },
      }
    default:
      return state
  }
}

// Actions Creators
export const Actions = {
  // Store Update
  onCategoriasLoading: (payload) => ({ type: Types.CATEGORIAS_LOADING, payload }),
  onCategoriasListLoad: (payload) => ({ type: Types.CATEGORIAS_LIST_LOAD, payload }),
  onCategoriasListUpdate: (payload) => ({ type: Types.CATEGORIAS_LIST_UPDATE, payload }),
  onCategoriasListClean: () => ({ type: Types.CATEGORIAS_LIST_CLEAN }),
  onCategoriasFormUpdate: (payload) => ({ type: Types.CATEGORIAS_FORM_UPDATE, payload }),
  onCategoriasFormNew: (payload) => ({
    type: Types.CATEGORIAS_FORM_UPDATE, payload: { ...initialState.form, ...payload },
  }),
  onCategoriasFormClean: () => ({
    type: Types.CATEGORIAS_FORM_UPDATE,
    payload: initialState.form,
  }),
  onCategoriasDataById: (id) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onCategoriasLoading({ find: true }))
    // Chamada API
    getCategoriasByIdApi({ token, id }).then((payload) => {
      dispatch(Actions.onCategoriasLoading({ find: false }))
      dispatch(Actions.onCategoriasFormUpdate(_.isObject(payload) ? payload : initialState.form))
    })
  },
  onCategoriasDataList: (filter) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onCategoriasLoading({ list: true }))
    // Chamada API
    listCategoriasApi({ token, filter }).then((payload) => {
      dispatch(Actions.onCategoriasLoading({ list: false }))
      dispatch(Actions.onCategoriasListLoad(payload || initialState.list))
    })
  },
  onCategoriasDataSave: (dto, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    // Chamada API
    if (dto.id) {
      dispatch(Actions.onCategoriasLoading({ edit: true }))
      editCategoriasApi({ token, dto, id: dto.id }).then((payload) => {
        dispatch(Actions.onCategoriasLoading({ edit: false }))
        if (payload && payload.status === 200 && payload.message === 'updated') {
          NotificationManager.success(`Categoria ${dto.descricao} atualizada com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    } else {
      dispatch(Actions.onCategoriasLoading({ add: true }))
      addCategoriasApi({ token, dto }).then((payload) => {
        dispatch(Actions.onCategoriasLoading({ add: false }))
        if (payload && payload.status === 200 && payload.message === 'inserted') {
          NotificationManager.success(`Categoria ${dto.descricao} cadastrada com sucesso!`, 'Sucesso!', 8000)
          if (_.isFunction(callback)) {
            callback()
          }
        }
      })
    }
  },
  onCategoriasDataDelete: (id, callback = null) => (dispatch, getState) => {
    const {
      auth: { session: { token } },
    } = getState()
    dispatch(Actions.onCategoriasLoading({ del: true }))
    // Chamada API
    deleteCategoriasApi({ token, id }).then((payload) => {
      dispatch(Actions.onCategoriasLoading({ del: false }))
      if (payload && payload.status === 200 && payload.message === 'deleted') {
        NotificationManager.success('Categoria excluída com sucesso!', 'Sucesso!', 8000)
        if (_.isFunction(callback)) {
          callback()
        }
      }
    })
  },
}
